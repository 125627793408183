import React from 'react';

export type SavedFilterLookup = {
  [dataset: string]: SavedFilter[] | undefined;
};

export type SavedFilterIdLookup = {
  [datasetFilterId: string]: SavedFilter | undefined;
};

interface SavedFiltersContextType {
  savedFilters: SavedFilter[];
  getDataTypeSavedFilters: (dataTypes: string[]) => SavedFilter[];
  savedFilterLookup: SavedFilterLookup;
  savedFilterIdLookup: SavedFilterIdLookup;
}

const SavedFiltersContext = React.createContext<SavedFiltersContextType>({
  savedFilters: [],
  getDataTypeSavedFilters: () => [],
  savedFilterLookup: {},
  savedFilterIdLookup: {},
});

export default SavedFiltersContext;
