import React from 'react';

interface CustomerLaneCommitsContextType {
  drillDowns: FixedFilter[];
  selectedLane?: string;
  commitmentSlideoutParams: CommitmentSlideoutParams;
  setCommitmentSlideoutParams: React.Dispatch<
    React.SetStateAction<CommitmentSlideoutParams>
  >;
}

const CustomerLaneCommitsContext =
  React.createContext<CustomerLaneCommitsContextType>(
    {} as CustomerLaneCommitsContextType,
  );

export default CustomerLaneCommitsContext;
