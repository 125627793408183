import React from 'react';

export interface Breadcrumb {
  name: string;
  url?: string;
  id?: string;
}
interface BreadcrumbsContextType {
  recordBreadcrumb: (
    eventType: Analytics.EventType,
    eventProperties: { [key: string]: string | undefined },
  ) => void;
  breadcrumbs: Breadcrumb[];
}

const AnalyticsContext = React.createContext<BreadcrumbsContextType>({
  recordBreadcrumb: () => {},
  breadcrumbs: [],
} as BreadcrumbsContextType);

export default AnalyticsContext;
