import { useContext } from 'react';
import { PortalsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import portalTypeCheckers from '../types/portalTypeCheckers';

const OnlyAdminPortal = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isPortalsEnabled, selectedPortal } = useContext(PortalsContext);
  if (!isPortalsEnabled) {
    return <>{children}</>;
  }

  if (selectedPortal && portalTypeCheckers.isAdminPortal(selectedPortal)) {
    return <>{children}</>;
  }

  return null;
};

export default OnlyAdminPortal;
