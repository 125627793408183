import React, { ChangeEvent } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import styled from 'styled-components';
import Inputs from 'components/Inputs';

import { Label } from '../../../MyAccount/Profile/styles';
import Button from 'kingpin/atoms/Button';
import FilterPlates from '../../../FilterPlates';
import ReportSelector from '../../../ConfigureDashboardGadget/ReportSelector';
import TextInput from '../../../../kingpin/atoms/TextInput';
import Typography from 'kingpin/atoms/Typography';

const ModalWrapper = styled.div`
  padding: 8px 16px;
`;

const Form = ({
  name,
  onNameChanged,
  createNewMetricItem,
  metricId,
  setMetricId,
  reportDrillDownId,
  setReportDrillDownId,
  boardDrillDownId,
  setBoardDrillDownId,
  setMetricDisplayName,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  createNewMetricItem: () => void;
  metricId?: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  reportDrillDownId?: string;
  setReportDrillDownId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  boardDrillDownId?: string;
  setBoardDrillDownId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMetricDisplayName?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const isValid = name !== '' && !!metricId;

  return (
    <div>
      <h2>Add Metric</h2>
      <TextInput label={'Name'} value={name} onChange={onNameChanged} />
      <ReportSelector
        label="Drill down"
        reportId={reportDrillDownId}
        setReportId={setReportDrillDownId}
        boardId={boardDrillDownId}
        setBoardId={setBoardDrillDownId}
        isBlockInput
        metricId={metricId}
        key={metricId || Math.random()}
      />
      <div style={{ marginBottom: 24 }}>
        <Label>
          <Typography.Body type="Label">Metric</Typography.Body>
        </Label>
        <Inputs.MetricPicker
          metricId={metricId}
          setMetricId={setMetricId}
          setMetricDisplayName={setMetricDisplayName}
        />
      </div>
      <div style={{ marginBottom: 24 }}>
        <Label>
          <Typography.Body type="Label">Run time filters:</Typography.Body>
        </Label>
        <FilterPlates />
      </div>
      <div style={{ marginBottom: 12 }}>
        <Label>
          <Typography.Body type="Label">Scope:</Typography.Body>
        </Label>
        <FilterPlates isScope />
      </div>
      <div style={{ marginTop: 42 }}>
        <Button
          onClick={createNewMetricItem}
          isDisabled={!isValid}
          type="Primary"
          size="Small"
          label="Add"
        />
      </div>
    </div>
  );
};

const AddMetricButton = ({
  isOpen,
  open,
  close,
  name,
  onNameChanged,
  createNewMetricItem,
  metricId,
  setMetricId,
  reportDrillDownId,
  setReportDrillDownId,
  boardDrillDownId,
  setBoardDrillDownId,
  setMetricDisplayName,
}: {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  createNewMetricItem: () => void;
  metricId?: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  reportDrillDownId?: string;
  setReportDrillDownId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  boardDrillDownId?: string;
  setBoardDrillDownId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMetricDisplayName?: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <React.Fragment>
    {isOpen && (
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <ModalWrapper>
              <Form
                metricId={metricId}
                setMetricId={setMetricId}
                onNameChanged={onNameChanged}
                name={name}
                createNewMetricItem={createNewMetricItem}
                reportDrillDownId={reportDrillDownId}
                setReportDrillDownId={setReportDrillDownId}
                boardDrillDownId={boardDrillDownId}
                setBoardDrillDownId={setBoardDrillDownId}
                setMetricDisplayName={setMetricDisplayName}
              />
            </ModalWrapper>
          </Modal>
        )}
      </ModalTransition>
    )}

    <Button
      onClick={isOpen ? close : open}
      type="Secondary"
      size="Small"
      label="Add Metric"
    />
  </React.Fragment>
);

export default AddMetricButton;
