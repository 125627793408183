import { useCallback, useContext } from 'react';
import _ from 'lodash';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';
import useGetIsUsedInMetricId from './useGetIsUsedInMetricId';

const useGetPerformanceUsage = () => {
  const { performanceConfigs } = useContext(DatasetDefinitionsContext);
  const getIsUsedInMetricId = useGetIsUsedInMetricId();

  const getPerformanceUsage = useCallback(
    (filter: SavedFilter): { name: string; id: string }[] => {
      const usage: { name: string; id: string }[] = [];

      performanceConfigs.forEach((c) => {
        if (
          c.metrics.some((m) =>
            getIsUsedInMetricId({ filter, metricId: m.metricId }),
          )
        ) {
          usage.push({
            name: c.name,
            id: c.id,
          });
        }
      });

      return _.uniqBy(usage, 'id');
    },
    [getIsUsedInMetricId, performanceConfigs],
  );

  return getPerformanceUsage;
};

export default useGetPerformanceUsage;
