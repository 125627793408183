import React, { ChangeEvent } from 'react';
import DatePicker from 'components/AtlassianTemp/DateTimePicker';
import { DateTime } from 'luxon';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';
import FilterPlates from '../../FilterPlates';
import Inputs from '../../Inputs';
import MultiUserSelect from '../../MultiUserSelect';
import KPIInputs from './KPIInputs';
import DatePickerIconFix from '../../DatePickerIconFix';
import isoDateToAmerican from '../../../isoDateToAmerican';
import AdvancedTargetInput from './KpiForm/AdvancedTargetInput';
import DateFieldPicker from './KpiForm/DateFieldPicker';
import Form from 'kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';
import { DefaultFiltersToggleForMetricId } from '../../DefaultFiltersToggle';

const GeneralGoalForm = ({
  metricId,
  setMetricId,
  title,
  onTitleChange,
  targetValue,
  setTargetValue,
  aboveTargetIsGoodOptions,
  visualiseAsOptions,
  kpis,
  setKpis,
  cadence,
  cadenceOptions,
  fixedStartDate,
  setFixedStartDate,
  fixedEndDate,
  setFixedEndDate,
  accountableId,
  setAccountableId,
  collaboratorIds,
  setCollaboratorIds,
  isValid,
  isLoading,
  close,
  onSubmit,
  isEditing,
  collaboratorNames,
  accountableName,
  targetMode,
  setTargetMode,
  advancedTarget,
  setAdvancedTarget,
  dataTypes,
  dateField,
  setDateField,
}: {
  metricId?: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  title: string;
  onTitleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  targetValue?: number;
  setTargetValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  aboveTargetIsGoodOptions: RadioOption[];
  visualiseAsOptions: RadioOption[];
  kpis: GoalKPI[];
  setKpis: React.Dispatch<React.SetStateAction<GoalKPI[]>>;
  cadence: FleetOps.Interval;
  cadenceOptions: RadioOption[];
  fixedStartDate: string;
  setFixedStartDate: (d: string) => void;
  fixedEndDate: string;
  setFixedEndDate: (d: string) => void;
  accountableId: string[];
  setAccountableId: React.Dispatch<React.SetStateAction<string[]>>;
  collaboratorIds: string[];
  setCollaboratorIds: React.Dispatch<React.SetStateAction<string[]>>;
  isValid: boolean;
  isLoading: boolean;
  close: () => void;
  onSubmit: () => void;
  isEditing: boolean;
  collaboratorNames: string;
  accountableName: string;
  targetMode?: 'basic' | 'advanced';
  setTargetMode: React.Dispatch<
    React.SetStateAction<'basic' | 'advanced' | undefined>
  >;
  advancedTarget: GoalAdvancedTarget;
  setAdvancedTarget: React.Dispatch<React.SetStateAction<GoalAdvancedTarget>>;
  dataTypes: string[];
  dateField?: string;
  setDateField: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => (
  <Form>
    <FormHeader
      title={isEditing ? 'Update General Goal' : 'Create General Goal'}
      onClose={close}
    />
    <FormContent>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Goal Title</Typography.Body>
        </div>
        <Inputs.TextInput
          data-testid="title-input"
          value={title}
          onChange={onTitleChange}
          placeholder={'Enter a title for your goal'}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Set a goal on</Typography.Body>
        </div>
        <Inputs.MetricPicker setMetricId={setMetricId} metricId={metricId} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Filters</Typography.Body>
        </div>
        <FilterPlates isDashboardCard testIdPrefix={'general'} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <DefaultFiltersToggleForMetricId metricId={metricId} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Query Date Field</Typography.Body>
        <DateFieldPicker
          dataTypes={dataTypes}
          dateField={dateField}
          setDateField={setDateField}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Goal starting on</Typography.Body>
        </div>
        <DatePickerIconFix>
          <DatePicker
            innerProps={{
              style: {
                width: '100%',
                border: '1px solid #dfe1e5',
                borderRadius: 3,
              },
            }}
            value={fixedStartDate}
            onChange={setFixedStartDate}
            dateFormat="MMM DD 'YY"
            placeholder={isoDateToAmerican(
              DateTime.local().startOf('month').toISODate(),
            )}
            appearance="subtle"
            testId={'start-date'}
          />
        </DatePickerIconFix>
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Goal to be achieved by</Typography.Body>
        </div>
        <DatePickerIconFix>
          <DatePicker
            innerProps={{
              style: {
                width: '100%',
                border: '1px solid #dfe1e5',
                borderRadius: 3,
              },
            }}
            value={fixedEndDate}
            onChange={setFixedEndDate}
            dateFormat="MMM DD 'YY"
            placeholder={isoDateToAmerican(
              DateTime.local().startOf('month').plus({ year: 1 }).toISODate(),
            )}
            appearance="subtle"
            testId={'end-date'}
          />
        </DatePickerIconFix>
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Goal Interval</Typography.Body>
        </div>
        <Inputs.Radio options={cadenceOptions} />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Goal Value</Typography.Body>
        </div>
        <AdvancedTargetInput
          target={targetValue}
          setTarget={setTargetValue}
          cadence={cadence}
          targetMode={targetMode}
          setTargetMode={setTargetMode}
          advancedTarget={advancedTarget}
          setAdvancedTarget={setAdvancedTarget}
          startDate={fixedStartDate}
          endDate={fixedEndDate}
          testIdPrefix={'general'}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Above target is</Typography.Body>
        </div>
        <Inputs.Radio options={aboveTargetIsGoodOptions} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Visualize goal as</Typography.Body>
        </div>
        <Inputs.Radio options={visualiseAsOptions} />
      </div>

      <div style={{ marginBottom: 24 }}>
        <KPIInputs
          kpis={kpis}
          setKpis={setKpis}
          cadence={cadence}
          startDate={fixedStartDate}
          endDate={fixedEndDate}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Accountable</Typography.Body>
        </div>
        <MultiUserSelect
          selectedUsers={accountableId}
          setSelectedUsers={setAccountableId}
          isSingle
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Collaborators</Typography.Body>
        </div>
        <MultiUserSelect
          selectedUsers={collaboratorIds}
          setSelectedUsers={setCollaboratorIds}
        />
      </div>
    </FormContent>
    <Row style={{ justifyContent: 'flex-end' }}>
      <Button
        testId={'save-goal'}
        isDisabled={!isValid}
        onClick={onSubmit}
        isLoading={isLoading}
        label={isEditing ? 'Update' : 'Create'}
        type="Primary"
        size="Small"
      />
    </Row>
  </Form>
);

export default GeneralGoalForm;
