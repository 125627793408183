import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import withoutNulls from './search/withoutNulls';
import cardTypeCheckers from '../types/cardTypeCheckers';
import { DASHBOARDS_COLLECTION } from '../constants';

const deleteImages = async (
  dashId: string,
  newDashboard: DashboardType,
  accountRef: firebase.firestore.DocumentReference,
) => {
  const dashDoc = await accountRef
    .collection(DASHBOARDS_COLLECTION)
    .doc(dashId)
    .get();
  const dash = dashDoc.data() as DashboardType;
  const oldImageCards = dash.canvas.cards.filter(cardTypeCheckers.isImage);
  const newImageCards = newDashboard.canvas.cards.filter(
    cardTypeCheckers.isImage,
  );
  const imagesToDelete = oldImageCards.filter((oldC) => {
    const matching = newImageCards.find(
      (newC) => newC.content.imageSrc === oldC.content.imageSrc,
    );
    return !matching;
  });

  const deletionRequests = imagesToDelete.map((c) =>
    firebase
      .storage()
      .ref(c.content.imageSrc)
      .delete()
      .catch(() => {
        // do nothing
      }),
  );
  await Promise.all(deletionRequests);
};

const deleteVideos = async (
  dashId: string,
  newDashboard: DashboardType,
  accountRef: firebase.firestore.DocumentReference,
) => {
  const dashDoc = await accountRef
    .collection(DASHBOARDS_COLLECTION)
    .doc(dashId)
    .get();
  const dash = dashDoc.data() as DashboardType;
  const oldVideoCards = dash.canvas.cards.filter(cardTypeCheckers.isVideo);
  const newVideoCards = newDashboard.canvas.cards.filter(
    cardTypeCheckers.isVideo,
  );
  const videosToDelete = oldVideoCards.filter((oldC) => {
    const matching = newVideoCards.find(
      (newC) => newC.content.videoSrc === oldC.content.videoSrc,
    );
    return !matching;
  });

  const deletionRequests = videosToDelete.map((c) =>
    firebase
      .storage()
      .ref(c.content.videoSrc)
      .delete()
      .catch(() => {
        // do nothing
      }),
  );
  await Promise.all(deletionRequests);
};

const updateDashboard = async ({
  id,
  newDashboard,
  accountRef,
}: {
  id: string;
  newDashboard: DashboardType;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  await deleteImages(id, newDashboard, accountRef);
  await deleteVideos(id, newDashboard, accountRef);
  return accountRef
    .collection(DASHBOARDS_COLLECTION)
    .doc(id)
    .set(withoutNulls(newDashboard));
};

export default updateDashboard;
