import { useCallback, useContext } from 'react';
import _ from 'lodash';
import ChartDefinitionsContext from 'contexts/ChartDefinitionsContext';
import ReportsContext from 'contexts/ReportsContext';
import cardTypeCheckers from 'types/cardTypeCheckers';
import captureException from 'services/captureException';
import useGetIsUsedInMetricId from './useGetIsUsedInMetricId';
import isFilterUsedInChart from '../isFilterUsedInChart';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';

const useGetReportUsage = () => {
  const { definitionsLookup } = useContext(ChartDefinitionsContext);
  const { allReports } = useContext(ReportsContext);
  const getIsUsedInMetricId = useGetIsUsedInMetricId();

  const getReportUsage = useCallback(
    (filter: SavedFilter): FleetOps.MetricReportUsage[] => {
      const usage: FleetOps.MetricReportUsage[] = [];
      allReports.forEach((r) => {
        if (
          r.drillDowns?.find(
            (plate) =>
              filterPlateTypeCheckers.isSavedFilter(plate) &&
              plate.datasetFilterId === filter.id,
          ) ||
          r.scope.find(
            (plate) =>
              filterPlateTypeCheckers.isSavedFilter(plate) &&
              plate.datasetFilterId === filter.id,
          )
        ) {
          usage.push({
            id: r.id,
            reportId: r.id,
            reportName: r.name,
          });
        }

        r.canvas.cards.forEach((card) => {
          if (cardTypeCheckers.isChartDefinition(card)) {
            const def = definitionsLookup[card.content.chartDefinitionId];
            if (!def) {
              return;
            }
            if (isFilterUsedInChart(filter, def, getIsUsedInMetricId)) {
              usage.push({
                id: `${def.id}-${r.id}`,
                gadgetName: def.name,
                gadgetId: def.id,
                reportId: r.id,
                reportName: r.name,
              });
            }
          } else {
            const error = new Error();
            error.name = `Unknown card type on report: ${r.id} (${card.content.type})`;
            captureException(error);
          }
        });
      });

      return _.uniqBy(usage, 'id');
    },
    [allReports, definitionsLookup, getIsUsedInMetricId],
  );

  return getReportUsage;
};

export default useGetReportUsage;
