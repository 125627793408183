import React, { useCallback } from 'react';
import CompoundMetricsContext from '../../../contexts/CompoundMetricsContext';
import useCompoundMetrics from '../../../hooks/useCompoundMetrics';

const CompoundMetricsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const {
    compoundMetrics: allCompoundMetrics,
    isLoadingCompoundMetrics: isLoading,
    compoundMetricInputsLookup,
  } = useCompoundMetrics();

  const getCompoundMetricWithMetricDefs = useCallback(
    (
      compoundMetricId: string,
    ): Metrics.CompoundMetricWithMetricDefs | undefined => {
      const cm = allCompoundMetrics.find((m) => m.id === compoundMetricId);
      const l = compoundMetricInputsLookup[compoundMetricId];
      if (!l || !cm) {
        return undefined;
      }

      return {
        id: compoundMetricId,
        name: cm.name,
        metrics: l,
        expression: cm.expression,
      };
    },
    [allCompoundMetrics, compoundMetricInputsLookup],
  );

  return (
    <CompoundMetricsContext.Provider
      value={{
        allCompoundMetrics,
        compoundMetricInputsLookup,
        getCompoundMetricWithMetricDefs,
        isLoading,
      }}
    >
      {children}
    </CompoundMetricsContext.Provider>
  );
};

export default CompoundMetricsProvider;
