import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import DateInputContext from '../../contexts/DateInputContext';
import buildDateRange from './buildDateRange';
import AccountContext from '../../contexts/AccountContext';
import useSelectedBonusPeriod from '../useSelectedBonusPeriod';
import FilterPlatesContext from '../../contexts/FilterPlatesContext';
import PeriodsContext from '../../contexts/PeriodsContext';
import GridContext from '../../contexts/GridContext';
import CardContext from '../../contexts/CardContext';
import WeekStartsOnOverrideContext from '../../contexts/WeekStartsOnOverrideContext';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import PopupContext from 'contexts/PopupContext';
import filterPlateTypeCheckers from '../../types/filterPlateTypeCheckers';
import filterTypeCheckers from '../../components/FilterPlateForm/filterTypeCheckers';
import FilterPlateFormContext from '../../contexts/FilterPlateFormContext';

const applyDateField = (
  dateRangeInput: DateRangeInput,
  dateField?: string,
): DateRangeInput => {
  return {
    ...dateRangeInput,
    dateField,
  };
};

const DEFAULT_DATE_SCOPE = {};

const useDateScope = ({
  comparison,
  isMetricListItem,
}: {
  comparison?: PersistedComparisonType;
  isMetricListItem?: boolean;
}) => {
  const { chartDefinition: chartDef } = useContext(CardContext);
  const { dateScopeOverride } = useContext(GridContext);
  const { bonusPeriods, selectedBonusPeriod } = useSelectedBonusPeriod();
  const { weekStartsOn: accountWeekStartsOn, timezone } =
    useContext(AccountContext);
  const { weekStartsOnOverride } = useContext(WeekStartsOnOverrideContext);
  const weekStartsOn = weekStartsOnOverride
    ? weekStartsOnOverride
    : accountWeekStartsOn;
  const { relativeDateRange, dateRange, advancedRelativeDateRange, dateField } =
    useContext(DateInputContext);
  const { drillDowns } = useContext(FilterPlatesContext);
  const { selectedPeriod, periods } = useContext(PeriodsContext);
  const { poppedUpKpi, selectedReport, selectedBoard } =
    useContext(PopupContext);
  const { isDateScopeDisabled } = useContext(FilterPlateFormContext);

  const buildDateScope = useCallback(() => {
    if (isDateScopeDisabled) {
      return DEFAULT_DATE_SCOPE;
    }
    if (dateScopeOverride) {
      return dateScopeOverride;
    }
    const dateDrill = drillDowns.find(
      (d) =>
        filterPlateTypeCheckers.isFixed(d) &&
        filterTypeCheckers.isDateFilter(d.fixedValue) &&
        !!d.fixedValue.dateRangeValues,
    );

    const overrideDateRange = (() => {
      if (
        !dateDrill ||
        !chartDef ||
        !filterPlateTypeCheckers.isFixed(dateDrill) ||
        (filterTypeCheckers.isDateFilter(dateDrill.fixedValue) &&
          !dateDrill.fixedValue.dateRangeValues)
      ) {
        return undefined;
      }

      const isV5WithDateDimension =
        isV5ChartDef(chartDef) &&
        chartDef.dimensionA &&
        chartDef.dimensionA.fieldType === 'date';

      if (isV5WithDateDimension || isSingleMetricDateMatrix(chartDef)) {
        const chartTrendingInterval =
          isV5ChartDef(chartDef) &&
          chartDef.trendByCalendarInterval &&
          chartDef.trendByCalendarInterval;
        const plateTrendingInterval =
          dateDrill && dateDrill.fixedValue && dateDrill.fixedValue.field;
        const isLastDrillDate =
          drillDowns[drillDowns.length - 1].id === dateDrill.id;
        if (
          isLastDrillDate &&
          chartTrendingInterval === plateTrendingInterval
        ) {
          return undefined;
        }
      }

      if (
        !filterTypeCheckers.isDateFilter(dateDrill.fixedValue) ||
        !dateDrill.fixedValue.dateRangeValues
      ) {
        return undefined;
      }
      return dateDrill.fixedValue.dateRangeValues[0].value;
    })();

    return applyDateField(
      buildDateRange({
        relativeDateRange: overrideDateRange ? undefined : relativeDateRange,
        dateRange: overrideDateRange ? overrideDateRange : dateRange,
        advancedRelativeDateRange: overrideDateRange
          ? undefined
          : advancedRelativeDateRange,
        comparison,
        weekStartsOn,
        chartDef,
        isMetricListItem,
        selectedBonusPeriod,
        bonusPeriods,
        selectedPeriod,
        periods,
        timezone,
        isScorecardPopupReport: !!poppedUpKpi && !!selectedReport,
        isUpcomingEventsBoard:
          !!selectedBoard && selectedBoard.dataType === 'upcomingEvents',
      }),
      dateField,
    );
  }, [
    isDateScopeDisabled,
    dateScopeOverride,
    drillDowns,
    relativeDateRange,
    dateRange,
    advancedRelativeDateRange,
    comparison,
    weekStartsOn,
    chartDef,
    isMetricListItem,
    selectedBonusPeriod,
    bonusPeriods,
    selectedPeriod,
    periods,
    timezone,
    poppedUpKpi,
    selectedReport,
    selectedBoard,
    dateField,
  ]);

  const [dateScope, setDateScope] = useState<DateRangeInput>(() =>
    buildDateScope(),
  );

  useEffect(() => {
    const newDateScope = buildDateScope();
    if (!_.isEqual(newDateScope, dateScope)) {
      setDateScope(newDateScope);
    }
  }, [buildDateScope, dateScope]);

  return dateScope;
};

export default useDateScope;
