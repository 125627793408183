import React from 'react';
import * as Highchart from 'highcharts';
import chartColors from '../../theme/chartColors';
import deselectedColor from './deselectedColor';
import epochToIsoDate from '../../epochToIsoDate';
import moment from 'moment';
import filterTypeCheckers from '../../components/FilterPlateForm/filterTypeCheckers';

const buildSyncDateChart = ({
  chartType,
  isLastDrillForField,
  chartRef,
  drillDowns,
  interval,
}: {
  chartType?: string;
  isLastDrillForField?: boolean;
  chartRef?: React.MutableRefObject<any>;
  drillDowns: FixedFilter[];
  interval: FleetOps.Interval | undefined;
}) => {
  return (drillDown?: FixedFilter) => {
    const lastDrill = drillDowns[drillDowns.length - 1];

    const hasBeenEdited = (point: Highchart.Point, chartType?: string) => {
      if (chartType === 'treeMap') {
        // @ts-ignore
        return point.color !== chartColors[point.index];
      } else {
        return point.color !== chartColors[point.colorIndex || 0];
      }
    };

    // Do not allow anything to be in the light gray 'selected' state
    if (chartRef && chartRef.current) {
      const c = chartRef.current.chart as Highchart.Chart | undefined;
      if (c === undefined) {
        return;
      }
      c.getSelectedSeries().forEach((s) => {
        s.select(false);
      });

      c.getSelectedPoints().forEach((s) => {
        s.select(false);
      });
    }

    // Reset colors if this chart shouldn't be highlighted
    if (!isLastDrillForField || !(drillDown || lastDrill)) {
      if (
        !chartRef ||
        !chartRef.current ||
        (chartRef.current.chart as Highchart.Chart).series.length === 0
      ) {
        return;
      }
      const chart = chartRef.current.chart as Highchart.Chart;
      chart.series.forEach((s) => {
        s.data
          .filter((d) => hasBeenEdited(d, chartType))
          .forEach((d) => {
            // @ts-ignore
            const i = chartType === 'treeMap' ? d.index : d.colorIndex;
            const color = chartColors[i || 0];
            if (d.color !== color) {
              d.update({ color }, false);
            }
          });
      });
      chart.redraw();

      return;
    }

    // Highlight the selected colors
    if (chartRef && chartRef.current) {
      const chart = chartRef.current.chart as Highchart.Chart;
      chart.series.forEach((s) => {
        s.data.forEach((d) => {
          const colorIndex = (() => {
            if (chartType === 'treeMap') {
              // @ts-ignore
              return d.index;
            }
            return d.colorIndex;
          })();
          const isSelected = (() => {
            const currentDateRangeValues = (() => {
              if (drillDown) {
                if (filterTypeCheckers.isDateFilter(drillDown)) {
                  return drillDown.dateRangeValues;
                }
                return [];
              }

              if (lastDrill && filterTypeCheckers.isDateFilter(lastDrill)) {
                return lastDrill.dateRangeValues;
              }

              return [];
            })();
            if (chartType === 'treeMap' || chartType === 'pie') {
              return (currentDateRangeValues || [])
                .map((d) => d.label)
                .includes(d.name.toString());
            }
            if (typeof d.category === 'string') {
              return (currentDateRangeValues || [])
                .map((d) =>
                  interval === 'hour'
                    ? moment(d.value.startDate).format('YYYY-MM-DD HH:mm:ss')
                    : d.value.startDate,
                )
                .includes(d.category);
            } else {
              return (currentDateRangeValues || [])
                .map((d) => d.label)
                .includes(epochToIsoDate(d.category));
            }
          })();

          const color = isSelected
            ? chartColors[colorIndex || 0]
            : deselectedColor;
          if (d.color !== color) {
            d.update({ color }, false);
          }
        });
      });
      chart.redraw();
    }
  };
};

export default buildSyncDateChart;
