import { useCallback, useContext } from 'react';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import RolesContext from '../../../../../contexts/RolesContext';
import ImpersonatorContext from '../../../../../contexts/ImpersonatorContext';

const useGetHasExecutivePortalAccess = () => {
  const { currentPermissions } = useContext(RolesContext);
  const currentUser = useContext(CurrentUserContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const getHasPortalAccess = useCallback(
    (executivePortal: ExecutivePortal) => {
      const currentUserId = impersonatorId ? impersonatorId : currentUser.id;
      const isManager = executivePortal.managerIds.some(
        (mid) => mid === currentUserId,
      );
      const isAdmin = currentPermissions.some(
        (p) => p === 'fleetops.permissions.content_access_view_all_admin_only',
      );

      const hasAccess = isManager || isAdmin;
      return hasAccess;
    },
    [currentPermissions, currentUser.id, impersonatorId],
  );

  return getHasPortalAccess;
};

export default useGetHasExecutivePortalAccess;
