/* eslint-disable no-undef */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Amplitude from 'amplitude-js';

import AnalyticsContext from '../../../contexts/AnalyticsContext';
import captureException from '../../../services/captureException';
import env from '../../../.env.json';
import { create } from 'apisauce';
import {
  addProblemMonitor,
  setAuthorizationToken,
} from '../../../services/buildCloudFunctionClient';
import useTrackFocusUsage from '../../../hooks/useTrackFocusUsage';
import VersionContext from '../../../contexts/VersionContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import AccountContext from '../../../contexts/AccountContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import USER_ROLES from '../../../roles';
import BreadcrumbsContext from '../../../contexts/BreadcrumbsContext';

const client = Amplitude.getInstance();

const createApi = (accountId: string, buildNumber?: number) => {
  const api = create({
    baseURL: env.USER_ANALYTICS_API,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'x-account-id': accountId,
      'x-build-number': buildNumber,
    },
  });
  setAuthorizationToken(api);
  addProblemMonitor(api);
  return api;
};

const WALLBOARD_USER_EVENT_WHITELIST = [
  'Wallboard - Started',
  'Wallboard - Stopped',
  'App - Automatically Updated',
];

interface UserAnalyticsInfo {
  id?: string;
  email?: string;
  isWallboardUser?: boolean;
  isAdmin?: boolean;
  displayName?: string;
  carrierName?: string;
  carrierId?: string;
  isAccountOnBoarded?: boolean;
  buildNumber?: number;
  signedUpOn?: string;
  isViewer: boolean;
  isEditor: boolean;
  isBoardOwner: boolean;
  isCampaignOwner: boolean;
}

const useAnalyticsInfo = () => {
  const { buildNumber } = useContext(VersionContext);
  const { id, email, isWallboardUser, displayName, createdOn } =
    useContext(CurrentUserContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const { carrierName, isAccountOnBoarded } = useContext(AccountContext);
  const [analyticsInfo, setAnalyticsInfo] = useState<
    UserAnalyticsInfo | undefined
  >();

  useEffect(() => {
    const isEditor = selectedAccount.roles.includes(USER_ROLES.EDITOR);
    const isAdmin = selectedAccount.roles.includes(USER_ROLES.ADMIN);
    const isViewer = selectedAccount.roles.includes(USER_ROLES.VIEWER);
    const isBoardOwner = selectedAccount.roles.includes(USER_ROLES.BOARD_OWNER);
    const isCampaignOwner = selectedAccount.roles.includes(
      USER_ROLES.CAMPAIGN_OWNER,
    );

    const newInfo: UserAnalyticsInfo = {
      id,
      email,
      isWallboardUser,
      isAdmin,
      isEditor,
      isViewer,
      isBoardOwner,
      isCampaignOwner,
      displayName,
      carrierName,
      carrierId: selectedAccount.accountId,
      isAccountOnBoarded,
      buildNumber,
      signedUpOn: createdOn,
    };

    setAnalyticsInfo(newInfo);
  }, [
    buildNumber,
    carrierName,
    displayName,
    email,
    id,
    isAccountOnBoarded,
    isWallboardUser,
    selectedAccount.roles,
    createdOn,
    selectedAccount.accountId,
  ]);

  return analyticsInfo;
};

const AnalyticsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { selectedAccount } = useContext(AccountPickerContext);
  const analyticsInfo = useAnalyticsInfo();
  const { buildNumber } = useContext(VersionContext);
  const [api, setApi] = useState(
    createApi(selectedAccount.accountId, buildNumber),
  );
  const { recordBreadcrumb } = useContext(BreadcrumbsContext);
  const [isReadyToTrack, setIsReadyToTrack] = useState<boolean>(false);
  const [isTracking, setIsTracking] = useState<{
    amplitude: boolean;
    hotJar: boolean;
    intercom: boolean;
    userSessions: boolean;
    vitally: boolean;
  }>({
    amplitude: false,
    hotJar: false,
    intercom: false,
    userSessions: false,
    vitally: false,
  });

  useEffect(() => {
    setApi(createApi(selectedAccount.accountId, buildNumber));
  }, [selectedAccount, buildNumber]);

  useEffect(() => {
    if (!analyticsInfo) {
      return;
    }
    setIsReadyToTrack(
      (analyticsInfo.carrierName !== undefined &&
        analyticsInfo.carrierName !== '' &&
        analyticsInfo.email !== '' &&
        analyticsInfo.email !== undefined &&
        analyticsInfo.isAccountOnBoarded !== undefined) ||
        !!analyticsInfo.isWallboardUser,
    );
  }, [analyticsInfo]);

  // set window.logTrackingEvents via query param
  // useful for debugging on page load
  useEffect(() => {
    if (window.location.search.includes('logTrackingEvents')) {
      // @ts-ignore
      window.logTrackingEvents = true;
    }
  }, []);

  const [unAuthenticatedEventBacklog, setUnAuthenticatedEventBacklog] =
    useState<
      {
        eventType: Analytics.EventType;
        eventProperties?: { [key: string]: string | undefined };
      }[]
    >([]);

  const initialDebuggingLog = (
    eventType: Analytics.EventType,
    eventProperties?: { [key: string]: string | undefined },
  ) => {
    if (window.logTrackingEvents) {
      console.log('Tracking event:');
      console.log(eventType);
      if (eventProperties) {
        console.log(eventProperties);
      }
    }
  };

  const logDebuggingEvent = (message: string) => {
    if (window.logTrackingEvents) {
      console.log(message);
    }
  };

  const recordInHotjar = useCallback((event: string, data: object) => {
    try {
      window.hj('event', event, data);
    } catch (ex) {
      captureException(ex);
    }
  }, []);

  const trackEvent = useCallback(
    (
      eventType: Analytics.EventType,
      baseEventProperties?: { [key: string]: string | undefined },
    ) => {
      const eventProperties: { [key: string]: string | undefined } =
        baseEventProperties
          ? { ...baseEventProperties, url: window.location.href }
          : { url: window.location.href };

      recordBreadcrumb(eventType, eventProperties);

      if (
        !analyticsInfo ||
        window.location.origin === 'http://localhost:3000'
      ) {
        return;
      }

      initialDebuggingLog(eventType, eventProperties);

      if (
        (window.location.href.includes('/wallBoard/') ||
          window.location.href.includes('/wallBoardConfig/')) &&
        (eventType === 'Dashboard - Opened' || eventType === 'Report - Opened')
      ) {
        // Do not track these events when within a wallboard preview
        return;
      }

      if (analyticsInfo.isWallboardUser) {
        if (!WALLBOARD_USER_EVENT_WHITELIST.includes(eventType)) {
          return;
        }
      }

      if (!isReadyToTrack) {
        // User is not logged in yet - store for later
        setUnAuthenticatedEventBacklog((b) => [
          ...b,
          { eventType, eventProperties },
        ]);
        return;
      }

      if (isTracking.userSessions && process.env.NODE_ENV !== 'test') {
        api
          .post('/', {
            eventType,
            eventProperties,
            userProperties: analyticsInfo,
          })
          .then((response) => {
            logDebuggingEvent(`User sessions: ${eventType}`);
            if (!response.ok) {
              const error = new Error();
              error.name = `UserSessions:${response.status} @ ${analyticsInfo.carrierName}`;
              captureException(error);
            }
          });
      }

      if (isTracking.amplitude) {
        client.setUserProperties({
          ...analyticsInfo,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        });
        client.logEvent(eventType, eventProperties);
        logDebuggingEvent(`Amplitude: ${eventType}`);
      }

      if (isTracking.intercom && process.env.NODE_ENV !== 'test') {
        try {
          // @ts-ignore
          window.Intercom('trackEvent', eventType, eventProperties);
          logDebuggingEvent(`Intercom: ${eventType}`);
        } catch (ex) {
          captureException(ex);
        }
      }

      if (isTracking.vitally && process.env.NODE_ENV !== 'test') {
        try {
          // @ts-ignore
          Vitally.track({
            event: eventType,
            userId: analyticsInfo.id,
            properties: eventProperties ? eventProperties : {}, // required
          });
          logDebuggingEvent(`Vitally: ${eventType}`);
        } catch (ex) {
          captureException(ex);
        }
      }
    },
    [
      analyticsInfo,
      api,
      isReadyToTrack,
      isTracking.amplitude,
      isTracking.intercom,
      isTracking.userSessions,
      isTracking.vitally,
      recordBreadcrumb,
    ],
  );
  useTrackFocusUsage(trackEvent);

  useEffect(() => {
    if (unAuthenticatedEventBacklog.length > 0) {
      const event = unAuthenticatedEventBacklog[0];
      setUnAuthenticatedEventBacklog((b) => [...b.slice(1)]);
      setTimeout(() => {
        trackEvent(event.eventType, event.eventProperties);
      }, 1000);
    }
  }, [
    trackEvent,
    unAuthenticatedEventBacklog,
    unAuthenticatedEventBacklog.length,
  ]);

  useEffect(() => {
    if (!analyticsInfo || process.env.NODE_ENV === 'test') {
      return;
    }
    if (analyticsInfo.isWallboardUser) {
      setIsTracking({
        userSessions: false,
        amplitude: true,
        intercom: false,
        vitally: false,
        hotJar: false,
      });
      return;
    }

    if (isReadyToTrack && !!analyticsInfo.email) {
      if (analyticsInfo.email.includes('@fleetops.com')) {
        setIsTracking({
          userSessions: env.ENV === 'Staging',
          amplitude: false,
          intercom: false,
          vitally: false,
          hotJar: false,
        });
      } else {
        setIsTracking({
          userSessions: true,
          amplitude: true,
          intercom: true,
          vitally: true,
          hotJar: true,
        });
      }
    }
  }, [isReadyToTrack, analyticsInfo]);

  useEffect(() => {
    if (isTracking.amplitude) {
      client.init('7512c28666ed087d117e311fbcdd84a6');
    }
  }, [isTracking.amplitude]);

  useEffect(() => {
    if (!analyticsInfo) {
      return;
    }
    if (isTracking.amplitude && analyticsInfo.email) {
      client.setUserId(analyticsInfo.email.toLowerCase());
    }
  }, [analyticsInfo, isTracking.amplitude]);

  useEffect(() => {
    if (!analyticsInfo) {
      return;
    }

    if (isReadyToTrack) {
      if (isTracking.amplitude) {
        client.setUserProperties(analyticsInfo);
      }

      if (isTracking.hotJar) {
        try {
          window.hj('identify', analyticsInfo.id, analyticsInfo);
        } catch (ex) {
          captureException(ex);
        }
      }

      if (
        isTracking.vitally &&
        !!analyticsInfo.carrierId &&
        process.env.NODE_ENV !== 'test'
      ) {
        try {
          // Vitally
          // @ts-ignore
          Vitally.account({
            accountId: analyticsInfo.carrierId,
            traits: {
              name: analyticsInfo.carrierName,
            },
          });

          // @ts-ignore
          Vitally.user({
            userId: analyticsInfo.id,
            accountId: analyticsInfo.carrierId,
            traits: {
              name: analyticsInfo.displayName,
              email: analyticsInfo.email,
              isViewer: analyticsInfo.isViewer,
              isEditor: analyticsInfo.isEditor,
              isAdmin: analyticsInfo.isAdmin,
              isBoardOwner: analyticsInfo.isBoardOwner,
              isCampaignOwner: analyticsInfo.isCampaignOwner,
            },
          });
        } catch (ex) {
          captureException(ex);
        }
      }
    }
  }, [
    analyticsInfo,
    buildNumber,
    isReadyToTrack,
    isTracking.amplitude,
    isTracking.hotJar,
    isTracking.vitally,
  ]);

  const updateVitallyAccountTrait = useCallback(
    ({
      traitName,
      traitValue,
    }: {
      traitName: string;
      traitValue: string | boolean;
    }) => {
      if (!analyticsInfo || process.env.NODE_ENV === 'test') {
        return;
      }

      // @ts-ignore
      Vitally.account({
        accountId: analyticsInfo.carrierId,
        traits: {
          [traitName]: traitValue,
        },
      });
    },
    [analyticsInfo],
  );

  return (
    <AnalyticsContext.Provider
      value={{ trackEvent, updateVitallyAccountTrait, recordInHotjar }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
