import React, { useCallback, useContext, useEffect, useState } from 'react';

import FilterPlatesContext from '../../contexts/FilterPlatesContext';
import buildSyncKeywordChart from './buildSyncKeywordChart';
import buildSyncDateChart from './buildSyncDateChart';
import buildOnDateDrillDown from './buildOnDateDrillDown';
import buildOnKeywordDrillDown from './buildOnKeywordDrillDown';
import buildOnRangeDrillDown from './buildOnRangeDrillDown';
import getIsHistogram from './getIsHistogram';
import getIsRangeChart from './getIsRangeChart';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import usePopupCallback from '../usePopupCallback';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import useDateScope from '../useDateScope';

const useChartDrillDowns = (
  field: string,
  chartRef?: React.MutableRefObject<HighchartsReactRefObject | null>,
  chartType?: GadgetType | 'SingleMetricDateMatrix' | 'PaceMatrix',
  chartDef?: V5ChartDefinition,
  autoInterval?: AutoInterval,
) => {
  const { setDrillDowns, drillDownsAsFixed } = useContext(FilterPlatesContext);
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [isLastDrillForField, setIsLastDrillForField] =
    useState<boolean>(false);
  const maybeOpenPopupReport = usePopupCallback(dashboardGadget);

  const isHistogram = getIsHistogram(chartDef);
  const isRangeChart = getIsRangeChart(chartDef);
  const dateScope = useDateScope({});

  useEffect(() => {
    const lastDrill = drillDownsAsFixed[drillDownsAsFixed.length - 1];
    if (autoInterval) {
      const shouldSync =
        drillDownsAsFixed.length > 0 &&
        lastDrill.field === autoInterval.interval;
      setIsLastDrillForField(shouldSync);
    } else {
      const shouldSync =
        drillDownsAsFixed.length > 0 &&
        lastDrill.field === field &&
        !lastDrill.isExclude;
      setIsLastDrillForField(shouldSync);
    }
  }, [autoInterval, field, drillDownsAsFixed]);

  const track = useCallback(() => {
    trackEvent('Report - Card - Filtering Interaction');
  }, [trackEvent]);

  const syncKeywordChart = useCallback(
    (drillDown?: FixedFilter) =>
      buildSyncKeywordChart({
        isLastDrillForField,
        drillDowns: drillDownsAsFixed,
        isRangeChart,
        chartDef,
        chartRef,
        chartType,
      })(drillDown),
    [
      isLastDrillForField,
      drillDownsAsFixed,
      isRangeChart,
      chartDef,
      chartRef,
      chartType,
    ],
  );

  const interval = autoInterval
    ? autoInterval.interval
    : chartDef && chartDef.trendByCalendarInterval;

  const syncDateChart = useCallback(
    (drillDown?: FixedFilter) =>
      buildSyncDateChart({
        chartType,
        isLastDrillForField,
        chartRef,
        drillDowns: drillDownsAsFixed,
        interval,
      })(drillDown),
    [chartType, isLastDrillForField, chartRef, drillDownsAsFixed, interval],
  );

  const onDateDrillDown = useCallback(
    (term: string, ctrlKey?: boolean) =>
      buildOnDateDrillDown({
        chartDef,
        drillDowns: drillDownsAsFixed,
        field,
        setDrillDowns,
        isLastDrillForField,
        autoInterval,
        maybeOpenPopupReport,
        track,
        dateScope,
      })(term, ctrlKey),
    [
      chartDef,
      dateScope,
      drillDownsAsFixed,
      field,
      setDrillDowns,
      isLastDrillForField,
      autoInterval,
      maybeOpenPopupReport,
      track,
    ],
  );

  const onKeywordDrillDown = useCallback(
    (term: string, ctrlKey?: boolean) =>
      buildOnKeywordDrillDown({
        chartDef,
        drillDowns: drillDownsAsFixed,
        field,
        setDrillDowns,
        isLastDrillForField,
        maybeOpenPopupReport,
        track,
      })(term, ctrlKey),
    [
      chartDef,
      drillDownsAsFixed,
      field,
      setDrillDowns,
      isLastDrillForField,
      maybeOpenPopupReport,
      track,
    ],
  );

  const onRangeDrillDown = useCallback(
    (term: string) =>
      buildOnRangeDrillDown({
        chartDef,
        drillDowns: drillDownsAsFixed,
        field,
        setDrillDowns,
        isLastDrillForField,
        maybeOpenPopupReport,
        track,
      })(term),
    [
      chartDef,
      drillDownsAsFixed,
      field,
      setDrillDowns,
      isLastDrillForField,
      maybeOpenPopupReport,
      track,
    ],
  );

  if (!chartDef) {
    return {
      onDrillDown: onKeywordDrillDown,
      syncChart: syncKeywordChart,
      isLastDrillForField,
    };
  }

  const onDrillDown = (() => {
    if (isRangeChart) {
      return onRangeDrillDown;
    }

    if (isHistogram) {
      return onDateDrillDown;
    }

    return onKeywordDrillDown;
  })();

  const syncChart = (() => {
    if (isRangeChart) {
      return syncKeywordChart;
    }

    if (isHistogram) {
      return syncDateChart;
    }

    return syncKeywordChart;
  })();

  return {
    onDrillDown,
    syncChart,
    isLastDrillForField,
  };
};

export default useChartDrillDowns;
