import React, { useCallback, useState } from 'react';
import KpiSlideOutContext from '../contexts/KpiSlideOutContext';

const SlideOutProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [slideoutKpi, setSlideoutKpi] = useState<
    Scorecards.ScorecardKpi | Scorecards.ManualKpiRow
  >();
  const [slideoutPeriod, setSlideoutPeriod] = useState<Period>();
  const [slideoutTrend, setSlideoutTrend] = useState<Goals.TrendResult[]>([]);
  const [slideoutMetric, setSlideoutMetric] = useState<Metrics.Metric>();

  const open = useCallback(
    ({
      kpi,
      period,
      trend,
      metric,
    }: {
      kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
      period: Period;
      trend: Goals.TrendResult[];
      metric?: Metrics.Metric;
    }) => {
      setSlideoutKpi(kpi);
      setSlideoutPeriod(period);
      setSlideoutTrend(trend);
      setSlideoutMetric(metric);
      setIsOpen(true);
    },
    [],
  );

  const close = useCallback(() => {
    setIsOpen(false);
    setSlideoutKpi(undefined);
    setSlideoutPeriod(undefined);
    setSlideoutTrend([]);
    setSlideoutMetric(undefined);
  }, []);

  return (
    <KpiSlideOutContext.Provider
      value={{
        isOpen,
        open,
        close,
        slideoutKpi,
        slideoutPeriod,
        slideoutTrend,
        slideoutMetric,
      }}
    >
      {children}
    </KpiSlideOutContext.Provider>
  );
};

export default SlideOutProvider;
