import { useCallback, useContext } from 'react';
import LocalTimelineContext from 'contexts/Timeline/LocalTimelineContext';
import isNormalMetric from 'types/metricTypeCheckers/isNormalMetric';
import { SAVED_FILTER_RESOURCE } from '../../../../../../constants';
import SavedFiltersContext from 'screens/DataManager/DatasetFilters/SavedFilters/context/SavedFiltersContext';

const useUpdateSavedFilterMetricTimeline = () => {
  const { addEvent } = useContext(LocalTimelineContext);
  const { savedFilterIdLookup } = useContext(SavedFiltersContext);

  const updateSavedFilterMetricTimeline = useCallback(
    async ({
      selectedMetric,
      metricDraft,
    }: {
      selectedMetric: Metrics.NormalMetric | Metrics.CompoundMetric | undefined;
      metricDraft: Metrics.NormalMetric | Metrics.CompoundMetric | undefined;
    }) => {
      if (!addEvent || !metricDraft) {
        return;
      }
      const oldSavedFilterIds = isNormalMetric(selectedMetric)
        ? selectedMetric?.datasetFilterIds || []
        : [];

      const newSavedFilterIds = isNormalMetric(metricDraft)
        ? metricDraft?.datasetFilterIds || []
        : [];

      const removedSavedFilterIds = oldSavedFilterIds.filter(
        (id) => !newSavedFilterIds.includes(id),
      );

      const addedSavedFilterIds = newSavedFilterIds.filter(
        (id) => !oldSavedFilterIds.includes(id),
      );

      const removedSavedFilterIdsPromises = removedSavedFilterIds.map((id) => {
        const savedFilter = savedFilterIdLookup[id];

        return addEvent({
          actionText: `removed ${savedFilter?.name} filter`,
          contextText: `from "${metricDraft.name}" metric`,
          destinationOverride: { id, type: SAVED_FILTER_RESOURCE },
        });
      });

      const addedSavedFilterIdsPromises = addedSavedFilterIds.map((id) => {
        const savedFilter = savedFilterIdLookup[id];

        return addEvent({
          actionText: `added ${savedFilter?.name} filter`,
          contextText: `to "${metricDraft.name}" metric`,
          destinationOverride: { id, type: SAVED_FILTER_RESOURCE },
        });
      });

      await Promise.all([
        ...removedSavedFilterIdsPromises,
        ...addedSavedFilterIdsPromises,
      ]);
    },
    [addEvent, savedFilterIdLookup],
  );

  return updateSavedFilterMetricTimeline;
};

export default useUpdateSavedFilterMetricTimeline;
