import React from 'react';
import useSelectedDoc from './useSelectedDoc';
import Loading from '../../../../Loading';
import styled from 'styled-components';
import Colors2 from '../../../../../theme/Colors2';
import ProgressField from './ProgressField';

const Card = styled.div`
  background-color: white;
  padding: 16px;
  border: 1px solid ${Colors2.Grey['7']};
  border-radius: 4px;
  box-shadow: 0 3px 3px 0 rgba(53, 45, 67, 0.07);
  margin-bottom: 8px;
`;

const TargetProgressContainer = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.TargetProgressSection;
}) => {
  const { selectedDoc, isLoading } = useSelectedDoc();

  if (isLoading || !selectedDoc) {
    return <Loading />;
  }

  return (
    <Card>
      {section.fields.map((field, index) => (
        <div
          key={field}
          style={{ marginBottom: section.fields.length - 1 === index ? 0 : 4 }}
        >
          <ProgressField field={field} selectedDoc={selectedDoc} key={field} />
        </div>
      ))}
    </Card>
  );
};

export default TargetProgressContainer;
