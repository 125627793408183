import metricDefinitions from './metricDefinitions';
import filterDefinitions from './filterDefinitions';
import visualisations from './visualisations';
import savedProgresses from './savedProgresses';
import contentDistributions from './contentDistributions';
import users from './userManagement';
import getBoardsRef from './getBoardsRef';
import getTimelineEventsRef from './getTimelineEventsRef';
import getDatasetsRef from './getDatasetsRef';
import getReasonCodesRef from './getReasonCodesRef';
import getLegacySavedFiltersRef from './filterDefinitions/getLegacySavedFiltersRef';
import getEntityDefinitionsRef from './getEntityDefinitionsRef';
import getReportsRef from './getReportsRef';
import getDashboardsRef from './getDashboardsRef';
import getPreviousUploadsRef from './getPreviousUploadsRef';
import getCopyContentReceiptsRef from './getCopyContentReceiptsRef';
import getWorkspacesRef from './getWorkspacesRef';
import getAccountRef from './getAccountRef';
import getUserProfilesRef from './getUserProfilesRef';
import getAccountsRef from './getAccountsRef';

const STORE = {
  metricDefinitions,
  filterDefinitions,
  visualisations,
  savedProgresses,
  contentDistributions,
  users,
  getBoardsRef,
  getTimelineEventsRef,
  getDatasetsRef,
  getReasonCodesRef,
  getLegacySavedFiltersRef,
  getEntityDefinitionsRef,
  getReportsRef,
  getWorkspacesRef,
  getDashboardsRef,
  getPreviousUploadsRef,
  getCopyContentReceiptsRef,
  getAccountRef,
  getAccountsRef,
  getUserProfilesRef,
};

export default STORE;
