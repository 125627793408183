import React, { useContext } from 'react';
import AppRoutes, { buildV5GadgetBuilder } from 'navigation/appRoutes';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from 'permissionDefinitions';
import FeatureGate, { FEATURE_GATES } from 'components/FeatureGate';

import NavSection from '../../NavSection';
import NavItem from '../../NavItem';
import { buildShowDatasets } from '../../../../screens/DataManager/AllSets/routes';
import { PortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
const BUILD_PERMISSIONS = [
  PERMISSIONS.DATA_MANAGEMENT.GADGETS,
  PERMISSIONS.DATA_MANAGEMENT.DATA_MANAGER_ACCESS,
];

const AdminPortalNavSection = () => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <>
      <NavSection label="View">
        <NavItem
          to={`admin${AppRoutes.loggedIn.dashboards}`}
          label={'Dashboards'}
          tooltip={'Dashboards'}
          icon={'dashboard'}
          onClickEvent={'Navigation Sidebar - Clicked Dashboards'}
        />
        <NavItem
          to={`admin${AppRoutes.loggedIn.reports}`}
          label={'Reports'}
          tooltip={'Reports'}
          icon={'find-in-page'}
          onClickEvent={'Navigation Sidebar - Clicked Reports'}
        />
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.USER_MANAGEMENT.CREATE_WALLBOARD}
        >
          <NavItem
            to={`admin${AppRoutes.loggedIn.wallBoardConfigs}`}
            label={'Wallboards'}
            tooltip={'Wallboards'}
            icon={'keyboard'}
          />
        </PermissionGates.Has>
      </NavSection>
      <NavSection label="Perform">
        <FeatureGate featureName={FEATURE_GATES.PERFORMANCE}>
          <NavItem
            to={`admin${AppRoutes.loggedIn.performanceApps}`}
            label={'Apps'}
            tooltip={'Performance'}
            icon={'navigation-metrics'}
            onClickEvent={'Navigation Sidebar - Clicked Performance Apps'}
          />
        </FeatureGate>
        <NavItem
          to={`admin${AppRoutes.loggedIn.kpiLists}`}
          label={'KPI Lists'}
          tooltip={'KPI Lists'}
          icon={'navigation-goals'}
          onClickEvent={'Navigation Sidebar - Clicked KPI Lists'}
        />
      </NavSection>
      <PermissionGates.HasAny requiredPermissions={BUILD_PERMISSIONS}>
        <NavSection label="Build">
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.DATA_MANAGEMENT.DATA_MANAGER_ACCESS}
          >
            <NavItem
              to={buildShowDatasets(selectedPortal)}
              label={'Data Manager'}
              tooltip={'Data Manager'}
              icon={'database'}
              onClickEvent={'Navigation Sidebar - Clicked Data Manager'}
            />
          </PermissionGates.Has>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.DATA_MANAGEMENT.GADGETS}
          >
            <NavItem
              to={buildV5GadgetBuilder(selectedPortal)}
              label={'Gadgets'}
              tooltip={'Gadgets'}
              icon={'note-stack-add'}
              onClickEvent={'Navigation Sidebar - Clicked Gadgets'}
            />
          </PermissionGates.Has>
        </NavSection>
      </PermissionGates.HasAny>
    </>
  );
};

export default AdminPortalNavSection;
