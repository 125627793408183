import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import Row from './Common/Row';
import Typography from 'kingpin/atoms/Typography';

const SubTitle = styled.div`
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  padding: 16px 24px;
`;

const ConfirmationModal = ({
  isOpen,
  close,
  title,
  body,
  bodyComponent,
  confirmText,
  cancelText,
  onConfirmed,
  isLoading,
  isConfirmDisabled,
  noMarginBottom,
}: {
  isOpen: boolean;
  close: () => void;
  title: string;
  body?: string;
  bodyComponent?: JSX.Element;
  cancelText?: string;
  confirmText: string;
  onConfirmed: () => void;
  isLoading?: boolean;
  isConfirmDisabled?: boolean;
  noMarginBottom?: boolean;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <Wrapper>
          <Row spaceBetween style={{ marginBottom: noMarginBottom ? 0 : 24 }}>
            <Typography.Header type={'H5'}>{title}</Typography.Header>
            <Button
              size={'Small'}
              type={'Secondary'}
              icon={'cross'}
              onClick={close}
            />
          </Row>
          {!!body && (
            <SubTitle>
              <Typography.Body type="Body 12">{body}</Typography.Body>
            </SubTitle>
          )}
          {!!bodyComponent && bodyComponent}
          <Row spaceBetween>
            <Button
              onClick={close}
              label={cancelText ? cancelText : 'Cancel'}
              type="Tertiary"
              size="Small"
            />
            <Button
              testId="confirmation-modal--confirm"
              onClick={onConfirmed}
              isLoading={isLoading}
              isDisabled={isConfirmDisabled}
              label={confirmText}
              type="Primary"
              size="Small"
            />
          </Row>
        </Wrapper>
      </Modal>
    )}
  </ModalTransition>
);

/**
 * Deprecated.
 * @see UnsavedChangedProvider
 */
export const UnsavedChangesConfirmation = ({
  isOpen,
  close,
  onConfirmed,
  body,
}: {
  isOpen: boolean;
  close: () => void;
  onConfirmed: () => void;
  body?: string;
}) => (
  <ConfirmationModal
    isOpen={isOpen}
    close={close}
    title={'Unsaved Changes'}
    confirmText={'Yes, Discard Changes'}
    cancelText="No, Return to Changes"
    body={
      body
        ? body
        : 'If you navigate away, all changes made will be lost. Are' +
          ' you sure you want to cancel these changes?'
    }
    onConfirmed={onConfirmed}
  />
);

export default ConfirmationModal;
