import React, { useCallback, useContext, useEffect, useState } from 'react';
import ColumnSizeSettingsStyles from './styles';
import Row from '../../../../Common/Row';
import Inputs from '../../../../Inputs';
import Typography from '../../../../../kingpin/atoms/Typography';
import useColumnOrder from '../hooks/useColumnOrder';
import PerformanceBoardSettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import useGetFieldLabel from '../../../../../hooks/useGetFieldLabel';

const SpecificFieldPicker = ({
  columnSizeStrategy,
  setColumnSizeStrategy,
}: {
  columnSizeStrategy: PerformanceBoardTypes.ColumnSizeStrategies.FitCellContents;
  setColumnSizeStrategy: React.Dispatch<
    React.SetStateAction<
      PerformanceBoardTypes.ColumnSizeStrategies.Strategy | undefined
    >
  >;
}) => {
  const { board } = useContext(PerformanceBoardSettingsContext);
  const { columnOrder } = useColumnOrder();
  const { getFieldLabel } = useGetFieldLabel();

  const getOptions = useCallback((): DropdownOption[] => {
    const fields = columnSizeStrategy.fields;
    if (fields === undefined) {
      return [];
    }

    return columnOrder
      .filter((c) => c.visible)
      .map((c) => {
        const isSelected = fields.some((field) => field === c.field);
        const isProgressField = columnOrder.some(
          (c2) => c2.field === c.field && c.type === 'progress',
        );

        const label = `${getFieldLabel({ field: c.field, dataType: board.dataType })}${isProgressField ? ' (Progress Field)' : ''}`;
        return {
          label,
          isSelected,
          onSelected: () => {
            if (isSelected) {
              setColumnSizeStrategy({
                ...columnSizeStrategy,
                fields: fields.filter((f) => f !== c.field),
              });
              return;
            }

            setColumnSizeStrategy({
              ...columnSizeStrategy,
              fields: [...fields, c.field],
            });
          },
        };
      });
  }, [
    board.dataType,
    columnOrder,
    columnSizeStrategy,
    getFieldLabel,
    setColumnSizeStrategy,
  ]);
  const [addOptions, setAddOptions] = useState<DropdownOption[]>(() =>
    getOptions(),
  );
  useEffect(() => {
    setAddOptions(getOptions());
  }, [getOptions]);

  return (
    <Inputs.Dropdown
      options={addOptions}
      isMulti
      isSearchEnabled
      placeholder="Add Fields"
    />
  );
};

const FitCellContents = ({
  columnSizeStrategy,
  setColumnSizeStrategy,
}: {
  columnSizeStrategy: PerformanceBoardTypes.ColumnSizeStrategies.FitCellContents;
  setColumnSizeStrategy: React.Dispatch<
    React.SetStateAction<
      PerformanceBoardTypes.ColumnSizeStrategies.Strategy | undefined
    >
  >;
}) => {
  const isJustSpecificFields = columnSizeStrategy.fields !== undefined;

  return (
    <>
      <ColumnSizeSettingsStyles.Section>
        <Row centerAlign>
          <div style={{ marginRight: 8 }}>
            <Inputs.Toggle
              value={columnSizeStrategy.skipHeader}
              onChange={() => {
                setColumnSizeStrategy({
                  ...columnSizeStrategy,
                  skipHeader: !columnSizeStrategy.skipHeader,
                });
              }}
            />
          </div>
          <div>
            <Typography.Body type="Label">Skip Headers</Typography.Body>
          </div>
        </Row>
      </ColumnSizeSettingsStyles.Section>
      <ColumnSizeSettingsStyles.Section>
        <Row centerAlign>
          <div style={{ marginRight: 8 }}>
            <Inputs.Toggle
              value={columnSizeStrategy.fields !== undefined}
              onChange={() => {
                setColumnSizeStrategy({
                  ...columnSizeStrategy,
                  fields: isJustSpecificFields ? undefined : [],
                });
              }}
              testId={'size-specific-columns-toggle'}
            />
          </div>
          <div>
            <Typography.Body type="Label">
              Size Just Specific Columns
            </Typography.Body>
          </div>
        </Row>
        {columnSizeStrategy.fields !== undefined && (
          <SpecificFieldPicker
            columnSizeStrategy={columnSizeStrategy}
            setColumnSizeStrategy={setColumnSizeStrategy}
          />
        )}
      </ColumnSizeSettingsStyles.Section>
    </>
  );
};

export default FitCellContents;
