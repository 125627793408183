import React from 'react';

interface PerformanceBoardSlideOutContextType {
  board: PerformanceBoardTypes.Board;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filterInput: FilterInput | undefined;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterInput | undefined>>;
  navState: PerformanceBoardTypes.SlideOut.NavState;
  setNavState: React.Dispatch<PerformanceBoardTypes.SlideOut.NavState>;
  flash: {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    flashMessage: string;
    setFlashMessage: React.Dispatch<React.SetStateAction<string>>;
  };
  performanceDataset: string;
  performanceFields: string[];
}

const PerformanceBoardSlideOutContext =
  React.createContext<PerformanceBoardSlideOutContextType>({
    isOpen: false,
    setIsOpen: (v: boolean) => {
      console.warn('setIsOpen is not defined');
    },
  } as PerformanceBoardSlideOutContextType);

export default PerformanceBoardSlideOutContext;
