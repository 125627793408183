import React, { useContext } from 'react';

import styled from 'styled-components';
import NavSideBarContext from '../../contexts/NavSideBarContext';
import NavItem from './NavItem';
import { buildSettings } from '../../navigation/appRoutes';
import ImpersonatorBanner from '../../components/NavigationSideBar/ImpersonatorBanner';
import PERMISSIONS from '../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';
import NotificationsButton from './NotificationsButton';
import ProfileButton from './ProfileButton';
import Colors2 from '../../theme/Colors2';
import OnlyAdminPortal from '../../components/OnlyAdminPortal';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import PortalPreviewButton from './PortalsPreviewButton';
import NonPortalAccountsGate from '../../components/NonPortalAccountsGate';
import NewUiPreviewBanner from '../../components/NavigationSideBar/NewUiPreviewBanner';
import HelpCenterButton from '../../components/NavigationSideBar/HelpCenterButton';

const MainNavBottomDiv = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isOpen ? 'row' : 'column')};
  border-top: solid 1px ${Colors2.Border};
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  padding: 8px 0px;

  a {
    margin-right: ${(props) => (props.isOpen ? '8px' : '0px')};
  }
`;

const SETTINGS_PERMISSIONS = [
  PERMISSIONS.BILLING.VIEW_BILLING,
  PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_VIEW,
  PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_MODIFY,
  PERMISSIONS.USER_MANAGEMENT.VIEW_USER,
];

const MainNavBottom = () => {
  const { selectedPortal, isPortalUIToggleEnabled } =
    useContext(PortalsContext);
  const { isOpen } = useContext(NavSideBarContext);

  return (
    <div>
      <NewUiPreviewBanner isOpen={isOpen} />
      <ImpersonatorBanner isOpen={isOpen} />
      <HelpCenterButton />
      <MainNavBottomDiv isOpen={isOpen}>
        <OnlyAdminPortal>
          <PermissionGates.HasAny requiredPermissions={SETTINGS_PERMISSIONS}>
            <NavItem
              testId={'settings-button'}
              to={buildSettings(selectedPortal)}
              icon={'settings-empty'}
              iconColor={'#666666'}
            />
          </PermissionGates.HasAny>
        </OnlyAdminPortal>
        <NotificationsButton />
        <ProfileButton />
        <NonPortalAccountsGate>
          <>{isPortalUIToggleEnabled && <PortalPreviewButton />}</>
        </NonPortalAccountsGate>
      </MainNavBottomDiv>
    </div>
  );
};

export default MainNavBottom;
