import React from 'react';

interface KpiSlideOutContextType {
  isOpen: boolean;
  close: () => void;
  open: ({
    kpi,
    period,
    trend,
    metric,
  }: {
    kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
    period: Period;
    trend: Goals.TrendResult[];
    metric?: Metrics.Metric;
  }) => void;
  slideoutKpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow | undefined;
  slideoutPeriod: Period | undefined;
  slideoutTrend: Goals.TrendResult[];
  slideoutMetric: Metrics.Metric | undefined;
}

const KpiSlideOutContext = React.createContext<KpiSlideOutContextType>(
  {} as KpiSlideOutContextType,
);

export default KpiSlideOutContext;
