import { useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../contexts/BaseViewsContext';
import fiToDrillDowns from '../screens/DataManager/Metrics/MetricPopup/MetricForm/NormalMetricForm/fiToDrillDowns';
import { getFieldValue } from '../components/FilterPlates/FilterPlate';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import useToFixedFilters from 'hooks/useToFixedFilters';

const getLabel = (
  drillDown: FixedFilter,
  baseViews: { [dataset: string]: FleetOps.BaseView | undefined },
) => {
  if (drillDown.dataset) {
    const baseView = baseViews[drillDown.dataset];
    if (baseView) {
      const f = baseView.fields[drillDown.field];
      if (f && f.nameAlias) {
        return f.nameAlias;
      }
    }
  }
  return drillDown.field;
};

const useFilterDescription = ({
  filterInput,
  filterOverrides,
  savedFilters,
}: {
  filterInput: FilterInput | undefined;
  filterOverrides?: FilterPlateType[];
  savedFilters?: SavedFilter[];
}) => {
  const { baseViews } = useContext(BaseViewsContext);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const [filterDescription, setFilterDescription] = useState<
    string | undefined
  >();
  const toDrillDowns = useToFixedFilters();

  useEffect(() => {
    if (!filterInput && !savedFilters && !filterOverrides) {
      setFilterDescription(undefined);
    } else {
      const drills = filterInput ? fiToDrillDowns(filterInput, datasets) : [];
      const overrideDrills = toDrillDowns({
        plates: filterOverrides ? filterOverrides : [],
        variableDrillDowns: [],
      });
      const netDrills: string[] = [
        ...drills,
        ...overrideDrills,
        ...(savedFilters?.map((df) => df.name) || []),
      ].map((d) => {
        if (typeof d === 'string') {
          return d;
        }

        return `${getLabel(d, baseViews)}: ${getFieldValue(d)}`;
      });
      const newDescription = netDrills.reduce(
        (currentD, currentDrill, currentIndex) => {
          currentD += currentDrill;

          if (currentIndex < netDrills.length - 1) {
            currentD += ' and ';
          } else if (currentIndex > netDrills.length - 1) {
            currentD += ', ';
          }
          return currentD;
        },
        '',
      );

      setFilterDescription(newDescription);
    }
  }, [
    baseViews,
    savedFilters,
    datasets,
    filterInput,
    filterOverrides,
    toDrillDowns,
  ]);

  return filterDescription;
};

export default useFilterDescription;
