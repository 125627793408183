import { useCallback, useContext } from 'react';
import _ from 'lodash';
import CompoundMetricsContext from 'contexts/CompoundMetricsContext';
import useGetIsUsedInMetricId from './useGetIsUsedInMetricId';

const useGetCompoundUsage = () => {
  const { allCompoundMetrics } = useContext(CompoundMetricsContext);
  const getIsUsedInMetricId = useGetIsUsedInMetricId();

  const getCompoundUsage = useCallback(
    (filter: SavedFilter): Metrics.CompoundMetric[] => {
      const usage: Metrics.CompoundMetric[] = [];
      allCompoundMetrics
        .filter((cm) => cm.status !== 'archived')
        .forEach((cm) => {
          if (
            cm.metricIds.some((metricId) =>
              getIsUsedInMetricId({ filter, metricId }),
            )
          ) {
            usage.push(cm);
          }
        });

      return _.uniqBy(usage, 'id');
    },
    [allCompoundMetrics, getIsUsedInMetricId],
  );

  return getCompoundUsage;
};

export default useGetCompoundUsage;
