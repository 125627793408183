import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';
import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import TextInput from '../../../kingpin/atoms/TextInput';
import Inputs from 'components/Inputs';

const RangePicker = ({
  operatorOptions,
  valueA,
  onValueAChange,
  valueB,
  onValueBChange,
  isValueBRequired,
  isBAndADisabled,
  onConfirm,
  isEditing,
  field,
  isValid,
}: {
  operator: FilterRangeOperator;
  operatorOptions: DropdownOption[];
  valueA: string;
  onValueAChange: (event: ChangeEvent<HTMLInputElement>) => void;
  valueB: string;
  onValueBChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isValueBRequired: boolean;
  isBAndADisabled: boolean;
  onConfirm: () => void;
  isEditing: boolean;
  field?: string;
  isValid: boolean;
}) => (
  <div style={{ padding: 8 }}>
    <Row centerAlign>
      <div style={{ marginRight: 8 }}>
        <Typography.Body type="Body 12">{field}</Typography.Body>
      </div>
      <Inputs.Dropdown options={operatorOptions} />
      {!isBAndADisabled && (
        <>
          <div style={{ marginRight: 8 }} />
          <TextInput
            value={valueA}
            onChange={onValueAChange}
            width={'100px'}
            inputSize="Small"
            type="number"
          />
          <div style={{ marginRight: 8 }} />
          {isValueBRequired && (
            <React.Fragment>
              <Typography.Body type="Body 12"> and</Typography.Body>
              <div style={{ marginRight: 8 }} />
              <TextInput
                value={valueB}
                onChange={onValueBChange}
                width={'100px'}
                inputSize="Small"
                type="number"
              />
            </React.Fragment>
          )}
        </>
      )}
    </Row>
    <Row centerAlign style={{ justifyContent: 'flex-end', marginTop: 16 }}>
      <Button
        isDisabled={!isValid}
        onClick={onConfirm}
        label={isEditing ? 'Update' : 'Add'}
        type="Primary"
        size="Small"
      />
    </Row>
  </div>
);

export default RangePicker;
