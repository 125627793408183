import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import BaseViewsContext from '../contexts/BaseViewsContext';
import baseViewTypeCheckers from '../types/baseViewTypeCheckers';
import isDefined from '../isDefined';

const useGetDateFieldsForDataTypes = () => {
  const { baseViews } = useContext(BaseViewsContext);
  const { datasets } = useContext(DatasetDefinitionsContext);

  const getDateFieldsForDataTypes = useCallback(
    ({
      dataTypes,
      mustBeAvailableOnAllDataTypes,
    }: {
      dataTypes: string[];
      mustBeAvailableOnAllDataTypes?: boolean;
    }) => {
      const dateFields = [] as DateField[];
      datasets
        .filter((set) => dataTypes.includes(set.type))
        .forEach((set) => {
          const dates: FleetOps.Field[] = set.fields
            .filter((f) => f.type === 'date')
            .map((f) => {
              const field: FleetOps.Field = { ...f, dataType: set.type };
              const baseView = baseViews[set.type];
              if (!baseView) {
                return undefined;
              }
              const baseViewField = baseView.fields[f.field];
              if (!baseViewField) {
                return undefined;
              }
              if (!baseViewTypeCheckers.isBaseViewDateField(baseViewField)) {
                return undefined;
              }
              return { field, baseViewField };
            })
            .filter(isDefined)
            .filter((item) => !item.baseViewField.isQueryDateUseDisabled)
            .map((item) => item.field);

          dates.forEach((date) => {
            const name = (() => {
              if (date.alias) {
                return date.alias;
              }
              if (date.dataType) {
                const baseView = baseViews[date.dataType];
                if (baseView) {
                  const f = baseView.fields[date.field];
                  if (f && f.nameAlias) {
                    return f.nameAlias;
                  }
                }
              }
              return date.field;
            })();

            const matchingDateFieldIndex = dateFields.findIndex(
              (f) => date.field === f.field,
            );
            if (matchingDateFieldIndex >= 0) {
              const matchingField = dateFields[matchingDateFieldIndex];
              dateFields[matchingDateFieldIndex] = {
                ...matchingField,
                name:
                  matchingField.name === matchingField.field
                    ? name
                    : matchingField.name,
                datasets: _.uniq([...matchingField.datasets, set.type]),
              };
            } else {
              const newField = {
                name,
                field: date.field,
                datasets: [set.type],
              };
              dateFields.push(newField);
            }
          });
        });

      if (mustBeAvailableOnAllDataTypes) {
        return dateFields.filter((dateField) => {
          return dataTypes.every((dataType) =>
            dateField.datasets.includes(dataType),
          );
        });
      }

      return dateFields;
    },
    [baseViews, datasets],
  );

  return getDateFieldsForDataTypes;
};

const useDateFields = ({
  dataTypes,
  mustBeAvailableOnAllDataTypes,
}: {
  dataTypes: string[];
  mustBeAvailableOnAllDataTypes?: boolean;
}) => {
  const [dateFields, setDateFields] = useState<DateField[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getDateFieldsForDataTypes = useGetDateFieldsForDataTypes();

  useEffect(() => {
    const newDateFields = getDateFieldsForDataTypes({
      dataTypes,
      mustBeAvailableOnAllDataTypes,
    });
    setDateFields(newDateFields);
    setIsLoading(false);
  }, [dataTypes, getDateFieldsForDataTypes, mustBeAvailableOnAllDataTypes]);

  return { dateFields, isLoading };
};

export default useDateFields;
