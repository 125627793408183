import buildFilterInput from './utils/buildFilterInput';

const toFilterInput = (
  savedFilter: LegacySavedFilter,
  variableDrillDowns: VariableDrillDownType[],
  toDrillDowns: ({
    plates,
    variableDrillDowns,
  }: {
    plates?: FilterPlateType[];
    variableDrillDowns?: VariableDrillDownType[];
  }) => FixedFilter[],
): FilterInput =>
  buildFilterInput({
    scopes: toDrillDowns({
      plates: savedFilter.scope,
      variableDrillDowns,
    }),
    drillDowns: toDrillDowns({
      plates: savedFilter.drillDowns,
      variableDrillDowns,
    }),
  });

export default toFilterInput;
