import { useContext } from 'react';
import DashboardsContext from '../../contexts/DashboardsContext';

const useTemplateInstances = (template?: DashboardType): DashboardType[] => {
  const { allDashboards } = useContext(DashboardsContext);

  if (!template) {
    return [];
  }
  if (template.isTemplate) {
    return allDashboards.filter((d) => d.templateId === template.id);
  } else {
    return [];
  }
};

export default useTemplateInstances;
