import { useCallback, useContext } from 'react';
import _ from 'lodash';
import ScorecardsContext from 'contexts/ScorecardsContext';
import { isKpiRow } from 'hooks/kpiTypeCheckers';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';

const useGetScorecardUsage = () => {
  const { scorecards } = useContext(ScorecardsContext);

  const getScorecardUsage = useCallback(
    (filter: SavedFilter): Scorecards.Scorecard[] => {
      const usage: Scorecards.Scorecard[] = [];
      scorecards.forEach((s) => {
        if (
          s.kpis
            .filter(isKpiRow)
            .find(
              (k) =>
                k.drillDowns.find(
                  (d) =>
                    filterPlateTypeCheckers.isSavedFilter(d) &&
                    d.datasetFilterId === filter.id,
                ) ||
                k.scopeDrillDowns.find(
                  (d) =>
                    filterPlateTypeCheckers.isSavedFilter(d) &&
                    d.datasetFilterId === filter.id,
                ),
            )
        ) {
          usage.push(s);
        }
      });

      return _.uniqBy(usage, 'id');
    },
    [scorecards],
  );

  return getScorecardUsage;
};

export default useGetScorecardUsage;
