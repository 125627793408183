import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const updateCostModel = async ({
  updateArgs,
  client,
}: {
  updateArgs: Costs.UpdateCostModelInput;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<string> => {
  const mutation = {
    mutation: gql`
      mutation updateCostModel(
        $costModelId: ID!
        $groupFieldName: String
        $groups: [GroupDefinitionInput!]
        $interval: Interval!
        $dateField: String!
        $startDate: Date!
        $startOfWeek: StartOfWeek
        $categories: [CostCategoryInput!]!
        $activityCostCalculationMode: ActivityCostCalculationMode
        $costsReconciliationType: CostsReconciliationType!
        $marginExcludeFSC: Boolean
        $marginExcludePassThru: Boolean
        $isDraft: Boolean!
      ) {
        updateCostModel(
          costModelId: $costModelId
          groupFieldName: $groupFieldName
          groups: $groups
          interval: $interval
          dateField: $dateField
          startDate: $startDate
          startOfWeek: $startOfWeek
          categories: $categories
          activityCostCalculationMode: $activityCostCalculationMode
          costsReconciliationType: $costsReconciliationType
          marginExcludeFSC: $marginExcludeFSC
          marginExcludePassThru: $marginExcludePassThru
          isDraft: $isDraft
        )
      }
    `,
    variables: {
      ...updateArgs,
    },
  };

  const response = await client.mutate(mutation);
  return response.data.updateCostModel;
};

export default updateCostModel;
