import { useCallback, useContext, useEffect, useState } from 'react';
import dateHistogramAggregation from './dateHistogramAggregation';
import useFilterInput from '../../../hooks/useFilterInput';
import ComparisonContext from '../../../contexts/ComparisonContext';
import AccountContext from '../../../contexts/AccountContext';
import useMetric from '../../../hooks/useMetric';
import toSentenceCase from '../../../services/toSentenceCase';
import isDefined from '../../../isDefined';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import useDateScope from '../../../hooks/useDateScope';
import WeekStartsOnOverrideContext from '../../../contexts/WeekStartsOnOverrideContext';
import GqlClientContext from '../../../contexts/GqlClientContext';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import useToMetricInput from 'hooks/useToMetricInput';
import useLockedDebouncedEffect from '../../../hooks/useLockedDebouncedEffect';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

interface InitialRequestArgs {
  metrics: MetricInput[];
  expression?: Expression2;
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  groupByField: string;
  dateInterval: FleetOps.Interval;
  comparison: PersistedComparisonType;
  gadget: VisualisationDefinitions.SingleMetricDateMatrix;
  weekStartsOn: WeekStartsOn;
  limitOverride?: number;
  client: ApolloClient<NormalizedCacheObject>;
}

const useMetricInputs = (metricId: string) => {
  const { metricOptions, normalMetrics } = useContext(MetricOptionsContext);
  const toMetricInput = useToMetricInput();
  const [metrics, setMetrics] = useState<MetricInput[] | undefined>();
  const [expression, setExpression] = useState<Expression2 | undefined>();

  useEffect(() => {
    const metric = metricOptions.find((m) => m.id === metricId);

    if (!metric || metricTypeCheckers.isSpecialMetric(metric)) {
      setMetrics(undefined);
      setExpression(undefined);
      return;
    }

    if (metricTypeCheckers.isCompoundMetric(metric)) {
      const baseMetrics = metric.metricIds
        .map((baseMetricId) => normalMetrics.find((m) => m.id === baseMetricId))
        .filter(isDefined)
        .map((m) => toMetricInput(m));

      setMetrics(baseMetrics);
      setExpression({
        id: metric.id,
        expression: metric.expression,
      });
      return;
    } else {
      setMetrics([toMetricInput(metric)]);
      return;
    }
  }, [metricId, metricOptions, normalMetrics, toMetricInput]);

  return { metrics, expression };
};

const getOrderedBy = (
  gadget: VisualisationDefinitions.SingleMetricDateMatrix,
  metric: Metrics.Metric | undefined,
) => {
  if (!metric) {
    return '...';
  }

  if (gadget.serverSortBy === 'metric (asc)') {
    return `${gadget.metricDisplayName} (asc)`;
  } else if (gadget.serverSortBy === 'metric (desc)') {
    return `${gadget.metricDisplayName} (desc)`;
  } else if (gadget.serverSortBy === 'alpha (asc)') {
    return `${gadget.groupByField} (asc)`;
  } else if (gadget.serverSortBy === 'alpha (desc)') {
    return `${gadget.groupByField} (desc)`;
  }

  return '...';
};

const useSingleMetricDateMatrixData = (
  gadget: VisualisationDefinitions.SingleMetricDateMatrix,
) => {
  const { client } = useContext(GqlClientContext);
  const { weekStartsOn: accountWeekStartsOn } = useContext(AccountContext);
  const { weekStartsOnOverride } = useContext(WeekStartsOnOverrideContext);
  const weekStartsOn = weekStartsOnOverride
    ? weekStartsOnOverride
    : accountWeekStartsOn;
  const { currentComparison } = useContext(ComparisonContext);
  const { metrics, expression } = useMetricInputs(gadget.metricId);
  const filterInput = useFilterInput(gadget.groupByField);
  const dateScope = useDateScope({});
  const metric = useMetric(gadget.metricId);

  const [response, setResponse] = useState<
    SingleMetricDateMatrixResponse | undefined
  >();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingAll, setIsLoadingAll] = useState<boolean>(false);
  const [isAllDataInitiallyAvailable, setIsAllDataInitiallyAvailable] =
    useState<boolean>(false);
  const [currentItems, setCurrentItems] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const toMetricInput = useToMetricInput();

  const onShowAllPressed = useCallback(() => {
    if (metrics && currentComparison && response) {
      setIsLoadingAll(true);
      dateHistogramAggregation({
        metrics,
        expression,
        dateScope,
        filterInput: [filterInput],
        groupByField: gadget.groupByField,
        dateInterval: gadget.interval,
        comparison: currentComparison,
        weekStartsOn,
        gadget,
        limitOverride: response.totalTerms,
        client,
      }).then((r) => {
        setResponse(r);
        setIsLoading(false);
        setCurrentItems(r.terms.length);
        setTotalItems(r.totalTerms);
      });
    }
  }, [
    client,
    currentComparison,
    dateScope,
    expression,
    filterInput,
    gadget,
    metrics,
    response,
    weekStartsOn,
  ]);

  const [args, setArgs] = useState<InitialRequestArgs | undefined>();
  useEffect(() => {
    if (metrics && currentComparison) {
      setArgs({
        metrics,
        expression,
        dateScope,
        filterInput: [filterInput],
        groupByField: gadget.groupByField,
        dateInterval: gadget.interval,
        comparison: currentComparison,
        weekStartsOn,
        gadget,
        client,
      });
    } else {
      setArgs(undefined);
    }
  }, [
    client,
    currentComparison,
    dateScope,
    expression,
    filterInput,
    gadget,
    metrics,
    toMetricInput,
    weekStartsOn,
  ]);

  const initialRequestCallback = useCallback(
    (requestArgs: InitialRequestArgs) => {
      setIsLoading(true);
      return dateHistogramAggregation(requestArgs);
    },
    [],
  );

  const responseHandler = useCallback(
    (response: SingleMetricDateMatrixResponse) => {
      setResponse(response);
      setIsLoading(false);
      setIsInitialLoading(false);
      setIsAllDataInitiallyAvailable(response.isFullSet);
      setCurrentItems(response.terms.length);
      setTotalItems(response.totalTerms);
    },
    [],
  );

  useLockedDebouncedEffect({
    args,
    responseHandler,
    callback: initialRequestCallback,
  });

  return {
    response,
    isLoading,
    isLoadingAll,
    onShowAllPressed,
    orderedBy: toSentenceCase(getOrderedBy(gadget, metric)),
    totalItems,
    currentItems,
    isAllDataInitiallyAvailable,
    isInitialLoading,
  };
};

export default useSingleMetricDateMatrixData;
