import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';
import STORE from '../../../../../store';
import useGetHasExecutivePortalAccess from './useGetHasExecutivePortalAccess';

const useExecutivePortals = () => {
  const [allExecutivePortals, setAllExecutivePortals] = useState<
    ExecutivePortal[]
  >([]);
  const [availableExecutivePortals, setAvailableExecutivePortals] = useState<
    ExecutivePortal[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getHasPortalAccess = useGetHasExecutivePortalAccess();
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    let isActive = true;
    const listener = STORE.contentDistributions
      .getExecutivePortalsRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((newPortalsSnap) => {
        if (isActive) {
          const newPortals: ExecutivePortal[] = [];
          newPortalsSnap.forEach((p) => {
            newPortals.push(p.data());
          });
          const sortedPortals = _.sortBy(
            newPortals.filter((p) => !p.isHidden),
            'name',
          );
          setAllExecutivePortals(sortedPortals);
          setAvailableExecutivePortals(
            sortedPortals.filter(getHasPortalAccess),
          );
          setIsLoading(false);
        }
      });

    return () => {
      listener();
    };
  }, [getHasPortalAccess, selectedAccountId]);

  return {
    allExecutivePortals,
    availableExecutivePortals,
    isLoading,
  };
};

export default useExecutivePortals;
