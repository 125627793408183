import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Button from 'kingpin/atoms/Button';

import ConfigureDashboardGadget from './ConfigureDashboardGadget';
import Loading from '../Loading';
import WidgetGalleryContext from '../../contexts/WidgetGalleryContext';
import withoutNulls from '../../api/expression/withoutNulls';
import ComparisonContext from '../../contexts/ComparisonContext';
import DashboardContext from '../../contexts/DashboardContext';
import useDashboardGadgetDataTypes from '../../hooks/useDashboardGadgetDataTypes';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import BonusPeriodsContext from '../../contexts/BonusPeriodsContext';
import migrateRelativeDateRange from '../../migrateRelativeDateRange';

import V5GadgetForm from '../V5GadgetForm';
import Row from '../Common/Row';
import CloseModal from '../CloseModal';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import isGauge from '../../types/visTypeCheckers/isGauge';
import GaugeForm from '../GaugeForm';
import GadgetForm from '../GadgetForm';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import SingleMetricDateMatrixForm from '../GadgetForm/SingleMetricDateMatrixForm';
import withDateFilter from '../../hocs/withDateFIlter';
import DateInputContext from '../../contexts/DateInputContext';
import { LAST_MONTH } from '../DateInput/constants';
import RemindersGadgetForm from '../RemindersGadgetForm';
import isRemindersGadget from '../../types/visTypeCheckers/isRemindersGadget';
import isRankingMatrix from '../../types/visTypeCheckers/isRankingMatrix';
import RankingMatrixForm from '../RankingMatrixForm';
import FilterPlatesProvider from '../../contextProviders/FilterPlatesProvider';
import isPaceMatrix from '../../types/visTypeCheckers/isPaceMatrix';
import PaceMatrixForm from '../GadgetForm/PaceMatrixForm';

const aguid = require('aguid');

const sanitizeGadget = (gadget: DashboardGadget) => withoutNulls(gadget);

export const parseSavedDateRange = (
  dateRange: DateRangeInput | DateRangeInput[] | undefined,
) => {
  if (dateRange === undefined) {
    return undefined;
  }

  if (Array.isArray(dateRange)) {
    if (dateRange.length > 0) {
      return dateRange[0];
    }
    return undefined;
  }

  return dateRange;
};

const ConfigureDashboardGadgetContainer = ({
  dashboardGadgetChart,
  onEditSave,
  editingGadget,
  close,
}: {
  dashboardGadgetChart?: VisualisationDefinition;
  onEditSave?: (g: DashboardGadget) => void;
  editingGadget?: DashboardGadget;
  close: () => void;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const { isCreatingNewChart, resetDashboardGadgetEditorRef, onConfigureSave } =
    useContext(WidgetGalleryContext);
  const [chartDef, setChartDef] = useState<VisualisationDefinition | undefined>(
    () => (editingGadget ? editingGadget.chartDef : undefined),
  );

  useEffect(() => {
    if (!chartDef && !!dashboardGadgetChart) {
      setChartDef(dashboardGadgetChart);
    }
  }, [chartDef, dashboardGadgetChart]);

  const { setIsEditingCard, dashboard } = useContext(DashboardContext);
  const {
    dateField,
    dateRange,
    relativeDateRange,
    advancedRelativeDateRange,
    setDateField,
    setDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
    setDataTypes,
  } = useContext(DateInputContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const usedDataTypes = useDashboardGadgetDataTypes(editingGadget);

  useEffect(() => {
    setDataTypes(usedDataTypes);
  }, [setDataTypes, usedDataTypes]);

  useEffect(() => {
    setDateRange(
      editingGadget ? parseSavedDateRange(editingGadget.dateRange) : undefined,
    );
    setRelativeDateRange(
      editingGadget
        ? editingGadget.relativeDateRange
          ? migrateRelativeDateRange(editingGadget.relativeDateRange)
          : undefined
        : LAST_MONTH,
    );
    setAdvancedRelativeDateRange(
      editingGadget ? editingGadget.advancedRelativeDateRange : undefined,
    );
    setDateField(editingGadget ? editingGadget.dateField : 'date');
  }, [
    editingGadget,
    setAdvancedRelativeDateRange,
    setDateField,
    setDateRange,
    setRelativeDateRange,
  ]);

  const [drillDowns, setDrillDowns] = useState<FilterPlateType[]>(
    editingGadget ? editingGadget.drillDowns : [],
  );
  const [weekStartsOnOverride, setWeekStartsOnOverride] = useState<
    WeekStartsOn | undefined
  >(editingGadget ? editingGadget.weekStartsOnOverride : undefined);
  const [comparison, setComparison] = useState<
    PersistedComparisonType | undefined
  >(editingGadget ? editingGadget.comparison : undefined);
  const [scope, setScope] = useState<FilterPlateType[]>(
    editingGadget ? editingGadget.scope : [],
  );
  const [isDefaultFiltersDisabled, setIsDefaultFiltersDisabled] =
    useState<boolean>(
      editingGadget ? !!editingGadget.isDefaultFiltersDisabled : false,
    );
  const [reportDrillDownId, setReportDrillDownId] = useState<
    string | undefined
  >(editingGadget ? editingGadget.reportDrillDownId : undefined);
  const [boardDrillDownId, setBoardDrillDownId] = useState<string | undefined>(
    editingGadget ? editingGadget.boardDrillDownId : undefined,
  );
  const [isBonusPeriodMode, setIsBonusPeriodMode] = useState<boolean>(
    editingGadget ? !!editingGadget.isBonusPeriodMode : false,
  );
  const [isEntityFilterEnabled, setIsEntityFilterEnabled] = useState<boolean>(
    () => {
      if (editingGadget) {
        if (editingGadget.isEntityFilterEnabled === undefined) {
          return true;
        }
        return editingGadget.isEntityFilterEnabled;
      }
      return true;
    },
  );

  const resetDashboardGadgetEditor = useCallback(() => {
    setChartDef(undefined);
    setDateRange(undefined);
    setRelativeDateRange(undefined);
    setAdvancedRelativeDateRange(undefined);
    setDrillDowns([]);
    setDateField('date');
    setComparison(undefined);
    setScope([]);
    setReportDrillDownId(undefined);
    setBoardDrillDownId(undefined);
    setIsBonusPeriodMode(false);
    setWeekStartsOnOverride(undefined);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetDashboardGadgetEditorRef.current = resetDashboardGadgetEditor;
  }, [resetDashboardGadgetEditor, resetDashboardGadgetEditorRef]);

  const dataTypes = useDashboardGadgetDataTypes(editingGadget, chartDef);

  useEffect(() => {
    setIsEditingCard(true);
    return () => {
      setIsEditingCard(false);
    };
  }, [setIsEditingCard]);

  const onSave = useCallback(() => {
    if (!chartDef) {
      return;
    }

    const gadget: DashboardGadget = {
      id: editingGadget ? editingGadget.id : aguid(),
      drillDowns,
      dateRange,
      dateField,
      relativeDateRange,
      advancedRelativeDateRange,
      comparison,
      scope,
      chartDef,
      boardDrillDownId: boardDrillDownId,
      reportDrillDownId,
      isBonusPeriodMode,
      createdBy: editingGadget ? editingGadget.createdBy : currentUser.id,
      createdOn: editingGadget
        ? editingGadget.createdOn
        : DateTime.utc().toISO(),
      updatedBy: currentUser.id,
      updatedOn: DateTime.utc().toISO(),
      weekStartsOnOverride,
      isEntityFilterEnabled,
      isDefaultFiltersDisabled,
    };
    if (onEditSave) {
      onEditSave(sanitizeGadget(gadget));
    } else {
      onConfigureSave(sanitizeGadget(gadget));
    }
  }, [
    advancedRelativeDateRange,
    boardDrillDownId,
    chartDef,
    comparison,
    currentUser.id,
    dateField,
    dateRange,
    drillDowns,
    editingGadget,
    isBonusPeriodMode,
    isDefaultFiltersDisabled,
    isEntityFilterEnabled,
    onConfigureSave,
    onEditSave,
    relativeDateRange,
    reportDrillDownId,
    scope,
    weekStartsOnOverride,
  ]);

  const onIsBonusPeriodModeChanged = (newStatus: boolean) => {
    setIsBonusPeriodMode(newStatus);
    if (newStatus) {
      setDateRange(undefined);
      setRelativeDateRange(undefined);
    }
  };

  useEffect(() => {
    if (!chartDef || !!comparison) {
      return;
    }
    if (isSingleMetricDateMatrix(chartDef)) {
      setComparison({
        compareType: 'compareTo',
        relativeDateOption: 'last 7 days',
      });
      return;
    }

    if (isPaceMatrix(chartDef)) {
      setComparison({
        compareType: 'compareTo',
        relativeDateOption: 'last 4 weeks',
      });
    }
  }, [chartDef, comparison]);

  const isValid = isRemindersGadget(chartDef)
    ? chartDef.events.length > 0 && chartDef.name !== ''
    : true;

  if ((!chartDef && !isCreatingNewChart) || !dashboard) {
    return <Loading />;
  }

  return (
    <ComparisonContext.Provider
      value={{
        currentComparison: comparison,
        setCurrentComparison: setComparison,
      }}
    >
      <FilterPlatesProvider
        drillDowns={drillDowns}
        setDrillDowns={setDrillDowns}
        scope={scope}
        setScope={setScope}
        dataTypes={dataTypes}
        isDefaultFiltersDisabled={isDefaultFiltersDisabled}
        setIsDefaultFiltersDisabled={setIsDefaultFiltersDisabled}
      >
        <CloseModal close={close} />
        <Row
          style={{
            height: '80vh',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '25%',
              overflowY: 'scroll',
              borderRight: '1px solid #f2f2f2',
            }}
          >
            {isV5ChartDef(chartDef) && (
              <V5GadgetForm
                defaultType={'card'}
                isGadgetBuilder={false}
                onChange={setChartDef}
                dashboardGadgetChart={chartDef}
              />
            )}
            {isGauge(chartDef) && (
              <GaugeForm gauge={chartDef} onChange={setChartDef} />
            )}
            {isSingleMetricDateMatrix(chartDef) && (
              <SingleMetricDateMatrixForm
                gadget={chartDef}
                onChange={setChartDef}
              />
            )}
            {isRemindersGadget(chartDef) && (
              <RemindersGadgetForm gadget={chartDef} onChange={setChartDef} />
            )}
            {isRankingMatrix(chartDef) && (
              <RankingMatrixForm gadget={chartDef} onChange={setChartDef} />
            )}
            {isPaceMatrix(chartDef) && (
              <PaceMatrixForm
                editingPaceGadget={chartDef}
                onDraftChanged={setChartDef}
                isGadgetBuilder={false}
              />
            )}
            {chartDef === undefined && <GadgetForm onChange={setChartDef} />}
          </div>
          <div style={{ width: '75%' }}>
            <div
              style={{
                overflowY: 'scroll',
                height: 'calc(80vh - 56px)',
              }}
            >
              <ConfigureDashboardGadget
                chartDef={chartDef}
                reportDrillDownId={reportDrillDownId}
                setReportDrillDownId={setReportDrillDownId}
                boardDrillDownId={boardDrillDownId}
                setBoardDrillDownId={setBoardDrillDownId}
                isBonusPeriodMode={isBonusPeriodMode}
                onIsBonusPeriodModeChanged={onIsBonusPeriodModeChanged}
                selectedBonusPeriod={selectedBonusPeriod}
                isValid={isValid}
                weekStartsOnOverride={weekStartsOnOverride}
                setWeekStartsOnOverride={setWeekStartsOnOverride}
                isEntityFilterEnabled={isEntityFilterEnabled}
                setIsEntityFilterEnabled={setIsEntityFilterEnabled}
              />
            </div>
            <Row
              spaceBetween
              centerAlign
              style={{
                height: 56,
                paddingRight: 22,
                paddingLeft: 22,
                borderTop: '1px solid #f2f2f2',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={close}
                type="Secondary"
                size="Small"
                label="Close"
              />
              <div style={{ height: 30 }}>
                <Button
                  onClick={onSave}
                  isDisabled={!isValid}
                  label="Save"
                  type="Primary"
                  size="Small"
                />
              </div>
            </Row>
          </div>
        </Row>
      </FilterPlatesProvider>
    </ComparisonContext.Provider>
  );
};

export default withDateFilter(ConfigureDashboardGadgetContainer, {});
