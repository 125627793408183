import React, { useState } from 'react';
import WidgetPreview from './WidgetPreview';
import ComparisonContext from '../../../contexts/ComparisonContext';
import withDateFilter from '../../../hocs/withDateFIlter';
import isV5ChartDef from '../../../types/visTypeCheckers/isV5ChartDef';
import isRankingMatrix from '../../../types/visTypeCheckers/isRankingMatrix';
import FilterPlatesProvider from '../../../contextProviders/FilterPlatesProvider';
import useNetworkingEffect from '../../../hooks/useNetworkingEffect';

const REVEAL_TIME = 800;

const LAST_90_DAYS = {
  type: 'last' as 'last',
  n: 90,
  interval: 'day' as 'day',
  currentToDate: false,
};

const DATA_TYPES = ['dispatch'];

const WidgetPreviewContainer = ({
  definition,
}: {
  definition: VisualisationDefinition;
}) => {
  const { id, name, description } = definition;
  const [isPreviewingChart, setIsPreviewingChart] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [revealProgress, setRevealProgress] = useState<number>(0);
  const showPreview = () => setIsPreviewingChart(true);

  useNetworkingEffect(() => {
    if (isMouseOver) {
      let isActive = true;
      const timeout = setTimeout(() => {
        if (isActive) {
          showPreview();
        }
      }, REVEAL_TIME);
      const interval = setInterval(() => {
        setRevealProgress((p) => Math.min(p + 1, 100));
      }, REVEAL_TIME / 100);
      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
        setRevealProgress(0);
        isActive = false;
      };
    }
  }, [isMouseOver]);

  const onMouseOver = () => {
    setIsMouseOver(true);
  };

  const onMouseOut = () => {
    setIsMouseOver(false);
  };

  return (
    <FilterPlatesProvider>
      <ComparisonContext.Provider
        value={{
          currentComparison: {
            compareType: 'previous',
          },
          setCurrentComparison: () => {},
        }}
      >
        <WidgetPreview
          id={id}
          name={name}
          description={description}
          type={
            isV5ChartDef(definition)
              ? definition.gadgetType
              : isRankingMatrix(definition)
                ? 'Matrix (% of total, Rank)'
                : definition.type
          }
          isPreviewingChart={isPreviewingChart}
          showPreview={showPreview}
          definition={definition}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          revealProgress={revealProgress}
        />
      </ComparisonContext.Provider>
    </FilterPlatesProvider>
  );
};

export default withDateFilter(WidgetPreviewContainer, {
  initialDataTypes: DATA_TYPES,
  initialRelativeDateRange: LAST_90_DAYS,
});
