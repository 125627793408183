import React, { useContext } from 'react';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import colors from 'theme/colors';
import Icon from 'kingpin/atoms/Icon';

import PerformanceBoardSlideOutContext from '../../../contexts/PerformanceBoardSlideOutContext';
import { PerformanceStatusProgress } from '../../../PerformanceBoard/Grid/PerformanceStatusCell';
import AccountContext from '../../../../../contexts/AccountContext';

const ProgressField = ({
  selectedDoc,
  field,
}: {
  selectedDoc: ElasticDocument;
  field: string;
}) => {
  const { isDemo6 } = useContext(AccountContext);
  const { performanceDataset } = useContext(PerformanceBoardSlideOutContext);
  const { getFieldLabel } = useGetFieldLabel();
  const trimbleActualField =
    field === 'casualMiles' && isDemo6 ? 'CasualMiles' : field;
  const trimbleField =
    field === 'casualMiles' && isDemo6 ? 'Casual Miles' : field;

  const actual = selectedDoc[trimbleActualField];
  const target = selectedDoc[`${trimbleField}Target`];
  const diff = (() => {
    const dataDiff = selectedDoc[`${trimbleField}Difference`];
    if (dataDiff !== undefined) {
      return dataDiff;
    }

    return target - actual;
  })();

  const isGood = (() => {
    if (field === 'casualMiles') {
      return !(diff >= 0);
    }

    return diff >= 0;
  })();
  const color = isGood ? colors.MEDIUM_SEA_GREEN : colors.RADICAL_RED;
  const targetName = getFieldLabel({
    field: field === 'casualMiles' ? trimbleActualField : field,
    dataType: performanceDataset,
  });

  const StatusIcon = (
    <Icon icon={isGood ? 'checkmark' : 'cross'} color={color} />
  );

  return (
    <PerformanceStatusProgress
      targetName={targetName}
      StatusIcon={StatusIcon}
      actual={actual}
      target={target}
      dataset={performanceDataset}
      field={field}
    />
  );
};

export default ProgressField;
