import React from 'react';

interface CopyGadgetContextType {
  isOpen: boolean;
  close: () => void;
  sourceDashboard?: DashboardType;
  startCopyDashboardGadget: (
    gadget: DashboardGadget | MetricListGadgetType,
    w: number,
    h: number,
    dashboard: DashboardType,
  ) => void;
  startCopyReportGadget: ({
    chartDef,
    drillDowns,
    scope,
    dateField,
    dateRange,
    relativeDateRange,
    advancedRelativeDateRange,
    comparison,
    w,
    h,
    reportId,
    weekStartsOnOverride,
  }: {
    chartDef: VisualisationDefinition;
    drillDowns: FilterPlateType[];
    scope: FilterPlateType[];
    dateField: string;
    dateRange?: DateRangeInput;
    relativeDateRange?: RelativeDateRange;
    advancedRelativeDateRange?: AdvancedRelativeDateRange;
    comparison?: PersistedComparisonType;
    w: number;
    h: number;
    reportId: string;
    weekStartsOnOverride?: WeekStartsOn;
  }) => void;
  createDashboard: (title: string, access: ResourceAccess) => Promise<void>;
  copyToDashboard: (dashboard: DashboardType) => Promise<void>;
  copyToWallboard: (wallboard: Wallboard) => Promise<void>;
  copyGadgetTo:
    | 'new dashboard'
    | 'existing dashboard'
    | 'wallboard'
    | undefined;
  setCopyGadgetTo: React.Dispatch<
    React.SetStateAction<
      'new dashboard' | 'existing dashboard' | 'wallboard' | undefined
    >
  >;
  isLoading: boolean;
}

const CopyGadgetContext = React.createContext<CopyGadgetContextType>({
  isOpen: false,
  close: () => {
    console.error('close not defined');
  },
  startCopyDashboardGadget: () => {
    console.error('startCopyDashboardGadget not defined');
  },
  startCopyReportGadget: () => {
    console.error('startCopyReportGadget not defined');
  },
  createDashboard: async () => {
    console.error('createDashboard not defined');
  },
  copyToDashboard: async () => {
    console.error('copyToDashboard not defined');
  },
  copyToWallboard: async () => {
    console.error('copyToWallboard not defined');
  },
  copyGadgetTo: undefined,
  setCopyGadgetTo: () => {
    console.error('setCopyGadgetTo not defined');
  },
  isLoading: false,
});

export default CopyGadgetContext;
