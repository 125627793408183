import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Comment from './Comment';
import useUser from '../../../hooks/useUser';
import getDownloadUrl from './getDownloadUrl';
import userTypeCheckers from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';

const useAttachedFiles = (comment: CommentType) => {
  const [attachedFiles, setAttachedFiles] = useState<
    { fileName: string; downloadUrl?: string; isLoading: boolean }[]
  >([]);

  useEffect(() => {
    if (!comment.attachedFiles) {
      setAttachedFiles([]);
      return;
    }

    setAttachedFiles(
      comment.attachedFiles.map((f) => ({
        fileName: f.fileName,
        isLoading: true,
      })),
    );

    comment.attachedFiles.map(async (f) => {
      const downloadUrl = await getDownloadUrl(f.path);
      setAttachedFiles((currentAttached) => {
        return currentAttached.map((a) => {
          if (a.fileName === f.fileName) {
            return {
              fileName: a.fileName,
              isLoading: false,
              downloadUrl,
            };
          }

          return a;
        });
      });
    });
  }, [comment.attachedFiles]);

  return attachedFiles;
};

const CommentContainer = ({ comment }: { comment: CommentType }) => {
  const attachedFiles = useAttachedFiles(comment);
  const { text, createdBy, createdOn } = comment;
  const { user } = useUser(createdBy);

  if (!user || userTypeCheckers.isWallboardUser(user)) {
    return null;
  }
  const formattedDate = moment.utc(createdOn).format('MMM Do [at] HH:mm');

  return (
    <Comment
      text={text}
      date={formattedDate}
      user={user}
      attachedFiles={attachedFiles}
    />
  );
};

export default CommentContainer;
