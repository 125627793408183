import { useCallback, useContext, useEffect, useState } from 'react';

import UsersContext from '../contexts/UsersContext';
import userTypeCheckers from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';

const useUsers = (isOnlyMarkStaff?: boolean, allowedUserIds?: string[]) => {
  const { allUsers } = useContext(UsersContext);
  const getUsers = useCallback(() => {
    return allUsers
      .filter(userTypeCheckers.isNotWallboardUser)
      .filter((u) => !u.isDeleted)
      .filter((user) => {
        if (allowedUserIds) {
          return allowedUserIds.includes(user.id);
        }

        if (userTypeCheckers.isFleetOpsStaffUser(user)) {
          if (isOnlyMarkStaff && user.email !== 'mark@fleetops.com') {
            return false;
          }
          return true;
        }

        return true;
      });
  }, [allUsers, allowedUserIds, isOnlyMarkStaff]);

  const [users, setUsers] = useState<
    (
      | UserManagement.SignedUpUser
      | UserManagement.PendingUser
      | UserManagement.FleetOpsStaffUser
    )[]
  >(() => getUsers());

  useEffect(() => {
    setUsers(getUsers());
  }, [allowedUserIds, getUsers, isOnlyMarkStaff]);

  return users;
};

export default useUsers;
