import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import GlobalTimelineContext from '../../contexts/Timeline/GlobalTimelineContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import LocalTimelineContext from '../../contexts/Timeline/LocalTimelineContext';
import getIdentifier from '../../getIdentifier';
import getTimeStamp from '../../getTimeStamp';
import addTimelineEvent from './addTimelineEvent';

const LocalTimelineProvider = ({
  children,
  sources,
  destination,
  startsOn,
}: {
  children: ReactNode;
  sources: Timeline.EventSource[];
  destination?: Timeline.EventSource;
  startsOn?: string;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const { events: allEvents, isLoading } = useContext(GlobalTimelineContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [events, setEvents] = useState<Timeline.Event[]>([]);

  useEffect(() => {
    setEvents(
      allEvents
        .filter((event) => {
          return sources.some(
            (source) =>
              source.id === event.timelineableId &&
              source.type === event.timelineableType,
          );
        })
        .filter((event) => (startsOn ? event.createdOn > startsOn : true)),
    );
  }, [allEvents, sources, startsOn]);

  const addEvent = useCallback(
    async ({
      actionText,
      contentText,
      contextText,
      interaction,
      isContentTextRich,
      destinationOverride,
    }: {
      actionText: string;
      contextText: string;
      contentText?: string;
      interaction?: Timeline.Interaction;
      isContentTextRich?: boolean;
      destinationOverride?: Timeline.EventSource;
    }) => {
      const destinationToUse = destinationOverride
        ? destinationOverride
        : destination;
      if (!destinationToUse) {
        return;
      }
      const timelineableId = destinationToUse.id;
      const timelineableType = destinationToUse.type;

      if (Array.isArray(timelineableId)) {
        const error = new Error();
        error.name = 'Cannot add events in this context';
        throw error;
      }

      const event = {
        id: getIdentifier(),
        timelineableId,
        timelineableType,
        createdOn: getTimeStamp(),
        createdBy: currentUser.id,
        userId: currentUser.id,
        headlineText: {
          userDisplayName: currentUser.displayName,
          actionText,
          contextText,
        },
        contentText,
        interaction,
        isContentTextRich,
      };

      await addTimelineEvent(event, selectedAccountId);
    },
    [selectedAccountId, currentUser.displayName, currentUser.id, destination],
  );

  return (
    <LocalTimelineContext.Provider
      value={{
        events,
        isLoading,
        addEvent,
      }}
    >
      {children}
    </LocalTimelineContext.Provider>
  );
};

export default LocalTimelineProvider;
