import { useCallback, useContext } from 'react';
import _ from 'lodash';
import DashboardsContext from 'contexts/DashboardsContext';
import DashboardGadgetsContext from 'contexts/DashboardGadgetsContext';
import ScorecardsContext from 'contexts/ScorecardsContext';
import MetricListsContext from 'contexts/MetricListsContext';
import cardTypeCheckers from 'types/cardTypeCheckers';
import useGetMetricIdsFromVis from './useGetMetricIdsFromVis';
import useGetIsUsedInMetricId from './useGetIsUsedInMetricId';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';
import LegacySavedFiltersContext from 'contexts/LegacySavedFiltersContext';
import { isKpiRow } from 'hooks/kpiTypeCheckers';

const useGetDashboardGadgetUsage = () => {
  const { allDashboards } = useContext(DashboardsContext);
  const { dashboardGadgetsLookup } = useContext(DashboardGadgetsContext);
  const { scorecardsLookup } = useContext(ScorecardsContext);
  const { metricListsLookup } = useContext(MetricListsContext);
  const { savedFiltersLookup } = useContext(LegacySavedFiltersContext);
  const getMetricIds = useGetMetricIdsFromVis();
  const getIsUsedInMetricId = useGetIsUsedInMetricId();

  const getDashboardGadgetUsage = useCallback(
    (filter: SavedFilter): FleetOps.DashboardGadgetUsage[] => {
      const usage: FleetOps.DashboardGadgetUsage[] = [];

      allDashboards.forEach((d) => {
        d.canvas.cards.forEach((card) => {
          if (cardTypeCheckers.isDashboardGadget(card)) {
            const gadget =
              dashboardGadgetsLookup[card.content.dashboardGadgetId];
            if (!gadget) {
              return;
            }

            if (
              gadget.scope.find(
                (plate) =>
                  filterPlateTypeCheckers.isSavedFilter(plate) &&
                  plate.datasetFilterId === filter.id,
              ) ||
              gadget.drillDowns.find(
                (plate) =>
                  filterPlateTypeCheckers.isSavedFilter(plate) &&
                  plate.datasetFilterId === filter.id,
              )
            ) {
              usage.push({
                id: `${gadget.id} - ${d.id}`,
                gadgetId: gadget.id,
                gadgetName: gadget.chartDef.name,
                gadgetType: 'chartDef',
                dashboardId: d.id,
                dashboardName: d.name,
              });
            }

            const usedMetrics = getMetricIds(gadget.chartDef);
            usedMetrics.forEach((metricId) => {
              if (getIsUsedInMetricId({ filter, metricId })) {
                usage.push({
                  id: `${gadget.id} - ${d.id}`,
                  gadgetId: gadget.id,
                  gadgetName: gadget.chartDef.name,
                  gadgetType: 'chartDef',
                  dashboardId: d.id,
                  dashboardName: d.name,
                });
              }
            });
          } else if (cardTypeCheckers.isMetricList(card)) {
            const metricList = metricListsLookup[card.content.metricListId];
            if (!metricList) {
              return;
            }

            if (
              metricList.list.find((li) => {
                const sf = li.savedFilterId
                  ? savedFiltersLookup[li.savedFilterId]
                  : undefined;

                const isUsed =
                  sf?.drillDowns.find(
                    (plate) =>
                      filterPlateTypeCheckers.isSavedFilter(plate) &&
                      plate.datasetFilterId === filter.id,
                  ) ||
                  sf?.scope.find(
                    (plate) =>
                      filterPlateTypeCheckers.isSavedFilter(plate) &&
                      plate.datasetFilterId === filter.id,
                  );

                return !!isUsed;
              })
            ) {
              usage.push({
                id: `${metricList.id} - ${d.id}`,
                gadgetId: metricList.id,
                gadgetName: metricList.name,
                gadgetType: 'metricList',
                dashboardId: d.id,
                dashboardName: d.name,
              });
            }

            const usedMetrics = getMetricIds(metricList);
            usedMetrics.forEach((metricId) => {
              if (getIsUsedInMetricId({ filter, metricId })) {
                usage.push({
                  id: `${metricList.id} - ${d.id}`,
                  gadgetId: metricList.id,
                  gadgetName: metricList.name,
                  gadgetType: 'metricList',
                  dashboardId: d.id,
                  dashboardName: d.name,
                });
              }
            });
          } else if (cardTypeCheckers.isScorecard(card)) {
            const scorecard = scorecardsLookup[card.content.scorecardId];
            if (!scorecard) {
              return;
            }

            if (
              scorecard.kpis.filter(isKpiRow).find((k) => {
                return (
                  k.scopeDrillDowns.find(
                    (plate) =>
                      filterPlateTypeCheckers.isSavedFilter(plate) &&
                      plate.datasetFilterId === filter.id,
                  ) ||
                  k.drillDowns.find(
                    (plate) =>
                      filterPlateTypeCheckers.isSavedFilter(plate) &&
                      plate.datasetFilterId === filter.id,
                  )
                );
              })
            ) {
              usage.push({
                id: `${scorecard.id} - ${d.id}`,
                gadgetId: scorecard.id,
                gadgetName: scorecard.title,
                gadgetType: 'scorecard',
                dashboardId: d.id,
                dashboardName: d.name,
              });
            }

            const usedMetrics = getMetricIds(scorecard);
            usedMetrics.forEach((metricId) => {
              if (getIsUsedInMetricId({ filter, metricId })) {
                usage.push({
                  id: `${scorecard.id} - ${d.id}`,
                  gadgetId: scorecard.id,
                  gadgetName: scorecard.title,
                  gadgetType: 'scorecard',
                  dashboardId: d.id,
                  dashboardName: d.name,
                });
              }
            });
          }
        });
      });

      return _.uniqBy(usage, 'id');
    },
    [
      allDashboards,
      dashboardGadgetsLookup,
      getIsUsedInMetricId,
      getMetricIds,
      metricListsLookup,
      savedFiltersLookup,
      scorecardsLookup,
    ],
  );

  return getDashboardGadgetUsage;
};

export default useGetDashboardGadgetUsage;
