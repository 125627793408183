import { useCallback, useContext } from 'react';
import UsersContext from '../../contexts/UsersContext';
import isDefined from '../../isDefined';
import userTypeCheckers from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import postQueueContentSharedEmails from './postQueueContentSharedEmails';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';

interface ResourceAccessArgs {
  type: 'Resource Access';
  initialAccess: ResourceAccess | undefined;
  newAccess: ResourceAccess;
}

interface AssignedListArgs {
  type: 'Assigned List';
  initialAssigned: string[] | undefined;
  newAssigned: string[];
}

type SendContentSharedEmailArgs = ResourceAccessArgs | AssignedListArgs;

const useContentSharedEmails = () => {
  const { usEastApi } = useContext(CloudFunctionClientContext);
  const { allUsers } = useContext(UsersContext);

  const getResourceUserIds = useCallback((access: ResourceAccess) => {
    if (access.type !== 'Users') {
      return [];
    }

    return access.userIds || [];
  }, []);

  const getUserIdsAdded = useCallback(
    (args: SendContentSharedEmailArgs) => {
      if (args.type === 'Resource Access') {
        if (args.newAccess.type !== 'Users') {
          return [];
        }

        const initialUsers = args.initialAccess
          ? getResourceUserIds(args.initialAccess)
          : [];
        const newUsers = getResourceUserIds(args.newAccess);

        return newUsers.filter((u) => !initialUsers.includes(u));
      } else {
        const initialUsers = args.initialAssigned ? args.initialAssigned : [];
        const newUsers = args.newAssigned;

        return newUsers.filter((u) => !initialUsers.includes(u));
      }
    },
    [getResourceUserIds],
  );

  const sendContentSharedEmails = useCallback(
    async (args: SendContentSharedEmailArgs) => {
      const userIdsAdded = getUserIdsAdded(args);

      const usersAdded = userIdsAdded
        .map((uid) => allUsers.find((u) => u.id === uid))
        .filter(isDefined);

      const pendingUsersAdded = usersAdded.filter(
        userTypeCheckers.isPendingUser,
      );

      const pendingUserIds = pendingUsersAdded.map((u) => u.id);
      if (pendingUserIds.length > 0) {
        await postQueueContentSharedEmails(usEastApi, pendingUserIds);
      }
    },
    [allUsers, usEastApi, getUserIdsAdded],
  );

  return { sendContentSharedEmails };
};

export default useContentSharedEmails;
