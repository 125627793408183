import React, { memo, useContext } from 'react';
import Loading from '../Loading';
import MetricListContext from 'contexts/MetricListContext';
import DashboardGadgetDateScopeProvider from 'contextProviders/DashboardGadgetDateScopeProvider';
import CanvasCard from '../Visualisations/CanvasCard';
import MetricListTopBar from './MetricListTopBar';
import MetricListContent from './MetricListContent';
import MetricListBottomBar from './MetricListBottomBar';
import GqlClientProvider from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/GqlClientProvider';
import MetricListsContext from '../../contexts/MetricListsContext';

export const MetricListGadgetContainer = ({
  metricListGadget,
  isPreview,
}: {
  metricListGadget: MetricListGadgetType;
  isPreview?: boolean;
}) => {
  return (
    <MetricListContext.Provider value={{ metricList: metricListGadget }}>
      <CanvasCard
        isContentWithoutPadding
        topBar={<MetricListTopBar isPreview={isPreview} />}
        content={
          <GqlClientProvider
            batchMax={100}
            visId={metricListGadget.id}
            visName={metricListGadget.name}
            visType={'metricList'}
          >
            <MetricListContent metricListGadget={metricListGadget} />
          </GqlClientProvider>
        }
        bottomBar={<MetricListBottomBar isPreview={isPreview} />}
      />
    </MetricListContext.Provider>
  );
};

const MemoMetricListGadgetContainer = memo(MetricListGadgetContainer);

const Gate = ({ metricListGadgetId }: { metricListGadgetId: string }) => {
  const { metricListsLookup } = useContext(MetricListsContext);
  const metricListGadget = metricListsLookup[metricListGadgetId];

  if (metricListGadget) {
    return (
      <DashboardGadgetDateScopeProvider dashboardGadget={metricListGadget}>
        <MemoMetricListGadgetContainer metricListGadget={metricListGadget} />
      </DashboardGadgetDateScopeProvider>
    );
  } else {
    return <Loading />;
  }
};

export default Gate;
