/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

interface DashboardContextType {
  dashboard?: DashboardType | DashboardTemplate;
  setIsEditingCard: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPopupGridOpen: React.Dispatch<React.SetStateAction<boolean>>;
  templatedFrom?: DashboardTemplate;
}

const DashboardContext = React.createContext<DashboardContextType>({
  setIsPopupGridOpen: (_status: boolean) => undefined,
} as DashboardContextType);

export default DashboardContext;
