import React, { useCallback, useContext, useEffect, useState } from 'react';
import useColumnOrder from '../hooks/useColumnOrder';
import Typography from '../../../../../kingpin/atoms/Typography';
import ColumnSizeSettingsStyles from './styles';
import useGetFieldLabel from '../../../../../hooks/useGetFieldLabel';
import PerformanceBoardSettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import Inputs from '../../../../Inputs';
import Button from '../../../../../kingpin/atoms/Button';
import Row from '../../../../Common/Row';

const SpecificField = ({
  columnSizeStrategy,
  setColumnSizeStrategy,
  columnSettings,
}: {
  columnSizeStrategy: PerformanceBoardTypes.ColumnSizeStrategies.FitGridWidth;
  setColumnSizeStrategy: React.Dispatch<
    React.SetStateAction<
      PerformanceBoardTypes.ColumnSizeStrategies.Strategy | undefined
    >
  >;
  columnSettings: {
    field: string;
    minWidth?: number;
    maxWidth?: number;
  };
}) => {
  const { board } = useContext(PerformanceBoardSettingsContext);
  const { columnOrder } = useColumnOrder();
  const isProgressField = columnOrder.some(
    (c) => c.field === columnSettings.field && c.type === 'progress',
  );
  const { getFieldLabel } = useGetFieldLabel();

  const isMinWidthEnabled = columnSettings.minWidth !== undefined;
  const isMaxWidthEnabled = columnSettings.maxWidth !== undefined;

  const onMinWidthToggled = useCallback(() => {
    setColumnSizeStrategy({
      ...columnSizeStrategy,
      columnLimits: columnSizeStrategy.columnLimits.map((cl) => {
        if (cl.field === columnSettings.field) {
          return {
            ...cl,
            minWidth: isMinWidthEnabled ? undefined : 80,
          };
        }
        return cl;
      }),
    });
  }, [
    columnSettings.field,
    columnSizeStrategy,
    isMinWidthEnabled,
    setColumnSizeStrategy,
  ]);

  const onMaxWidthToggled = useCallback(() => {
    setColumnSizeStrategy({
      ...columnSizeStrategy,
      columnLimits: columnSizeStrategy.columnLimits.map((cl) => {
        if (cl.field === columnSettings.field) {
          return {
            ...cl,
            maxWidth: isMaxWidthEnabled ? undefined : 80,
          };
        }
        return cl;
      }),
    });
  }, [
    columnSettings.field,
    columnSizeStrategy,
    isMaxWidthEnabled,
    setColumnSizeStrategy,
  ]);

  const onMinWidthChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setColumnSizeStrategy({
        ...columnSizeStrategy,
        columnLimits: columnSizeStrategy.columnLimits.map((cl) => {
          if (cl.field === columnSettings.field) {
            return {
              ...cl,
              minWidth: Number.parseInt(event.target.value),
            };
          }
          return cl;
        }),
      });
    },
    [columnSettings.field, columnSizeStrategy, setColumnSizeStrategy],
  );

  const onMaxWidthChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setColumnSizeStrategy({
        ...columnSizeStrategy,
        columnLimits: columnSizeStrategy.columnLimits.map((cl) => {
          if (cl.field === columnSettings.field) {
            return {
              ...cl,
              maxWidth: Number.parseInt(event.target.value),
            };
          }
          return cl;
        }),
      });
    },
    [columnSettings.field, columnSizeStrategy, setColumnSizeStrategy],
  );

  const onRemoveClicked = useCallback(() => {
    setColumnSizeStrategy({
      ...columnSizeStrategy,
      columnLimits: columnSizeStrategy.columnLimits.filter((cl) => {
        return cl.field !== columnSettings.field;
      }),
    });
  }, [columnSettings.field, columnSizeStrategy, setColumnSizeStrategy]);
  return (
    <div
      style={{
        padding: 8,
        borderRadius: 4,
        border: '1px solid #eaeaea',
        marginBottom: 16,
      }}
    >
      <ColumnSizeSettingsStyles.Section>
        <div style={{ marginBottom: 4 }}>
          <Row spaceBetween centerAlign>
            <Typography.Body type={'Body Bold'}>
              {getFieldLabel({
                field: columnSettings.field,
                dataType: board.dataType,
              })}
              {isProgressField ? '(Progress Field)' : ''}
            </Typography.Body>
            <Button
              size="Small"
              type={'Secondary'}
              icon={'cross'}
              onClick={onRemoveClicked}
            />
          </Row>
        </div>
      </ColumnSizeSettingsStyles.Section>
      <ColumnSizeSettingsStyles.Section>
        <ColumnSizeSettingsStyles.OptionalNumberInput
          label={'Min Width'}
          isEnabled={isMinWidthEnabled}
          onToggled={onMinWidthToggled}
          value={columnSettings.minWidth}
          onChange={onMinWidthChanged}
          testIdPrefix={`${columnSettings.field}-min`}
        />
      </ColumnSizeSettingsStyles.Section>
      <ColumnSizeSettingsStyles.Section>
        <ColumnSizeSettingsStyles.OptionalNumberInput
          label={'Max Width'}
          isEnabled={isMaxWidthEnabled}
          onToggled={onMaxWidthToggled}
          value={columnSettings.maxWidth}
          onChange={onMaxWidthChanged}
          testIdPrefix={`${columnSettings.field}-max`}
        />
      </ColumnSizeSettingsStyles.Section>
    </div>
  );
};

const SpecificFieldSettings = ({
  columnSizeStrategy,
  setColumnSizeStrategy,
}: {
  columnSizeStrategy: PerformanceBoardTypes.ColumnSizeStrategies.FitGridWidth;
  setColumnSizeStrategy: React.Dispatch<
    React.SetStateAction<
      PerformanceBoardTypes.ColumnSizeStrategies.Strategy | undefined
    >
  >;
}) => {
  const { board } = useContext(PerformanceBoardSettingsContext);
  const { columnOrder } = useColumnOrder();
  const { getFieldLabel } = useGetFieldLabel();
  const getOptions = useCallback((): DropdownOption[] => {
    return columnOrder
      .filter((c) => c.visible)
      .map((c) => {
        const isSelected = columnSizeStrategy.columnLimits.some(
          (sc) => sc.field === c.field,
        );
        const isProgressField = columnOrder.some(
          (c2) => c2.field === c.field && c.type === 'progress',
        );

        const label = `${getFieldLabel({ field: c.field, dataType: board.dataType })}${isProgressField ? ' (Progress Field)' : ''}`;
        return {
          label,
          isSelected: false,
          onSelected: () => {
            if (isSelected) {
              setColumnSizeStrategy({
                ...columnSizeStrategy,
                columnLimits: columnSizeStrategy.columnLimits.filter(
                  (c2) => c2.field !== c.field,
                ),
              });
              return;
            }

            setColumnSizeStrategy({
              ...columnSizeStrategy,
              columnLimits: [
                ...columnSizeStrategy.columnLimits,
                { field: c.field },
              ],
            });
          },
        };
      });
  }, [
    board.dataType,
    columnOrder,
    columnSizeStrategy,
    getFieldLabel,
    setColumnSizeStrategy,
  ]);
  const [addOptions, setAddOptions] = useState<DropdownOption[]>(() =>
    getOptions(),
  );
  useEffect(() => {
    setAddOptions(getOptions());
  }, [getOptions]);

  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <Typography.Header type={'H5'}>
          Specific Field Settings
        </Typography.Header>
      </div>
      {columnSizeStrategy.columnLimits.map((col) => (
        <SpecificField
          key={col.field}
          columnSizeStrategy={columnSizeStrategy}
          setColumnSizeStrategy={setColumnSizeStrategy}
          columnSettings={col}
        />
      ))}
      <Inputs.Dropdown
        options={addOptions}
        placeholder="Add Specific Field Settings"
      />
    </>
  );
};

export default SpecificFieldSettings;
