import React, { useCallback, useEffect, useMemo } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import useWarningConfirmation from 'components/ConfirmationModals/hooks/useWarningConfirmation';
import { useBlocker } from 'react-router-dom';

interface UnsavedChangesContextType {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseAttempt: () => Promise<void>;
  close: () => void;
}

export const UnsavedChangesContext =
  React.createContext<UnsavedChangesContextType>({
    hasUnsavedChanges: false,
    setHasUnsavedChanges: window.tokenFunction,
    onCloseAttempt: () => Promise.resolve(),
    close: () => {},
  });

const UnsavedChangesProvider = ({
  children,
  close,
}: {
  children:
    | ((onCloseAttempt: () => Promise<void>) => JSX.Element | JSX.Element[])
    | JSX.Element[]
    | JSX.Element;
  close: () => void;
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    React.useState<boolean>(false);
  const { getConfirmationThat, DangerConfirmation } = useWarningConfirmation({
    title: 'Unsaved changes',
    continueText: 'Discard changes',
  });
  const blocker = useBlocker(hasUnsavedChanges);

  const onBeforeUnload = useCallback(
    async (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
      }
    },
    [hasUnsavedChanges],
  );

  const onCloseAttempt = useCallback(async () => {
    if (!hasUnsavedChanges) {
      close();
      return;
    }

    const isConfirmed = await getConfirmationThat(
      'You’re about to leave the page. Do you want to discard your changes?',
    );
    if (!isConfirmed) {
      return;
    }

    close();
  }, [close, getConfirmationThat, hasUnsavedChanges]);

  useEffect(() => {
    if (blocker.state !== 'blocked') {
      return;
    }

    getConfirmationThat(
      'You’re about to leave the page. Do you want to discard your changes?',
    ).then((isConfirmed) => {
      if (isConfirmed) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    });
  }, [blocker, getConfirmationThat]);

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload]);

  const innerContent = useMemo(() => {
    if (typeof children === 'function') {
      return <>{children(onCloseAttempt)}</>;
    } else {
      return <>{children}</>;
    }
  }, [children, onCloseAttempt]);

  return (
    <UnsavedChangesContext.Provider
      value={{
        hasUnsavedChanges,
        setHasUnsavedChanges,
        onCloseAttempt,
        close,
      }}
    >
      {innerContent}
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </UnsavedChangesContext.Provider>
  );
};

export default UnsavedChangesProvider;
