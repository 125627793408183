import React, { useCallback } from 'react';
import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import baseViewTypeCheckers from 'types/baseViewTypeCheckers';
import FieldSlideOutSection from './FieldSlideOutSection';

const IsQueryDateUseDisabledToggle = ({
  fieldView,
  updateView,
}: {
  fieldView: FleetOps.BaseViewDateField;
  updateView: (newView: FleetOps.BaseViewField) => void;
}) => {
  const onChange = useCallback(
    (newValue: boolean) => {
      const newView = {
        ...fieldView,
        isQueryDateUseDisabled: newValue,
      };
      updateView(newView);
    },
    [fieldView, updateView],
  );

  return (
    <FieldSlideOutSection>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">
          Is Query Date Use Disabled
        </Typography.Body>
      </div>
      <Inputs.Toggle
        testId={'toggle-query-date'}
        value={!!fieldView.isQueryDateUseDisabled}
        onChange={onChange}
      />
    </FieldSlideOutSection>
  );
};

const Gate = ({
  fieldView,
  updateView,
}: {
  fieldView: FleetOps.BaseViewField;
  updateView: (newView: FleetOps.BaseViewField) => void;
}) => {
  if (!baseViewTypeCheckers.isBaseViewDateField(fieldView)) {
    return null;
  }

  return (
    <IsQueryDateUseDisabledToggle
      fieldView={fieldView}
      updateView={updateView}
    />
  );
};

export default Gate;
