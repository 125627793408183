import React from 'react';

interface FilterPlateContextType {
  isOpen: boolean;
}

const FilterPlateContext = React.createContext({
  isOpen: false,
} as FilterPlateContextType);

export default FilterPlateContext;
