import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useMetricDatasetLabel from '../../Inputs/MetricPicker/MetricPickerPopup/hooks/useMetricdatasetLabel';
import Typography from 'kingpin/atoms/Typography';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import Card from '../../Common/Card';
import FilterPlates from './FilterPlates';
import CardInner from './CardInner';
import Row from '../../Common/Row';
import { getMetricLink } from '../../../screens/DataManager/AllSets/routes';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import Tooltip from '../../Tooltip';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';
import useOriginMetric from '../../../hooks/useOriginMetric';
import InfoIcon from '../../InfoIcon';
import Button from '../../../kingpin/atoms/Button';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

export const MetricLink = ({
  metric,
}: {
  metric: Metrics.NormalMetric | Metrics.SpecialMetric | Metrics.CompoundMetric;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const onMetricLinkClicked = useCallback(() => {
    trackEvent('Metric definition - Link to data manager clicked');
  }, [trackEvent]);

  if (metricTypeCheckers.isSpecialMetric(metric)) {
    return null;
  }

  return (
    <>
      {!metricTypeCheckers.isSingleUseMetric(metric) && (
        <PermissionGates.Has
          requiredPermission={
            PERMISSIONS.DATA_MANAGEMENT.REUSABLE_METRICS_CONFIG
          }
        >
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.DATA_MANAGEMENT.DATA_MANAGER_ACCESS}
          >
            <Tooltip content="Edit metric in Data Manager">
              <Link
                to={getMetricLink({
                  metric,
                  portal: selectedPortal,
                })}
                target="_blank"
                onClick={onMetricLinkClicked}
              >
                <Button size={'Small'} type={'Secondary'} icon={'open-link'} />
              </Link>
            </Tooltip>
          </PermissionGates.Has>
        </PermissionGates.Has>
      )}
    </>
  );
};

const FieldOriginalMetric = ({ metric }: { metric: Metrics.NormalMetric }) => {
  const { selectedPortal } = useContext(PortalsContext);
  const originMetric = useOriginMetric(metric);

  if (!originMetric) {
    return null;
  }

  return (
    <Row style={{ marginBottom: 16 }} centerAlign>
      <div style={{ marginRight: 4 }}>
        <Typography.Body type="Label">Field Origin:</Typography.Body>
      </div>
      <div style={{ marginRight: 4 }}>
        <InfoIcon tooltip="The field used in the calculation of this metric originated from the linked metric" />
      </div>
      <Link
        to={getMetricLink({
          metric: originMetric,
          portal: selectedPortal,
        })}
        target="_blank"
      >
        <Typography.Body type="Body 12">{originMetric.name}</Typography.Body>
      </Link>
    </Row>
  );
};

const MetricCard = ({
  metric,
  color,
}: {
  metric: Metrics.NormalMetric | Metrics.SpecialMetric;
  color?: string;
}) => {
  const { datasetLabel } = useMetricDatasetLabel(metric);
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  useEffect(() => {
    if (metricTypeCheckers.isSpecialMetric(metric)) {
      setHasFilters(false);
      return;
    }

    const {
      ranges,
      wildcardFilters,
      booleanFilters,
      keywords,
      mustExistFilters,
      mustNotExistFilters,
    } = metric.filters;
    const hasRange = !!ranges && ranges.length !== 0;
    const hasWildcard = !!wildcardFilters && wildcardFilters.length !== 0;
    const hasBoolean = !!booleanFilters && booleanFilters.length !== 0;
    const hasKeywords = !!keywords && keywords.length !== 0;
    const hasMustExistFilters =
      !!mustExistFilters && mustExistFilters.length !== 0;
    const hasMustNotExistFilters =
      !!mustNotExistFilters && mustNotExistFilters.length !== 0;

    setHasFilters(
      hasRange ||
        hasWildcard ||
        hasBoolean ||
        hasKeywords ||
        hasMustExistFilters ||
        hasMustNotExistFilters,
    );
  }, [metric]);

  return (
    <Card>
      <CardInner>
        {!!color && (
          <div style={{ marginBottom: 16 }}>
            <Row spaceBetween centerAlign>
              <div style={{ marginRight: 8 }}>
                <Typography.Header type="H5" color={color}>
                  {metric.name}
                </Typography.Header>
              </div>

              <MetricLink metric={metric} />
            </Row>
          </div>
        )}
        <div style={{ marginBottom: 16 }}>
          <Row spaceBetween centerAlign>
            <Row centerAlign style={{ gap: 4 }}>
              <Typography.Body type="Label">Dataset:</Typography.Body>
              <Typography.Body type="Body 13">{datasetLabel}</Typography.Body>
            </Row>

            {color === undefined && <MetricLink metric={metric} />}
          </Row>
        </div>
        {metric.description !== '' && (
          <Row style={{ marginBottom: 16, gap: 4 }} centerAlign>
            <Typography.Body type="Label">Description:</Typography.Body>
            <Typography.Body type="Body 13">
              {metric.description}
            </Typography.Body>
          </Row>
        )}
        {metricTypeCheckers.isNormalMetric(metric) && (
          <>
            {!!hasFilters && (
              <div style={{ marginBottom: 16 }}>
                <div style={{ marginBottom: 8, marginRight: 4 }}>
                  <Typography.Body type="Label">
                    Dataset Filters
                  </Typography.Body>
                </div>
                <FilterPlates filters={metric.filters} />
              </div>
            )}
            <Row centerAlign style={{ gap: 4 }}>
              <Typography.Body type="Label">Calculation:</Typography.Body>
              <Typography.Body type="Body 13">{`${metric.aggFunc} of ${metric.field}`}</Typography.Body>
            </Row>
            <FieldOriginalMetric metric={metric} />
          </>
        )}
      </CardInner>
    </Card>
  );
};

export default MetricCard;
