import React, { useCallback, useContext, useEffect, useState } from 'react';

import useGetMetricUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetMetricUsage';
import useGetCompoundUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetCompoundUsage';
import useGetScorecardUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetScorecardUsage';
import useGetReportUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetReportUsage';
import useGetGoalUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetGoalUsage';
import useGetPerformanceUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetPerformanceUsage';
import useGetChartDefinitionUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetChartDefinitionUsage';
import useGetDashboardGadgetUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetDashboardGadgetUsage';
import useGetBoardUsage from '../../../screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetBoardUsage';

import SavedFiltersUsageLookupContext from '../../../screens/DataManager/DatasetFilters/SavedFilters/context/SavedFiltersUsageLookupContext';
import SavedFiltersContext from '../../../screens/DataManager/DatasetFilters/SavedFilters/context/SavedFiltersContext';

const SavedFiltersUsageLookupProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { savedFilters: allSavedFilters } = useContext(SavedFiltersContext);
  const getMetricUsage = useGetMetricUsage();
  const getCompoundUsage = useGetCompoundUsage();
  const getScorecardUsage = useGetScorecardUsage();
  const getReportUsage = useGetReportUsage();
  const getGoalUsage = useGetGoalUsage();
  const getPerformanceUsage = useGetPerformanceUsage();
  const getChartDefinitionUsage = useGetChartDefinitionUsage();
  const getDashboardGadgetUsage = useGetDashboardGadgetUsage();
  const getBoardUsage = useGetBoardUsage();

  const [savedFiltersLookup, setSavedFiltersLookup] =
    useState<FleetOps.SavedFiltersUsageLookup>({});

  const getSavedFiltersUsage = useCallback(
    (savedFilter: SavedFilter): FleetOps.SavedFiltersLookupResults => {
      const mtr = getMetricUsage(savedFilter);
      const cms = getCompoundUsage(savedFilter);
      const scs = getScorecardUsage(savedFilter);
      const rs = getReportUsage(savedFilter);
      const gs = getGoalUsage(savedFilter);
      const perf = getPerformanceUsage(savedFilter);
      const cds = getChartDefinitionUsage(savedFilter);
      const dgs = getDashboardGadgetUsage(savedFilter);
      const bs = getBoardUsage(savedFilter);

      const usageCount =
        mtr.length +
        cms.length +
        scs.length +
        rs.length +
        gs.length +
        perf.length +
        cds.length +
        dgs.length +
        bs.length;

      const baseUsage = {
        metrics: mtr,
        compoundMetrics: cms,
        scorecards: scs,
        reports: rs,
        goals: gs,
        performanceConfigurations: perf,
        chartDefinitions: cds,
        dashboardGadgets: dgs,
        boards: bs,
        usageCount,
      };

      return baseUsage;
    },
    [
      getBoardUsage,
      getChartDefinitionUsage,
      getCompoundUsage,
      getDashboardGadgetUsage,
      getGoalUsage,
      getMetricUsage,
      getPerformanceUsage,
      getReportUsage,
      getScorecardUsage,
    ],
  );

  useEffect(() => {
    const newLookup: FleetOps.SavedFiltersUsageLookup = {};
    allSavedFilters.forEach((df) => {
      newLookup[df.id] = getSavedFiltersUsage(df);
    });
    setSavedFiltersLookup(newLookup);
  }, [allSavedFilters, getSavedFiltersUsage]);

  return (
    <SavedFiltersUsageLookupContext.Provider
      value={{ savedFiltersLookup: savedFiltersLookup }}
    >
      {children}
    </SavedFiltersUsageLookupContext.Provider>
  );
};

export default SavedFiltersUsageLookupProvider;
