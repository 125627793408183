import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PortalsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import FlexCentered from '../components/Common/FlexCentered';
import Loading from '../components/Loading';
import portalTypeCheckers from '../types/portalTypeCheckers';
import ExecutivePortalSwitch from './ExecutivePortalSwitch';
import EngagementPortalSwitch from './EngagementPortalSwitch';
import AdminPortalSwitch from './AdminPortalSwitch';

const NoAssignedPortal = () => (
  <FlexCentered>
    <h1>No portal assigned. Please contact system admin.</h1>
  </FlexCentered>
);

const PortalsSwitch = () => {
  const { portal } = useParams<{ portal: string }>();
  const { hasAvailablePortal, selectedPortal, onPortalUrlSlugChanged } =
    useContext(PortalsContext);

  useEffect(() => {
    if (!portal) {
      return;
    }

    onPortalUrlSlugChanged(portal);
  }, [onPortalUrlSlugChanged, portal]);

  if (!hasAvailablePortal) {
    return <NoAssignedPortal />;
  }

  if (!selectedPortal) {
    return (
      <FlexCentered>
        <Loading />
      </FlexCentered>
    );
  }

  if (portalTypeCheckers.isAdminPortal(selectedPortal)) {
    return <AdminPortalSwitch />;
  }

  if (portalTypeCheckers.isExecutivePortal(selectedPortal)) {
    return <ExecutivePortalSwitch portal={selectedPortal} />;
  }

  if (portalTypeCheckers.isEngagementPortal(selectedPortal)) {
    return <EngagementPortalSwitch portal={selectedPortal} />;
  }

  return (
    <FlexCentered>
      <Loading />
    </FlexCentered>
  );
};

export default PortalsSwitch;
