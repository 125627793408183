import React, { useCallback, useContext, useState } from 'react';

import MetricUsageLookupContext from '../../../../contexts/MetricUsageLookupContext';
import useNetworkingEffect from '../../../../hooks/useNetworkingEffect';
import MetricsContext from '../../../../contexts/MetricsContext';
import CompoundMetricsContext from '../../../../contexts/CompoundMetricsContext';
import useGetDashboardGadgetUsage from './useGetDashboardGadgetUsage';
import useGetBoardUsage from './useGetBoardUsage';
import useGetGoalUsage from './useGetGoalUsage';
import useGetCompoundUsage from './useGetCompoundUsage';
import useGetScorecardUsage from './useGetScorecardUsage';
import useGetChartDefinitionUsage from './useGetChartDefinitionUsage';
import useGetReportUsage from './useGetReportUsage';
import useGetPerformanceUsage from './useGetPerformanceUsage';
import MetricOptionsContext from '../../../../contexts/MetricOptionsContext';

const MetricUsageLookupProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isLoading } = useContext(MetricOptionsContext);
  const { allMetrics } = useContext(MetricsContext);
  const { allCompoundMetrics } = useContext(CompoundMetricsContext);
  const getChartDefinitionUsage = useGetChartDefinitionUsage();
  const getReportUsage = useGetReportUsage();
  const getDashboardGadgetUsage = useGetDashboardGadgetUsage();
  const getBoardUsage = useGetBoardUsage();
  const getGoalUsage = useGetGoalUsage();
  const getScorecardUsage = useGetScorecardUsage();
  const getCompoundUsage = useGetCompoundUsage();
  const getPerformanceUsage = useGetPerformanceUsage();

  const [metricLookup, setMetricLookup] = useState<FleetOps.MetricUsageLookup>(
    {},
  );
  const [compoundMetricLookup, setCompoundMetricLookup] =
    useState<FleetOps.MetricUsageLookup>({});

  const getMetricUsage = useCallback(
    (
      metric: Metrics.NormalMetric | Metrics.CompoundMetric,
    ): FleetOps.MetricLookupResults => {
      const cds = getChartDefinitionUsage(metric);
      const rs = getReportUsage(metric);
      const dgs = getDashboardGadgetUsage(metric);
      const bs = getBoardUsage(metric);
      const gs = getGoalUsage(metric);
      const scs = getScorecardUsage(metric);
      const cms = getCompoundUsage(metric);
      const perf = getPerformanceUsage(metric);

      const usageCount =
        cds.length +
        rs.length +
        dgs.length +
        bs.length +
        gs.length +
        scs.length +
        cms.length +
        perf.length;
      const baseUsage = {
        chartDefinitions: cds,
        reports: rs,
        dashboardGadgets: dgs,
        boards: bs,
        goals: gs,
        scorecards: scs,
        compoundMetrics: cms,
        performanceConfigurations: perf,
        usageCount,
      };

      return baseUsage;
    },
    [
      getChartDefinitionUsage,
      getReportUsage,
      getDashboardGadgetUsage,
      getBoardUsage,
      getGoalUsage,
      getScorecardUsage,
      getCompoundUsage,
      getPerformanceUsage,
    ],
  );

  useNetworkingEffect(() => {
    if (isLoading) {
      return;
    }
    const newLookup = {} as FleetOps.MetricUsageLookup;
    allMetrics.forEach((m) => {
      newLookup[m.id] = getMetricUsage(m);
    });
    setMetricLookup(newLookup);
  }, [allMetrics, getMetricUsage, isLoading]);

  useNetworkingEffect(() => {
    if (isLoading) {
      return;
    }
    const newLookup = {} as FleetOps.MetricUsageLookup;
    allCompoundMetrics.forEach((m) => {
      newLookup[m.id] = getMetricUsage(m);
    });
    setCompoundMetricLookup(newLookup);
  }, [allCompoundMetrics, getMetricUsage, isLoading]);

  return (
    <MetricUsageLookupContext.Provider
      value={{ metricLookup, compoundMetricLookup }}
    >
      {children}
    </MetricUsageLookupContext.Provider>
  );
};

export default MetricUsageLookupProvider;
