import React, { useContext, useMemo } from 'react';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Inputs from './Inputs';
import FilterPlatesContext from '../contexts/FilterPlatesContext';
import { DefaultDatasetFiltersContext } from '../contextProviders/SplashScreenProviders/DatasetProviders/DefaultDatasetFiltersProvider';
import useGetMetricDataTypes from '../hooks/useGetMetricDataTypes';

export const DefaultFiltersToggleForMetricId = ({
  metricId,
}: {
  metricId: string | undefined;
}) => {
  const getMetricDataTypes = useGetMetricDataTypes();
  const relevantDataTypes = useMemo(() => {
    if (!metricId) {
      return [];
    }

    return getMetricDataTypes(metricId);
  }, [getMetricDataTypes, metricId]);

  return <DefaultFiltersToggle relevantDataTypes={relevantDataTypes} />;
};

const DefaultFiltersToggle = ({
  relevantDataTypes,
}: {
  relevantDataTypes: string[];
}) => {
  const { isDefaultFiltersDisabled, setIsDefaultFiltersDisabled } =
    useContext(FilterPlatesContext);
  const { defaultDatasetFilters } = useContext(DefaultDatasetFiltersContext);
  const isRelevant = useMemo(() => {
    return defaultDatasetFilters.some((f) =>
      relevantDataTypes.includes(f.dataset),
    );
  }, [defaultDatasetFilters, relevantDataTypes]);

  if (!setIsDefaultFiltersDisabled || !isRelevant) {
    return null;
  }

  return (
    <Row centerAlign>
      <div style={{ marginRight: 8 }}>
        <Inputs.Toggle
          testId={'toggle-default-filter'}
          value={isDefaultFiltersDisabled === true ? false : true}
          onChange={(v) =>
            setIsDefaultFiltersDisabled(v === true ? false : true)
          }
        />
      </div>
      <div style={{ marginRight: 8 }}>
        <Typography.Body type="Label">
          Use Default Dataset Filters
        </Typography.Body>
      </div>
    </Row>
  );
};
export default DefaultFiltersToggle;
