import React, { useCallback, useContext } from 'react';

import { ReactComponent as FieldsSvg } from '../icons/Fields.svg';
import { ReactComponent as FiltersSvg } from '../icons/Filters.svg';
import { ReactComponent as SettingsSvg } from '../icons/Settings.svg';
import { ReactComponent as ReasonCodesSvg } from '../icons/ReasonCodes.svg';
import Colors2 from 'theme/Colors2';
import PerformanceBoardSettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import useWarningConfirmation from 'components/ConfirmationModals/hooks/useWarningConfirmation';
import SettingsNavItem from './SettingsNavItem';
import Icon from '../../../../../kingpin/atoms/Icon';

const SettingsNavItemContainer = ({
  type,
}: {
  type: PerformanceBoardTypes.SettingsModes;
}) => {
  const { navState, setNavState, hasUnsavedChanges, setHasUnsavedChanges } =
    useContext(PerformanceBoardSettingsContext);
  const isActive = navState.mode === type;

  const { getConfirmationThat, DangerConfirmation } = useWarningConfirmation({
    title: 'Unsaved Changes',
    continueText: 'Close without saving',
  });

  const handleNavClick = useCallback(() => {
    if (!hasUnsavedChanges) {
      setNavState({ mode: type });
      return;
    }
    getConfirmationThat(
      'If you navigate away, all changes made will be lost.',
    ).then((isConfirmed) => {
      if (isConfirmed) {
        setNavState({ mode: type });
        setHasUnsavedChanges(false);
      }
    });
  }, [
    getConfirmationThat,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    setNavState,
    type,
  ]);
  const color = isActive ? Colors2.Grey['1'] : Colors2.Grey['2'];

  switch (type) {
    case 'General':
      return (
        <SettingsNavItem
          IconSvg={<SettingsSvg color={color} />}
          label={type}
          isActive={isActive}
          handleNavClick={handleNavClick}
          DangerConfirmation={DangerConfirmation}
        />
      );
    case 'Fields':
      return (
        <SettingsNavItem
          IconSvg={<FieldsSvg color={color} />}
          label={type}
          isActive={isActive}
          handleNavClick={handleNavClick}
          DangerConfirmation={DangerConfirmation}
        />
      );
    case 'Quick Filters':
      return (
        <SettingsNavItem
          IconSvg={<FiltersSvg color={color} />}
          label={type}
          isActive={isActive || navState.mode === 'Quick Filters Config'}
          handleNavClick={handleNavClick}
          DangerConfirmation={DangerConfirmation}
        />
      );
    case 'Reason Codes':
      return (
        <SettingsNavItem
          IconSvg={<ReasonCodesSvg color={color} />}
          label={type}
          isActive={isActive}
          handleNavClick={handleNavClick}
          DangerConfirmation={DangerConfirmation}
        />
      );
    case 'Column Sizes':
      return (
        <SettingsNavItem
          IconSvg={<Icon color={color} icon={'datasets'} />}
          label={type}
          isActive={isActive}
          handleNavClick={handleNavClick}
          DangerConfirmation={DangerConfirmation}
        />
      );
    default:
      return null;
  }
};

export default SettingsNavItemContainer;
