import React, { useCallback, useContext } from 'react';
import SlideOutContext from '../contexts/PerformanceBoardSlideOutContext';
import SlideOutHeader from './SlideOutHeader';
import PerformanceSlideoutWizard from './Wizards/PerformanceSlideoutWizard';
import sectionTypeCheckers from './sectionTypeCheckers';
import MetricSlideoutWizard from './Wizards/MetricSlideoutWizard';
import GridSlideoutWizard from './Wizards/GridSlideoutWizard';
import PropertiesSlideoutWizard from './Wizards/PropertiesSlideoutWizard';
import TargetProgressSlideoutWizard from './Wizards/TargetProgressSlideoutWizard';

const WizardSlideout = () => {
  const { navState, setNavState, board } = useContext(SlideOutContext);

  const close = useCallback(() => {
    if (board.slideout.length === 0) {
      setNavState({ slideoutMode: 'Empty' });
    } else {
      setNavState({ slideoutMode: 'Home' });
    }
  }, [board.slideout.length, setNavState]);

  return (
    <>
      {navState.creatingSectionType === 'Performance Graph' && (
        <>
          <SlideOutHeader slideoutMode="Creating" type="Performance Graph" />
          <PerformanceSlideoutWizard
            close={close}
            editingSection={
              navState.editingSection &&
              sectionTypeCheckers.isPerformanceSection(navState.editingSection)
                ? navState.editingSection
                : undefined
            }
          />
        </>
      )}
      {navState.creatingSectionType === 'Metric Widget' && (
        <>
          <SlideOutHeader slideoutMode="Creating" type="Metric Widget" />
          <MetricSlideoutWizard
            close={close}
            editingSection={
              navState.editingSection &&
              sectionTypeCheckers.isMetricSection(navState.editingSection)
                ? navState.editingSection
                : undefined
            }
          />
        </>
      )}
      {navState.creatingSectionType === 'Simple Grid' && (
        <>
          <SlideOutHeader slideoutMode="Creating" type="Simple Grid" />
          <GridSlideoutWizard
            close={close}
            editingSection={
              navState.editingSection &&
              sectionTypeCheckers.isGridSection(navState.editingSection)
                ? navState.editingSection
                : undefined
            }
          />
        </>
      )}
      {navState.creatingSectionType === 'Properties' && (
        <>
          <SlideOutHeader slideoutMode="Creating" type="Properties" />
          <PropertiesSlideoutWizard
            close={close}
            editingSection={
              navState.editingSection &&
              sectionTypeCheckers.isPropertiesSection(navState.editingSection)
                ? navState.editingSection
                : undefined
            }
          />
        </>
      )}
      {navState.creatingSectionType === 'Target Progress' && (
        <>
          <SlideOutHeader slideoutMode="Creating" type="Target Progress" />
          <TargetProgressSlideoutWizard
            close={close}
            editingSection={
              navState.editingSection &&
              sectionTypeCheckers.isTargetProgressSection(
                navState.editingSection,
              )
                ? navState.editingSection
                : undefined
            }
          />
        </>
      )}
    </>
  );
};

export default WizardSlideout;
