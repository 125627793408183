import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import React from 'react';
import Colors2 from '../../theme/Colors2';

const FormHeaderDiv = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid ${Colors2.Border};
  padding-bottom: 12px;
`;

const FormHeaderTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FormHeader = ({
  title,
  titleExtra,
  subTitle,
  onClose,
}: {
  title: string;
  titleExtra?: JSX.Element;
  subTitle?: string;
  onClose?: () => void;
}) => {
  return (
    <FormHeaderDiv>
      <FormHeaderTopRow>
        <TitleRow>
          <Typography.Header type={'H4'}>{title}</Typography.Header>
          {titleExtra ? <>{titleExtra}</> : null}
        </TitleRow>
        {onClose ? (
          <Button
            size={'Small'}
            type={'Secondary'}
            icon="cross"
            onClick={onClose}
            testId="close"
          />
        ) : (
          <div />
        )}
      </FormHeaderTopRow>
      {subTitle && (
        <Typography.Body type={'Body 13'} color="#808080">
          {subTitle}
        </Typography.Body>
      )}
    </FormHeaderDiv>
  );
};

export default FormHeader;
