import { useContext, useEffect, useState } from 'react';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';

const useSavedFilters = () => {
  const [savedFilters, setSavedFilters] = useState<SavedFilter[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = STORE.filterDefinitions
      .getSavedFiltersRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        const data: SavedFilter[] = [];
        snapshot.docs.forEach((d) => {
          data.push(d.data());
        });

        const newData = data.filter((df) => !df.isHidden);

        setSavedFilters(newData);
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  return {
    savedFilters,
    isLoading,
  };
};

export default useSavedFilters;
