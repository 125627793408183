import React from 'react';

import useCompoundMetricWithMetricDefs from '../../../hooks/useCompoundMetricWithMetricDefs';
import MetricCard from './MetricCard';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import AdditionalFilters from './AdditionalFilters';
import getTermColor from '../getTermColor';
import DefaultFiltersDisabledAlert from './DefaultFiltersDisabledAlert';

const CompoundMetricInformation = ({
  metric,
  metricInfo,
}: {
  metric: Metrics.CompoundMetric;
  metricInfo: MetricsInfoButton.NormalMetricInfo;
}) => {
  const compoundMetric = useCompoundMetricWithMetricDefs(metric.id);
  if (!compoundMetric) {
    return null;
  }

  return (
    <>
      {compoundMetric.metrics.map((m, index) => (
        <div key={m.id} style={{ marginBottom: 8 }}>
          <MetricCard metric={m} color={getTermColor(index)} key={m.id} />
        </div>
      ))}
      <AdditionalFilters metricInfo={metricInfo} />
      <DefaultFiltersDisabledAlert metricInfo={metricInfo} />
    </>
  );
};

const MetricInformation = ({
  metricInfo,
}: {
  metricInfo: MetricsInfoButton.NormalMetricInfo;
}) => {
  if (metricTypeCheckers.isCompoundMetric(metricInfo.metric)) {
    return (
      <CompoundMetricInformation
        metricInfo={metricInfo}
        metric={metricInfo.metric}
      />
    );
  } else {
    return (
      <>
        <MetricCard metric={metricInfo.metric} />
        <AdditionalFilters metricInfo={metricInfo} />
        <DefaultFiltersDisabledAlert metricInfo={metricInfo} />
      </>
    );
  }
};

export default MetricInformation;
