import React, { useState } from 'react';
import { toRangeDrillDown } from '../../../../utils/buildFilterInput/toRangeInput';
import Typography from 'kingpin/atoms/Typography';
import PlateStyles from './PlateStyles';

const RangeFilterPlate = ({ filter }: { filter: RangeInput }) => {
  const [middleText] = useState<string>(() => {
    return toRangeDrillDown(filter).operator;
  });
  const [rightText] = useState<string>(() => {
    const { valueA, valueB } = toRangeDrillDown(filter);
    if (valueB === undefined) {
      return `${valueA}`;
    }

    return `${valueA} and ${valueB}`;
  });

  return (
    <div className="filter-plate">
      <PlateStyles.Plate>
        <PlateStyles.LeftPlate>
          <Typography.Body type="Body 12">{filter.field}</Typography.Body>
        </PlateStyles.LeftPlate>
        <PlateStyles.MiddlePlate>
          <Typography.Body type="Body 12">{middleText}</Typography.Body>
        </PlateStyles.MiddlePlate>
        <PlateStyles.RightPlate>
          <Typography.Body type="Body 12">{rightText}</Typography.Body>
        </PlateStyles.RightPlate>
      </PlateStyles.Plate>
    </div>
  );
};

export default RangeFilterPlate;
