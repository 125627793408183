import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import ExecutivePortalsProvider from './ExecutivePortalsProvider';
import EngagementPortalsProvider from './EngagementPortalsProvider';
import useIsPortalsEnabled from './useIsPortalsEnabled';
import FlexCentered from '../../../../components/Common/FlexCentered';
import Loading from '../../../../components/Loading';
import useHasAvailablePortal from './useHasAvailablePortal';
import appRoutes from '../../../../navigation/appRoutes';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';
import ToastContext from '../../../../contexts/ToastContext';
import usePublishedLookup, { PublishedLookup } from './usePublishedLookup';

interface PortalsProviderContextType {
  selectedPortal?: Portal;
  lastSelectedPortal?: Portal;
  onPortalSelected: (portal: Portal) => void;
  locallyUpdateSelectedPortal: (portal: Portal) => void;
  isPortalsEnabled: boolean;
  isPortalsEnabledAtAccountLevel: boolean;
  isPortalUIToggleEnabled: boolean;
  onPreviewNewUiClicked: () => void;
  onRevertToOldUiClicked: () => void;
  hasAvailablePortal: boolean;
  availablePortals: Portal[];
  onPortalUrlSlugChanged: (portalUrlSlug: string) => void;
  publishedLookup: PublishedLookup;
}

export const PortalsContext = createContext<PortalsProviderContextType>({
  onPortalSelected: window.tokenFunction,
  isPortalsEnabled: false,
  isPortalsEnabledAtAccountLevel: false,
  isPortalUIToggleEnabled: false,
  onPreviewNewUiClicked: window.tokenFunction,
  onRevertToOldUiClicked: window.tokenFunction,
  hasAvailablePortal: false,
  availablePortals: window.emptyArray,
  onPortalUrlSlugChanged: window.tokenFunction,
  publishedLookup: {
    reports: {},
    dashboards: {},
    scorecards: {},
  },
  locallyUpdateSelectedPortal: window.tokenFunction,
});

const PortalsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const navigate = useNavigate();
  const {
    isPortalsEnabledAtAccountLevel,
    isPortalsEnabled,
    isPortalUIToggleEnabled,
    isLoading,
    onPreviewNewUiClicked,
    onRevertToOldUiClicked,
  } = useIsPortalsEnabled();
  const { showToast } = useContext(ToastContext);
  const [selectedPortal, setSelectedPortal] = useState<Portal | undefined>();
  const { hasAvailablePortal, availablePortals } = useHasAvailablePortal();
  const publishedLookup = usePublishedLookup();

  const onPortalSelected = useCallback(
    (portal: Portal) => {
      setSelectedPortal(portal);
      if (portalTypeCheckers.isAdminPortal(portal)) {
        navigate('/admin');
      } else if (portalTypeCheckers.isExecutivePortal(portal)) {
        navigate(`/${portal.urlSlug}/dashboards`);
      } else if (portalTypeCheckers.isEngagementPortal(portal)) {
        navigate(`/${portal.urlSlug}`);
      }
    },
    [navigate],
  );

  // Unselect portal when isPortalsEnabled gets toggled off
  useEffect(() => {
    if (isPortalsEnabled) {
      return;
    }

    setSelectedPortal(undefined);
  }, [isPortalsEnabled]);

  // This is handy if we need to update something in the portal immediately,
  // eg: add new report to the portal and nav to it.
  const locallyUpdateSelectedPortal = useCallback((newPortal: Portal) => {
    setSelectedPortal(newPortal);
  }, []);

  useEffect(() => {
    if (!selectedPortal) {
      return;
    }

    const latestVersionOfSelected = availablePortals.find(
      (p) => p.id === selectedPortal.id,
    );

    const isUpdateAvailable = !_.isEqual(
      latestVersionOfSelected,
      selectedPortal,
    );

    if (!isUpdateAvailable) {
      return;
    }

    setSelectedPortal(latestVersionOfSelected);
  }, [selectedPortal, availablePortals]);

  /**
   * The PortalsProvider is higher in the React tree than the Route where
   * the portalUrlSlug is defined. Therefore we need to provide
   * this via context
   */
  const onPortalUrlSlugChanged = useCallback(
    (portalUrlSlug: string) => {
      if (selectedPortal && portalTypeCheckers.isAdminPortal(selectedPortal)) {
        return;
      }

      if (selectedPortal && selectedPortal.urlSlug === portalUrlSlug) {
        return;
      }

      const newSelectedPortal = availablePortals.find(
        (p) => p.urlSlug === portalUrlSlug,
      );

      if (!newSelectedPortal) {
        showToast('We could not find that portal');
        navigate(appRoutes.home);
        return;
      }

      setSelectedPortal(newSelectedPortal);
    },
    [availablePortals, navigate, selectedPortal, showToast],
  );

  if (isLoading) {
    return (
      <FlexCentered>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <PortalsContext.Provider
      value={{
        onPortalSelected,
        selectedPortal,
        isPortalsEnabledAtAccountLevel: !!isPortalsEnabledAtAccountLevel,
        isPortalsEnabled,
        isPortalUIToggleEnabled,
        onPreviewNewUiClicked,
        onRevertToOldUiClicked,
        hasAvailablePortal,
        availablePortals,
        onPortalUrlSlugChanged,
        publishedLookup,
        locallyUpdateSelectedPortal,
      }}
    >
      {children}
    </PortalsContext.Provider>
  );
};

const Gate = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <ExecutivePortalsProvider>
      <EngagementPortalsProvider>
        <PortalsProvider>{children}</PortalsProvider>
      </EngagementPortalsProvider>
    </ExecutivePortalsProvider>
  );
};

export default Gate;
