import { useCallback, useContext } from 'react';
import _ from 'lodash';
import MetricsContext from 'contexts/MetricsContext';

const useGetMetricUsage = () => {
  const { allMetrics } = useContext(MetricsContext);

  const getMetricUsage = useCallback(
    (filter: SavedFilter): Metrics.NormalMetric[] => {
      const usage: Metrics.NormalMetric[] = [];

      allMetrics.forEach((metric) => {
        if (metric.datasetFilterIds?.includes(filter.id)) {
          usage.push(metric);
        }
      });

      return _.uniqBy(usage, 'id');
    },
    [allMetrics],
  );

  return getMetricUsage;
};

export default useGetMetricUsage;
