import React, { useCallback, useContext, useEffect, useState } from 'react';
import DragAndDropList from 'components/DragAndDropList';
import isDefined from 'isDefined';
import store from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import PerformanceBoardSlideOutContext from 'components/PerformanceBoards/contexts/PerformanceBoardSlideOutContext';
import SectionListItem from './SectionListItem';
import Flash from 'components/Flash';
import ShadowedList from 'components/ShadowedList';

const HomeList = ({
  sections,
}: {
  sections: PerformanceBoardTypes.SlideOut.SlideOutSection[];
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { board, flash } = useContext(PerformanceBoardSlideOutContext);
  const [sectionsOrder, setSectionsOrder] = useState<string[]>(() =>
    sections.map((s) => s.id),
  );

  const getSectionById = useCallback(
    (id: string) => {
      return sections.find((section) => section.id === id);
    },
    [sections],
  );

  const updateSlideOutOrder = useCallback(
    (newOrder: string[]) => {
      setSectionsOrder(newOrder);
      const newSections = newOrder.map(getSectionById).filter(isDefined);

      store.contentDistributions
        .getPerformanceBoardsRef({ accountId: selectedAccountId })
        .doc(board.id)
        .set({ ...board, slideout: newSections });
    },
    [board, getSectionById, selectedAccountId],
  );

  const removeSection = useCallback(
    (sectionId: string) => {
      const newOrder = sectionsOrder.filter((s) => s !== sectionId);
      updateSlideOutOrder(newOrder);
    },
    [sectionsOrder, updateSlideOutOrder],
  );

  // Update Sections Home Screen on creation
  useEffect(() => {
    setSectionsOrder(sections.map((s) => s.id));
  }, [sections]);

  return (
    <ShadowedList>
      <div style={{ padding: '0 8px' }}>
        <Flash
          isFlashVisible={flash.isVisible}
          setIsFlashVisible={flash.setIsVisible}
          type="success"
          message={flash.flashMessage}
        />
        <DragAndDropList
          items={sectionsOrder}
          onOrderChanged={updateSlideOutOrder}
          droppableId="sectionId"
          renderItem={(sectionId) => (
            <SectionListItem
              sectionId={sectionId}
              section={getSectionById(sectionId)}
              removeSection={removeSection}
            />
          )}
        />
      </div>
    </ShadowedList>
  );
};

export default HomeList;
