import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import BoardContext from 'contexts/BoardContext';
import SlideOutContext from '../contexts/PerformanceBoardSlideOutContext';
import isPerformanceBoard from 'isPerformanceBoard';
import SlideOutHeader from './SlideOutHeader';
import EmptySlideout from './EmptySlideout/EmptySlideout';
import SlideOut from './SlideOut';
import HomeList from './HomeWidgetList/HomeList';
import WizardSlideout from './WizardSlideout';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const PerformanceBoardSlideOutContainer = () => {
  const { navState, setNavState, board } = useContext(SlideOutContext);

  useEffect(() => {
    if (board.slideout.length === 0) {
      setNavState({ slideoutMode: 'Empty' });
    }
  }, [board, setNavState]);

  return (
    <Content>
      {navState.slideoutMode === 'Empty' && (
        <>
          <SlideOutHeader slideoutMode={'Empty'} />
          <EmptySlideout />
        </>
      )}
      {navState.slideoutMode === 'Home' && (
        <>
          <SlideOutHeader slideoutMode={'Home'} />
          <HomeList sections={board.slideout} />
        </>
      )}
      {navState.slideoutMode === 'Creating' && <WizardSlideout />}
    </Content>
  );
};

const Gate = () => {
  const { board } = useContext(BoardContext);

  if (!isPerformanceBoard(board)) {
    return null;
  }

  return (
    <SlideOut>
      <PerformanceBoardSlideOutContainer />
    </SlideOut>
  );
};

export default Gate;
