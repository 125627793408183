import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import store from 'store';
import Button from 'kingpin/atoms/Button';
import Modal from '@atlaskit/modal-dialog';
import AccountPickerContext from 'contexts/AccountPickerContext';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import Dropdown from 'components/Inputs/Dropdown';
import CloseButton from 'components/CloseButton';

import usePerformanceDatasetsOptions from './usePerformanceDatasetsOptions';
import getIdentifier from 'getIdentifier';
import { buildShowBoard } from 'navigation/appRoutes';
import PERFORMANCE_WIZARD_CONSTANTS from 'screens/DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/constants';
import ToastContext from 'contexts/ToastContext';
import FilterPlatesProvider from 'contextProviders/FilterPlatesProvider';
import FilterPlates from 'components/FilterPlates';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import getTimeStamp from 'getTimeStamp';

export const TopBarDiv = styled.div`
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
`;

export const LabelDiv = styled.div`
  margin-bottom: 4px;
`;

const ModalContent = styled.div`
  padding: ${PERFORMANCE_WIZARD_CONSTANTS.MODAL_PADDING_VERTICAL}px
    ${PERFORMANCE_WIZARD_CONSTANTS.MODAL_PADDING_HORIZONTAL}px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  overflow-y: hidden;
`;

export const toPlates = (drillDowns: FixedFilter[]): FilterPlateType[] => {
  return drillDowns.map((d) => ({
    id: d.id,
    type: 'Fixed',
    fixedValue: d,
  }));
};

const PerformanceBoardForm = ({
  close,
  board,
}: {
  board?: PerformanceBoardTypes.Board;
  close: () => void;
}) => {
  const { getPerformanceConfig } = useContext(DatasetDefinitionsContext);
  const { showToast } = useContext(ToastContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [title, setTitle] = useState<string>('');
  const [plates, setPlates] = useState<FilterPlateType[]>(() =>
    board ? board.plates : [],
  );

  const { datasetOptions, selectedDataset } = usePerformanceDatasetsOptions();
  const currentUser = useContext(CurrentUserContext);

  const [dataTypes, setDataTypes] = useState<string[]>([]);
  useEffect(() => {
    if (!selectedDataset) {
      setDataTypes([]);
      return;
    }
    setDataTypes([selectedDataset.type]);
  }, [selectedDataset]);
  const navigate = useNavigate();

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  const saveBoard = useCallback(() => {
    if (!selectedDataset) {
      alert('Please select a dataset');
      return;
    }

    const config = getPerformanceConfig(selectedDataset.type);

    if (!config) {
      return;
    }

    const board: PerformanceBoardTypes.Board = {
      category: 'Performance' as 'Performance',
      id: getIdentifier(),
      name: title,
      dataType: selectedDataset.type,
      quickFilterOrder: [],
      columnOrder: [
        {
          key: getIdentifier(),
          field: config.entity,
          visible: true,
          type: 'field',
        },
      ],
      slideout: [],
      plates,
      defaultSort: {
        field: config.entity,
        direction: 'asc',
      },
      updatedBy: currentUser.id,
      updatedOn: getTimeStamp(),
      createdBy: currentUser.id,
      createdOn: getTimeStamp(),
      isEntityFilterEnabled: false,
      columnSizeStrategy: {
        type: 'fitGridWidth',
        columnLimits: [],
      },
    };

    store.contentDistributions
      .getPerformanceBoardsRef({ accountId: selectedAccountId })
      .doc(board.id)
      .set(board)
      .then(() => {
        navigate(buildShowBoard(board.id));
        showToast('Board Created');
      });
  }, [
    currentUser.id,
    getPerformanceConfig,
    navigate,
    plates,
    selectedAccountId,
    selectedDataset,
    showToast,
    title,
  ]);

  const isDisabled = title === '' || !selectedDataset;

  return (
    <FilterPlatesProvider
      drillDowns={plates}
      setDrillDowns={setPlates}
      dataTypes={dataTypes}
    >
      <Modal
        width={'500px'}
        height={'60vh'}
        onClose={close}
        shouldScrollInViewport={false}
        autoFocus={false}
      >
        <ModalContent>
          <div>
            <TopBarDiv>
              <Row
                centerAlign
                spaceBetween
                style={{ height: '100%', paddingBottom: 16 }}
              >
                <Row centerAlign style={{ height: '100%' }}>
                  <Typography.Header type="H4">Create Board</Typography.Header>
                </Row>
                <CloseButton close={close} />
              </Row>
            </TopBarDiv>
            <div style={{ marginBottom: 24 }}>
              <LabelDiv>
                <Typography.Body type="Label">Board Name</Typography.Body>
              </LabelDiv>
              <Inputs.TextInput
                value={title}
                onChange={onTitleChanged}
                maxLength={40}
                autoFocus
                data-testid={'title'}
              />
            </div>
            <div style={{ width: '100%', marginBottom: 16 }}>
              <LabelDiv>
                <Typography.Body type="Label">Select A Dataset</Typography.Body>
              </LabelDiv>
              <Dropdown
                options={datasetOptions}
                placeholder={'Select Dataset'}
                isDisabled={datasetOptions.length === 0}
              />
            </div>
            <div style={{ width: '100%' }}>
              <LabelDiv>
                <Typography.Body type="Label">Filters</Typography.Body>
              </LabelDiv>
              <FilterPlates />
            </div>
          </div>
          <Row spaceBetween centerAlign>
            <Button
              onClick={close}
              type="Secondary"
              size="Small"
              label="Cancel"
            />
            <Button
              label="Create Board"
              testId="create-board-btn"
              onClick={saveBoard}
              isDisabled={isDisabled}
              type="Primary"
              size="Small"
            />
          </Row>
        </ModalContent>
      </Modal>
    </FilterPlatesProvider>
  );
};

export default PerformanceBoardForm;
