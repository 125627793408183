// This exists to scrub out the metadata gql adds to the data structures
import getIdentifier from '../../../../getIdentifier';

const sanitiseConfig = (config: FleetOps.PerformanceDatasetConfig) => ({
  id: config.id,
  name: config.name,
  dataType: config.dataType,
  entity: config.entity,
  interval: config.interval,
  intervalLength: config.intervalLength,
  entityDataTypes: config.entityDataTypes,
  entityWindowLength: config.entityWindowLength,
  entityWindowDateField: config.entityWindowDateField,
  status: config.status,
  startDate: config.startDate,
  startOfWeek: config.startOfWeek,
  metrics: config.metrics.map((m) => ({
    key: getIdentifier(undefined, true),
    metricId: m.metricId,
    dateField: m.dateField,
    datePeriodType: m.datePeriodType,
    datePeriodLength: m.datePeriodLength,
    fieldName: m.fieldName,
  })),
  fields: config.fields
    ? config.fields.map((keywords) => ({
        dataType: keywords.dataType,
        fields: keywords.fields,
      }))
    : [],
  createdBy: config.createdBy,
  createdOn: config.createdOn,
  updatedBy: config.updatedBy,
  updatedOn: config.updatedOn,
});

export default sanitiseConfig;
