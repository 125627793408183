import { useContext, useEffect, useState } from 'react';
import UsersContext from '../../contexts/UsersContext';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';
import getUsedSeats from './getUsedSeats';
import { ProductTierContext } from '../SplashScreenProviders/UserAndAccountProviders/ProductTierProvider';

const useUsedSeats = () => {
  const { allUsers } = useContext(UsersContext);
  const { seatLimitation } = useContext(ProductTierContext);
  const { api } = useContext(CloudFunctionClientContext);
  const [usedSeats, setUsedSeats] = useState<number>(0);

  useEffect(() => {
    let isActive = true;
    getUsedSeats(api).then((newUsedSeats) => {
      if (!isActive) {
        return;
      }

      setUsedSeats(newUsedSeats);
    });
  }, [allUsers, api, seatLimitation]);

  return usedSeats;
};

export default useUsedSeats;
