import React from 'react';
import AddReportDrillDownButton from '../AddFilterPlateButton';
import Row from '../Common/Row';
import FilterPlate from './FilterPlate';

const FilterPlates = ({
  drillDowns,
  scope,
  isScope,
  isReport,
  isDashboardCard,
  onManualFilterChanged,
  onOpened,
  onFilterAdded,
  onFilterRemoved,
  testIdPrefix,
  label,
  isDisabled,
  isDateScopeDisabled,
}: {
  drillDowns: FilterPlateType[];
  scope: FilterPlateType[];
  isScope?: boolean;
  isReport?: boolean;
  isDashboardCard?: boolean;
  onManualFilterChanged?: () => void;
  onOpened?: () => void;
  onFilterAdded?: () => void;
  onFilterRemoved?: () => void;
  testIdPrefix?: string;
  label?: string;
  isDisabled?: boolean;
  isDateScopeDisabled?: boolean;
}) => (
  <Row style={{ flexWrap: 'wrap', gap: 8 }}>
    {isScope && (
      <React.Fragment>
        {scope.map((d) => {
          return (
            <FilterPlate
              plate={d}
              key={d.id}
              isScope={isScope}
              isReport={isReport}
              isDashboardCard={isDashboardCard}
              onManualFilterChanged={onManualFilterChanged}
              onFilterRemoved={onFilterRemoved}
              isDisabled={isDisabled}
              isDateScopeDisabled={isDateScopeDisabled}
            />
          );
        })}
      </React.Fragment>
    )}
    {!isScope && (
      <React.Fragment>
        {drillDowns.map((d) => {
          return (
            <FilterPlate
              plate={d}
              key={d.id}
              isScope={false}
              isReport={isReport}
              isDashboardCard={isDashboardCard}
              onManualFilterChanged={onManualFilterChanged}
              onFilterRemoved={onFilterRemoved}
              isDisabled={isDisabled}
              isDateScopeDisabled={isDateScopeDisabled}
            />
          );
        })}
      </React.Fragment>
    )}

    <AddReportDrillDownButton
      isScope={!!isScope}
      isReport={isReport}
      onManualFilterChanged={onManualFilterChanged}
      onOpened={onOpened}
      onFilterAdded={onFilterAdded}
      testIdPrefix={testIdPrefix}
      label={label}
      isDisabled={isDisabled}
      isDateScopeDisabled={isDateScopeDisabled}
    />
  </Row>
);

export default FilterPlates;
