import React, { useCallback, useContext, useEffect, useState } from 'react';
import FilterPlatesContext from '../../../contexts/FilterPlatesContext';
import Plate from './Plate';
import Interactive from './Interactive';
import usePopup from '../../../hooks/usePopup';
import FilterPlateContext from '../../../contexts/FilterPlateContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import aguid from 'aguid';
import VariableFiltersContext from '../../../contexts/VariableFiltersContext';
import { toFilterPlate } from '../../../migrateScope';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import useDrillDownFieldName from '../../../hooks/useDrillDownFieldName';
import WallboardContext from '../../../contexts/WallboardContext';
import DashboardContext from '../../../contexts/DashboardContext';
import useUpdateVariableFilters from '../../VariableFiltersSlideOut/useUpdateVariableFilters';
import isDashboardTemplate from '../../../types/isDashboardTemplate';
import filterPlateTypeCheckers from '../../../types/filterPlateTypeCheckers';
import filterTypeCheckers from '../../FilterPlateForm/filterTypeCheckers';
import { getWildcardFilterMode } from '../../FilterPlateForm/TextDrillDownForm/useTextFilterMode';
import toSentenceCase from '../../../services/toSentenceCase';
import SavedFilterPlate from './SavedFilterPlate';

export const getDrillDownDescription = (
  drillDown: FixedFilter,
  fieldName: string,
): string => {
  if (filterTypeCheckers.isTextFilter(drillDown)) {
    const base = (() => {
      if (drillDown.keywordsExists !== undefined) {
        if (!drillDown.keywordsExists) {
          return `${['Blanks', ...(drillDown.keywordValues || [])].join(', ')}`;
        } else {
          return `${(drillDown.keywordValues || []).join(', ')}`;
        }
      } else {
        return `${(drillDown.keywordValues || []).join(', ')}`;
      }
    })();

    if (drillDown.isExclude) {
      return `${fieldName}s excluding ${base}`;
    } else {
      return `${fieldName}s are ${base}`;
    }
  } else if (filterTypeCheckers.isWildcardFilter(drillDown)) {
    const base = drillDown.wildcardFilter.pattern;

    if (drillDown.wildcardFilter.exclude) {
      return `${fieldName}s excluding ${base}`;
    } else {
      return `${fieldName}s are ${base}`;
    }
  } else if (filterTypeCheckers.isNumberFilter(drillDown)) {
    if (drillDown.rangeValue) {
      if (drillDown.rangeValue.valueB) {
        return `${fieldName} ${drillDown.rangeValue.operator} ${drillDown.rangeValue.valueA} and ${drillDown.rangeValue.valueB}`;
      }
      return `${fieldName} ${drillDown.rangeValue.operator} ${drillDown.rangeValue.valueA}`;
    }
    if (drillDown.exists !== undefined) {
      if (drillDown.exists) {
        return `${fieldName} has some value`;
      } else {
        return `${fieldName} has no value`;
      }
    }
  } else if (filterTypeCheckers.isBooleanFilter(drillDown)) {
    if (
      drillDown.booleanValue === undefined &&
      drillDown.exists !== undefined
    ) {
      if (drillDown.exists === false) {
        return `${fieldName} has no value`;
      }
    } else {
      return `${fieldName} is ${drillDown.booleanValue}`;
    }
  } else if (filterTypeCheckers.isDateFilter(drillDown)) {
    const { dateRangeValues, exists } = drillDown;
    const mustExist = exists !== undefined && exists;
    const mustNotExist = exists !== undefined && !exists;
    if (dateRangeValues) {
      return `${fieldName}s are ${(drillDown.dateRangeValues || [])
        .map((d) => d.label)
        .join(', ')}`;
    } else if (mustExist) {
      return `${fieldName} must exist`;
    } else if (mustNotExist) {
      return `${fieldName} must not exist`;
    } else {
      return `${fieldName} is ...`;
    }
  }

  return '';
};

export const getFieldValue = (drillDown: FixedFilter): string => {
  if (filterTypeCheckers.isTextFilter(drillDown)) {
    const base = (() => {
      if (drillDown.keywordsExists !== undefined) {
        if (!drillDown.keywordsExists) {
          return `${['Blanks', ...(drillDown.keywordValues || [])].join(', ')}`;
        } else {
          return `${(drillDown.keywordValues || []).join(', ')}`;
        }
      } else {
        return `${(drillDown.keywordValues || []).join(', ')}`;
      }
    })();
    if (drillDown.isExclude) {
      return `Exclude: ${base}`;
    } else {
      return base;
    }
  } else if (filterTypeCheckers.isWildcardFilter(drillDown)) {
    const mode = getWildcardFilterMode(drillDown);
    const pattern = drillDown.wildcardFilter.pattern.replaceAll('*', '');
    if (mode) {
      return `${toSentenceCase(mode, true)} ${pattern}`;
    }
    return pattern;
  } else if (filterTypeCheckers.isDateFilter(drillDown)) {
    const { dateRangeValues, exists } = drillDown;
    const mustExist = exists !== undefined && exists;
    const mustNotExist = exists !== undefined && !exists;
    if (dateRangeValues) {
      return `${(drillDown.dateRangeValues || [])
        .map((d) => d.label)
        .join(', ')}`;
    } else if (mustExist) {
      return `must exist`;
    } else if (mustNotExist) {
      return `must not exist`;
    } else {
      return `...`;
    }
  } else if (filterTypeCheckers.isNumberFilter(drillDown)) {
    return getDrillDownDescription(drillDown, '');
  } else if (filterTypeCheckers.isBooleanFilter(drillDown)) {
    return getDrillDownDescription(drillDown, '').replace(` is `, '');
  }

  return '';
};

const replaceFieldWithAliasInDescription = (
  description: string,
  drillDown: FixedFilter,
  baseViews: { [key: string]: FleetOps.BaseView | undefined },
) => {
  const baseView = drillDown.dataset ? baseViews[drillDown.dataset] : undefined;
  const baseFieldView = baseView ? baseView.fields[drillDown.field] : undefined;

  if (baseFieldView && baseFieldView.nameAlias) {
    return description.replace(drillDown.field, baseFieldView.nameAlias);
  } else {
    return description;
  }
};

const FilterPlateContainer = ({
  drillDown,
  isScope,
  isReport,
  isDashboardCard,
  isInVariableFilterList,
  onUpdated,
  onManualFilterChanged,
  onFilterRemoved,
  isDisabled,
  isDateScopeDisabled,
}: {
  drillDown: FilterPlateType;
  isScope: boolean;
  isReport?: boolean;
  isDashboardCard?: boolean;
  isInVariableFilterList?: boolean;
  onUpdated?: (newDrillDown: FixedFilter) => void;
  onManualFilterChanged?: () => void;
  onFilterRemoved?: () => void;
  isDisabled?: boolean;
  isDateScopeDisabled?: boolean;
}) => {
  const {
    drillDowns: runTimeDs,
    setDrillDowns: setRunTimeDs,
    scope: savedDs,
    setScope: setSavedDs,
  } = useContext(FilterPlatesContext);
  const { baseViews } = useContext(BaseViewsContext);
  const { dashboard } = useContext(DashboardContext);
  const updateVariableFilters = useUpdateVariableFilters(
    isDashboardTemplate(dashboard) ? dashboard : undefined,
  );
  const { variableFilters, setVariableFilters } = useContext(
    VariableFiltersContext,
  );
  const { isWallboard } = useContext(WallboardContext);
  const drillDowns = isScope ? savedDs : runTimeDs;
  const setDrillDowns = isScope ? setSavedDs : setRunTimeDs;
  const { isOpen, open, close } = usePopup();
  const { trackEvent } = useContext(AnalyticsContext);
  const isInteractive = (() => {
    if (isDisabled) {
      return false;
    }
    if (isScope || !isReport) {
      return true;
    }
    if (drillDowns.length === 0) {
      return true;
    }

    const lastDrill = drillDowns[drillDowns.length - 1];
    if (filterPlateTypeCheckers.isFixed(lastDrill)) {
      return (
        filterPlateTypeCheckers.isFixed(drillDown) &&
        lastDrill.fixedValue.id === drillDown.fixedValue.id
      );
    } else {
      return lastDrill.id === drillDown.id;
    }
  })();

  const variableFilter = filterPlateTypeCheckers.isVariable(drillDown)
    ? variableFilters.find((vf) => vf.id === drillDown.variableId)
    : undefined;
  const drillDownValue = variableFilter
    ? variableFilter.value
    : filterPlateTypeCheckers.isFixed(drillDown)
      ? drillDown.fixedValue
      : undefined;

  const fieldName = useDrillDownFieldName(drillDownValue);

  const description = ((): string => {
    if (filterPlateTypeCheckers.isFixed(drillDown) && drillDown.fixedValue) {
      const description = getDrillDownDescription(
        drillDown.fixedValue,
        fieldName,
      );
      return replaceFieldWithAliasInDescription(
        description,
        drillDown.fixedValue,
        baseViews,
      );
    } else if (filterPlateTypeCheckers.isVariable(drillDown)) {
      const d = variableFilters.find((f) => f.id === drillDown.variableId);
      if (d) {
        return replaceFieldWithAliasInDescription(
          getDrillDownDescription(d.value, fieldName),
          d.value,
          baseViews,
        );
      }
    }

    return '';
  })();

  const fieldValue = ((): string => {
    if (filterPlateTypeCheckers.isFixed(drillDown) && drillDown.fixedValue) {
      return getFieldValue(drillDown.fixedValue);
    }

    if (
      filterPlateTypeCheckers.isVariable(drillDown) &&
      !!drillDown.variableId
    ) {
      const variableFilter = variableFilters.find(
        (f) => f.id === drillDown.variableId,
      );
      if (variableFilter) {
        return getFieldValue(variableFilter.value);
      }
    }
    return '';
  })();

  const onRemoved = useCallback(() => {
    if (isReport) {
      if (isScope) {
        trackEvent('Report - Edit - Scope Changed');
      } else {
        trackEvent('Report - Filter Changed');
      }
    }

    if (isDashboardCard) {
      trackEvent('Dashboard Card - Edit - Filter Changed', {
        isWallboardSlide: isWallboard ? 'true' : 'false',
      });
    }

    if (onManualFilterChanged) {
      onManualFilterChanged();
    }

    if (onFilterRemoved) {
      onFilterRemoved();
    }

    setDrillDowns(drillDowns.filter((d) => d.id !== drillDown.id));
  }, [
    drillDown.id,
    drillDowns,
    isDashboardCard,
    isReport,
    isScope,
    isWallboard,
    onFilterRemoved,
    onManualFilterChanged,
    setDrillDowns,
    trackEvent,
  ]);

  const isVariable = filterPlateTypeCheckers.isVariable(drillDown);
  const onSetToVariable = useCallback(() => {
    if (filterPlateTypeCheckers.isFixed(drillDown)) {
      const variableId = (() => {
        const currentVarFilterForField = variableFilters.find(
          (vf) => vf.value.field === drillDown.fixedValue.field,
        );
        if (currentVarFilterForField) {
          return currentVarFilterForField.id;
        }

        const newVariableFilter: VariableDrillDownType = {
          id: aguid(),
          type: 'Variable',
          value: drillDown.fixedValue,
        };

        const newFilters = [...variableFilters, newVariableFilter];
        setVariableFilters(newFilters);
        if (updateVariableFilters) {
          updateVariableFilters(newFilters);
        }

        return newVariableFilter.id;
      })();

      const newPlate = {
        id: drillDown.id,
        type: 'Variable',
        variableId,
      } as FilterPlateType;
      if (!isScope) {
        setRunTimeDs((ds) => {
          return ds.map((d) => {
            if (d.id === drillDown.id) {
              return newPlate;
            } else {
              return d;
            }
          });
        });
      } else {
        setSavedDs((ds) => {
          return ds.map((d) => {
            if (d.id === drillDown.id) {
              return newPlate;
            } else {
              return d;
            }
          });
        });
      }
    }
  }, [
    drillDown,
    isScope,
    setRunTimeDs,
    setSavedDs,
    setVariableFilters,
    updateVariableFilters,
    variableFilters,
  ]);

  const onSetToFixed = useCallback(() => {
    if (filterPlateTypeCheckers.isVariable(drillDown)) {
      const variableFilter = variableFilters.find(
        (f) => f.id === drillDown.variableId,
      );
      if (variableFilter) {
        const newPlate = toFilterPlate(variableFilter.value);
        if (!isScope) {
          setRunTimeDs((ds) => {
            return ds.map((d) => {
              if (d.id === drillDown.id) {
                return newPlate;
              } else {
                return d;
              }
            });
          });
        } else {
          setSavedDs((ds) => {
            return ds.map((d) => {
              if (d.id === drillDown.id) {
                return newPlate;
              } else {
                return d;
              }
            });
          });
        }
      }
    }
  }, [drillDown, isScope, setRunTimeDs, setSavedDs, variableFilters]);

  const getNetDrillDown = useCallback(() => {
    if (filterPlateTypeCheckers.isVariable(drillDown)) {
      const variableFilter = variableFilters.find(
        (f) => f.id === drillDown.variableId,
      );

      if (variableFilter) {
        return variableFilter.value;
      }
    } else if (filterPlateTypeCheckers.isFixed(drillDown)) {
      return drillDown.fixedValue;
    }
  }, [drillDown, variableFilters]);
  const [netDrillDown, setNetDrillDown] = useState<FixedFilter | undefined>(
    () => getNetDrillDown(),
  );
  useEffect(() => {
    setNetDrillDown(getNetDrillDown());
  }, [getNetDrillDown]);

  if (!netDrillDown) {
    return null;
  }

  if (isInteractive) {
    return (
      <FilterPlateContext.Provider value={{ isOpen }}>
        <Interactive
          label={fieldName}
          fieldValue={fieldValue}
          onRemoved={onRemoved}
          drillDown={netDrillDown}
          isOpen={isOpen}
          open={open}
          close={close}
          isScope={isScope}
          isVariable={isVariable}
          onSetToVariable={onSetToVariable}
          onSetToFixed={onSetToFixed}
          isInVariableFilterList={isInVariableFilterList}
          onUpdated={onUpdated}
          isTemplate={isDashboardTemplate(dashboard)}
          onManualFilterChanged={onManualFilterChanged}
          isDateScopeDisabled={isDateScopeDisabled}
        />
      </FilterPlateContext.Provider>
    );
  } else {
    return (
      <Plate
        description={description}
        onSetToFixed={onSetToFixed}
        isVariable={isVariable}
        onSetToVariable={onSetToVariable}
        isTemplate={isDashboardTemplate(dashboard)}
      />
    );
  }
};

const Gate = ({
  plate,
  isScope,
  isReport,
  isDashboardCard,
  isInVariableFilterList,
  onUpdated,
  onManualFilterChanged,
  onFilterRemoved,
  isDisabled,
  isDateScopeDisabled,
}: {
  plate: FilterPlateType;
  isScope: boolean;
  isReport?: boolean;
  isDashboardCard?: boolean;
  isInVariableFilterList?: boolean;
  onUpdated?: (newDrillDown: FixedFilter) => void;
  onManualFilterChanged?: () => void;
  onFilterRemoved?: () => void;
  isDisabled?: boolean;
  isDateScopeDisabled?: boolean;
}) => {
  if (filterPlateTypeCheckers.isSavedFilter(plate)) {
    return (
      <SavedFilterPlate
        key={plate.id}
        savedFilterId={plate.datasetFilterId}
        isScope={!!isScope}
      />
    );
  } else {
    return (
      <FilterPlateContainer
        drillDown={plate}
        key={plate.id}
        isScope={!!isScope}
        isReport={isReport}
        isDashboardCard={isDashboardCard}
        onManualFilterChanged={onManualFilterChanged}
        onFilterRemoved={onFilterRemoved}
        isDisabled={isDisabled}
        isDateScopeDisabled={isDateScopeDisabled}
        isInVariableFilterList={isInVariableFilterList}
        onUpdated={onUpdated}
      />
    );
  }
};

export default Gate;
