import { useContext } from 'react';
import FilterPlateFormContext from 'contexts/FilterPlateFormContext';
import filterTypeCheckers from '../filterTypeCheckers';

const useEditingDrillDown = () => {
  const { drillDown } = useContext(FilterPlateFormContext);
  const { editingTextDrillDown, editingWildcardDrillDown } = (() => {
    if (drillDown && filterTypeCheckers.isTextFilter(drillDown)) {
      return {
        editingTextDrillDown: drillDown,
        editingWildcardDrillDown: undefined,
      };
    }

    if (drillDown && filterTypeCheckers.isWildcardFilter(drillDown)) {
      return {
        editingTextDrillDown: undefined,
        editingWildcardDrillDown: drillDown,
      };
    }

    return {
      editingTextDrillDown: undefined,
      editingWildcardDrillDown: undefined,
    };
  })();

  return {
    editingTextDrillDown,
    editingWildcardDrillDown,
  };
};

export default useEditingDrillDown;
