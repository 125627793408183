import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ChartDefinitionsContext from '../../../contexts/ChartDefinitionsContext';
import useChartDefinitions from '../../../hooks/useChartDefinitions';
import MetricsProvider from './MetricsProvider';
import CompoundMetricsProvider from './CompoundMetricsProvider';
import SpecialMetricsProvider from './SpecialMetricsProvider';
import useSingleMetricHistogramDefinitions from '../../../hooks/useSingleMetricHistogramDefinitions';
import MetricOptionsProvider from './MetricOptionsProvider';
import useGaugeDefinitions from '../../../hooks/useGaugeDefinitions';
import useRankingMatrixDefinitions from '../../../hooks/useRankingMatrixDefinitions';
import PerformanceDatasetMetricsProvider from './PerformanceDatasetMetricsProvider';
import MetricListsProvider from './MetricListsProvider';
import usePaceMatrixDefinitions from '../../../hooks/usePaceMatrixDefinitions';
import ExcludedPaceDatesProvider from './ExcludedPaceDatesProvider';

const ChartDefinitionsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [allDefs, setAllDefs] = useState<VisualisationDefinition[]>([]);
  const [definitionsLookup, setDefinitionsLookup] = useState<{
    [id: string]: VisualisationDefinition | undefined;
  }>({});
  const { chartDefs, isLoading: isLoadingChartDefs } = useChartDefinitions();

  const {
    chartDefs: singleMetricHistogramDefs,
    isLoading: isLoadingSingleMetricHistograms,
  } = useSingleMetricHistogramDefinitions();

  const { paceMatrixDefinitions, isLoadingPaceMatrixDefinitions } =
    usePaceMatrixDefinitions();

  const { gaugeDefs, isLoading: isLoadingGauges } = useGaugeDefinitions();

  const { rankingMatrixDefs, isLoading: isLoadingRankingMatrix } =
    useRankingMatrixDefinitions();

  useEffect(() => {
    const newDefs = [
      ...chartDefs,
      ...singleMetricHistogramDefs,
      ...paceMatrixDefinitions,
      ...gaugeDefs,
      ...rankingMatrixDefs,
    ];

    setAllDefs(_.sortBy(newDefs, (item) => item.name.toLowerCase()));
  }, [
    chartDefs,
    gaugeDefs,
    paceMatrixDefinitions,
    rankingMatrixDefs,
    singleMetricHistogramDefs,
  ]);

  useEffect(() => {
    const newLookup = {} as {
      [id: string]: VisualisationDefinition | undefined;
    };
    allDefs.forEach((def) => {
      newLookup[def.id] = def;
    });
    setDefinitionsLookup(newLookup);
  }, [allDefs]);

  const isLoading =
    isLoadingChartDefs ||
    isLoadingSingleMetricHistograms ||
    isLoadingPaceMatrixDefinitions ||
    isLoadingGauges ||
    isLoadingRankingMatrix;

  return (
    <MetricsProvider>
      <CompoundMetricsProvider>
        <SpecialMetricsProvider>
          <MetricOptionsProvider>
            <PerformanceDatasetMetricsProvider>
              <ExcludedPaceDatesProvider>
                <ChartDefinitionsContext.Provider
                  value={{
                    isLoading,
                    definitions: allDefs,
                    definitionsLookup,
                  }}
                >
                  <MetricListsProvider>{children}</MetricListsProvider>
                </ChartDefinitionsContext.Provider>
              </ExcludedPaceDatesProvider>
            </PerformanceDatasetMetricsProvider>
          </MetricOptionsProvider>
        </SpecialMetricsProvider>
      </CompoundMetricsProvider>
    </MetricsProvider>
  );
};

export default ChartDefinitionsProvider;
