import React, { useContext, useEffect, useState } from 'react';
import BaseViewsContext from 'contexts/BaseViewsContext';

const useDataSourceOptions = ({
  onDataTypeChanged,
  setDrillDowns,
}: {
  onDataTypeChanged: (newType: string) => void;
  setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlateType[]>>;
}) => {
  const { dataTypeOptions } = useContext(BaseViewsContext);
  const [dataSourceOptions, setDataSourceOptions] = useState<DropdownOption[]>(
    [],
  );

  useEffect(() => {
    setDataSourceOptions(
      dataTypeOptions.map(({ label, value }) => {
        return {
          label,
          value,
          onSelected: () => {
            const dataset = value;
            onDataTypeChanged(dataset);
          },
        };
      }),
    );
  }, [dataTypeOptions, onDataTypeChanged, setDrillDowns]);

  return dataSourceOptions;
};

export default useDataSourceOptions;
