import { DASHBOARDS_COLLECTION } from '../constants';
import firebase from 'firebase/compat/app';

const createDashboardAlt = async ({
  dashboard,
  accountRef,
}: {
  dashboard: DashboardType;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  await accountRef
    .collection(DASHBOARDS_COLLECTION)
    .doc(dashboard.id)
    .set(dashboard);
};

export default createDashboardAlt;
