import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const DATASET_FILTER_DOC = 'datasetFilters';
export const CURRENT_DATASET_FILTER_VERSION = 'v1';

const savedFilterConverter = {
  toFirestore(databaseFilter: SavedFilter): firebase.firestore.DocumentData {
    return withoutNulls({ ...databaseFilter });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<SavedFilter>,
    options: firebase.firestore.SnapshotOptions,
  ): SavedFilter {
    const data = snapshot.data(options);
    return data;
  },
};

/**
 * Path: accounts/:accountId/filterDefinitions/:datasetFilters/v1/:dataset
 */
const getSavedFiltersRef = ({ accountId }: { accountId: string }) => {
  return firebase
    .firestore()
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.FILTER_DEFINITIONS_COLLECTION)
    .doc(DATASET_FILTER_DOC)
    .collection(CURRENT_DATASET_FILTER_VERSION)
    .withConverter(savedFilterConverter);
};

export default getSavedFiltersRef;
