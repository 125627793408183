import React from 'react';

import styled from 'styled-components';
import Icon from '../../kingpin/atoms/Icon';
import { GHOST_HOVER_BACKGROUND_COLOR } from '../../kingpin/atoms/Button';

const FreshnessTooltipIconDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 8px;
  cursor: help;
  &:hover {
    opacity: 0.7;
    background-color: ${GHOST_HOVER_BACKGROUND_COLOR};
  }
`;

const FreshnessTooltipCTA = () => (
  <FreshnessTooltipIconDiv data-testid="dataset-freshness-cta">
    <Icon color={'#808080'} icon={'clock'} width={14} height={14} />
  </FreshnessTooltipIconDiv>
);

export default FreshnessTooltipCTA;
