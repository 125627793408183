import React, { ChangeEvent } from 'react';

import Row from '../../../Common/Row';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import FilterPlates from '../../../FilterPlates';
import Inputs from '../../../Inputs';
import AdvancedTargetInput from './AdvancedTargetInput';
import ReportSelector from '../../../ConfigureDashboardGadget/ReportSelector';
import DateFieldPicker from './DateFieldPicker';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';
import { DefaultFiltersToggleForMetricId } from '../../../DefaultFiltersToggle';

const KpiForm = ({
  name,
  onNameChange,
  metricId,
  setMetricId,
  cadence,
  targetMode,
  setTargetMode,
  basicTarget,
  setBasicTarget,
  advancedTarget,
  setAdvancedTarget,
  startDate,
  endDate,
  isEditing,
  onClose,
  onSave,
  isValid,
  aboveTargetIsGoodOptions,
  isSaving,
  reportId,
  setReportId,
  boardId,
  setBoardId,
  dataTypes,
  dateField,
  setDateField,
}: {
  dataTypes: string[];
  name?: string;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  metricId?: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  drillDowns: FilterPlateType[];
  setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlateType[]>>;
  cadence: FleetOps.Interval;
  targetMode?: 'basic' | 'advanced';
  setTargetMode: React.Dispatch<
    React.SetStateAction<'basic' | 'advanced' | undefined>
  >;
  basicTarget: number;
  setBasicTarget: React.Dispatch<React.SetStateAction<number | undefined>>;
  advancedTarget: GoalAdvancedTarget;
  setAdvancedTarget: React.Dispatch<React.SetStateAction<GoalAdvancedTarget>>;
  aboveTargetIsGoodOptions: RadioOption[];
  startDate: string;
  endDate: string;
  isEditing: boolean;
  onClose: () => void;
  onSave: () => void;
  isValid: boolean;
  isSaving: boolean;
  reportId?: string;
  setReportId: React.Dispatch<React.SetStateAction<string | undefined>>;
  boardId?: string;
  setBoardId: React.Dispatch<React.SetStateAction<string | undefined>>;
  dateField?: string;
  setDateField: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => (
  <Form
    data-testid="kpi-form"
    onClick={(event) => {
      event.stopPropagation();
    }}
  >
    <FormHeader title={isEditing ? 'Edit KPI' : 'Add KPI'} onClose={onClose} />
    <FormContent>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Name</Typography.Body>
        <Inputs.TextInput
          value={name || ''}
          onChange={onNameChange}
          placeholder={'Enter a title for your KPI'}
          data-testid={'kpi-title'}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Set a target on</Typography.Body>
        <Inputs.MetricPicker
          metricId={metricId}
          setMetricId={setMetricId}
          testIdPrefix={'kpi'}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Filters</Typography.Body>
        <FilterPlates testIdPrefix={'kpi'} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <DefaultFiltersToggleForMetricId metricId={metricId} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Query Date Field</Typography.Body>
        <DateFieldPicker
          dataTypes={dataTypes}
          dateField={dateField}
          setDateField={setDateField}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Target enabled</Typography.Body>
        <Inputs.Toggle
          value={targetMode !== undefined}
          onChange={() => {
            if (targetMode === undefined) {
              setTargetMode('basic');
            } else {
              setTargetMode(undefined);
            }
          }}
        />
      </div>
      {targetMode !== undefined && (
        <>
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Target Values</Typography.Body>
            <div>
              <AdvancedTargetInput
                isKpi
                target={basicTarget}
                setTarget={setBasicTarget}
                cadence={cadence}
                targetMode={targetMode}
                setTargetMode={setTargetMode}
                advancedTarget={advancedTarget}
                setAdvancedTarget={setAdvancedTarget}
                startDate={startDate}
                endDate={endDate}
                testIdPrefix={'kpi'}
              />
            </div>
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Above target is</Typography.Body>
            <Inputs.Radio options={aboveTargetIsGoodOptions} />
          </div>
        </>
      )}
      <div style={{ marginBottom: 16 }}>
        <ReportSelector
          reportId={reportId}
          setReportId={setReportId}
          boardId={boardId}
          setBoardId={setBoardId}
          metricId={metricId}
          isBlockInput
          label="Drilldown"
        />
      </div>
    </FormContent>
    <Row spaceBetween centerAlign style={{ marginBottom: 16 }}>
      <Button onClick={onClose} type="Ghost" size="Small" label="Cancel" />
      <Button
        onClick={onSave}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
        testId={'kpi-add'}
        label={isEditing ? 'Update' : 'Add'}
        type="Primary"
        size="Small"
      />
    </Row>
  </Form>
);

export default KpiForm;
