import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../Common/Row';
import FilterPlates from '../../../FilterPlates';
import DateFieldPicker from '../../../Goals/GeneralGoalForm/KpiForm/DateFieldPicker';
import Inputs from '../../../Inputs';
import ReportSelector from '../../../ConfigureDashboardGadget/ReportSelector';
import TargetBands from './TargetBands';
import TargetBandsForm from './TargetBandsForm';
import ScorecardUsageWarning from '../../ScorecardUsageWarning';
import Colors2 from '../../../../theme/Colors2';
import Tooltip from '../../../Tooltip';
import ScorecardContext from '../../../../contexts/ScorecardContext';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import targetDateToString from '../../../../types/scorecardDates/targetDateToString';
import Icon from '../../../../kingpin/atoms/Icon';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import { DefaultFiltersToggleForMetricId } from '../../../DefaultFiltersToggle';

const KpiFormDiv = styled(Form)`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.div`
  height: 600px;
  overflow-y: auto;
`;

export const DisableTargetsCheckbox = ({
  isTargetsDisabled,
  setIsTargetsDisabled,
  markHasUnsavedChanges,
}: {
  isTargetsDisabled: boolean;
  setIsTargetsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  markHasUnsavedChanges: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  return (
    <Row centerAlign style={{ marginBottom: 8 }}>
      <div style={{ marginRight: 8 }}>
        <Inputs.Checkbox
          isChecked={isTargetsDisabled}
          onToggled={() => {
            setIsTargetsDisabled(!isTargetsDisabled);
            markHasUnsavedChanges();
            trackEvent('KPI List - KPI - Set targets later Clicked');
          }}
          testId={'disable-targets'}
        />
      </div>

      <Typography.Body type="Label">Set targets later</Typography.Body>
    </Row>
  );
};

export const DynamicTargetCheckbox = ({
  isDynamicTargetByDefault,
  dynamicTargetOverride,
  onDynamicTargetOverrideToggled,
}: {
  isDynamicTargetByDefault: boolean;
  dynamicTargetOverride?: boolean;
  onDynamicTargetOverrideToggled: (newValue: boolean) => void;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const [tooltip, setTooltip] = useState<string>('');

  useEffect(() => {
    if (scorecard.cadence === 'week') {
      setTooltip(
        'This setting shows progress for the current week based on how many days of the week have passed. For example, if the weekly target is set to 7000 and 2 days of the week have passed, the dynamic target will be 2000',
      );
    } else {
      setTooltip(
        'This setting shows progress for the current month based on how many days of the month have passed. For example, if the monthly target is set to 3000 and 10 days of the month have passed, the dynamic target will be 1000 assuming that the month has 30 days.',
      );
    }
  }, [scorecard.cadence]);

  return (
    <Row centerAlign style={{ marginBottom: 8 }}>
      <div style={{ marginRight: 8 }}>
        <Inputs.Checkbox
          isChecked={
            dynamicTargetOverride === undefined
              ? isDynamicTargetByDefault
              : dynamicTargetOverride
          }
          onToggled={() => {
            onDynamicTargetOverrideToggled(
              dynamicTargetOverride === undefined
                ? !isDynamicTargetByDefault
                : !dynamicTargetOverride,
            );
          }}
        />
      </div>

      <Typography.Body type="Label">
        Use dynamic targets for current period
      </Typography.Body>

      <div style={{ marginLeft: 8 }}>
        <Tooltip content={tooltip}>
          <Icon icon="info" color={Colors2.Grey['2']} />
        </Tooltip>
      </div>
    </Row>
  );
};

export const ColorOrderCheckbox = ({
  isColorOrderReversed,
  onReverseColorOrderToggled,
}: {
  isColorOrderReversed: boolean;
  onReverseColorOrderToggled: () => void;
}) => {
  return (
    <Row centerAlign style={{ marginBottom: 8 }}>
      <div style={{ marginRight: 8 }}>
        <Inputs.Checkbox
          testId={'color-order'}
          isChecked={isColorOrderReversed}
          onToggled={onReverseColorOrderToggled}
        />
      </div>

      <Typography.Body type="Label">Reverse color order</Typography.Body>
    </Row>
  );
};

const KpiForm = ({
  scorecard,
  isEditing,
  isShowingAdvanced,
  onAdvancedToggled,
  metricId,
  setMetricId,
  close,
  dataTypes,
  dateField,
  setDateField,
  displayName,
  onDisplayNameChanged,
  isTargetsDisabled,
  setIsTargetsDisabled,
  sortedTargetBands,
  targets,
  setTargets,
  onTargetBandSaved,
  onNewTargetBandSaved,
  onAddClicked,
  onNewTargetBandCancelled,
  isEditingDateTarget,
  setIsEditingDateTarget,
  isAddingNewTargetBand,
  isColorOrderReversed,
  onReverseColorOrderToggled,
  reportDrillDownId,
  setReportDrillDownId,
  boardDrillDownId,
  setBoardDrillDownId,
  trackDrillDownAdded,
  trackQueryDateSelected,
  setCanSaveKpi,
  isDynamicTargetByDefault,
  dynamicTargetOverride,
  onDynamicTargetOverrideToggled,
  markHasUnsavedChanges,
  scrollingContentRef,
  isContentScrollable,
  onSave,
  isNotValidMessage,
  isValid,
  isLoading,
}: {
  scorecard: Scorecards.Scorecard;
  isEditing?: boolean;
  isShowingAdvanced: boolean;
  onAdvancedToggled: () => void;
  metricId?: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  close: () => void;
  dataTypes: string[];
  dateField?: string;
  setDateField: React.Dispatch<React.SetStateAction<string | undefined>>;
  displayName?: string;
  onDisplayNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isTargetsDisabled: boolean;
  setIsTargetsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  sortedTargetBands: {
    date: Scorecards.IsoDate | Scorecards.WeekDate;
    band: string[] | undefined;
  }[];
  targets: Scorecards.WeekTargets | Scorecards.IsoTargets;
  setTargets: React.Dispatch<
    React.SetStateAction<Scorecards.WeekTargets | Scorecards.IsoTargets>
  >;
  onTargetBandSaved: (
    newStartDate: Scorecards.IsoDate | Scorecards.WeekDate,
    newBands: string[],
  ) => void;
  onNewTargetBandSaved: (
    newStartDate: Scorecards.IsoDate | Scorecards.WeekDate,
    newBands: string[],
  ) => void;
  onNewTargetBandCancelled: () => void;
  isEditingDateTarget: boolean;
  setIsEditingDateTarget: React.Dispatch<React.SetStateAction<boolean>>;
  onAddClicked: () => void;
  isAddingNewTargetBand: boolean;
  isColorOrderReversed: boolean;
  onReverseColorOrderToggled: () => void;
  reportDrillDownId?: string;
  setReportDrillDownId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  boardDrillDownId?: string;
  setBoardDrillDownId: React.Dispatch<React.SetStateAction<string | undefined>>;
  trackDrillDownAdded: ({
    type,
    id,
    name,
  }: {
    type: 'Report' | 'Board';
    id: string;
    name: string;
  }) => void;
  trackQueryDateSelected: () => void;
  setCanSaveKpi: React.Dispatch<React.SetStateAction<boolean>>;
  isDynamicTargetByDefault: boolean;
  dynamicTargetOverride?: boolean;
  onDynamicTargetOverrideToggled: (newValue: boolean) => void;
  markHasUnsavedChanges: () => void;
  scrollingContentRef: React.MutableRefObject<HTMLDivElement | null>;
  isContentScrollable: boolean;
  onSave: () => void;
  isNotValidMessage?: string;
  isValid: boolean;
  isLoading: boolean;
}) => (
  <KpiFormDiv>
    <div>
      <FormHeader
        title={isEditing ? 'Edit KPI' : 'Add KPI'}
        titleExtra={
          <div style={{ marginLeft: 16 }}>
            <Button
              type="Tertiary"
              size="Small"
              label={isShowingAdvanced ? 'Go to Basic' : 'Go to Advanced'}
              onClick={onAdvancedToggled}
            />
          </div>
        }
        onClose={close}
      />
      <ScorecardUsageWarning scorecard={scorecard} />
      <Content ref={(c) => (scrollingContentRef.current = c)}>
        <div style={{ marginBottom: 16, maxWidth: 400 }}>
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Label">Select metric</Typography.Body>
          </div>
          <Inputs.MetricPicker
            metricId={metricId}
            setMetricId={setMetricId}
            placeholder="Pick a metric"
            onMetricSelected={markHasUnsavedChanges}
          />
          <div style={{ marginBottom: 16, marginTop: 16 }}>
            <div style={{ marginBottom: 8 }}>
              <Typography.Body type="Label">Select query date</Typography.Body>
            </div>
            <DateFieldPicker
              dataTypes={dataTypes}
              dateField={dateField}
              setDateField={setDateField}
              trackQueryDateSelected={trackQueryDateSelected}
            />
          </div>
          <div style={{ marginTop: '16px' }}>
            <div style={{ marginBottom: 16 }}>
              <div style={{ marginBottom: 8 }}>
                <Typography.Body type="Label">
                  {' '}
                  Run time filters{' '}
                  <span style={{ color: Colors2.Grey['5'] }}>(optional)</span>
                </Typography.Body>
              </div>
              <FilterPlates
                testIdPrefix={'kpi'}
                onManualFilterChanged={markHasUnsavedChanges}
              />
            </div>
            <div style={{ marginBottom: 16 }}>
              <div style={{ marginBottom: 8 }}>
                <Typography.Body type="Label">
                  Scope{' '}
                  <span style={{ color: Colors2.Grey['5'] }}>(optional)</span>
                </Typography.Body>
              </div>
              <FilterPlates
                isScope
                testIdPrefix={'kpi-scope'}
                onManualFilterChanged={markHasUnsavedChanges}
              />
            </div>
            <div style={{ marginBottom: 16 }}>
              <DefaultFiltersToggleForMetricId metricId={metricId} />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: 16, maxWidth: 400 }}>
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Label">Display name</Typography.Body>
          </div>
          <Inputs.TextInput
            value={displayName || ''}
            onChange={onDisplayNameChanged}
            placeholder="Enter a name for your KPI"
            inputSize="Small"
            style={{ width: 400 }}
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Label">Targets</Typography.Body>
          </div>
          <DisableTargetsCheckbox
            isTargetsDisabled={isTargetsDisabled}
            setIsTargetsDisabled={setIsTargetsDisabled}
            markHasUnsavedChanges={markHasUnsavedChanges}
          />
          {!isTargetsDisabled && isShowingAdvanced && (
            <>
              <DynamicTargetCheckbox
                dynamicTargetOverride={dynamicTargetOverride}
                isDynamicTargetByDefault={isDynamicTargetByDefault}
                onDynamicTargetOverrideToggled={onDynamicTargetOverrideToggled}
              />
              <ColorOrderCheckbox
                isColorOrderReversed={isColorOrderReversed}
                onReverseColorOrderToggled={onReverseColorOrderToggled}
              />
            </>
          )}
        </div>

        {!isTargetsDisabled && (
          <>
            <div style={{ marginBottom: 16 }}>
              {!isAddingNewTargetBand && !isEditingDateTarget && (
                <div style={{ width: 'fit-content', marginBottom: 16 }}>
                  <Button
                    label={'Add new'}
                    onClick={onAddClicked}
                    type={'Tertiary'}
                    size="Small"
                  />
                </div>
              )}
              {isAddingNewTargetBand && (
                <TargetBandsForm
                  initialStartDate={
                    Object.keys(targets.targets).length === 0
                      ? scorecard.startDate
                      : undefined
                  }
                  targets={targets}
                  isStartDateEditable={Object.keys(targets.targets).length > 0}
                  isColorOrderReversed={isColorOrderReversed}
                  onCancelled={onNewTargetBandCancelled}
                  onSaved={onNewTargetBandSaved}
                  setCanSaveKpi={setCanSaveKpi}
                  markHasUnsavedChanges={markHasUnsavedChanges}
                />
              )}

              {sortedTargetBands.map(({ date, band }) => {
                if (band === undefined) {
                  return null;
                }

                return (
                  <TargetBands
                    key={`${targetDateToString(date)}-bands`}
                    startDate={date}
                    targetBands={band}
                    targets={targets}
                    setTargets={setTargets}
                    isColorOrderReversed={isColorOrderReversed}
                    onTargetBandSaved={onTargetBandSaved}
                    isAddingNewTargetBand={isAddingNewTargetBand}
                    isEditingDateTarget={isEditingDateTarget}
                    setIsEditingDateTarget={setIsEditingDateTarget}
                    setCanSaveKpi={setCanSaveKpi}
                    markHasUnsavedChanges={markHasUnsavedChanges}
                  />
                );
              })}
            </div>
          </>
        )}

        <div style={{ marginBottom: 16, maxWidth: 400 }}>
          <ReportSelector
            reportId={reportDrillDownId}
            setReportId={setReportDrillDownId}
            boardId={boardDrillDownId}
            setBoardId={setBoardDrillDownId}
            metricId={metricId}
            label="Select drill down view (optional)"
            placeholder="Search available reports/boards"
            trackDrillDownAdded={trackDrillDownAdded}
            onChanged={markHasUnsavedChanges}
          />
        </div>
      </Content>
    </div>
    <Row
      spaceBetween
      centerAlign
      isBoxShadowTop={isContentScrollable}
      style={{ paddingTop: 16 }}
    >
      <Button label={'Cancel'} onClick={close} type="Tertiary" size="Small" />
      <Button
        onClick={onSave}
        isDisabled={!isValid}
        isDisabledMessage={isNotValidMessage}
        isLoading={isLoading}
        label="Save"
        type="Primary"
        size="Small"
      />
    </Row>
  </KpiFormDiv>
);

export default KpiForm;
