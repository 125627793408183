export const isEmptyRow = (
  row: Scorecards.ScorecardKpi | Scorecards.EmptyRow | Scorecards.ManualKpiRow,
): row is Scorecards.EmptyRow => 'type' in row && row.type === 'emptyRow';

export const isManualKpiRow = (
  row: Scorecards.ScorecardKpi | Scorecards.EmptyRow | Scorecards.ManualKpiRow,
): row is Scorecards.ManualKpiRow => {
  return 'type' in row && row.type === 'manualKpiRow';
};

export const isKpiRow = (
  row: Scorecards.ScorecardKpi | Scorecards.EmptyRow | Scorecards.ManualKpiRow,
): row is Scorecards.ScorecardKpi => !isEmptyRow(row) && !isManualKpiRow(row);

export const isNormalMetricInfo = (
  row:
    | MetricsInfoButton.MetricInfo
    | MetricsInfoButton.ManualKpiInfo
    | MetricsInfoButton.NormalMetricInfo,
): row is MetricsInfoButton.NormalMetricInfo =>
  'type' in row && row.type === 'NormalMetricInfo';

export const isManualKpiInfo = (
  row:
    | MetricsInfoButton.MetricInfo
    | MetricsInfoButton.ManualKpiInfo
    | MetricsInfoButton.NormalMetricInfo,
): row is MetricsInfoButton.ManualKpiInfo =>
  'type' in row && row.type === 'ManualKpiInfo';
