import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from '@atlaskit/modal-dialog';
import useUser from '../../../../hooks/useUser';
import Typography from 'kingpin/atoms/Typography';
import getUserDisplayName from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/getUserDisplayName';

const ViewInfoPopup = ({
  metric,
  close,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  close: () => void;
}) => {
  const createdBy = useUser(metric.createdBy);
  const updatedBy = useUser(metric.updatedBy);

  const [info, setInfo] = useState<{
    createdByName?: string;
    createdOnText?: string;
    lastModifiedByName?: string;
    lastModifiedOnText?: string;
  }>({
    createdByName: '',
    createdOnText: '',
    lastModifiedByName: '',
    lastModifiedOnText: '',
  });

  useEffect(() => {
    setInfo({
      createdByName: createdBy.user
        ? getUserDisplayName(createdBy.user)
        : undefined,
      createdOnText: metric.createdOn
        ? moment.utc(metric.createdOn).format("Do MMM 'YY - HH:mm")
        : undefined,
      lastModifiedByName: updatedBy.user
        ? getUserDisplayName(updatedBy.user)
        : undefined,
      lastModifiedOnText: metric.updatedOn
        ? moment.utc(metric.updatedOn).format("Do MMM 'YY - HH:mm")
        : undefined,
    });
  }, [createdBy.user, metric.createdOn, metric.updatedOn, updatedBy.user]);

  return (
    <Modal
      onClose={close}
      shouldScrollInViewport={false}
      autoFocus={false}
      width={800}
    >
      <div style={{ padding: 16 }}>
        {info.createdByName && info.createdOnText && (
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Created By:</Typography.Body>
            <div>
              <Typography.Body type="Body 12">
                {info.createdByName}
              </Typography.Body>
            </div>
            <Typography.Body type="Body 12">
              {info.createdOnText}
            </Typography.Body>
          </div>
        )}

        {info.lastModifiedByName && info.lastModifiedOnText && (
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Last modified by:</Typography.Body>
            <div>
              <Typography.Body type="Body 12">
                {info.lastModifiedByName}
              </Typography.Body>
            </div>
            <Typography.Body type="Body 12">
              {info.lastModifiedOnText}
            </Typography.Body>
          </div>
        )}

        {!info.lastModifiedByName && !info.lastModifiedOnText && (
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Last modified by:</Typography.Body>
            <div>
              <Typography.Body type="Body 12">FleetOps Admin</Typography.Body>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewInfoPopup;
