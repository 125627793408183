import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { useParams } from 'react-router-dom';
import Button from 'kingpin/atoms/Button';

import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import isDefined from '../../../isDefined';
import Grid from '../../../components/Grid/Grid';
import buildGridOptions from '../../../components/Grid/buildGridOptions';
import Loading from '../../../components/Loading/Loading';
import { getKpiTargetForMonth } from '../../../components/Goals/GeneralGoalForm/KpiForm/AdvancedTargetInput/MonthlyTargetBuilder';
import AccountContext from '../../../contexts/AccountContext';
import PerformanceCell from '../../../components/PerformanceTargetsBoard/PerformanceCell/PerformanceCell';
import Tooltip from '../../../components/Tooltip';
import { TooltipContent } from '../../../components/ProgressCellRenderer';
import Row from '../../../components/Common/Row';
import { DeltaNumber } from '../../../components/OnTargetCellRenderer';
import colors from '../../../theme/colors';
import FlexCentered from '../../../components/Common/FlexCentered';
import Colors2 from '../../../theme/Colors2';
import usePopup from '../../../hooks/usePopup';
import KpiForm from '../../../components/Goals/GeneralGoalForm/KpiForm';
import updateGoal from '../../../hooks/goals/updateGoal';
import Cell from '../../../components/V5Gadget/Matrix/Cell';
import HideUnlessMouseOver from '../../../components/HideUnlessMouseOver';
import GoalIntervalRenderer from '../GoalIntervalRenderer';
import formatDateLabel from '../../../components/V5Gadget/formatDateLabel';
import formatFloat from '../../../api/getChartSeries/formatFloat';
import GoalContext from '../../../contexts/GoalContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import ReportsContext from '../../../contexts/ReportsContext';
import BoardsContext from '../../../contexts/BoardsContext';
import PopupContext from '../../../contexts/PopupContext';
import PermissionGates from '../../../components/PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import visTypeCheckers from '../../../types/visTypeCheckers';
import useValueFormatters from '../../../hooks/useValueFormatters';
import WallboardContext from 'contexts/WallboardContext';
import { RowHeightContext } from '../../../contextProviders/SplashScreenProviders/MiscProviders/RowHeightProvider';

export const TREND_CELL_RENDERER = 'TREND_CELL_RENDERER';
export const KPI_NAME_RENDERER = 'KPI_NAME_RENDERER';

const handleSuppressKeyboardEvent = () => true;

interface KpiCellColDef extends ColDef {
  cellRendererParams: {
    date: string;
    isCard?: boolean;
  };
}

interface KpiNameColDef extends ColDef {
  cellRendererParams: {
    isCard?: boolean;
  };
}

export interface KPICellParams extends ICellRendererParams {
  colDef: KpiCellColDef;
}

interface KPINameParams extends ICellRendererParams {
  colDef: KpiNameColDef;
}

const useKpi = (params: KPICellParams) => {
  const { isDemoAccount, demoAccountNow } = useContext(AccountContext);
  const { lastCompletedDateBucket, currentDateBucket, goal } =
    useContext(GoalContext);
  const { date, isCard } = params.colDef.cellRendererParams;
  const { formatMetric } = useValueFormatters();

  const kpiSetting = params.node.data.kpiSetting as GoalKPI;
  const metric = params.node.data.metric as Metrics.Metric;

  const { formatting } = metric;
  const targetMode = kpiSetting ? kpiSetting.targetMode : undefined;
  const expected = (() => {
    if (kpiSetting.targetMode === undefined) {
      return undefined;
    }

    const baseExpected = (() => {
      if (targetMode === 'basic' && kpiSetting.basicTarget !== undefined) {
        return kpiSetting.basicTarget;
      } else if (targetMode === 'advanced' && kpiSetting.advancedTarget) {
        return getKpiTargetForMonth(kpiSetting.advancedTarget, date);
      } else {
        return 0;
      }
    })();
    const isCurrentMonth = currentDateBucket === date;
    const isProrateEligible = metricTypeCheckers.isNormalMetric(metric)
      ? metric.aggFunc === 'sum'
      : false;
    if (isCurrentMonth && isProrateEligible) {
      if (goal.cadence !== 'week' && goal.cadence !== 'month') {
        const err = new Error('');
        err.name = `Unsupported goal cadence: ${goal.cadence}`;
        throw err;
      }

      const divideBy = goal.cadence === 'week' ? 7 : moment(date).daysInMonth();
      const expectedPerDay = baseExpected / divideBy;
      const now = moment(
        isDemoAccount && demoAccountNow ? demoAccountNow : undefined,
      );
      const elapsedDays = Math.abs(now.diff(moment(date), 'days')) + 1;
      return formatFloat(expectedPerDay * elapsedDays, formatting.precision);
    } else {
      return formatFloat(baseExpected, formatting.precision);
    }
  })();
  const actual =
    params.value === null || params.value === undefined
      ? null
      : formatFloat(params.value, formatting.precision);
  const isGood = formatting.positiveDeltaIsGood
    ? actual >= expected
    : actual <= expected;
  const diff = actual - expected;
  const adverb = (() => {
    if (isGood) {
      if (formatting.positiveDeltaIsGood) {
        return 'ahead of';
      } else {
        return 'behind';
      }
    } else {
      if (formatting.positiveDeltaIsGood) {
        return 'behind';
      } else {
        return 'ahead of';
      }
    }
  })();
  const actualFormatted = formatMetric({
    value: params.value,
    metricId: metric.id,
  });
  const expectedFormatted = formatMetric({
    value: expected,
    metricId: metric.id,
  });
  const diffFormatted = formatMetric({
    value: Math.abs(diff),
    metricId: metric.id,
  });
  const onTargetText = `${diffFormatted} ${adverb} target`;
  const popupText = `${actualFormatted} / ${expectedFormatted}`;
  const color =
    targetMode === undefined
      ? '#000'
      : isGood
        ? colors.MEDIUM_SEA_GREEN
        : colors.RADICAL_RED;
  const hasActual = actual !== null;

  return {
    hasActual,
    isCurrentMonth: date === lastCompletedDateBucket && !isCard,
    date,
    popupText,
    onTargetText,
    isGood,
    diff,
    metricName: metric.name,
    actualFormatted,
    expectedFormatted,
    color,
    kpiSetting,
    targetMode,
  };
};

const EditButton = ({ kpi }: { kpi: GoalKPI }) => {
  const { goal } = useContext(GoalContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const { isOpen, open, close } = usePopup();

  const onSave = useCallback(
    async (newKpi: GoalKPI) => {
      const newGoal = {
        ...goal,
        kpis: goal.kpis.map((i) => {
          if (i.id === newKpi.id) {
            return newKpi;
          }
          return i;
        }),
      };

      return updateGoal(newGoal, selectedAccountId).then(() => {
        close();
      });
    },
    [selectedAccountId, close, goal],
  );

  return (
    <div className="showOnMouseOver">
      <PermissionGates.Has requiredPermission={PERMISSIONS.GOALS.CRUD_GOALS}>
        <Button
          onClick={(event) => {
            open();
            event.stopPropagation();
            event.preventDefault();
          }}
          icon="edit-filled"
          size={'Small'}
          type="Ghost"
        />

        <ModalTransition>
          {isOpen && (
            <Modal shouldScrollInViewport={false} autoFocus={false}>
              <KpiForm
                kpi={kpi}
                onClose={close}
                cadence={goal.cadence}
                onSave={onSave}
                startDate={goal.fixedStartDate}
                endDate={
                  visTypeCheckers.isGeneralGoal(goal)
                    ? goal.fixedEndDate
                    : undefined
                }
              />
            </Modal>
          )}
        </ModalTransition>
      </PermissionGates.Has>
    </div>
  );
};

export const KpiNameRenderer = (params: KPINameParams) => {
  const kpiSetting = params.node.data.kpiSetting as GoalKPI | undefined;
  const rowType = params.node.data.rowType as 'goal' | 'kpi heading' | 'kpi';

  if (rowType === 'kpi heading') {
    return (
      <Cell
        style={{
          backgroundColor: 'white',
          width: 'calc(100% + 13px)',
          height: '32px',
          marginLeft: -6,
          marginRight: -6,
          padding: 5,
          borderBottom: '1px solid #e6e6ea',
          borderTop: '1px solid #e6e6ea',
        }}
      >
        <span>KPIs</span>
      </Cell>
    );
  }

  if (!kpiSetting) {
    return null;
  }

  return (
    <HideUnlessMouseOver>
      <Cell>
        <Row centerAlign>
          <div style={{ marginRight: 8 }}>
            <span>{params.value}</span>
          </div>
          {rowType === 'kpi' && <EditButton kpi={kpiSetting} />}
        </Row>
      </Cell>
    </HideUnlessMouseOver>
  );
};

export const TrendCellRenderer = (params: KPICellParams) => {
  const rowType = params.node.data.rowType as 'goal' | 'kpi heading' | 'kpi';
  if (rowType === 'kpi') {
    return <KpiCellRenderer {...params} />;
  } else if (rowType === 'goal') {
    return <GoalIntervalRenderer {...params} />;
  } else {
    return (
      <Cell
        style={{
          backgroundColor: 'white',
          width: 'calc(100% + 13px)',
          height: '32px',
          marginLeft: -6,
          marginRight: -6,
          borderBottom: '1px solid #e6e6ea',
          borderTop: '1px solid #e6e6ea',
        }}
      />
    );
  }
};

const KpiCellRenderer = (params: KPICellParams) => {
  const {
    isCurrentMonth,
    popupText,
    onTargetText,
    isGood,
    diff,
    metricName,
    actualFormatted,
    color,
    hasActual,
    expectedFormatted,
    targetMode,
    kpiSetting,
    date,
  } = useKpi(params);
  const { goal } = useContext(GoalContext);
  const { allReports } = useContext(ReportsContext);
  const { boards } = useContext(BoardsContext);
  const { openPopupReport, openPopupBoard } = useContext(PopupContext);

  const hasDrillDown =
    kpiSetting.reportDrillDownId || kpiSetting.boardDrillDownId;
  const onClick = useCallback(() => {
    if (!kpiSetting.reportDrillDownId && !kpiSetting.boardDrillDownId) {
      return;
    }
    if (!goal) {
      return;
    }

    const startDate = date;
    const endDate = moment(date)
      .endOf(goal.cadence === 'week' ? 'week' : 'month')
      .format('YYYY-MM-DD');

    if (kpiSetting.reportDrillDownId) {
      const report = allReports.find(
        (r) => r.id === kpiSetting.reportDrillDownId,
      );
      if (report) {
        const unSavedFilter: UnSavedFilter = {
          drillDowns: kpiSetting.drillDowns,
          dateScope: {},
          scope: [],
          dateField: kpiSetting.dateField || 'date',
          dateRange: {
            startDate,
            endDate,
          },
        };
        openPopupReport({
          drillDowns: [],
          origin: 'Goal',
          selectedReport: report,
          unSavedFilter,
        });
      }
    } else if (kpiSetting.boardDrillDownId) {
      const board = boards.find((b) => b.id === kpiSetting.boardDrillDownId);
      if (board) {
        const dateRange = {
          startDate,
          endDate,
        };
        openPopupBoard({
          popupFilters: kpiSetting.drillDowns,
          scope: [],
          popupDateRange: dateRange,
          origin: 'Scorecard',
          selectedBoard: board,
        });
      }
    }
  }, [
    allReports,
    boards,
    date,
    goal,
    kpiSetting.boardDrillDownId,
    kpiSetting.dateField,
    kpiSetting.drillDowns,
    kpiSetting.reportDrillDownId,
    openPopupBoard,
    openPopupReport,
  ]);

  if (!hasActual) {
    if (targetMode === undefined) {
      return null;
    }
    return (
      <PerformanceCell
        isCurrentMonth={isCurrentMonth}
        onClick={hasDrillDown ? onClick : undefined}
        isInput
      >
        <DeltaNumber color={Colors2.Grey['4']}>{expectedFormatted}</DeltaNumber>
      </PerformanceCell>
    );
  }

  return (
    <PerformanceCell
      isCurrentMonth={isCurrentMonth}
      onClick={hasDrillDown ? onClick : undefined}
      isInput
    >
      <Tooltip
        isAltTooltip
        content={
          targetMode === undefined ? null : (
            <TooltipContent
              progressText={popupText}
              onTargetText={onTargetText}
              isGood={isGood}
              delta={diff}
              tooltipTitle={metricName}
            />
          )
        }
      >
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <DeltaNumber color={color}>{actualFormatted}</DeltaNumber>
        </Row>
      </Tooltip>
    </PerformanceCell>
  );
};

const useColDefs = ({
  isCard,
  primaryTrend,
}: {
  isCard?: boolean;
  primaryTrend?: Goals.MetricResult;
}) => {
  const { dateBuckets, goal } = useContext(GoalContext);
  const { isDemoAccount, weekStartsOn, demoAccountNow } =
    useContext(AccountContext);
  const { goalId } = useParams<{ goalId?: string }>();
  const isList = goalId === undefined;
  const [colDefs, setColDefs] = useState<ColDef[] | undefined>();

  useEffect(() => {
    if (!primaryTrend || !primaryTrend.trend) {
      return;
    }
    const metricColDef = {
      headerName: '',
      field: 'metricName',
      pinned: 'left' as 'left',
      minWidth: 300,
      flex: 1,
      suppressHeaderMenuButton: true,
      cellRenderer: KPI_NAME_RENDERER,
      cellRendererParams: {
        isCard,
      },
    };

    const dateColDefs = (() => {
      if (isList) {
        const now = moment
          .utc(isDemoAccount && demoAccountNow ? demoAccountNow : undefined)
          .startOf('day')
          .format('YYYY-MM-DD');
        const goalDataInPast = primaryTrend.trend.filter((t) => t.date < now);
        const offset = visTypeCheckers.isGeneralGoal(goal) ? 2 : 1;
        if (primaryTrend.trend && goalDataInPast.length >= offset) {
          const trendItemToUse = goalDataInPast[goalDataInPast.length - offset];
          return [
            {
              key: moment(trendItemToUse.date).format('YYYY-MM-DD'),
              field: trendItemToUse.date,
              headerName:
                goal.cadence === 'month'
                  ? moment(trendItemToUse.date).format("MMM 'YY")
                  : formatDateLabel(trendItemToUse.date, goal.cadence, true),
              minWidth: 120,
              wrapHeaderText: true,
              autoHeaderHeight: true,
              headerClass: 'rightAlignedHeader',
              suppressHeaderMenuButton: true,
              flex: 1,
              cellRenderer: TREND_CELL_RENDERER,
              cellRendererParams: {
                date: trendItemToUse.date,
                isCard,
              },
              cellStyle: { justifyContent: 'flex-end' },
            },
          ];
        } else {
          return [];
        }
      } else {
        return dateBuckets.map((date) => {
          return {
            key: moment(date).format('YYYY-MM-DD'),
            field: date,
            headerName:
              goal.cadence === 'month'
                ? moment(date).format("MMM 'YY")
                : formatDateLabel(date, goal.cadence, true),
            minWidth: 120,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            headerClass: 'rightAlignedHeader',
            suppressHeaderMenuButton: true,
            flex: 1,
            cellRenderer: TREND_CELL_RENDERER,
            cellRendererParams: {
              date,
              isCard,
            },
            cellStyle: { justifyContent: 'flex-end' },
          };
        });
      }
    })();

    setColDefs([metricColDef, ...dateColDefs]);
  }, [
    isCard,
    isDemoAccount,
    isList,
    dateBuckets,
    weekStartsOn,
    primaryTrend,
    goal.cadence,
    goal,
    demoAccountNow,
  ]);

  return colDefs;
};

const useRows = ({
  kpis,
  kpiResults,
  topGoalRow,
}: {
  kpis: GoalKPI[];
  kpiResults?: Goals.MetricResult[];
  topGoalRow?: {
    result: Goals.GoalMetricResult;
    metric: Metrics.Metric;
  };
}) => {
  const { dateBuckets } = useContext(GoalContext);
  const { unitsLocale, weekStartsOn } = useContext(AccountContext);
  const { metricOptions } = useContext(MetricOptionsContext);
  const [rowData, setRowData] = useState<any[]>([]);

  useEffect(() => {
    if (!kpiResults) {
      return;
    }

    const newRowData = [] as any[];

    if (topGoalRow) {
      // Goal row
      const goalRow = {
        unitsLocale,
        metricName: topGoalRow.metric.name,
        rowType: 'goal',
      } as {
        [key: string]:
          | null
          | undefined
          | number
          | string
          | Goals.MetricResult
          | GoalKPI
          | boolean
          | 'goal'
          | 'kpi heading'
          | 'kpi';
      };
      dateBuckets.forEach((date) => {
        if (topGoalRow.result.trend) {
          const trendItem = topGoalRow.result.trend.find(
            (t) => t.date === date,
          );
          if (trendItem) {
            goalRow[date] = trendItem.value;
          } else {
            goalRow[date] = null;
          }
        } else {
          goalRow[date] = null;
        }
      });
      newRowData.push(goalRow);
    }

    const kpiRows = kpiResults
      .map((kpi) => {
        const kpiSetting = kpis.find((setting) => setting.id === kpi.id);
        if (!kpiSetting) {
          return undefined;
        }
        const metric = metricOptions.find(
          (mo) => mo.id === kpiSetting.metricId,
        );
        if (!metric || !kpi.trend || !kpiSetting) {
          return undefined;
        }
        const row = {
          kpi,
          kpiSetting,
          metric,
          unitsLocale,
          metricName: kpiSetting.name ? kpiSetting.name : metric.name,
          rowType: 'kpi',
        } as {
          [key: string]:
            | null
            | undefined
            | number
            | string
            | Goals.MetricResult
            | GoalKPI
            | Metrics.Metric;
        };
        dateBuckets.forEach((date) => {
          if (kpi.trend) {
            const trendItem = kpi.trend.find((t) => t.date === date);
            if (trendItem) {
              row[date] = trendItem.value;
            } else {
              row[date] = null;
            }
          } else {
            row[date] = null;
          }
        });

        return row;
      })
      .filter(isDefined);

    newRowData.push(...kpiRows);
    setRowData(newRowData);
  }, [
    weekStartsOn,
    metricOptions,
    unitsLocale,
    dateBuckets,
    kpiResults,
    topGoalRow,
    kpis,
  ]);

  return rowData;
};

const GoalKpiVis = ({
  isCard,
  primaryTrend,
  kpis,
  kpiResults,
  topGoalRow,
}: {
  isCard?: boolean;
  primaryTrend?: Goals.MetricResult;
  kpis: GoalKPI[];
  kpiResults?: Goals.MetricResult[];
  topGoalRow?: {
    result: Goals.GoalMetricResult;
    metric: Metrics.Metric;
  };
}) => {
  const { rowHeight } = useContext(RowHeightContext);
  const { goal, dateBuckets } = useContext(GoalContext);
  const { demoAccountNow } = useContext(AccountContext);
  const { isWallboard } = useContext(WallboardContext);
  const colDefs = useColDefs({
    isCard,
    primaryTrend,
  });
  const rowData = useRows({ kpis, kpiResults, topGoalRow });

  const createGridOptions = useCallback(() => {
    return {
      ...buildGridOptions({
        rowModelType: 'clientSide',
        rowHeight,
      }),
      groupDisplayType: 'custom' as 'custom',
      suppressAggFuncInHeader: true,
      columnDefs: colDefs,
      suppressCellFocus: true,
      rememberGroupStateWhenNewData: true,
      domLayout: 'autoHeight' as 'autoHeight',
      defaultColDef: {
        suppressKeyboardEvent: handleSuppressKeyboardEvent,
      },
    };
  }, [colDefs, rowHeight]);
  const [gridOptions, setGridOptions] = useState<GridOptions>(() =>
    createGridOptions(),
  );

  useEffect(() => {
    setGridOptions(createGridOptions());
  }, [createGridOptions]);

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      const dateToScrollTo = (() => {
        const today = demoAccountNow
          ? demoAccountNow
          : moment().format('YYYY-MM-DD');
        let dateToUse = dateBuckets[0];
        dateBuckets.forEach((date) => {
          if (today >= date) {
            dateToUse = date;
          }
        });

        return dateToUse;
      })();
      const indexOfDateToUse = dateBuckets.findIndex(
        (d) => d === dateToScrollTo,
      );
      const col = event.api.getColumn(dateBuckets[indexOfDateToUse]);

      if (col) {
        setTimeout(() => {
          event.api.ensureColumnVisible(col);
        }, 1000);
        setTimeout(() => {
          event.api.ensureColumnVisible(col);
        }, 2000);
      }
    },
    [dateBuckets, demoAccountNow],
  );

  if (!colDefs) {
    return (
      <FlexCentered style={{ height: 50 + goal.kpis.length * 32 }}>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <div style={{ fontSize: isWallboard ? '1.2em' : '12px' }}>
      <Grid
        onGridReady={onGridReady}
        columnDefs={colDefs}
        gridOptions={gridOptions}
        gridId="performance-fleet"
        rowData={rowData}
        totalsRow={undefined}
        isBoard
        disableAutoLayout
        isPerformanceSettings
        layoutOnModelUpdated={true}
        layoutOnColumnChange={true}
        layoutOnFirstRender={true}
        hasBorders={false}
        hasDarkHeader
      />
    </div>
  );
};

export default GoalKpiVis;
