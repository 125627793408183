import React from 'react';
import DriverBonusConfigProvider from './DriverBonusConfigProvider';
import BonusPeriodsProvider from './BonusPeriodsProvider';

const LegacyDriverBonusProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <DriverBonusConfigProvider>
    <BonusPeriodsProvider>
      <DriverBonusConfigProvider>{children}</DriverBonusConfigProvider>
    </BonusPeriodsProvider>
  </DriverBonusConfigProvider>
);

export default LegacyDriverBonusProviders;
