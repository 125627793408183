import styled from 'styled-components';
import Typography from '../../../../../kingpin/atoms/Typography';
import Row from '../../../../Common/Row';
import Inputs from '../../../../Inputs';
import React from 'react';

const Section = styled.div`
  margin-bottom: 8px;
`;

const OptionalNumberInput = ({
  label,
  isEnabled,
  onToggled,
  value,
  onChange,
  testIdPrefix,
}: {
  label: string;
  isEnabled: boolean;
  onToggled: (newValue: boolean) => void;
  value: number | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  testIdPrefix: string;
}) => {
  return (
    <ColumnSizeSettingsStyles.Section>
      <div>
        <Typography.Body type="Label">{label}</Typography.Body>
      </div>

      <Row style={{ gap: 8, height: 32 }} centerAlign>
        <div style={{ width: 'min-content' }}>
          <Inputs.Toggle
            value={isEnabled}
            onChange={onToggled}
            testId={`${testIdPrefix}-toggle`}
          />
        </div>

        <Typography.Body type="Label">Enabled</Typography.Body>
        {isEnabled && (
          <>
            <div
              style={{
                width: 1,
                height: 32,
                borderRight: '1px solid #E8E8E8',
              }}
            />
            <Inputs.TextInput
              type="number"
              value={value ? value.toString() : ''}
              onChange={onChange}
              data-testid={`${testIdPrefix}-input`}
            />
          </>
        )}
      </Row>
    </ColumnSizeSettingsStyles.Section>
  );
};

const ColumnSizeSettingsStyles = {
  Section,
  OptionalNumberInput,
};

export default ColumnSizeSettingsStyles;
