import getIdentifier from '../../../../../../getIdentifier';
import { toRangeDrillDown } from '../../../../../../utils/buildFilterInput/toRangeInput';
import keywordFilterToDrillDown from './keywordFilterToDrillDown';

const findFieldDefType = (
  field: string,
  datasets: FleetOps.DatasetDefinition[],
) => {
  for (let i = 0; i < datasets.length; i++) {
    const dataset = datasets[i];
    for (let j = 0; j < dataset.fields.length; j++) {
      const fieldDef = dataset.fields[j];
      if (fieldDef.field === field && fieldDef.type !== undefined) {
        return fieldDef.type;
      }
    }
  }
};

const fiToDrillDowns = (
  filters: FilterInput,
  datasets: FleetOps.DatasetDefinition[],
): FixedFilter[] => {
  const drills = [] as FixedFilter[];
  (filters.keywords || []).forEach((keyword) => {
    drills.push(keywordFilterToDrillDown(keyword));
  });

  (filters.booleanFilters || []).forEach((booleanFilter) => {
    const drill = {
      id: getIdentifier(),
      field: booleanFilter.field,
      fieldType: 'boolean' as 'boolean',
      mode: 'plate' as 'plate',
      booleanValue: booleanFilter.value,
    };
    drills.push(drill);
  });

  (filters.ranges || []).forEach((range) => {
    const drill = {
      id: getIdentifier(),
      field: range.field,
      fieldType: 'float' as 'float',
      mode: 'plate' as 'plate',
      rangeValue: toRangeDrillDown(range),
    };
    drills.push(drill);
  });

  (filters.wildcardFilters || []).forEach((wildcard) => {
    const drill = {
      id: getIdentifier(),
      field: wildcard.field,
      mode: 'plate' as 'plate',
      fieldType: 'text' as 'text',
      wildcardFilter: {
        field: wildcard.field,
        pattern: wildcard.pattern,
        exclude: wildcard.exclude,
      },
    };
    drills.push(drill);
  });

  (filters.mustExistFilters || []).forEach((mustExistFilter) => {
    const fieldType = findFieldDefType(mustExistFilter, datasets);

    if (fieldType) {
      if (fieldType === 'text') {
        const drill = {
          id: getIdentifier(),
          field: mustExistFilter,
          fieldType,
          mode: 'plate' as 'plate',
          exists: true,
          keywordValues: [],
        };
        drills.push(drill);
      } else if (fieldType === 'boolean') {
        const drill = {
          id: getIdentifier(),
          field: mustExistFilter,
          fieldType,
          mode: 'plate' as 'plate',
          exists: true,
        };
        drills.push(drill);
      } else if (fieldType === 'date' || fieldType === 'dateText') {
        const drill: DateFilter = {
          id: getIdentifier(),
          field: mustExistFilter,
          fieldType: 'date',
          mode: 'plate' as 'plate',
          exists: true,
        };
        drills.push(drill);
      } else if (
        fieldType === 'int' ||
        fieldType === 'float' ||
        fieldType === 'long'
      ) {
        const drill: NumberFilter = {
          id: getIdentifier(),
          field: mustExistFilter,
          fieldType,
          mode: 'plate' as 'plate',
          exists: true,
        };
        drills.push(drill);
      }
    }
  });

  (filters.mustNotExistFilters || []).forEach((mustNotExistFilters) => {
    const fieldType = findFieldDefType(mustNotExistFilters, datasets);

    if (fieldType) {
      if (fieldType === 'text') {
        const drill = {
          id: getIdentifier(),
          field: mustNotExistFilters,
          fieldType,
          mode: 'plate' as 'plate',
          exists: false,
          keywordValues: [],
        };
        drills.push(drill);
      } else if (fieldType === 'boolean') {
        const drill = {
          id: getIdentifier(),
          field: mustNotExistFilters,
          fieldType,
          mode: 'plate' as 'plate',
          exists: false,
        };
        drills.push(drill);
      } else if (fieldType === 'date' || fieldType === 'dateText') {
        const drill: DateFilter = {
          id: getIdentifier(),
          field: mustNotExistFilters,
          fieldType: 'date',
          mode: 'plate' as 'plate',
          exists: false,
        };
        drills.push(drill);
      } else if (
        fieldType === 'int' ||
        fieldType === 'float' ||
        fieldType === 'long'
      ) {
        const drill: NumberFilter = {
          id: getIdentifier(),
          field: mustNotExistFilters,
          fieldType,
          mode: 'plate' as 'plate',
          exists: false,
        };
        drills.push(drill);
      }
    }
  });

  return drills;
};

export default fiToDrillDowns;
