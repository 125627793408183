import React from 'react';

interface LegacySavedFiltersContextType {
  savedFilters: LegacySavedFilter[];
  savedFiltersLookup: { [id: string]: LegacySavedFilter | undefined };
  isLoading: boolean;
}

const LegacySavedFiltersContext =
  React.createContext<LegacySavedFiltersContextType>({
    savedFilters: [],
    savedFiltersLookup: {},
    isLoading: process.env.NODE_ENV === 'test' ? false : true,
  });

export default LegacySavedFiltersContext;
