import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';

import KpiForm from './KpiForm';
import FilterPlatesProvider from 'contextProviders/FilterPlatesProvider';
import updateScorecardDoc from '../../../../screens/ScorecardsIndex/updateScorecardDoc';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import LocalTimelineContext from '../../../../contexts/Timeline/LocalTimelineContext';
import ScorecardContext from '../../../../contexts/ScorecardContext';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import useMetric from '../../../../hooks/useMetric';
import useIsDynamicTarget from '../hooks/useIsDynamicTarget';
import { UnsavedChangesConfirmation } from '../../../ConfirmationModal';
import { useDataTypesFromMetricId } from '../../../../hooks/useDataTypesFromSeriesAndMetricListItems';
import getIdentifier from '../../../../getIdentifier';
import useManualFormState from '../hooks/useManualFormState';
import useTargetBandsUtils from '../hooks/useTargetBandsUtils';

const KpiFormContainer = ({
  kpi,
  close,
  setHasUnsavedChanges,
  isCloseConfirmationOpen,
  onCloseConfirmed,
  onCloseConfirmationClosed,
}: {
  kpi?: Scorecards.ScorecardKpi;
  close: () => void;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  isCloseConfirmationOpen: boolean;
  onCloseConfirmed: () => void;
  onCloseConfirmationClosed: () => void;
}) => {
  const { addEvent } = useContext(LocalTimelineContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { scorecard, updateScorecard } = useContext(ScorecardContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const [id] = useState<string>(kpi ? kpi.id : getIdentifier());
  const [metricId, setMetricId] = useState<string | undefined>(
    kpi ? kpi.metricId : undefined,
  );
  const [queryDateField, setQueryDateField] = useState<string | undefined>(
    kpi ? kpi.queryDateField : undefined,
  );
  const [isColorOrderReversed, setIsColorOrderReversed] = useState<boolean>(
    kpi ? kpi.isColorOrderReversed : false,
  );
  const [displayName, setDisplayName] = useState<string | undefined>(
    kpi ? kpi.displayName : undefined,
  );
  const [drillDowns, setDrillDowns] = useState<FilterPlateType[]>(
    kpi ? kpi.drillDowns : [],
  );
  const [scopeDrillDowns, setScopeDrillDowns] = useState<FilterPlateType[]>(
    kpi ? kpi.scopeDrillDowns : [],
  );
  const [isDefaultFiltersDisabled, setIsDefaultFiltersDisabled] =
    useState<boolean>(kpi ? !!kpi.isDefaultFiltersDisabled : false);
  const [sortedTargetBands, setSortedTargetBands] = useState<
    {
      band: string[] | undefined;
      date: Scorecards.IsoDate | Scorecards.WeekDate;
    }[]
  >([]);
  const [isAddingNewTargetBand, setIsAddingNewTargetBand] =
    useState<boolean>(!kpi);
  const [isTargetsDisabled, setIsTargetsDisabled] = useState<boolean>(
    kpi ? kpi.isTargetsDisabled : false,
  );
  const [dynamicTargetOverride, setProrationOverride] = useState<
    boolean | undefined
  >(kpi ? kpi.dynamicTargetOverride : undefined);
  const isDynamicTargetByDefault = useIsDynamicTarget(metricId);
  const [isEditingDateTarget, setIsEditingDateTarget] =
    useState<boolean>(false);
  const [reportDrillDownId, setReportDrillDownId] = useState<
    string | undefined
  >(kpi ? kpi.reportDrillDownId : undefined);
  const [boardDrillDownId, setBoardDrillDownId] = useState<string | undefined>(
    kpi ? kpi.boardDrillDownId : undefined,
  );
  const [targets, setTargets] = useState<
    Scorecards.WeekTargets | Scorecards.IsoTargets
  >(
    kpi
      ? _.cloneDeep(kpi.targets)
      : scorecard.cadence === 'week'
        ? ({ mode: 'Week', targets: {} } as Scorecards.WeekTargets)
        : ({ mode: 'ISO', targets: {} } as Scorecards.IsoTargets),
  );
  const [canSaveKpi, setCanSaveKpi] = useState<boolean>(true);
  const [isShowingAdvanced, setIsShowingAdvanced] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isContentScrollable, setIsContentScrollable] =
    useState<boolean>(false);
  const scrollingContentRef = useRef<HTMLDivElement>(null);

  const dataTypes = useDataTypesFromMetricId(metricId);
  const previousMetric = useMetric(kpi ? kpi.metricId : undefined);
  const metric = useMetric(metricId);
  const { markHasUnsavedChanges } = useManualFormState({
    setHasUnsavedChanges,
  });
  const {
    onAddClicked,
    onNewTargetBandSaved,
    onTargetBandSaved,
    onDynamicTargetOverrideToggled,
    onReverseColorOrderToggled,
    onNewTargetBandCancelled,
  } = useTargetBandsUtils({
    setIsAddingNewTargetBand,
    markHasUnsavedChanges,
    isTargetsDisabled,
    targets,
    setTargets,
    setSortedTargetBands,
    setProrationOverride,
    setIsColorOrderReversed,
  });

  useEffect(() => {
    return () => {
      setHasUnsavedChanges(false);
    };
  }, [setHasUnsavedChanges]);

  const onDisplayNameChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '') {
        setDisplayName(undefined);
      } else {
        setDisplayName(event.target.value);
      }
      markHasUnsavedChanges();
    },
    [markHasUnsavedChanges],
  );

  const trackQueryDateSelected = useCallback(() => {
    trackEvent('KPI List - KPI - Query Date Selected', {
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
  }, [scorecard.id, scorecard.title, trackEvent]);

  const onAdvancedToggled = useCallback(() => {
    if (isShowingAdvanced) {
      trackEvent('KPI List - KPI - Basic Clicked', {
        scorecardName: scorecard.title,
        scorecardId: scorecard.id,
      });
      setIsShowingAdvanced(false);
    } else {
      trackEvent('KPI List - KPI - Advanced Clicked', {
        scorecardName: scorecard.title,
        scorecardId: scorecard.id,
      });
      setIsShowingAdvanced(true);
    }
  }, [isShowingAdvanced, scorecard.id, scorecard.title, trackEvent]);

  const buildNewKpi = useCallback((): Scorecards.ScorecardKpi | undefined => {
    if (
      !metricId ||
      (Object.keys(targets.targets).length === 0 && !isTargetsDisabled)
    ) {
      return undefined;
    }

    return {
      id,
      displayName,
      metricId,
      queryDateField,
      drillDowns,
      scopeDrillDowns,
      isDefaultFiltersDisabled,
      isColorOrderReversed,
      targets,
      reportDrillDownId,
      boardDrillDownId,
      isTargetsDisabled,
      dynamicTargetOverride,
    };
  }, [
    metricId,
    targets,
    isTargetsDisabled,
    id,
    displayName,
    queryDateField,
    drillDowns,
    scopeDrillDowns,
    isDefaultFiltersDisabled,
    isColorOrderReversed,
    reportDrillDownId,
    boardDrillDownId,
    dynamicTargetOverride,
  ]);

  const trackDrillDownAdded = useCallback(
    ({
      type,
      id,
      name,
    }: {
      type: 'Report' | 'Board';
      id: string;
      name: string;
    }) => {
      trackEvent('KPI List - KPI - DrillDown added', {
        scorecardName: scorecard.title,
        scorecardId: scorecard.id,
        drillDownType: type,
        drillDownId: id,
        drillDownName: name,
      });
    },
    [scorecard.id, scorecard.title, trackEvent],
  );

  useEffect(() => {
    if (isTargetsDisabled) {
      return;
    }

    if (Object.keys(targets.targets).length === 0) {
      setIsAddingNewTargetBand(true);
    }
  }, [isTargetsDisabled, targets.targets]);

  const isValid = buildNewKpi() !== undefined && canSaveKpi;
  const isNotValidMessage =
    buildNewKpi() === undefined
      ? 'Please select a metric and a target'
      : !canSaveKpi
        ? 'Please confirm targets'
        : undefined;

  const onSave = useCallback(() => {
    const newKpi = buildNewKpi();
    if (!newKpi) {
      return;
    }

    const newKpis = (() => {
      if (kpi) {
        return scorecard.kpis.map((kpi) => {
          if (kpi.id === id) {
            return newKpi;
          }
          return kpi;
        });
      }

      return [newKpi, ...scorecard.kpis];
    })();

    const newScorecard = {
      ...scorecard,
      kpis: newKpis,
    };

    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
      setHasUnsavedChanges(false);
      onCloseConfirmed();
    };
    const newName = (() => {
      if (!metric) {
        return '';
      }

      if (displayName) {
        return displayName;
      }

      return metric.name;
    })();

    if (kpi) {
      updateScorecard(newScorecard)
        .then(async () => {
          trackEvent('KPI List - KPI - Edit Saved', {
            scorecardName: newScorecard.title,
            scorecardId: newScorecard.id,
          });
          if (addEvent) {
            const previousName = (() => {
              if (!previousMetric) {
                return undefined;
              }

              if (kpi.displayName) {
                return kpi.displayName;
              }

              return previousMetric.name;
            })();

            const hasChangedMetricName = previousName !== newName;
            if (hasChangedMetricName) {
              await addEvent({
                actionText: 'changed metric',
                contextText: `from ${previousName} to ${newName}`,
              });
            }

            const hasChangedTargets = !_.isEqual(newKpi.targets, kpi.targets);
            if (hasChangedTargets) {
              await addEvent({
                actionText: 'changed targets',
                contextText: `for ${newName}`,
              });
            }
          }
        })
        .then(onSuccess);
    } else {
      updateScorecardDoc(newScorecard, selectedAccountId)
        .then(async () => {
          trackEvent('KPI List - KPI - Created', {
            scorecardName: newScorecard.title,
            scorecardId: newScorecard.id,
          });
          if (addEvent) {
            await addEvent({
              actionText: 'added KPI',
              contextText: newName,
            });
          }
        })
        .then(onSuccess);
    }
  }, [
    addEvent,
    buildNewKpi,
    displayName,
    id,
    kpi,
    metric,
    onCloseConfirmed,
    previousMetric,
    scorecard,
    selectedAccountId,
    setHasUnsavedChanges,
    trackEvent,
    updateScorecard,
  ]);

  useLayoutEffect(() => {
    if (!scrollingContentRef.current) {
      return;
    }

    const elem = scrollingContentRef.current;
    setIsContentScrollable(elem.scrollHeight > elem.clientHeight);
  }, [isShowingAdvanced, targets]);

  return (
    <FilterPlatesProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      scope={scopeDrillDowns}
      setScope={setScopeDrillDowns}
      dataTypes={dataTypes}
      isDefaultFiltersDisabled={isDefaultFiltersDisabled}
      setIsDefaultFiltersDisabled={setIsDefaultFiltersDisabled}
    >
      <KpiForm
        scorecard={scorecard}
        isShowingAdvanced={isShowingAdvanced}
        onAdvancedToggled={onAdvancedToggled}
        metricId={metricId}
        setMetricId={setMetricId}
        isEditing={!!kpi}
        close={close}
        dataTypes={dataTypes}
        dateField={queryDateField}
        setDateField={setQueryDateField}
        trackQueryDateSelected={trackQueryDateSelected}
        displayName={displayName}
        onDisplayNameChanged={onDisplayNameChanged}
        isTargetsDisabled={isTargetsDisabled}
        setIsTargetsDisabled={setIsTargetsDisabled}
        sortedTargetBands={sortedTargetBands}
        targets={targets}
        setTargets={setTargets}
        onTargetBandSaved={onTargetBandSaved}
        onNewTargetBandSaved={onNewTargetBandSaved}
        onAddClicked={onAddClicked}
        isAddingNewTargetBand={isAddingNewTargetBand}
        onNewTargetBandCancelled={onNewTargetBandCancelled}
        isEditingDateTarget={isEditingDateTarget}
        setIsEditingDateTarget={setIsEditingDateTarget}
        isColorOrderReversed={isColorOrderReversed}
        onReverseColorOrderToggled={onReverseColorOrderToggled}
        reportDrillDownId={reportDrillDownId}
        setReportDrillDownId={setReportDrillDownId}
        boardDrillDownId={boardDrillDownId}
        setBoardDrillDownId={setBoardDrillDownId}
        trackDrillDownAdded={trackDrillDownAdded}
        setCanSaveKpi={setCanSaveKpi}
        markHasUnsavedChanges={markHasUnsavedChanges}
        isDynamicTargetByDefault={isDynamicTargetByDefault}
        dynamicTargetOverride={dynamicTargetOverride}
        onDynamicTargetOverrideToggled={onDynamicTargetOverrideToggled}
        scrollingContentRef={scrollingContentRef}
        isContentScrollable={isContentScrollable}
        isLoading={isLoading}
        isNotValidMessage={isNotValidMessage}
        isValid={isValid}
        onSave={onSave}
      />
      <UnsavedChangesConfirmation
        isOpen={isCloseConfirmationOpen}
        close={onCloseConfirmationClosed}
        onConfirmed={onCloseConfirmed}
      />
    </FilterPlatesProvider>
  );
};

export default KpiFormContainer;
