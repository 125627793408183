import getIdentifier from '../../../../../../getIdentifier';

const keywordFilterToDrillDown = (
  keywordFilter: KeywordFilter,
): FixedFilter => {
  return {
    id: getIdentifier(),
    field: keywordFilter.field,
    fieldType: 'text',
    mode: 'plate',
    keywordValues: keywordFilter.values,
    isExclude: keywordFilter.exclude,
    keywordsExists: keywordFilter.exists,
  } as FixedFilter;
};

export default keywordFilterToDrillDown;
