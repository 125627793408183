import React, { useCallback, useContext, useEffect, useState } from 'react';
import WorkSpacesContext from 'contexts/WorkSpacesContext';
import { isTargetsApp } from 'isWorkSpace';
import { engagementPortalRouteBuilders } from 'navigation/portalRoutes/engagementPortalRoutes';
import targetAppTypeCheckers from 'screens/TargetsAppShow/targetAppTypeCheckers';

import NavSection from '../../NavSection';
import NavItem from '../../NavItem';
import useEntity from '../../../../hooks/useEntity';
import PermissionGates from '../../../../components/PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import { useLocation } from 'react-router-dom';
import { buildEntityAppRedirect } from '../../../../navigation/appRoutes';
import { PortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const EntityLink = ({
  entity,
  entityLink,
}: {
  entity: EntityDetails.Entity;
  entityLink: EngagementPortalEntityLink;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  const { pathname } = useLocation();
  const getIsActive = useCallback(() => {
    return (
      pathname.includes(`entities/${entity.primaryField}`) ||
      pathname.includes(`entity/${entity.id}`)
    );
  }, [pathname, entity.primaryField, entity.id]);
  const [isActive, setIsActive] = useState<boolean>(() => getIsActive());
  useEffect(() => {
    setIsActive(getIsActive());
  }, [getIsActive]);

  return (
    <NavItem
      testId={`${entityLink.navLabel}-ready`}
      to={buildEntityAppRedirect({
        portal: selectedPortal,
        entityId: entity.id,
      })}
      isActiveOverride={isActive}
      label={entityLink.navLabel}
      icon={'navigation-metrics'}
    />
  );
};

const EntityLinkGate = ({
  entityLink,
}: {
  entityLink: EngagementPortalEntityLink;
}) => {
  const { entity } = useEntity(entityLink.entityId);

  if (!entity) {
    return null;
  }

  return <EntityLink entityLink={entityLink} entity={entity} />;
};

const EntityLinks = ({ portal }: { portal: EngagementPortal }) => {
  const { entityLinks } = portal;

  return (
    <>
      {entityLinks.map((link) => (
        <EntityLinkGate entityLink={link} key={link.entityId} />
      ))}
    </>
  );
};

const TargetsLink = ({ portal }: { portal: EngagementPortal }) => {
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const { targetsLink } = portal;
  if (!targetsLink.isEnabled) {
    return null;
  }

  const targetsApp = allWorkSpaces
    .filter(isTargetsApp)
    .filter(targetAppTypeCheckers.isConfiguredApp)
    .find((a) => a.id === targetsLink.targetAppId);

  if (!targetsApp) {
    return null;
  }

  return (
    <NavItem
      to={engagementPortalRouteBuilders(portal.urlSlug).targetManagerShow(
        targetsApp.dataset,
      )}
      label={targetsLink.navLabel}
      icon={'navigation-goals'}
    />
  );
};

const EngagementPortalNavSection = ({
  portal,
}: {
  portal: EngagementPortal;
}) => {
  const hasReports = portal.reportIds.length > 0;
  const hasScorecards = portal.scorecardIds.length > 0;
  const { pathname } = useLocation();

  return (
    <>
      <NavSection label="View">
        <>
          <NavItem
            to={`/${portal.urlSlug}`}
            isActiveOverride={pathname === `/${portal.urlSlug}`}
            label={'Home'}
            tooltip={'Home'}
            icon={'home'}
          />
          <EntityLinks portal={portal} />
          <NavItem
            to={engagementPortalRouteBuilders(portal.urlSlug).dashboardsIndex}
            label={'Dashboards'}
            tooltip={'Dashboards'}
            icon={'dashboard'}
            onClickEvent={'Navigation Sidebar - Clicked Dashboards'}
          />
          {hasReports && (
            <NavItem
              to={engagementPortalRouteBuilders(portal.urlSlug).reportsIndex}
              label={'Reports'}
              tooltip={'Reports'}
              icon={'find-in-page'}
              onClickEvent={'Navigation Sidebar - Clicked Reports'}
            />
          )}
          {hasScorecards && (
            <NavItem
              to={engagementPortalRouteBuilders(portal.urlSlug).scorecardsIndex}
              label={'KPI Lists'}
              tooltip={'KPI Lists'}
              icon={'navigation-goals'}
              onClickEvent={'Navigation Sidebar - Clicked KPI Lists'}
            />
          )}
          <TargetsLink portal={portal} />
        </>
      </NavSection>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.CONTENT_ACCESS.ADD_USERS_TO_PORTAL}
      >
        <NavSection label="Build">
          <NavItem
            to={engagementPortalRouteBuilders(portal.urlSlug).users}
            isActiveOverride={pathname.includes(`/${portal.urlSlug}/access`)}
            label={'Users'}
            icon={'group'}
            tooltip="Users"
          />
        </NavSection>
      </PermissionGates.Has>
    </>
  );
};

export default EngagementPortalNavSection;
