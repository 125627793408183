import React from 'react';
import InlineDialog from 'components/InlineDialog';
import FilterPlateForm from 'components/FilterPlateForm';
import Button from 'kingpin/atoms/Button';
import usePopup from 'hooks/usePopup';

const AddFilterButton = ({ text }: { text?: string }) => {
  const { isOpen, open, close } = usePopup();

  return (
    <InlineDialog
      isOpen={isOpen}
      onClose={close}
      content={<FilterPlateForm close={close} isScope={false} />}
    >
      <Button
        type="Tertiary"
        size="Small"
        onClick={isOpen ? close : open}
        icon="add"
        label={text || 'Add Filter'}
        testId={'add-filter'}
      />
    </InlineDialog>
  );
};

export default AddFilterButton;
