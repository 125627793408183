import { useEffect, useState } from 'react';
import useDataTypesFromSeriesAndMetricListItems, {
  useDataTypesFromMetricIds,
} from './useDataTypesFromSeriesAndMetricListItems';
import isV5ChartDef from '../types/visTypeCheckers/isV5ChartDef';
import isSingleMetricDateMatrix from '../types/visTypeCheckers/isSingleMetricDateMatrix';
import _ from 'lodash';
import isGauge from '../types/visTypeCheckers/isGauge';
import isRankingMatrix from '../types/visTypeCheckers/isRankingMatrix';
import isRemindersGadget from '../types/visTypeCheckers/isRemindersGadget';
import isPaceMatrix from '../types/visTypeCheckers/isPaceMatrix';

const useDashboardGadgetDataTypes = (
  gadget?: DashboardGadget,
  chartDef?: VisualisationDefinition,
): string[] => {
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  const [metricIds, setMetricIds] = useState<string[]>([]);
  const [series, setSeries] = useState<V5ChartDefinitionSeries[]>([]);
  const [dataType, setDataType] = useState<string | undefined>();
  const fromSeries = useDataTypesFromSeriesAndMetricListItems(series);
  const fromMetricIds = useDataTypesFromMetricIds(metricIds);

  useEffect(() => {
    const usedDefinition = chartDef
      ? chartDef
      : gadget
        ? gadget.chartDef
        : undefined;
    const newSeries = (() => {
      if (isV5ChartDef(usedDefinition)) {
        return usedDefinition.series;
      } else if (
        usedDefinition &&
        (isSingleMetricDateMatrix(usedDefinition) || isGauge(usedDefinition))
      ) {
        return [
          {
            id: usedDefinition.metricId,
            metricId: usedDefinition.metricId,
          },
        ];
      } else if (usedDefinition && isRankingMatrix(usedDefinition)) {
        return usedDefinition.metrics.map((rankingM) => ({
          id: rankingM.key,
          metricId: rankingM.metricId,
        }));
      } else if (usedDefinition && isRemindersGadget(usedDefinition)) {
        return [];
      } else if (usedDefinition && isPaceMatrix(usedDefinition)) {
        return [
          {
            id: usedDefinition.metricId,
            metricId: usedDefinition.metricId,
          },
        ];
      } else if (usedDefinition) {
        if (process.env.NODE_ENV === 'development') {
          const e = new Error('');
          e.name = 'Unknown vis type';
          throw e;
        }
        return [];
      } else {
        return [];
      }
    })();
    const newDataType = (() => {
      if (
        usedDefinition &&
        isV5ChartDef(usedDefinition) &&
        usedDefinition.gadgetType === 'simpleGrid' &&
        usedDefinition.simpleGridConfig
      ) {
        return usedDefinition.simpleGridConfig.dataType;
      }

      if (usedDefinition && isRemindersGadget(usedDefinition)) {
        return 'upcomingEvents';
      }
      return undefined;
    })();

    const newMetricIds =
      usedDefinition && isSingleMetricDateMatrix(usedDefinition)
        ? [usedDefinition.metricId]
        : [];

    setSeries((currentSeries) => {
      if (_.isEqual(currentSeries, newSeries)) {
        return currentSeries;
      } else {
        return newSeries;
      }
    });
    setMetricIds((currentIds) => {
      if (_.isEqual(currentIds, newMetricIds)) {
        return currentIds;
      } else {
        return newMetricIds;
      }
    });
    setDataType(newDataType);
  }, [chartDef, gadget, series]);

  useEffect(() => {
    const base = dataType ? [dataType] : [...fromSeries, ...fromMetricIds];
    setDataTypes(_.uniq(base));
  }, [dataType, fromMetricIds, fromSeries]);

  return dataTypes;
};

export default useDashboardGadgetDataTypes;
