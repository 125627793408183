import React, { lazy, useContext, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { Layout } from './styles';
import appRoutes, { buildShowWallBoard } from './appRoutes';
import AccountContext from '../contexts/AccountContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import { SLIDE_OUT_ELEMENT_ID, FADE_IN_ELEMENT_ID } from '../constants';
import Loading from '../components/Loading';
import FlexCentered from '../components/Common/FlexCentered';
import FleetOpsSuspense from '../FleetOpsSuspense';
import MainNav from '../kingpin/navigation/MainNav';

import OnBoardingSwitch from './OnboardingSwitch';
import { PortalsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import LegacyAuthenticatedSwitch from './LegacyAuthenticatedSwitch';
import PortalsSwitch from './PortalsSwitch';

import { CurrentUserContentSettingsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/CurrentUserContentSettingsProvider';
import useEntity from '../hooks/useEntity';
import Typography from '../kingpin/atoms/Typography';
import Microsoft from '../screens/Settings/SsoConfigurations/Microsoft';
import useEntityLink from '../components/GlobalSearch/hooks/useEntityLink';

const WallBoard = lazy(() => import('../screens/WallBoard'));
const BoardShowScreen = lazy(() => import('../screens/BoardShow'));
const BoardsScreen = lazy(() => import('../screens/Boards'));
const TargetsAppShow = lazy(() => import('../screens/TargetsAppShow'));
const SignOutScreen = lazy(() => import('../screens/SignOut'));

const HomeScreen = lazy(() => import('../screens/Home'));
const SharedIndexScreen = lazy(() => import('../screens/SharedIndex'));
const EntityDetailsShow = lazy(() => import('../screens/EntityDetailsShow'));
const EntityDetailsShowRedirect = lazy(
  () => import('../screens/EntityDetailsShowRedirect'),
);
const MyAccountScreen = lazy(() => import('../screens/MyAccount'));
const ReportShow = lazy(() => import('../screens/ReportShow'));
const DashboardShow = lazy(() => import('../screens/DashboardShow'));
const WorkSpacesIndex = lazy(() => import('../screens/WorkSpacesIndex'));
const WorkSpacesShow = lazy(() => import('../screens/WorkSpacesShow'));

const ScorecardShow = lazy(() => import('../screens/ScorecardShow'));
const GoalShow = lazy(() => import('../screens/GoalShow'));

const Sidebar = () => (
  <Routes>
    <Route path={appRoutes.loggedIn.showWallBoard} element={null} />
    <Route path="*" element={<MainNav />} />
  </Routes>
);

const PortalModeSwitch = () => {
  return (
    <Routes>
      <Route path={appRoutes.home} element={<HomeScreen />} />
      <Route
        path={appRoutes.loggedIn.workspaces}
        element={<WorkSpacesIndex />}
      />
      <Route
        path={appRoutes.loggedIn.showWorkSpace}
        element={<WorkSpacesShow />}
      />
      <Route
        path={appRoutes.loggedIn.showWorkSpaceTab}
        element={<WorkSpacesShow />}
      />
      <Route path={appRoutes.loggedIn.shared} element={<SharedIndexScreen />} />
      <Route
        path={`/shared${appRoutes.loggedIn.showDashboard}`}
        element={<DashboardShow />}
      />
      <Route
        path={`/shared${appRoutes.loggedIn.showReport}`}
        element={<ReportShow />}
      />
      <Route
        path={`/shared${appRoutes.loggedIn.showGlobalKpiList}`}
        element={<ScorecardShow />}
      />
      <Route
        path={appRoutes.loggedIn.showDashboard}
        element={<DashboardShow />}
      />
      <Route path={appRoutes.loggedIn.showReport} element={<ReportShow />} />
      <Route
        path={appRoutes.loggedIn.showGlobalKpiList}
        element={<ScorecardShow />}
      />
      <Route path={appRoutes.loggedIn.showWallBoard} element={<WallBoard />} />
      <Route
        path={appRoutes.loggedIn.entityAppRedirect}
        element={<EntityDetailsShowRedirect />}
      />
      <Route
        path={appRoutes.loggedIn.showEntityApp}
        element={<EntityDetailsShow />}
      />
      <Route
        path={appRoutes.loggedIn.showEntityAppContentTab}
        element={<EntityDetailsShow />}
      />
      <Route
        path={appRoutes.loggedIn.myAccount}
        element={<MyAccountScreen />}
      />
      <Route path={appRoutes.loggedIn.boards} element={<BoardsScreen />} />
      <Route
        path={appRoutes.loggedIn.showBoard}
        element={<BoardShowScreen />}
      />
      <Route
        path={appRoutes.loggedIn.showTargetApp}
        element={<TargetsAppShow />}
      />
      <Route
        path={appRoutes.loggedIn.showTargetAppTasksTab}
        element={<TargetsAppShow />}
      />
      <Route
        path={appRoutes.loggedIn.showTargetAppContentTab}
        element={<TargetsAppShow />}
      />
      <Route path={appRoutes.loggedIn.showGlobalGoal} element={<GoalShow />} />
      <Route
        path={appRoutes.loggedIn.showCampaignGoal}
        element={<GoalShow />}
      />
      <Route
        path={appRoutes.loggedIn.microsoftSsoRedirect}
        element={<Microsoft.ConsentRedirect />}
      />
      <Route path={appRoutes.loggedIn.signOut} element={<SignOutScreen />} />
      <Route path={'/:portal/*'} element={<PortalsSwitch />} />
    </Routes>
  );
};

const EngagementRedirect = ({
  entity,
  engagementContentSettings,
}: {
  engagementContentSettings: UserManagement.EngagementContentSettings;
  entity: EntityDetails.Entity;
}) => {
  const navigate = useNavigate();

  const entityLink = useEntityLink({
    entity,
    value: engagementContentSettings.primaryFieldValue,
  });

  useEffect(() => {
    if (entityLink) {
      navigate(entityLink);
    }
  }, [
    engagementContentSettings.primaryFieldValue,
    entity.primaryField,
    entityLink,
    navigate,
  ]);

  return (
    <div>
      <Typography.Header type="H1">
        Page not found, redirecting...
      </Typography.Header>
    </div>
  );
};

const EngagementModeSwitch = ({
  engagementContentSettings,
}: {
  engagementContentSettings: UserManagement.EngagementContentSettings;
}) => {
  const { entity, app } = useEntity(engagementContentSettings.entityId);

  if (!entity || !app) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route
        path={appRoutes.loggedIn.showEntityApp}
        element={<EntityDetailsShow />}
      />
      <Route
        path={appRoutes.loggedIn.showEntityAppContentTab}
        element={<EntityDetailsShow />}
      />
      <Route
        path="*"
        element={
          <EngagementRedirect
            engagementContentSettings={engagementContentSettings}
            entity={entity}
          />
        }
      />
    </Routes>
  );
};

const AuthenticatedSwitch = () => {
  const { isPortalsEnabled } = useContext(PortalsContext);
  const { contentSettings } = useContext(CurrentUserContentSettingsContext);

  if (isPortalsEnabled) {
    if (contentSettings.mode === 'engagement') {
      return (
        <EngagementModeSwitch engagementContentSettings={contentSettings} />
      );
    }

    return <PortalModeSwitch />;
  }

  return <LegacyAuthenticatedSwitch />;
};

const Authenticated = () => (
  <div>
    <Layout>
      <Routes>
        <Route
          path={'*'}
          element={
            <>
              <Sidebar />
              <FleetOpsSuspense>
                <AuthenticatedSwitch />
              </FleetOpsSuspense>
              <div id={SLIDE_OUT_ELEMENT_ID} />
              <div id={FADE_IN_ELEMENT_ID} />
            </>
          }
        />
      </Routes>
    </Layout>
  </div>
);

const OnBoardingGate = () => {
  const { status } = useContext(AccountContext);
  const { isWallboardUser, wallBoardId, isFleetOpsStaff } =
    useContext(CurrentUserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isWallboardUser && wallBoardId) {
      if (location.pathname !== buildShowWallBoard(wallBoardId, undefined)) {
        navigate(buildShowWallBoard(wallBoardId, undefined));
      }
    }
  }, [isWallboardUser, location.pathname, navigate, wallBoardId]);

  if (!status) {
    return (
      <div style={{ height: '100vh' }}>
        <FlexCentered style={{ height: '100%' }}>
          <Loading />
        </FlexCentered>
      </div>
    );
  }

  if (status !== 'active' && !isFleetOpsStaff) {
    return <OnBoardingSwitch />;
  }

  return <Authenticated />;
};

export default OnBoardingGate;
