import BreadcrumbsContext, {
  Breadcrumb,
} from '../../../contexts/BreadcrumbsContext';
import React, { useCallback, useState } from 'react';

const MAX_BREADCRUMB_COUNT = 20;

const convert_event_to_breadcrumb = (
  eventType: Analytics.EventType,
  eventProperties: { [key: string]: string | undefined },
) => {
  if (
    eventType.startsWith('Nav') ||
    eventType.startsWith('Help') ||
    eventType.startsWith('Metrics') ||
    eventType.startsWith('Usage')
  ) {
    return;
  }
  return {
    name: eventType,
    url: eventProperties.url,
    id: eventProperties.id,
  };
};

const BreadcrumbsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const recordBreadcrumb = useCallback(
    (
      eventType: Analytics.EventType,
      eventProperties: { [key: string]: string | undefined },
    ) => {
      const breadcrumb = convert_event_to_breadcrumb(
        eventType,
        eventProperties,
      );
      if (!breadcrumb) {
        return;
      }
      setBreadcrumbs((oldBreadcrumbs) => {
        const newBreadcrumbs = [breadcrumb, ...oldBreadcrumbs];
        if (newBreadcrumbs.length > MAX_BREADCRUMB_COUNT) {
          newBreadcrumbs.pop();
        }
        return newBreadcrumbs;
      });
    },
    [setBreadcrumbs],
  );

  return (
    <BreadcrumbsContext.Provider value={{ recordBreadcrumb, breadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsProvider;
