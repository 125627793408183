import React, { useCallback } from 'react';
import useDatasetFreshnessInfo from './useDatasetFreshnessInfo';
import moment from 'moment';

export const DatasetFreshnessContext =
  React.createContext<IDatasetFreshnessContext>({
    datasets: [],
    getLastSyncedAt: (dataset: string) => 'NA',
    isLoading: true,
    refresh: window.tokenFunction,
  });

const DatasetFreshnessProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isLoading, data, refresh } = useDatasetFreshnessInfo();

  const getLastSyncedAt = useCallback(
    (dataset: string): string => {
      const info = data.find((d) => d.name === dataset);
      if (!info) {
        return 'NA';
      }

      return moment.utc(info.latestRecordTimeStampUTC).fromNow();
    },
    [data],
  );

  return (
    <DatasetFreshnessContext.Provider
      value={{
        datasets: data,
        refresh,
        isLoading,
        getLastSyncedAt,
      }}
    >
      {children}
    </DatasetFreshnessContext.Provider>
  );
};

export default DatasetFreshnessProvider;
