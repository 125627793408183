import React, { ChangeEvent, useCallback, useState } from 'react';
import usePopup from '../../../hooks/usePopup';
import AssetPicker from './AssetPicker';

const AssetPickerContainer = ({
  workSpaces,
  reports,
  dashboards,
  selectedWorkSpaceIds,
  selectedReportIds,
  selectedDashboardIds,
  setSelectedWorkSpaceIds,
  setSelectedReportIds,
  setSelectedDashboardIds,
  clearSelection,
}: {
  workSpaces: (WorkSpace | TargetsApp.App)[];
  reports: ReportType[];
  dashboards: DashboardType[];
  selectedWorkSpaceIds: string[];
  selectedReportIds: string[];
  selectedDashboardIds: string[];
  setSelectedWorkSpaceIds: (ids: string[]) => void;
  setSelectedReportIds: (ids: string[]) => void;
  setSelectedDashboardIds: (ids: string[]) => void;
  clearSelection: () => void;
}) => {
  const { isOpen, open: openPopup, close } = usePopup();
  const [searchText, setSearchText] = useState<string>('');
  const [selectedDashboardsOnOpen, setSelectedDashboardsOnOpen] = useState<
    string[]
  >([]);
  const [selectedReportsOnOpen, setSelectedReportsOnOpen] = useState<string[]>(
    [],
  );
  const [selectedWorkSpacesOnOpen, setSelectedWorkSpacesOnOpen] = useState<
    string[]
  >([]);

  const open = useCallback(() => {
    setSelectedDashboardsOnOpen(selectedDashboardIds);
    setSelectedReportsOnOpen(selectedReportIds);
    setSelectedWorkSpacesOnOpen(selectedWorkSpaceIds);
    openPopup();
  }, [
    openPopup,
    selectedDashboardIds,
    selectedReportIds,
    selectedWorkSpaceIds,
  ]);

  const filteredWorkSpaces = workSpaces.filter((w) =>
    w.title.trim().toLowerCase().includes(searchText.trim().toLowerCase()),
  );
  const filteredReports = reports.filter((r) =>
    r.name.toLowerCase().includes(searchText.toLowerCase()),
  );
  const filteredDashboards = dashboards.filter((r) =>
    r.name.trim().toLowerCase().includes(searchText.trim().toLowerCase()),
  );

  const filteredSelectedReports = (
    selectedReportsOnOpen
      .map((r) => reports.find((report) => report.id === r))
      .filter((r) => !!r) as ReportType[]
  ).filter((r) => r.name.toLowerCase().includes(searchText.toLowerCase()));
  const filteredSelectedDashboards = (
    selectedDashboardsOnOpen
      .map((d) => dashboards.find((dashboard) => dashboard.id === d))
      .filter((d) => !!d) as DashboardType[]
  ).filter((d) => d.name.toLowerCase().includes(searchText.toLowerCase()));
  const filteredSelectedWorkspaces = (
    selectedWorkSpacesOnOpen
      .map((d) => workSpaces.find((workSpace) => workSpace.id === d))
      .filter((d) => !!d) as WorkSpace[]
  ).filter((w) => w.title.toLowerCase().includes(searchText.toLowerCase()));

  const onSearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const hasResults =
    filteredDashboards.length !== 0 ||
    filteredReports.length !== 0 ||
    filteredWorkSpaces.length !== 0;

  const selectedCount =
    selectedWorkSpaceIds.length +
    selectedReportIds.length +
    selectedDashboardIds.length;

  const shouldShowClearSelection =
    filteredSelectedReports.length !== 0 ||
    filteredSelectedDashboards.length !== 0 ||
    filteredSelectedWorkspaces.length !== 0;

  return (
    <AssetPicker
      close={close}
      isOpen={isOpen}
      open={open}
      setSelectedDashboardIds={setSelectedDashboardIds}
      selectedWorkSpaceIds={selectedWorkSpaceIds}
      selectedDashboardIds={selectedDashboardIds}
      hasResults={hasResults}
      onSearchTextChanged={onSearchTextChanged}
      searchText={searchText}
      clearSelection={clearSelection}
      filteredDashboards={filteredDashboards}
      filteredReports={filteredReports}
      filteredSelectedDashboards={filteredSelectedDashboards}
      filteredSelectedReports={filteredSelectedReports}
      filteredSelectedWorkspaces={filteredSelectedWorkspaces}
      filteredWorkSpaces={filteredWorkSpaces}
      selectedCount={selectedCount}
      selectedReportIds={selectedReportIds}
      setSelectedReportIds={setSelectedReportIds}
      setSelectedWorkSpaceIds={setSelectedWorkSpaceIds}
      shouldShowClearSelection={shouldShowClearSelection}
    />
  );
};

export default AssetPickerContainer;
