import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import KeywordPicker from './KeywordPicker';
import useFilterValues from '../../../../hooks/useFilterValues';
import FilterPlateFormContext from '../../../../contexts/FilterPlateFormContext';
import useFilterInput from '../../../../hooks/useFilterInput';
import useDateScope from '../../../../hooks/useDateScope';
import filterTypeCheckers from '../../filterTypeCheckers';
import useContextualDataTypes from '../../hooks/useContextualDataTypes';
import getIdentifier from '../../../../getIdentifier';

const useEditingTextFilter = () => {
  const { drillDown } = useContext(FilterPlateFormContext);

  if (!drillDown) {
    return undefined;
  }
  if (filterTypeCheckers.isTextFilter(drillDown)) {
    return drillDown;
  }

  return undefined;
};

const KeywordPickerContainer = ({
  mode,
  editingId,
}: {
  mode: 'is one of' | 'is not one of';
  editingId?: string;
}) => {
  const { field, isEditing, onDrillDownConfirmed, dataset } = useContext(
    FilterPlateFormContext,
  );
  const dataTypes = useContextualDataTypes();
  const editingTextFilter = useEditingTextFilter();
  const filterInput = useFilterInput(field, dataTypes);
  const dateScope = useDateScope({});

  const [searchText, setSearchText] = useState('');

  const [alreadySelectedKeywords] = useState<string[]>(
    editingTextFilter ? editingTextFilter.keywordValues || [] : [],
  );
  const [keywordValues, setKeywordValues] = useState<string[]>(
    editingTextFilter ? editingTextFilter.keywordValues || [] : [],
  );
  const [blanks, setBlanks] = useState<boolean | undefined>(
    editingTextFilter ? editingTextFilter.keywordsExists : undefined,
  );
  const { values, isLoading: isLoadingDataTypes } = useFilterValues({
    field,
    value: searchText,
    dateScope,
    filterInput,
  });

  const [optionsWithoutPreSelected, setOptionsWithoutPreSelected] = useState<
    string[]
  >([]);

  useEffect(() => {
    const newOptionsWithoutPreSelected = alreadySelectedKeywords
      ? values.filter((o) => !alreadySelectedKeywords.includes(o))
      : values;
    setOptionsWithoutPreSelected(newOptionsWithoutPreSelected);
  }, [alreadySelectedKeywords, values]);

  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  useEffect(() => {
    const newFilteredOptions = (() => {
      if (searchText === '') {
        return optionsWithoutPreSelected;
      }

      return optionsWithoutPreSelected.filter((o) =>
        o.toLowerCase().includes(searchText.toLowerCase()),
      );
    })();
    setFilteredOptions(newFilteredOptions);
  }, [optionsWithoutPreSelected, searchText]);

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onKeywordSelected = (keyword: string) => {
    setKeywordValues([...(keywordValues || []), keyword]);
  };

  const onKeywordDeselected = (keyword: string) => {
    setKeywordValues([...(keywordValues || []).filter((k) => k !== keyword)]);
  };

  const clearSelectedOptions = () => {
    setKeywordValues([]);
  };

  const onBlanksToggled = () => {
    setBlanks((e) => {
      if (e === undefined) {
        return false;
      } else {
        return !e;
      }
    });
  };

  const onConfirm = useCallback(() => {
    if (!field) {
      return;
    }
    const newDrillDown: TextFilter = {
      id: editingId ? editingId : getIdentifier(undefined, true),
      field,
      fieldType: 'text' as 'text',
      keywordValues,
      isExclude: mode === 'is not one of',
      dataset,
      mode: 'editing' as 'editing',
      keywordsExists: blanks,
    };

    onDrillDownConfirmed(newDrillDown);
  }, [
    dataset,
    editingId,
    blanks,
    field,
    mode,
    keywordValues,
    onDrillDownConfirmed,
  ]);

  const isSearching = searchText !== '';
  const selectedOptions = keywordValues || [];
  const canClearSelectedOptions =
    !isSearching &&
    alreadySelectedKeywords &&
    selectedOptions.filter((o) => alreadySelectedKeywords.includes(o)).length >
      1;

  const isValid = keywordValues.length > 0 || blanks !== undefined;
  const showBlanksTicked = blanks === undefined ? false : !blanks;

  return (
    <KeywordPicker
      isSearching={isSearching}
      canClearSelectedOptions={!!canClearSelectedOptions}
      alreadySelectedKeywords={
        alreadySelectedKeywords
          ? alreadySelectedKeywords.filter((k) =>
              k.toLowerCase().includes(searchText.toLowerCase()),
            )
          : []
      }
      options={filteredOptions}
      selectedOptions={selectedOptions}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      onKeywordSelected={onKeywordSelected}
      onKeywordDeselected={onKeywordDeselected}
      onConfirm={onConfirm}
      isEditing={isEditing}
      isLoadingDataTypes={isLoadingDataTypes}
      clearSelectedOptions={clearSelectedOptions}
      onBlanksToggled={onBlanksToggled}
      showBlanksTicked={showBlanksTicked}
      isValid={isValid}
    />
  );
};

export default KeywordPickerContainer;
