import React, { useContext } from 'react';
import MetricListColumn from '../MetrlicListColumn';
import MetricItem from '../MetricItem';
import styled from 'styled-components';
import Row from '../../Common/Row';
import {
  HEADERS_BACKGROUND_COLOR,
  HEADERS_BORDER_BOTTOM_COLOR,
} from '../../Grid/StyleOverrides';
import { RowHeightContext } from '../../../contextProviders/SplashScreenProviders/MiscProviders/RowHeightProvider';
import FilterPlatesProvider from '../../../contextProviders/FilterPlatesProvider';

const HeadersRow = styled(Row)`
  border-bottom: 1px solid ${HEADERS_BORDER_BOTTOM_COLOR} !important;
  background-color: ${HEADERS_BACKGROUND_COLOR};
  align-items: center;
`;

const MetricListContent = ({
  orderedMetricList,
  comparison,
  isBonusPeriodMode,
  bonusPeriodId,
}: {
  orderedMetricList: MetricListItemType[];
  comparison?: PersistedComparisonType;
  isBonusPeriodMode: boolean;
  bonusPeriodId?: string;
}) => {
  const { rowHeight } = useContext(RowHeightContext);

  return (
    <div>
      <HeadersRow>
        <MetricListColumn flex={2} isHeader rowHeight={rowHeight}>
          <span>Metric</span>
        </MetricListColumn>
        <MetricListColumn flex={1} isHeader rowHeight={rowHeight}>
          <span>Value</span>
        </MetricListColumn>
        <MetricListColumn flex={1} isHeader rowHeight={rowHeight}>
          <span>Change</span>
        </MetricListColumn>
      </HeadersRow>
      {orderedMetricList.map((item, index) => (
        <FilterPlatesProvider
          key={item.id}
          isDefaultFiltersDisabled={item.isDefaultFiltersDisabled}
        >
          <MetricItem
            item={item}
            comparison={comparison}
            isBonusPeriodMode={isBonusPeriodMode}
            bonusPeriodId={bonusPeriodId}
          />
        </FilterPlatesProvider>
      ))}
    </div>
  );
};

export default MetricListContent;
