import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { buildShowGlobalKpiList } from '../../../../../navigation/appRoutes';
import Card from '../../../../../components/Common/Card';
import { PortalsContext } from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const ScorecardUsage = ({
  scorecards,
  usedId,
}: {
  scorecards: Scorecards.Scorecard[];
  usedId: string;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <>
      {scorecards.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: '8px' }}>
            <Typography.Header type="H5">{`Scorecards (${scorecards.length})`}</Typography.Header>
          </div>
          <Card>
            {scorecards.map((sc, index) => (
              <ListItemWrapper
                key={`${sc.id}-${usedId}`}
                isLast={scorecards.length - 1 === index}
              >
                <Column
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Link
                    to={buildShowGlobalKpiList({
                      scorecardId: sc.id,
                      portal: selectedPortal,
                    })}
                    target="_blank"
                    style={{ display: 'flex' }}
                  >
                    <Typography.Body type="Body 12" color="#0041ea">
                      {sc.title}
                    </Typography.Body>
                  </Link>
                </Column>
              </ListItemWrapper>
            ))}
          </Card>
        </div>
      )}
    </>
  );
};

export default ScorecardUsage;
