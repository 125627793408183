import React from 'react';
import ErrorBoundary from '../Common/ErrorBoundary';
import Row from '../Common/Row';
import DateInput from '../DateInput';
import Card from 'components/Common/Card';
import Gauge from '../Gauge';
import withDateFilter from '../../hocs/withDateFIlter';
import FilterPlatesProvider from '../../contextProviders/FilterPlatesProvider';

const GaugePreview = ({ gauge }: { gauge: VisualisationDefinitions.Gauge }) => {
  return (
    <ErrorBoundary>
      <FilterPlatesProvider>
        <Row spaceBetween style={{ marginBottom: 24 }}>
          <DateInput />
        </Row>
        <Card style={{ height: 700 }}>
          <Gauge gauge={gauge} />
        </Card>
      </FilterPlatesProvider>
    </ErrorBoundary>
  );
};

export default withDateFilter(GaugePreview, {});
