import { useCallback, useContext, useEffect, useState } from 'react';
import GqlClientContext from '../../../../contexts/GqlClientContext';
import useGetPerformanceDatasetConfigs from './useGetPerformanceDatasetConfigs';

const usePerformanceDatasetConfigs = (
  datasets: FleetOps.DatasetDefinition[],
) => {
  const { client } = useContext(GqlClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [performanceConfigs, setPerformanceConfigs] = useState<
    FleetOps.PerformanceDatasetConfig[]
  >([]);
  const [performanceMetricIds, setPerformanceMetricIds] = useState<string[]>(
    [],
  );
  const getPerformanceConfigs = useGetPerformanceDatasetConfigs();

  const refreshPerformanceConfigs = useCallback(async () => {
    const configs = await getPerformanceConfigs();
    setPerformanceConfigs(configs);
    setIsLoading(false);
  }, [getPerformanceConfigs]);

  // setPerformanceConfigs
  useEffect(() => {
    let isActive = true;

    getPerformanceConfigs().then((configs) => {
      if (!isActive) {
        return;
      }
      setPerformanceConfigs(configs);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
    // Refresh when datasets update
  }, [client, getPerformanceConfigs, datasets]);

  // setPerformanceMetricIds
  useEffect(() => {
    const newMetricIds = performanceConfigs.reduce(
      (a, b) => [...a, ...b.metrics.map((m) => m.metricId)],
      [] as string[],
    );
    setPerformanceMetricIds(newMetricIds);
  }, [performanceConfigs]);

  return {
    performanceConfigs,
    performanceMetricIds,
    refreshPerformanceConfigs,
    isLoading,
  };
};

export default usePerformanceDatasetConfigs;
