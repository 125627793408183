import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import Inputs from '../Inputs';
import AccessInput from '../AccessInput';
import Search from '../Search';
import Button from '../../kingpin/atoms/Button';
import Icon from '../../kingpin/atoms/Icon';
import Form from 'kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import FormContent from '../../kingpin/forms/FormContent';

const List = styled.div`
  margin-bottom: 24px;
  height: 400px;
  overflow-y: auto;
  padding-right: 12px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;
  height: 32px;
  &:hover {
    background-color: #ededed;
  }
`;

const CopyGadgetModal = ({
  step,
  isLoading,
  // Step 1
  copyGadgetTo,
  copyGadgetToOptions,
  close,
  onCopyClicked,
  // Step 2
  onSaveClicked,
  onBackClicked,
  // Step 2 - new dashboard
  title,
  onTitleChanged,
  access,
  setAccess,
  // Step 2 - existing dashboard
  searchText,
  onSearchTextChanged,
  filteredDashboards,
  selectedDashboard,
  setSelectedDashboard,
  selectedWallboard,
  setSelectedWallboard,
  filteredWallboards,
}: {
  step: number;
  isLoading: boolean;
  // Step 1
  copyGadgetTo:
    | 'new dashboard'
    | 'existing dashboard'
    | 'wallboard'
    | undefined;
  copyGadgetToOptions: RadioOption[];

  close: () => void;
  onCopyClicked: () => void;
  // Step 2
  onSaveClicked: () => void;
  onBackClicked: () => void;
  // Step 2 - new dashboard
  title: string;
  onTitleChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  access: ResourceAccess;
  setAccess: React.Dispatch<React.SetStateAction<ResourceAccess>>;

  // Step 2 - existing dashboard
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredDashboards: DashboardType[];
  setSelectedDashboard: React.Dispatch<
    React.SetStateAction<DashboardType | undefined>
  >;
  selectedDashboard: DashboardType | undefined;

  // Step 2 - wallboard
  filteredWallboards: Wallboard[];
  selectedWallboard?: Wallboard;
  setSelectedWallboard: React.Dispatch<
    React.SetStateAction<Wallboard | undefined>
  >;
}) => {
  if (step === 1 || copyGadgetTo === undefined) {
    return (
      <Form data-testid="copy-gadget-flow">
        <FormHeader title={'Copy gadget to'} onClose={close} />
        <FormContent>
          <div
            style={{
              marginBottom: 16,
            }}
          >
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type={'Label'}>Destination</Typography.Body>
            </div>
            <Inputs.Radio options={copyGadgetToOptions} />
          </div>
        </FormContent>
        <Row
          style={{ justifyContent: 'flex-end', marginBottom: 18 }}
          centerAlign
        >
          <Row centerAlign>
            <div style={{ marginRight: 12 }}>
              <Button
                onClick={close}
                size="Small"
                type="Ghost"
                label="Cancel"
              />
            </div>
            <Button
              type="Primary"
              size="Small"
              label="Copy"
              onClick={onCopyClicked}
              isDisabled={copyGadgetTo === undefined}
            />
          </Row>
        </Row>
      </Form>
    );
  }

  if (copyGadgetTo === 'new dashboard') {
    return (
      <Form>
        <FormHeader title={'Create Dashboard'} onClose={close} />
        <FormContent>
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">
              Dashboard Title (max. 40 characters)
            </Typography.Body>
            <Inputs.TextInput
              value={title}
              onChange={onTitleChanged}
              data-testid={'dashboard-title'}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <AccessInput
              access={access}
              setAccess={setAccess}
              canSetToPrivate={true}
            />
          </div>
        </FormContent>
        <Row centerAlign spaceBetween style={{ marginBottom: 16 }}>
          <Button
            onClick={close}
            isDisabled={isLoading}
            type="Ghost"
            size="Small"
            label="Cancel"
          />
          <Row centerAlign>
            <div style={{ marginRight: 12 }}>
              <Button
                type="Ghost"
                size="Small"
                onClick={onBackClicked}
                isDisabled={isLoading}
                label="Back"
              />
            </div>
            <Button
              type="Primary"
              size="Small"
              label="Save"
              onClick={onSaveClicked}
              isLoading={isLoading}
              isDisabled={title === '' || isLoading}
            />
          </Row>
        </Row>
      </Form>
    );
  }

  if (copyGadgetTo === 'existing dashboard') {
    return (
      <Form>
        <FormHeader title={'Select Dashboard'} onClose={close} />
        <FormContent>
          <div style={{ marginBottom: 16 }}>
            <Search
              onSearchTextChanged={onSearchTextChanged}
              searchText={searchText}
              onClearClicked={() => {
                // @ts-ignore
                onSearchTextChanged({ target: { value: '' } });
              }}
            />
          </div>
          <List>
            {filteredDashboards.map((d) => (
              <Item
                key={d.id}
                onClick={() => {
                  if (selectedDashboard && selectedDashboard.id === d.id) {
                    setSelectedDashboard(undefined);
                  } else {
                    setSelectedDashboard(d);
                  }
                }}
              >
                <Row
                  centerAlign
                  style={{ justifyContent: 'space-between', width: '100%' }}
                >
                  <Typography.Body type="Body 12">{d.name}</Typography.Body>
                  {!!selectedDashboard && selectedDashboard.id === d.id && (
                    <Icon icon="checkmark" />
                  )}
                </Row>
              </Item>
            ))}
          </List>
        </FormContent>
        <Row centerAlign spaceBetween style={{ marginBottom: 16 }}>
          <Button
            onClick={close}
            isDisabled={isLoading}
            label="Cancel"
            size="Small"
            type="Ghost"
          />
          <Row centerAlign>
            <div style={{ marginRight: 12 }}>
              <Button
                onClick={onBackClicked}
                isDisabled={isLoading}
                label="Back"
                size="Small"
                type="Ghost"
              />
            </div>
            <Button
              type="Primary"
              size="Small"
              onClick={onSaveClicked}
              isDisabled={isLoading || !selectedDashboard}
              isLoading={isLoading}
              label="Save"
            />
          </Row>
        </Row>
      </Form>
    );
  }

  if (copyGadgetTo === 'wallboard') {
    return (
      <Form>
        <FormHeader title={'Select Wallboard'} onClose={close} />
        <FormContent>
          <div style={{ marginBottom: 16 }}>
            <Search
              onSearchTextChanged={onSearchTextChanged}
              searchText={searchText}
              onClearClicked={() => {
                // @ts-ignore
                onSearchTextChanged({ target: { value: '' } });
              }}
            />
          </div>
          <List>
            {filteredWallboards.map((d) => (
              <Item
                key={d.id}
                onClick={() => {
                  if (selectedWallboard && selectedWallboard.id === d.id) {
                    setSelectedWallboard(undefined);
                  } else {
                    setSelectedWallboard(d);
                  }
                }}
              >
                <Row
                  centerAlign
                  style={{ justifyContent: 'space-between', width: '100%' }}
                >
                  <Typography.Body type="Body 12">{d.name}</Typography.Body>
                  {!!selectedWallboard && selectedWallboard.id === d.id && (
                    <Icon icon="checkmark" />
                  )}
                </Row>
              </Item>
            ))}
          </List>
        </FormContent>
        <Row centerAlign spaceBetween style={{ marginBottom: 16 }}>
          <Button
            onClick={close}
            isDisabled={isLoading}
            size="Small"
            type="Ghost"
            label="Cancel"
          />
          <Row centerAlign>
            <div style={{ marginRight: 12 }}>
              <Button
                onClick={onBackClicked}
                isDisabled={isLoading}
                size="Small"
                type="Ghost"
                label="Back"
              />
            </div>

            <Button
              type="Primary"
              size="Small"
              onClick={onSaveClicked}
              isDisabled={isLoading || !selectedWallboard}
              isLoading={isLoading}
              label="Save"
            />
          </Row>
        </Row>
      </Form>
    );
  }

  return null;
};

export default CopyGadgetModal;
