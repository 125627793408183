import React, { useContext, useEffect, useState } from 'react';
import ComparisonContext from '../../contexts/ComparisonContext';
import Card from 'components/Common/Card';
import Row from '../Common/Row';
import ErrorBoundary from '../Common/ErrorBoundary';
import DateInput from '../DateInput';
import withDateFilter from '../../hocs/withDateFIlter';
import { useDataTypesFromMetricIds } from '../../hooks/useDataTypesFromSeriesAndMetricListItems';
import RankingMatrix from '../RankingMatrix';
import DateInputContext from '../../contexts/DateInputContext';
import VisualisationUsageDetails from '../VisualisationUsageDetails';
import FilterPlatesProvider from '../../contextProviders/FilterPlatesProvider';

const RankingMatrixPreview = ({
  gadget,
}: {
  gadget: VisualisationDefinitions.RankingMatrix;
}) => {
  const { setDataTypes } = useContext(DateInputContext);
  const [metricIds, setMetricIds] = useState<string[]>([]);
  const dataTypes = useDataTypesFromMetricIds(metricIds);
  const [comparison, setComparison] = useState<
    PersistedComparisonType | undefined
  >({
    compareType: 'compareTo',
    relativeDateOption: 'last 7 days',
  });

  useEffect(() => {
    setMetricIds(gadget.metrics.map((m) => m.metricId));
  }, [gadget.metrics]);

  useEffect(() => {
    setDataTypes(dataTypes);
  }, [dataTypes, setDataTypes]);

  return (
    <ErrorBoundary>
      <FilterPlatesProvider>
        <ComparisonContext.Provider
          value={{
            currentComparison: comparison,
            setCurrentComparison: setComparison,
          }}
        >
          <Row spaceBetween style={{ marginBottom: 24 }}>
            <DateInput />
            <VisualisationUsageDetails chatDefinition={gadget} />
          </Row>
          <Card style={{ height: 700 }}>
            <RankingMatrix gadget={gadget} />
          </Card>
        </ComparisonContext.Provider>
      </FilterPlatesProvider>
    </ErrorBoundary>
  );
};

export default withDateFilter(RankingMatrixPreview, {});
