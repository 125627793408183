import React, { useState } from 'react';
import DashboardGadgetContext from '../contexts/DashboardGadgetContext';
import DashboardContextMenuProvider from './DashboardContextMenuProvider';
import useDashboardGadgetDataTypes from '../hooks/useDashboardGadgetDataTypes';
import FilterPlatesProvider from './FilterPlatesProvider';

const DashboardGadgetProvider = ({
  children,
  dashboardGadget,
}: {
  children: JSX.Element | JSX.Element[];
  dashboardGadget: DashboardGadget;
}) => {
  const dataTypes = useDashboardGadgetDataTypes(dashboardGadget);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <DashboardGadgetContext.Provider
      value={{
        dashboardGadget,
        isEditing,
        setIsEditing,
      }}
    >
      <FilterPlatesProvider
        drillDowns={dashboardGadget.drillDowns}
        scope={dashboardGadget.scope}
        dataTypes={dataTypes}
        isDefaultFiltersDisabled={dashboardGadget.isDefaultFiltersDisabled}
      >
        <DashboardContextMenuProvider>{children}</DashboardContextMenuProvider>
      </FilterPlatesProvider>
    </DashboardGadgetContext.Provider>
  );
};

export default DashboardGadgetProvider;
