import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import Row from '../../Common/Row';
import Colors2 from '../../../theme/Colors2';
import Icon from '../../../kingpin/atoms/Icon';

const Filter = styled.div<{ isSelected?: boolean }>`
  height: 32px;
  border-radius: 8px;
  margin-right: 8px;
  padding: 0px 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => (props.isSelected ? '#e7edff' : '#f6f6f6')};

  cursor: pointer;
`;

const QuickFilter = ({
  onClick,
  isSelected,
  name,
}: {
  onClick: () => void;
  isSelected: boolean;
  name: string;
}) => (
  <Filter isSelected={isSelected} onClick={onClick}>
    {isSelected && (
      <div style={{ marginRight: 4, display: 'flex' }}>
        <Icon icon={'checkmark'} color={Colors2.Secondary.info} />
      </div>
    )}
    <Typography.Body
      type={'Button Text'}
      color={isSelected ? Colors2.Secondary.info : Colors2.Grey['4']}
    >
      {name}
    </Typography.Body>
  </Filter>
);

const QuickFilters = ({
  quickFilters,
  onFilterToggled,
  selectedQuickFilters,
}: {
  quickFilters: LegacySavedFilter[];
  onFilterToggled: (f: LegacySavedFilter) => void;
  selectedQuickFilters: LegacySavedFilter[];
}) => (
  <Row centerAlign>
    {quickFilters.map((f) => (
      <QuickFilter
        key={f.id}
        onClick={() => onFilterToggled(f)}
        isSelected={selectedQuickFilters.some((sf) => sf.id === f.id)}
        name={f.name || 'Filter'}
      />
    ))}
  </Row>
);

export default QuickFilters;
