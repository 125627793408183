import { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import mergeFilterInputs from 'mergeFilterInputs';
import {
  usePlatesToFilterInput,
  useGetMetric,
} from 'screens/GoalShow/useGoalData';
import isDefined from 'isDefined';
import AccountContext from 'contexts/AccountContext';
import GqlClientContext from 'contexts/GqlClientContext';
import useLockedDebouncedEffect from 'hooks/useLockedDebouncedEffect';
import useBuildKpiInputs from './useBuildKpiInputs';
import { isKpiRow } from 'hooks/kpiTypeCheckers';
import metricTypeCheckers from 'types/metricTypeCheckers';
import aggregateSpecialMetrics from './aggregateSpecialMetrics';
import calculateScorecard from './calculateScorecard';

const useScorecardData = ({
  scorecard,
  selectedPeriods,
  isKpisDisabled,
}: {
  scorecard: Scorecards.Scorecard;
  selectedPeriods: Period[];
  isKpisDisabled?: boolean;
}) => {
  const { isDemoAccount, demoAccountNow } = useContext(AccountContext);
  const { client } = useContext(GqlClientContext);
  const [data, setData] = useState<Goals.MetricResult[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [scorecardInput, setScorecardInput] =
    useState<Scorecards.ScorecardInput>();
  const getMetric = useGetMetric();
  const platesToFilterInput = usePlatesToFilterInput();

  const buildKpiInputs = useBuildKpiInputs(scorecard);

  const buildMetricArgs =
    useCallback((): Scorecards.GetScorecardDataArgs | null => {
      const specialKpis = scorecard.kpis
        .filter(isKpiRow)
        .map((kpi) => {
          const metric = getMetric(kpi.metricId);
          if (!metricTypeCheckers.isSpecialMetric(metric)) {
            return undefined;
          }
          return {
            kpi,
            metric,
          };
        })
        .filter(isDefined);

      if (
        (!scorecardInput && specialKpis.length === 0) ||
        selectedPeriods.length === 0
      ) {
        return null;
      }

      const dateScope = {
        startDate: selectedPeriods[0].startDate,
        endDate: moment(isDemoAccount ? demoAccountNow : undefined)
          .subtract({ day: 1 })
          .format('YYYY-MM-DD'),
        dateField: 'date',
      };

      const uniqueFilterMetrics = [] as Scorecards.UniqFilterMetrics[];

      specialKpis.forEach((specialKpi) => {
        const filterInput = mergeFilterInputs(
          platesToFilterInput(specialKpi.kpi.drillDowns),
          platesToFilterInput(specialKpi.kpi.scopeDrillDowns),
        );

        const uniqueMetricsGroupIndex = uniqueFilterMetrics.findIndex(
          (filterMetrics) =>
            _.isEqual(filterMetrics.filters, filterInput) &&
            filterMetrics.queryName === specialKpi.metric.query,
        );

        if (uniqueMetricsGroupIndex === -1) {
          uniqueFilterMetrics.push({
            filters: filterInput,
            queryName: specialKpi.metric.query,
            specialMetric: [specialKpi.metric],
          });
        } else {
          uniqueFilterMetrics[uniqueMetricsGroupIndex].specialMetric.push(
            specialKpi.metric,
          );
        }
      });

      return {
        client,
        normalMetricsArgs: scorecardInput || undefined,
        specialMetricRequests: uniqueFilterMetrics.map((filterMetrics) => ({
          specialMetric: filterMetrics.specialMetric,
          filters: filterMetrics.filters,
          queryName: filterMetrics.queryName,
          dateScope,
          interval: scorecard.cadence,
        })),
      };
    }, [
      client,
      demoAccountNow,
      getMetric,
      isDemoAccount,
      platesToFilterInput,
      scorecard.cadence,
      scorecard.kpis,
      scorecardInput,
      selectedPeriods,
    ]);

  const [scorecardArgs, setScorecardArgs] =
    useState<Scorecards.GetScorecardDataArgs | null>(() => buildMetricArgs());

  useEffect(() => {
    setScorecardArgs(buildMetricArgs());
  }, [buildMetricArgs]);

  const getMetricsDataCallback = useCallback(
    async (
      args: Scorecards.GetScorecardDataArgs | null,
    ): Promise<Goals.MetricResult[]> => {
      if (!args) {
        return [];
      }

      const scorecardInputArgs = args.normalMetricsArgs;

      const calculateScoresResponse = scorecardInputArgs
        ? calculateScorecard({ scorecardInputArgs, client })
        : Promise.resolve([]);

      const specialMetricResponses = await Promise.all(
        args.specialMetricRequests.map(async (r) => ({
          response: await aggregateSpecialMetrics({
            client,
            filterInput: [r.filters],
            dateScope: r.dateScope,
            trendByCalendarInterval: r.interval,
            queryName: r.queryName,
          }),
          metrics: r.specialMetric,
        })),
      );

      const specialMetricResults: {
        id: string;
        trend: {
          date: string;
        }[];
      }[] = [];

      specialMetricResponses.forEach((metricResponse) => {
        metricResponse.metrics.forEach((metric) => {
          const specialMetricRow = {
            id: metric.id,
            trend: selectedPeriods.map((period) => {
              const metricPeriodResponse = metricResponse.response.find(
                (res) => res.date === period.startDate,
              );
              return {
                date: period.startDate,
                value: metricPeriodResponse
                  ? metricPeriodResponse[metric.id]
                  : undefined,
              };
            }),
          };
          specialMetricResults.push(specialMetricRow);
        });
      });

      const calculateScoresResults = await calculateScoresResponse;

      return [...calculateScoresResults, ...specialMetricResults];
    },
    [client, selectedPeriods],
  );

  const responseHandler = useCallback((response: Goals.MetricResult[]) => {
    setIsLoading(false);
    setData(response);
  }, []);

  useLockedDebouncedEffect({
    args: scorecardArgs,
    callback: getMetricsDataCallback,
    responseHandler,
  });

  const buildScorecardInput = useCallback(() => {
    const metrics = buildKpiInputs();

    if (
      selectedPeriods.length === 0 ||
      metrics.length === 0 ||
      isKpisDisabled
    ) {
      return;
    }

    const dateScope = {
      startDate: selectedPeriods[0].startDate,
      endDate: moment(isDemoAccount ? demoAccountNow : undefined)
        .subtract({ day: 1 })
        .format('YYYY-MM-DD'),
      dateField: 'date',
    };

    return {
      metrics,
      dateScope,
      interval: scorecard.cadence,
    };
  }, [
    buildKpiInputs,
    demoAccountNow,
    isDemoAccount,
    isKpisDisabled,
    scorecard,
    selectedPeriods,
  ]);

  useEffect(() => {
    const newGoalInput = buildScorecardInput();
    if (!newGoalInput) {
      return;
    }

    if (_.isEqual(scorecardInput, newGoalInput)) {
      return;
    }
    setScorecardInput(newGoalInput);
  }, [buildScorecardInput, scorecard, scorecardInput]);

  return {
    data,
    isLoading,
    scorecardInput,
  };
};

export default useScorecardData;
