import { useCallback, useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import DataManagerSpecificSetContext from '../../contexts/DataManagerSpecificSetContext';
import ReportContext from '../../contexts/ReportContext';
import { useNavigate } from 'react-router-dom';
import SPECIFIC_SET_ROUTES from '../../screens/DataManager/SpecificSet/routes';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const useOnValidationReportRemoved = () => {
  const { selectedPortal } = useContext(PortalsContext);
  const { updateBaseView } = useContext(BaseViewsContext);
  const { baseView } = useContext(DataManagerSpecificSetContext);
  const { report } = useContext(ReportContext);
  const navigate = useNavigate();
  const [isValidationReport, setIsValidationReport] = useState<boolean>(false);

  useEffect(() => {
    if (!report || !baseView || !baseView.validationReportIds) {
      setIsValidationReport(false);
      return;
    }

    setIsValidationReport(baseView.validationReportIds.includes(report.id));
  }, [baseView, report]);

  const onValidationReportRemoved = useCallback(() => {
    if (!report || !baseView || !baseView.validationReportIds) {
      return;
    }

    const newBaseView = {
      ...baseView,
      validationReportIds: baseView.validationReportIds.filter(
        (rId) => rId !== report.id,
      ),
    };

    updateBaseView(newBaseView);
    navigate(SPECIFIC_SET_ROUTES.buildBase(baseView.type, selectedPortal));
  }, [baseView, navigate, report, selectedPortal, updateBaseView]);

  return { isValidationReport, onValidationReportRemoved };
};

export default useOnValidationReportRemoved;
