import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import Dropdown from '../../components/Inputs/Dropdown';
import IndexListItem, { IndexListHeader } from '../../components/IndexListItem';

import ReportContextMenu from './ReportContextMenu';
import ResourceTopBar from '../../components/ResourceTopBar';
import NoIndexItemsFound from '../../components/NoIndexItemsFound';
import FlexCentered from '../../components/Common/FlexCentered';
import Loading from 'components/Loading';
import NoIndexItems from '../../components/NoIndexItems';
import PermissionGates from '../../components/PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import FeatureGate, { FEATURE_GATES } from '../../components/FeatureGate';
import RolesContext from '../../contexts/RolesContext';
import TextInput from '../../kingpin/atoms/TextInput';
import NonPortalAccountsGate from '../../components/NonPortalAccountsGate';
import Badge from '../../components/Badge';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  padding: 16px 24px;
`;

const ListWrapper = styled.div`
  flex: 1;
  padding: 0px 24px 16px 24px;
  overflow-y: auto;
`;

const List = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  padding: 0px;
  padding-bottom: 64px;
`;

const TopBar = ({
  onCreateReportClicked,
}: {
  onCreateReportClicked: () => void;
}) => (
  <ResourceTopBar>
    <Row centerAlign spaceBetween style={{ height: '100%', width: '100%' }}>
      <Typography.Header type="H3">Reports</Typography.Header>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.REPORT_MANAGEMENT.CREATE}
      >
        <FeatureGate featureName={FEATURE_GATES.SELF_SERVE}>
          <Button
            onClick={onCreateReportClicked}
            type="Primary"
            size="Small"
            label="Create Report"
            icon="add"
          />
        </FeatureGate>
      </PermissionGates.Has>
    </Row>
  </ResourceTopBar>
);

const SortByInput = ({
  sortBy,
  setSortBy,
}: {
  sortBy?: IndexControls.SortBy;
  setSortBy: React.Dispatch<
    React.SetStateAction<IndexControls.SortBy | undefined>
  >;
}) => {
  const options = (() => {
    if (!sortBy) {
      return [
        {
          label: 'Last Viewed',
          onSelected: () => {
            setSortBy('Last Viewed (asc)');
          },
        },
        {
          label: 'Title',
          onSelected: () => {
            setSortBy('Title (asc)');
          },
        },
      ];
    }
    if (sortBy === 'Last Viewed (asc)') {
      return [
        {
          label: 'Last Viewed',
          onSelected: () => {
            setSortBy('Last Viewed (desc)');
          },
          arrow: 'up',
        },
        {
          label: 'Title',
          onSelected: () => {
            setSortBy('Title (asc)');
          },
        },
      ];
    }
    if (sortBy === 'Last Viewed (desc)') {
      return [
        {
          label: 'Last Viewed',
          onSelected: () => {
            setSortBy(undefined);
          },
          arrow: 'down',
        },
        {
          label: 'Title',
          onSelected: () => {
            setSortBy('Title (asc)');
          },
        },
      ];
    }
    if (sortBy === 'Title (asc)') {
      return [
        {
          label: 'Last Viewed',
          onSelected: () => {
            setSortBy('Last Viewed (asc)');
          },
        },
        {
          label: 'Title',
          onSelected: () => {
            setSortBy('Title (desc)');
          },
          arrow: 'up',
        },
      ];
    }
    if (sortBy === 'Title (desc)') {
      return [
        {
          label: 'Last Viewed',
          onSelected: () => {
            setSortBy('Last Viewed (asc)');
          },
        },
        {
          label: 'Title',
          onSelected: () => {
            setSortBy(undefined);
          },
          arrow: 'down',
        },
      ];
    }
    return [];
  })() as DropdownOption[];

  const selectedLabel = (() => {
    if (sortBy) {
      if (sortBy.includes('Title')) {
        return 'Title';
      }
      if (sortBy.includes('Last Viewed')) {
        return 'Last Viewed';
      }
    }
    return undefined;
  })();
  const selectedArrow = (() => {
    if (sortBy) {
      if (sortBy.includes('asc')) {
        return 'up';
      }
      if (sortBy.includes('desc')) {
        return 'down';
      }
    }
    return undefined;
  })();

  return (
    <div style={{ width: 137, marginRight: 16 }}>
      <Dropdown
        options={options}
        placeholder={'Sort By'}
        selectedLabel={selectedLabel}
        selectedArrow={selectedArrow}
        listWidth={137}
      />
    </div>
  );
};

const ReportFilterInput = ({
  reportFilter,
  setReportFilter,
}: {
  reportFilter: IndexControls.ReportFilter;
  setReportFilter: React.Dispatch<
    React.SetStateAction<IndexControls.ReportFilter>
  >;
}) => {
  const { currentPermissions } = useContext(RolesContext);
  const buildOptions = useCallback((): DropdownOption[] => {
    const newOptions: DropdownOption[] = [];
    const order: IndexControls.ReportFilter[] = (() => {
      if (
        currentPermissions.includes(
          PERMISSIONS.CONTENT_ACCESS.VIEW_SE_ONLY_REPORTS,
        )
      ) {
        return ['All', 'Standard', 'Popups', 'SE Only'];
      } else {
        return ['Standard', 'Popups', 'All'];
      }
    })();
    order.forEach((o) => {
      newOptions.push({
        label: o,
        key: o,
        isSelected: reportFilter === o,
        onSelected: () => {
          setReportFilter(o);
        },
      });
    });

    return newOptions;
  }, [currentPermissions, reportFilter, setReportFilter]);
  const [options, setOptions] = useState<DropdownOption[]>(() =>
    buildOptions(),
  );
  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return (
    <div style={{ width: 176, marginRight: 16 }}>
      <Dropdown
        options={options}
        placeholder={'Filter'}
        selectedLabel={reportFilter}
        listWidth={176}
      />
    </div>
  );
};

const AccessFilterInput = ({
  accessFilter,
  setAccessFilter,
}: {
  accessFilter?: IndexControls.AccessFilter;
  setAccessFilter: React.Dispatch<
    React.SetStateAction<IndexControls.AccessFilter | undefined>
  >;
}) => {
  const { currentPermissions } = useContext(RolesContext);
  const options = [
    {
      label: 'Private',
      onSelected: () => {
        if (accessFilter === 'Private') {
          setAccessFilter(undefined);
        } else {
          setAccessFilter('Private');
        }
      },
    },
    {
      label: 'Public',
      onSelected: () => {
        if (accessFilter === 'Public') {
          setAccessFilter(undefined);
        } else {
          setAccessFilter('Public');
        }
      },
    },
    {
      label: 'Admin Only',
      onSelected: () => {
        if (accessFilter === 'Admin Only') {
          setAccessFilter(undefined);
        } else {
          setAccessFilter('Admin Only');
        }
      },
    },
    {
      label: 'Users',
      onSelected: () => {
        if (accessFilter === 'Users') {
          setAccessFilter(undefined);
        } else {
          setAccessFilter('Users');
        }
      },
    },
  ] as DropdownOption[];

  if (currentPermissions.includes(PERMISSIONS.REPORT_MANAGEMENT.CREATE)) {
    options.push({
      label: 'Only Mine',
      onSelected: () => {
        if (accessFilter === 'Only Mine') {
          setAccessFilter(undefined);
        } else {
          setAccessFilter('Only Mine');
        }
      },
    });
  }

  return (
    <div style={{ width: 176 }} id={'access-filter-button'}>
      <Dropdown
        options={options}
        placeholder={'Access'}
        selectedLabel={accessFilter}
        listWidth={176}
      />
    </div>
  );
};

const Controls = ({
  searchText,
  onSearchTextChanged,
  reportFilter,
  setReportFilter,
  accessFilter,
  setAccessFilter,
  sortBy,
  setSortBy,
}: {
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  reportFilter: IndexControls.ReportFilter;
  setReportFilter: React.Dispatch<
    React.SetStateAction<IndexControls.ReportFilter>
  >;
  accessFilter?: IndexControls.AccessFilter;
  setAccessFilter: React.Dispatch<
    React.SetStateAction<IndexControls.AccessFilter | undefined>
  >;
  sortBy?: IndexControls.SortBy;
  setSortBy: React.Dispatch<
    React.SetStateAction<IndexControls.SortBy | undefined>
  >;
}) => (
  <ContentWrapper>
    <Row centerAlign spaceBetween>
      <div style={{ width: 400 }}>
        <TextInput
          value={searchText}
          onChange={onSearchTextChanged}
          icon="search"
          inputSize="Small"
          placeholder="Search"
          autoFocus
        />
      </div>

      <Row centerAlign>
        <SortByInput sortBy={sortBy} setSortBy={setSortBy} />
        <ReportFilterInput
          reportFilter={reportFilter}
          setReportFilter={setReportFilter}
        />
        <NonPortalAccountsGate>
          <AccessFilterInput
            accessFilter={accessFilter}
            setAccessFilter={setAccessFilter}
          />
        </NonPortalAccountsGate>
      </Row>
    </Row>
  </ContentWrapper>
);

const CONTEXT_MENU_PERMISSIONS = [
  PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC,
  PERMISSIONS.REPORT_MANAGEMENT.DELETE,
];

export const AfterReportNameBadge = ({ report }: { report: ReportType }) => {
  if (report.isMarkedAsSEOnly && report.isMarkedAsPopup) {
    return (
      <Row centerAlign style={{ gap: 8 }}>
        <Badge text={'SE Only'} badgeType={'Info'} />
        <Badge text={'Popup'} badgeType={'Default'} />
      </Row>
    );
  } else if (report.isMarkedAsPopup) {
    return <Badge text={'Popup'} badgeType={'Default'} />;
  } else if (report.isMarkedAsSEOnly) {
    return <Badge text={'SE Only'} badgeType={'Info'} />;
  }

  return null;
};

const Reports = ({
  onCreateReportClicked,
  searchText,
  onSearchTextChanged,
  reportFilter,
  setReportFilter,
  accessFilter,
  setAccessFilter,
  sortBy,
  setSortBy,
  listItems,
  isLoading,
  hasNoReports,
}: {
  onCreateReportClicked: () => void;
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  reportFilter: IndexControls.ReportFilter;
  setReportFilter: React.Dispatch<
    React.SetStateAction<IndexControls.ReportFilter>
  >;
  accessFilter?: IndexControls.AccessFilter;
  setAccessFilter: React.Dispatch<
    React.SetStateAction<IndexControls.AccessFilter | undefined>
  >;
  sortBy?: IndexControls.SortBy;
  setSortBy: React.Dispatch<
    React.SetStateAction<IndexControls.SortBy | undefined>
  >;
  listItems: IndexScreenListItem<ReportType>[];
  isLoading: boolean;
  hasNoReports: boolean;
}) => (
  <Wrapper>
    <TopBar onCreateReportClicked={onCreateReportClicked} />
    {hasNoReports && <NoIndexItems message={'No Reports to display yet'} />}
    {!hasNoReports && (
      <React.Fragment>
        <Controls
          searchText={searchText}
          onSearchTextChanged={onSearchTextChanged}
          reportFilter={reportFilter}
          setReportFilter={setReportFilter}
          accessFilter={accessFilter}
          setAccessFilter={setAccessFilter}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        {isLoading && (
          <FlexCentered style={{ height: '100%' }}>
            <Loading />
          </FlexCentered>
        )}
        {!isLoading && listItems.length === 0 && <NoIndexItemsFound />}
        {!isLoading && listItems.length > 0 && (
          <>
            <IndexListHeader type={'report'} />
            <ListWrapper>
              <List>
                {listItems.map((reportItem, index) => (
                  <IndexListItem
                    type={'report'}
                    typeId={reportItem.item.id}
                    key={reportItem.item.id}
                    access={reportItem.access}
                    title={reportItem.item.name}
                    link={`${reportItem.item.id}`}
                    contextMenu={
                      <PermissionGates.HasAny
                        requiredPermissions={CONTEXT_MENU_PERMISSIONS}
                      >
                        <div style={{ marginLeft: 8, paddingRight: 8 }}>
                          <ReportContextMenu report={reportItem.item} />
                        </div>
                      </PermissionGates.HasAny>
                    }
                    afterName={
                      <AfterReportNameBadge report={reportItem.item} />
                    }
                    isFirstItem={index === 0}
                    badges={reportItem.badges}
                  />
                ))}
              </List>
            </ListWrapper>
          </>
        )}
      </React.Fragment>
    )}
  </Wrapper>
);

export default Reports;
