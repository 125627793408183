import React from 'react';

interface BoardContextType {
  board:
    | CustomerLaneBoard
    | DriverPerformanceBoard
    | GeneralBoard
    | PerformanceBoardTypes.Board;
  reasonCodes: ReasonCode[];
  isDeletable?: boolean;
  onDeleted?: () => void;
  quickFilters: LegacySavedFilter[];
  selectedQuickFilters: LegacySavedFilter[];
  setSelectedQuickFilters: React.Dispatch<
    React.SetStateAction<LegacySavedFilter[]>
  >;
  isBoardOwner?: boolean;
}

export const toDateRange = (bonusPeriod: Period): DateRangeInput => ({
  startDate: bonusPeriod.startDate,
  endDate: bonusPeriod.endDate,
});

const BoardContext = React.createContext({} as BoardContextType);

export default BoardContext;
