import React, { useContext } from 'react';
import { MetricsContainer } from 'components/BoardSlideOut/Metrics';
import usePerformanceSlideOutFilterInput from 'components/PerformanceBoards/hooks/usePerformanceSlideOutFilterInput';
import useFullDataInterval from 'components/PerformanceBoards/hooks/useFullDataInterval';
import PerformanceBoardSlideOutContext from 'components/PerformanceBoards/contexts/PerformanceBoardSlideOutContext';

const MetricContainer = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.MetricsSection;
}) => {
  const filterInput = usePerformanceSlideOutFilterInput();
  const { dateScope } = useFullDataInterval({
    mode: section.dateRange.mode,
    n: section.dateRange.n,
    isToDate: section.dateRange.isToDate,
  });

  const { navState } = useContext(PerformanceBoardSlideOutContext);

  if (!filterInput || !dateScope) {
    return null;
  }

  if (navState.slideoutMode === 'Creating' || navState.editingSection) {
    return (
      <div
        style={{
          marginBottom: '24px',
          padding: '16px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
        }}
      >
        <MetricsContainer
          filterInput={filterInput}
          dateScope={dateScope}
          metricIds={section.metricIds}
          title={section.title}
        />
      </div>
    );
  }

  return (
    <MetricsContainer
      filterInput={filterInput}
      dateScope={dateScope}
      metricIds={section.metricIds}
      title={section.title}
      homeViz
    />
  );
};

export default MetricContainer;
