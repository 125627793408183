import React, { useContext, useEffect, useState } from 'react';
import BonusPeriodsContext from '../../../../contexts/BonusPeriodsContext';
import useBonusPeriods from './useBonusPeriods';
import WorkSpaceContext from '../../../../contexts/WorkSpaceContext';
import DashboardContext from '../../../../contexts/DashboardContext';
import _ from 'lodash';

const BonusPeriodsProvider = ({
  children,
  bonusPeriodId,
}: {
  children: JSX.Element | JSX.Element[];
  bonusPeriodId?: string;
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState<BonusPeriod>();
  const { bonusPeriods, bonusPeriodOptions } = useBonusPeriods();

  useEffect(() => {
    if (selectedPeriod) {
      const updatedBp = bonusPeriods.find((bp) => bp.id === selectedPeriod.id);

      if (!_.isEqual(updatedBp, selectedPeriod)) {
        setSelectedPeriod(updatedBp);
      }
    }
  }, [bonusPeriods, selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod) {
      return;
    }

    if (bonusPeriods.length > 0) {
      if (bonusPeriodId) {
        const maybeBp = bonusPeriods.find((bp) => bp.id === bonusPeriodId);
        if (maybeBp) {
          setSelectedPeriod(maybeBp);
        }
      } else {
        const reviewItem =
          bonusPeriods.find((bp) => bp.status === 'pending sign off') ||
          bonusPeriods.find((bp) => bp.status === 'in review');
        if (reviewItem) {
          setSelectedPeriod(reviewItem);
        } else if (bonusPeriodId) {
          const maybeBp = bonusPeriods.find((bp) => bp.id === bonusPeriodId);
          if (maybeBp) {
            setSelectedPeriod(maybeBp);
          }
        } else {
          setSelectedPeriod(bonusPeriods[0]);
        }
      }
    }
  }, [bonusPeriodId, bonusPeriods, selectedPeriod]);

  return (
    <BonusPeriodsContext.Provider
      value={{
        bonusPeriods,
        bonusPeriodOptions,
        selectedBonusPeriod: selectedPeriod,
        setSelectedBonusPeriod: setSelectedPeriod,
      }}
    >
      {children}
    </BonusPeriodsContext.Provider>
  );
};

const Gate = ({
  children,
  bonusPeriodId,
}: {
  children: JSX.Element | JSX.Element[];
  bonusPeriodId?: string;
}) => {
  const { workSpace } = useContext(WorkSpaceContext);
  const { dashboard } = useContext(DashboardContext);

  if (
    workSpace &&
    workSpace.campaignType &&
    workSpace.campaignType === 'driverBonus' &&
    !!dashboard
  ) {
    return <>{children}</>;
  }

  return (
    <BonusPeriodsProvider bonusPeriodId={bonusPeriodId}>
      {children}
    </BonusPeriodsProvider>
  );
};

export default Gate;
