import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from 'kingpin/atoms/Button';
import Row from '../Common/Row';
import TextInput from '../../kingpin/atoms/TextInput';
import FormHeader from '../../kingpin/forms/FormHeader';
import Inputs from '../Inputs';
import Typography from '../../kingpin/atoms/Typography';
import Colors2 from '../../theme/Colors2';
import Icon from '../../kingpin/atoms/Icon';
import styled from 'styled-components';
import Modal from '@atlaskit/modal-dialog';
import ToastContext from '../../contexts/ToastContext';

const AttachmentsList = styled.div`
  margin-bottom: 16px;
  flex-direction: column;
  display: flex;
  gap: 24px;
`;

const Attachment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 13px;
  background-color: ${Colors2.Primary[8]};
`;

const AttachmentName = styled.div`
  display: flex;
  gap: 6px;
  align-content: center;
`;

const ModalForm = styled.div`
  height: 100%;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

const ModalFormScrollableContent = styled.div`
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 10px;
`;

const ModalFormFooter = styled.div`
  padding: 16px 0;
  flex-shrink: 0;
`;

const CONTACT_US_TYPES: { [key: string]: string } = {
  bug: 'I need to report an issue/bug',
  question: 'I have a question',
  feature: 'I want to request a feature',
};

// workaround to rename a file
const renameFile = (originalFile: File, newName: string) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

// given a file to add to the attachments, compare it's name to already attached files and rename it
// following the convention of "ORIGINAL_NAME (1).ABC", "ORIGINAL_NAME (2).ABC" etc.
const ensureUniqueName = (
  fileToAdd: File,
  existingFiles: File[],
  number = 0,
): File => {
  const existingFileNames = existingFiles.map((f) => f.name);
  let newName = fileToAdd.name;
  if (number > 0) {
    const index = newName.lastIndexOf('.');
    newName =
      newName.slice(0, index) + ' (' + number + ')' + newName.slice(index);
  }
  if (existingFileNames.includes(newName)) {
    return ensureUniqueName(fileToAdd, existingFiles, number + 1);
  } else {
    return renameFile(fileToAdd, newName);
  }
};

const ContactUsForm = ({
  onSubmit,
  close,
  isLoading,
  type,
  setType,
  subject,
  onSubjectChanged,
  message,
  onMessageChanged,
  attachments,
  setAttachments,
}: {
  onSubmit: () => void;
  close: () => void;
  isLoading: boolean;
  type: string;
  setType: (type: string) => void;
  subject: string;
  onSubjectChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  message: string;
  onMessageChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  attachments: File[];
  setAttachments: (attachments: File[]) => void;
}) => {
  const { showToast } = useContext(ToastContext);
  const options = useMemo((): RadioOption[] => {
    return Object.keys(CONTACT_US_TYPES).map((t) => ({
      key: t,
      label: CONTACT_US_TYPES[t],
      isSelected: type === t,
      onSelected: () => {
        setType(t);
      },
    }));
  }, [setType, type]);

  const [isValid, setIsValid] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onClickUploadButton = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const addAttachments = useCallback(
    (files: FileList) => {
      const allFiles = attachments.slice();
      const newFiles = Array.prototype.slice.call(files);
      let newFileNames = [];

      for (let file of newFiles) {
        const uniqueFile = ensureUniqueName(file, allFiles);
        newFileNames.push(uniqueFile.name);
        allFiles.push(uniqueFile);
      }
      setAttachments(allFiles);

      if (newFileNames.length === 1) {
        showToast(`${newFileNames[0]} attached.`);
      } else {
        showToast(`Added ${newFileNames.length} attachments.`);
      }
    },
    [attachments, setAttachments, showToast],
  );

  const handleUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        addAttachments(e.target.files);
      }
      // clear file input so the same file can be removed and selected again
      // https://stackoverflow.com/questions/26634616/filereader-upload-same-file-again-not-working
      e.target.value = '';
    },
    [addAttachments],
  );

  const removeAttachment = useCallback(
    (file: File) => {
      const remainingFiles = attachments
        .slice()
        .filter((f) => f.name !== file.name);
      setAttachments(remainingFiles);
    },
    [attachments, setAttachments],
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent) => {
      if (event.clipboardData.files.length > 0) {
        addAttachments(event.clipboardData.files);
        event.preventDefault();
      }
    },
    [addAttachments],
  );

  useEffect(() => {
    setIsValid(
      !!message &&
        message.length > 0 &&
        !!type &&
        !!subject &&
        subject.length > 0,
    );
  }, [message, type, subject, setIsValid]);

  return (
    <Modal autoFocus={false} width="small" onClose={close}>
      <ModalForm onPaste={handlePaste}>
        <FormHeader title="Get In Touch" onClose={close} />
        <ModalFormScrollableContent>
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Label">
                What help do you need?
              </Typography.Body>
            </div>
            <Inputs.Dropdown
              options={options}
              testId={'support-ticket-type'}
              placeholder="What help do you need?"
              buttonStyle={{ width: '100%' }}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <TextInput
              data-testid="subject"
              inputSize="Small"
              maxLength={100}
              label="Subject"
              value={subject}
              placeholder="Subject"
              onChange={onSubjectChanged}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Label">Your Message</Typography.Body>
            </div>
            <Inputs.TextArea
              data-testid="message"
              placeholder="Tell us more about your issue or request"
              value={message}
              onChange={onMessageChanged}
              style={{ height: '148px' }}
            />
          </div>
          <Row style={{ marginBottom: 16 }}>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Button
                onClick={onClickUploadButton}
                label="Add Attachment"
                icon="fileUpload"
                size="Small"
                type="Secondary"
              />
              <div>
                <Typography.Body type="Button Text" color={Colors2.Grey[6]}>
                  Or paste attachment here
                </Typography.Body>
              </div>
              <input
                onChange={handleUpload}
                data-testid="file-upload"
                ref={fileInputRef}
                type="file"
                multiple
                hidden
              />
            </div>
          </Row>
          <AttachmentsList>
            {attachments.map((f) => (
              <Attachment key={f.name}>
                <AttachmentName>
                  <Icon icon="attachment"></Icon>
                  <div style={{ maxWidth: '260px' }}>
                    <Typography.Body type="Body 13">{f.name}</Typography.Body>
                  </div>
                </AttachmentName>
                <Button
                  icon="cross"
                  type="Ghost"
                  size="Small"
                  onClick={() => removeAttachment(f)}
                />
              </Attachment>
            ))}
          </AttachmentsList>
        </ModalFormScrollableContent>
        <ModalFormFooter>
          <Button
            isDisabled={isLoading || !isValid}
            isLoading={isLoading}
            onClick={onSubmit}
            label="Send Message"
            type="Primary"
            size="Medium"
          />
        </ModalFormFooter>
      </ModalForm>
    </Modal>
  );
};

export default ContactUsForm;
