import React, { ChangeEvent, useContext } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import Typography from 'kingpin/atoms/Typography';
import Row from '../../../Common/Row';
import Button from 'kingpin/atoms/Button';
import WorkSpaceContext from '../../../../contexts/WorkSpaceContext';
import useUser from '../../../../hooks/useUser';
import Warning from '../../../Warning';
import userTypeCheckers from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import Form from 'kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';
import TextArea from '../../../Inputs/TextArea';

const PendingSignOffWarning = ({ driver }: { driver: string }) => {
  const { driverBonusWorkSpace: workSpace } = useContext(WorkSpaceContext);
  const { user: owner } = useUser(workSpace ? workSpace.ownerId : undefined);
  const displayName =
    !owner || userTypeCheckers.isWallboardUser(owner) ? '-' : owner.displayName;

  return (
    <Warning
      warnings={[
        `This will re-calculate scores exclusively for ${driver}. We suggest
        making overrides only while the campaign is 'In Review' so that scores
        are re-calculated for all drivers. Contact ${displayName} for assistance.`,
      ]}
    />
  );
};

const CreateOverrideButton = ({
  currentUserName,
  category,
  driver,
  isOpen,
  open,
  onClose,
  note,
  onNoteChanged,
  onCreated,
  isSaving,
  isInReview,
  isPendingSignOff,
  isClosed,
}: {
  currentUserName: string;
  category: string;
  driver: string;
  isOpen: boolean;
  open: () => void;
  onClose: () => void;
  note: string;
  onNoteChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onCreated: () => void;
  isSaving: boolean;
  isInReview: boolean;
  isPendingSignOff: boolean;
  isClosed: boolean;
}) => (
  <>
    <Button
      type="Ghost"
      size="Small"
      label={'Override'}
      onClick={open}
      isLoading={isSaving}
      icon="checkmark"
    />
    <ModalTransition>
      {isOpen && (
        <Modal
          onClose={onClose}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <Form>
            <FormHeader
              title={`${category} Override for ${driver}`}
              onClose={onClose}
            />
            <FormContent>
              <Typography.Header type="H4">{`${category} Override for ${driver}`}</Typography.Header>
              {isInReview && (
                <Typography.Body type="Body 12">
                  This will re-calculate scores for all drivers
                </Typography.Body>
              )}
              {isPendingSignOff && <PendingSignOffWarning driver={driver} />}
              {isClosed && (
                <Typography.Body type="Body 12">
                  {`This will re-calculate scores exclusively for ${driver}`}
                </Typography.Body>
              )}
              <Typography.Body type="Body 12">{`Override by: ${currentUserName}`}</Typography.Body>
              <TextArea
                value={note}
                onChange={onNoteChanged}
                style={{
                  marginBottom: 16,
                  padding: '12px 16px',
                  minHeight: 117,
                }}
                placeholder={'This action requires a reason note'}
              />
            </FormContent>
            <Row
              centerAlign
              style={{ justifyContent: 'flex-end', marginBottom: 32 }}
            >
              <div style={{ marginRight: 16 }}>
                <Button
                  onClick={onClose}
                  label="Cancel"
                  type="Ghost"
                  size="Small"
                />
              </div>
              <Button
                type="Primary"
                size="Small"
                label="Override"
                onClick={onCreated}
                isDisabled={isSaving || note === ''}
                isLoading={isSaving}
              />
            </Row>
          </Form>
        </Modal>
      )}
    </ModalTransition>
  </>
);

export default CreateOverrideButton;
