import React, { useCallback, useContext, useEffect, useState } from 'react';
import Kpis from './Kpis';
import ScorecardContext from '../../../contexts/ScorecardContext';
import { DropResult } from 'react-beautiful-dnd';
import RolesContext from '../../../contexts/RolesContext';
import PERMISSIONS from '../../../permissionDefinitions';
import SlideOutProvider from './providers/SlideoutProvider';
import KpiSlideOutContext from './contexts/KpiSlideOutContext';
import WorkSpaceContext from 'contexts/WorkSpaceContext';
import DashboardContext from 'contexts/DashboardContext';
import useShadows from './hooks/useShadows';

const KpisContainer = () => {
  const {
    scorecard,
    updateScorecard,
    isLoading,
    selectedPeriods,
    isDragAndDropEnabled,
    isKpisDisabled,
    kpisListDivRef,
    setHoveredKpiId,
  } = useContext(ScorecardContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const { dashboard } = useContext(DashboardContext);
  const { currentPermissions } = useContext(RolesContext);
  const { isOpen } = useContext(KpiSlideOutContext);
  const [canDragAndDrop, setCanDragAndDrop] = useState<boolean>(false);
  const {
    isShadowLeftVisible,
    isShadowRightVisible,
    rightShadowRightPos,
    height,
    width,
    kpisScrollerRef,
    gridRef,
  } = useShadows();
  const isEmpty = scorecard.kpis.length === 0;
  const hasCRUDPermissions = currentPermissions.includes(
    PERMISSIONS.SCORECARDS.CRUD_SCORECARD,
  );

  useEffect(() => {
    setCanDragAndDrop(
      currentPermissions.includes(PERMISSIONS.SCORECARDS.CRUD_SCORECARD) &&
        !!isDragAndDropEnabled,
    );
  }, [currentPermissions, isDragAndDropEnabled]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { draggableId, destination } = result;
      if (!destination) {
        return;
      }

      const kpisWithoutDropped = scorecard.kpis.filter(
        (kpi) => kpi.id !== draggableId,
      );
      const theKpi = scorecard.kpis.find((kpi) => kpi.id === draggableId);
      if (!theKpi) {
        throw new Error('Item not found');
      }

      const newKpis = [
        ...kpisWithoutDropped.slice(0, destination.index),
        theKpi,
        ...kpisWithoutDropped.slice(
          destination.index,
          kpisWithoutDropped.length,
        ),
      ];

      const newScorecard = {
        ...scorecard,
        kpis: newKpis,
      };
      updateScorecard(newScorecard);
    },
    [scorecard, updateScorecard],
  );

  const onKpiMouseLeave = useCallback(() => {
    setHoveredKpiId(undefined);
  }, [setHoveredKpiId]);

  if (isKpisDisabled) {
    return null;
  }

  return (
    <Kpis
      isEmpty={isEmpty}
      kpis={scorecard.kpis}
      onDragEnd={onDragEnd}
      isLoading={isLoading}
      canDragAndDrop={canDragAndDrop}
      selectedPeriods={selectedPeriods}
      kpisScrollerRef={kpisScrollerRef}
      kpisListDivRef={kpisListDivRef}
      hasCRUDPermissions={hasCRUDPermissions}
      isSlideoutOpen={isOpen}
      isShadowLeftVisible={isShadowLeftVisible}
      isShadowRightVisible={isShadowRightVisible}
      rightShadowRightPos={rightShadowRightPos}
      onKpiMouseLeave={onKpiMouseLeave}
      isWorkspace={!!workSpace}
      isDashboard={!!dashboard}
      height={height}
      width={width}
      gridRef={gridRef}
    />
  );
};

const Gate = () => {
  return (
    <SlideOutProvider>
      <KpisContainer />
    </SlideOutProvider>
  );
};

export default Gate;
