import React, { useCallback, useEffect, useState } from 'react';
import Typography from '../../../../../kingpin/atoms/Typography';
import Dropdown from '../../../../Inputs/Dropdown';
import ColumnSizeSettingsStyles from './styles';

const SizeStrategyDropdown = ({
  columnSizeStrategy,
  setColumnSizeStrategy,
}: {
  columnSizeStrategy:
    | PerformanceBoardTypes.ColumnSizeStrategies.Strategy
    | undefined;
  setColumnSizeStrategy: React.Dispatch<
    React.SetStateAction<
      PerformanceBoardTypes.ColumnSizeStrategies.Strategy | undefined
    >
  >;
}) => {
  const buildOptions = useCallback((): DropdownOption[] => {
    return [
      {
        label: 'Disabled',
        isSelected: columnSizeStrategy === undefined,
        onSelected: () => {
          setColumnSizeStrategy(undefined);
        },
      },
      {
        label: 'Fit Cell Contents',
        isSelected:
          !!columnSizeStrategy && columnSizeStrategy.type === 'fitCellContents',
        onSelected: () => {
          setColumnSizeStrategy({
            type: 'fitCellContents',
            skipHeader: false,
          });
        },
      },
      {
        label: 'Fit Grid Width',
        isSelected:
          !!columnSizeStrategy && columnSizeStrategy.type === 'fitGridWidth',
        onSelected: () => {
          setColumnSizeStrategy({
            type: 'fitGridWidth',
            columnLimits: [],
          });
        },
      },
    ];
  }, [columnSizeStrategy, setColumnSizeStrategy]);

  const [options, setOptions] = useState<DropdownOption[]>(() =>
    buildOptions(),
  );

  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return (
    <ColumnSizeSettingsStyles.Section>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Auto Size Strategy</Typography.Body>
      </div>
      <Dropdown options={options} />
    </ColumnSizeSettingsStyles.Section>
  );
};

export default SizeStrategyDropdown;
