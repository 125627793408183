import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { gql } from '@apollo/client';

const buildCalculateScoresQuery = (input: Scorecards.ScorecardInput) => ({
  query: gql`
    query calculateScores(
      $metrics: [KPIInput]!
      $dateScope: DateRangeInput!
      $interval: Interval!
    ) {
      calculateScores(
        metrics: $metrics
        dateScope: $dateScope
        interval: $interval
      ) {
        id
        value
        trend {
          date
          value
        }
      }
    }
  `,
  variables: {
    metrics: input.metrics,
    dateScope: input.dateScope,
    interval: input.interval,
  },
});

const calculateScorecard = async ({
  scorecardInputArgs,
  client,
}: {
  scorecardInputArgs: Scorecards.ScorecardInput;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<Goals.MetricResult[]> => {
  const query = buildCalculateScoresQuery(scorecardInputArgs);
  const response = await client.query(query);
  return response.data.calculateScores;
};

export default calculateScorecard;
