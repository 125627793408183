import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import AccountPickerContext from 'contexts/AccountPickerContext';
import STORE from 'store';
import useGetHasEngagementPortalAccess from './useGetHasEngagementPortalAccess';

const useEngagementPortals = () => {
  const [allEngagementPortals, setAllEngagementPortals] = useState<
    EngagementPortal[]
  >([]);
  const [availableEngagementPortals, setAvailableEngagementPortals] = useState<
    EngagementPortal[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const getHasEngagementPortalAccess = useGetHasEngagementPortalAccess();

  useEffect(() => {
    let isActive = true;
    const listener = STORE.contentDistributions
      .getEngagementPortalsRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        if (isActive) {
          const newApps: EngagementPortal[] = [];
          snapshot.forEach((p) => {
            newApps.push(p.data());
          });
          const sortedApps = _.sortBy(
            newApps.filter((p) => !p.isHidden),
            'name',
          );
          setAllEngagementPortals(sortedApps);
          setAvailableEngagementPortals(
            sortedApps.filter(getHasEngagementPortalAccess),
          );
          setIsLoading(false);
        }
      });

    return () => {
      listener();
    };
  }, [getHasEngagementPortalAccess, selectedAccountId]);

  return {
    availableEngagementPortals,
    allEngagementPortals,
    isLoading,
  };
};

export default useEngagementPortals;
