import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';

import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import { SPECIAL_COMMITMENT_FIELDS } from '../../../../components/Grid/gridConstants';
import useFieldAggFuncDefinitions from '../../../../hooks/useFieldAggFuncDefinitions';
import ALL_SETS_ROUTES from '../../../../screens/DataManager/AllSets/routes';
import store from '../../../../store';
import injectAggFuncs from './injectAggFuncs';

const useDatasets = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isLoading: isLoadingAggFuncs, fieldAggFuncDefinitions } =
    useFieldAggFuncDefinitions();
  const [datasets, setDatasets] = useState<FleetOps.DatasetDefinition[]>([]);
  const [performanceDatasets, setPerformanceDatasets] = useState<
    FleetOps.DatasetDefinition[]
  >([]);
  const [isLiveUpdating, setIsLiveUpdating] = useState<boolean>(false);
  const location = useLocation();
  const handleFirestoreSnapshot = useCallback(
    (
      snapshot: firebase.firestore.QuerySnapshot<FleetOps.DatasetDefinition>,
    ) => {
      const sets = [] as FleetOps.DatasetDefinition[];
      snapshot.docs.forEach((d) => {
        const dataset = d.data();
        if (dataset.type === 'customerLaneCommitments') {
          dataset.fields = [
            ...dataset.fields.filter(
              (f) => !SPECIAL_COMMITMENT_FIELDS.includes(_.startCase(f.field)),
            ),
            ...SPECIAL_COMMITMENT_FIELDS.map((f) => ({
              field: f,
              type: 'text' as FleetOps.FieldType,
              aggFuncs: [],
            })),
          ];
        }

        sets.push(dataset);
      });

      setDatasets(injectAggFuncs(sets, fieldAggFuncDefinitions));
      setIsLoading(false);
    },
    [fieldAggFuncDefinitions],
  );

  // live update datasets
  useEffect(() => {
    if (!isLiveUpdating) {
      return;
    }

    const listener = store
      .getDatasetsRef({ accountId: selectedAccountId })
      .onSnapshot(handleFirestoreSnapshot);
    return () => {
      listener();
    };
  }, [selectedAccountId, handleFirestoreSnapshot, isLiveUpdating]);

  // update datasets (not live updating mode)
  const getDatasets = useCallback(async () => {
    if (isLoadingAggFuncs) {
      return;
    }

    if (!isLiveUpdating) {
      store
        .getDatasetsRef({ accountId: selectedAccountId })
        .get()
        .then(handleFirestoreSnapshot);
    }
  }, [
    handleFirestoreSnapshot,
    isLiveUpdating,
    isLoadingAggFuncs,
    selectedAccountId,
  ]);

  // Poll dataset definitions daily
  useEffect(() => {
    if (!isLiveUpdating) {
      getDatasets();
      const refreshInterval = setInterval(getDatasets, 1000 * 60 * 60 * 24);
      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [getDatasets, isLiveUpdating]);

  useEffect(() => {
    setPerformanceDatasets(datasets.filter((ds) => ds.isPerformance));
  }, [datasets]);

  // setIsLiveUpdating
  useEffect(() => {
    setIsLiveUpdating(location.pathname.includes(ALL_SETS_ROUTES.DATASETS));
  }, [location.pathname]);

  return {
    datasets,
    performanceDatasets,
    isLoading,
  };
};

export default useDatasets;
