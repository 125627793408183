import { useContext, useEffect } from 'react';
import { DatasetFreshnessContext } from '../contextProviders/SplashScreenProviders/DatasetProviders/DatasetFreshnessProvider';

const useRefreshDatasetFreshnessOnMount = () => {
  const { refresh } = useContext(DatasetFreshnessContext);

  useEffect(() => {
    refresh();
  }, [refresh]);
};

export default useRefreshDatasetFreshnessOnMount;
