import { useCallback, useContext, useEffect, useState } from 'react';
import FilterPlateFormContext from 'contexts/FilterPlateFormContext';
import toSentenceCase from '../../../services/toSentenceCase';
import useEditingDrillDown from './useEditingDrillDown';

type TextFilterMode =
  | 'is one of'
  | 'is not one of'
  | 'contains'
  | 'does not contain'
  | 'starts with'
  | 'does not start with'
  | 'ends with'
  | 'does not end with'
  | 'unknown';

export const getWildcardFilterMode = (drillDown: WildcardFilter) => {
  const { pattern, exclude } = drillDown.wildcardFilter;
  if (pattern.length === 0) {
    return undefined;
  }

  const isExcludes = exclude;
  const isContains =
    pattern.split('*').length === 1 || pattern.split('*').length === 3;

  const isEndsWith = pattern[0] === '*';
  const isStartsWith = pattern[pattern.length - 1] === '*';
  if (isContains) {
    return isExcludes ? 'does not contain' : 'contains';
  } else if (isStartsWith) {
    return isExcludes ? 'does not start with' : 'starts with';
  } else if (isEndsWith) {
    return isExcludes ? 'does not end with' : 'ends with';
  } else {
    return undefined;
  }
};

const useTextFilterMode = () => {
  const { editingTextDrillDown, editingWildcardDrillDown } =
    useEditingDrillDown();
  const { drillDown } = useContext(FilterPlateFormContext);
  const getTextFilterMode = useCallback((): TextFilterMode | undefined => {
    if (!drillDown) {
      return 'is one of';
    }

    if (editingTextDrillDown) {
      if (editingTextDrillDown.isExclude) {
        return 'is not one of';
      }
      return 'is one of';
    } else if (editingWildcardDrillDown) {
      return getWildcardFilterMode(editingWildcardDrillDown);
    }

    return undefined;
  }, [drillDown, editingTextDrillDown, editingWildcardDrillDown]);
  const [mode, setMode] = useState<TextFilterMode | undefined>(() =>
    getTextFilterMode(),
  );
  useEffect(() => {
    setMode(getTextFilterMode());
  }, [getTextFilterMode]);

  const getModeOptions = useCallback((): DropdownOption[] => {
    const modes: TextFilterMode[] = [
      'is one of',
      'is not one of',
      'contains',
      'does not contain',
      'starts with',
      'does not start with',
      'ends with',
      'does not end with',
    ];
    return modes.map((m) => ({
      key: m,
      label: toSentenceCase(m, true),
      isSelected: m === mode,
      onSelected: () => {
        setMode(m);
      },
    }));
  }, [mode]);
  const [modeOptions, setModeOptions] = useState<DropdownOption[]>(() =>
    getModeOptions(),
  );
  useEffect(() => {
    setModeOptions(getModeOptions());
  }, [getModeOptions]);

  return { mode, modeOptions };
};

export default useTextFilterMode;
