import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import { Label } from 'components/MyAccount/Profile/styles';

import Row from '../Common/Row';
import Colors from '../../theme/colors';
import UserIcon from '../Common/UserIcon';
import getUserDisplayName from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/getUserDisplayName';
import Icon from '../../kingpin/atoms/Icon';
import TextInput from '../../kingpin/atoms/TextInput';

export const Scrollable = styled.div<{ isLarge?: boolean }>`
  max-height: ${(props) => (props.isLarge ? '400px' : '200px')};
  overflow-y: scroll;
`;

export const ListItem = styled(Row)`
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.BLUE_GREY};
  }
`;

const UserPicker = ({
  userOptions,
  searchText,
  onSearchTextChange,
  label,
  isLarge,
  placeholder,
}: {
  userOptions: {
    user:
      | UserManagement.PendingUser
      | UserManagement.FleetOpsStaffUser
      | UserManagement.SignedUpUser;
    isSelected: boolean;
    onSelected: () => void;
  }[];
  searchText: string;
  onSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  isLarge?: boolean;
  placeholder?: string;
}) => (
  <div>
    {!!label && (
      <Label>
        <Typography.Body type={'Label'}>{label}</Typography.Body>
      </Label>
    )}
    <div style={{ marginBottom: 8 }}>
      <TextInput
        icon="search"
        inputSize="Small"
        value={searchText}
        onChange={onSearchTextChange}
        placeholder={placeholder}
      />
    </div>

    <Scrollable isLarge={isLarge}>
      {userOptions.map((option) => (
        <ListItem key={option.user.id} onClick={option.onSelected}>
          <Row centerAlign>
            <UserIcon {...option.user} />
            <Typography.Body type={'Body 12'}>
              {getUserDisplayName(option.user)}
            </Typography.Body>
          </Row>
          {option.isSelected && <Icon icon={'checkmark'} />}
        </ListItem>
      ))}
    </Scrollable>
  </div>
);

export default UserPicker;
