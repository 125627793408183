import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import aggregateMetricMatrix from 'api/aggregateMetricMatrix';

const runAggregation = ({
  client,
  metrics,
  expressions,
  filterInput,
  dateScope,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  metrics: (MetricInput | Metrics.SpecialMetric)[];
  expressions: Expression2[];
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
}) => {
  return aggregateMetricMatrix({
    client,
    metrics,
    expressions,
    filterInput,
    dateScope,
  });
};

export default runAggregation;
