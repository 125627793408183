import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { buildV5GadgetShow } from '../../../../../navigation/appRoutes';
import Card from '../../../../../components/Common/Card';
import { PortalsContext } from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const ChartDefinitionUsage = ({
  chartDefinitions,
  usedId,
}: {
  chartDefinitions: VisualisationDefinition[];
  usedId: string;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  return (
    <>
      {chartDefinitions.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: '8px' }}>
            <Typography.Header type="H5">
              {`Chart Definitions (${chartDefinitions.length})`}
            </Typography.Header>
          </div>

          <Card>
            {chartDefinitions.map((cd, index) => (
              <ListItemWrapper
                key={`${cd.id}-${usedId}`}
                isLast={chartDefinitions.length - 1 === index}
              >
                <Column
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Link
                    to={buildV5GadgetShow(cd.id, selectedPortal)}
                    target="_blank"
                    style={{ display: 'flex' }}
                  >
                    <Typography.Body type="Body 12" color="#0041ea">
                      {cd.name}
                    </Typography.Body>
                  </Link>
                </Column>
              </ListItemWrapper>
            ))}
          </Card>
        </div>
      )}
    </>
  );
};

export default ChartDefinitionUsage;
