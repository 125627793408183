import { useContext, useEffect, useState } from 'react';
import STORE from '../../../../store';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import { CURRENT_ENTITY_DEFINITIONS_VERSION } from '../../../../store/getEntityDefinitionsRef';
import useHasAccess from '../../../../hooks/useHasAccess';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

const useEntityDefinitions = () => {
  const hasAccess = useHasAccess();
  const { isWallboardUser } = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [entityDefinitions, setEntityDefinitions] = useState<
    EntityDetails.Entity[]
  >([]);

  useEffect(() => {
    if (isWallboardUser) {
      return;
    }
    let isActive = true;
    setIsLoading(true);

    STORE.getEntityDefinitionsRef({
      accountId: selectedAccountId,
    })
      .where('version', '==', CURRENT_ENTITY_DEFINITIONS_VERSION)
      .onSnapshot((snapshot) => {
        if (!isActive) {
          return;
        }
        const newDefs = snapshot.docs.map((d) => d.data());
        setEntityDefinitions(
          newDefs
            .filter((e) => !e.isHidden)
            .filter((ed) =>
              hasAccess({
                access: ed.access,
                resource: ed,
                type: 'entity',
                typeId: ed.id,
              }),
            ),
        );
        setIsLoading(false);
      });

    return () => {
      isActive = false;
    };
  }, [hasAccess, isWallboardUser, selectedAccountId]);

  return {
    isLoading,
    entityDefinitions,
  };
};

export default useEntityDefinitions;
