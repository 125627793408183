import React, { useEffect, useState } from 'react';
import ReportDateDrillDownOptionsContext from 'contexts/ReportDateDrillDownOptionsContext';
import moment from 'moment';
import { getBase } from '../relativeDateRangeToDateRange';
import formatDateLabel from '../components/V5Gadget/formatDateLabel';
import { DateTime } from 'luxon';
import useDateScope from '../hooks/useDateScope';

const buildDateOption = ({
  periodStartDate,
  visEndDate,
  cadence,
  n,
}: {
  periodStartDate: string; // we expect this to be aligned with the cadence
  visEndDate: string;
  cadence: 'day' | 'week' | 'month' | 'quarter' | 'year';
  n: number;
}): {
  label: string;
  value: {
    startDate: string;
    endDate: string;
  };
} => {
  const getBase = () => DateTime.fromISO(periodStartDate);
  const startDateTime = getBase().plus({ [cadence]: n });
  const getEndDateTime = () => {
    const baseEndDate = (() => {
      if (cadence === 'day') {
        return getBase().plus({ [cadence]: n + 1 });
      }
      return getBase()
        .plus({ [cadence]: n + 1 })
        .minus({ day: 1 });
    })();

    if (baseEndDate.toISODate() > visEndDate) {
      return {
        endDate: visEndDate,
        isPartial: true,
      };
    }
    return {
      endDate: baseEndDate.toISODate(),
      isPartial: false,
    };
  };

  const startDate = startDateTime.toISODate();
  const { endDate, isPartial } = getEndDateTime();
  const baseLabel = formatDateLabel(startDate, cadence, true);

  const label = isPartial ? `${baseLabel} (To Date)` : baseLabel;
  return {
    value: {
      startDate,
      endDate,
    },
    label,
  };
};

const getNumIntervals = ({
  startDate,
  endDate,
  cadence,
}: {
  startDate: string;
  endDate: string;
  cadence: 'day' | 'week' | 'month' | 'quarter' | 'year';
}) => {
  const firstIntervalStartMoment = moment.utc(startDate).startOf(cadence);
  const lastIntervalEndMoment = moment
    .utc(endDate)
    .endOf(cadence)
    .add({ day: cadence === 'quarter' ? 1 : 0 });

  return Math.ceil(
    lastIntervalEndMoment.diff(firstIntervalStartMoment, cadence, true),
  );
};

const getOptions = (
  dateRange: DateRangeInput,
  unit: 'day' | 'week' | 'month' | 'quarter' | 'year',
  startOfWeek: WeekStartsOn,
): DateOption[] => {
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;
  if (!startDate || !endDate || startDate > endDate) {
    return [];
  }

  // Start startDate is not guaranteed to be aligned with the cadence
  const periodStartDate = getBase({
    unit,
    comparedTo: startDate,
    startOfWeek,
  }).toISODate();

  const numIntervals = getNumIntervals({
    startDate: periodStartDate,
    endDate,
    cadence: unit,
  });

  const options: DateOption[] = [];
  for (let i = 0; i < numIntervals; i++) {
    const newOption = buildDateOption({
      periodStartDate,
      visEndDate: endDate,
      cadence: unit,
      n: i,
    });
    options.push(newOption);
  }

  return options;
};

const ReportDateDrillDownOptionsProvider = ({
  children,
  startOfWeek,
}: {
  children: JSX.Element | JSX.Element[];
  startOfWeek: WeekStartsOn;
}) => {
  const dateScope = useDateScope({});

  const [dayOptions, setDayOptions] = useState<DateOption[]>([]);
  const [weekOptions, setWeekOptions] = useState<DateOption[]>([]);
  const [monthOptions, setMonthOptions] = useState<DateOption[]>([]);
  const [quarterOptions, setQuarterOptions] = useState<DateOption[]>([]);
  const [yearOptions, setYearOptions] = useState<DateOption[]>([]);

  useEffect(() => {
    if (!dateScope || !dateScope.startDate) {
      return;
    }

    setDayOptions(getOptions(dateScope, 'day', startOfWeek));
    setWeekOptions(getOptions(dateScope, 'week', startOfWeek));
    setMonthOptions(getOptions(dateScope, 'month', startOfWeek));
    setQuarterOptions(getOptions(dateScope, 'quarter', startOfWeek));
    setYearOptions(getOptions(dateScope, 'year', startOfWeek));
  }, [dateScope, startOfWeek]);

  return (
    <ReportDateDrillDownOptionsContext.Provider
      value={{
        dayOptions,
        weekOptions,
        monthOptions,
        quarterOptions,
        yearOptions,
      }}
    >
      {children}
    </ReportDateDrillDownOptionsContext.Provider>
  );
};

export default ReportDateDrillDownOptionsProvider;
