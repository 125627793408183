import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Grid from './Grid';
import {
  GetContextMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  RowClickedEvent,
} from 'ag-grid-community';
import buildGridOptions from './buildGridOptions';

import getGridTotalsRow from '../../api/getGridTotalsRow';
import GridContext from '../../contexts/GridContext';
import useFilterInput from '../../hooks/useFilterInput';
import NoDataToDisplay from '../V5Gadget/NoDataToDisplay';
import usePopup from '../../hooks/usePopup';
import DetailsSlideOutContext from '../../contexts/DetailsSlideOutContext';
import FilterPlatesContext from '../../contexts/FilterPlatesContext';
import CustomerLaneSlideOutContext from '../../contexts/CustomerLaneSlideOut';
import CommitmentQueryContext from '../../contexts/CommitmentQueryContext';
import useDatasource from '../../hooks/grids/useDatasource';
import ServerSideDataSource from './ServerSideDatasource';
import GridDataSourceContext from '../../contexts/GridDataSourceContext';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import useColumnDefs from '../../hooks/useColumnDefs';
import { TOTALS_QUERY } from './gridConstants';
import getBaseViewFields from './getBaseViewFields';
import GqlClientContext from '../../contexts/GqlClientContext';
import useDateScope from '../../hooks/useDateScope';
import CardContext from '../../contexts/CardContext';
import { DRIVER_BONUS_DATA_SETS } from '../../constants';
import useNetworkingEffect from '../../hooks/useNetworkingEffect';
import { RowHeightContext } from '../../contextProviders/SplashScreenProviders/MiscProviders/RowHeightProvider';
import { DefaultDatasetFiltersContext } from '../../contextProviders/SplashScreenProviders/DatasetProviders/DefaultDatasetFiltersProvider';

const GridContainerGate = ({
  bottomBar,
  isBoard,
  onRowClicked,
  getContextMenuItems,
  hideTotalsRow,
  disableAutoLayout,
  layoutOnColumnChange,
  layoutOnFirstRender,
  layoutOnModelUpdated,
  autoSizeFirstColumnOnly,
  autoSizeSecondColumnOnly,
  hasBorders,
  hasDarkHeader,
  isSearchScreen,
}: {
  bottomBar?: JSX.Element | JSX.Element[];
  isBoard?: boolean;
  onRowClicked?: (event: RowClickedEvent) => void;
  getContextMenuItems?: (params: GetContextMenuItemsParams) => {
    name: string;
    action: () => void;
  }[];
  isPopupGrid?: boolean;
  hideTotalsRow?: boolean;
  disableAutoLayout?: boolean;
  layoutOnModelUpdated?: boolean;
  layoutOnFirstRender?: boolean;
  layoutOnColumnChange?: boolean;
  autoSizeFirstColumnOnly?: boolean;
  autoSizeSecondColumnOnly?: boolean;
  hasBorders: boolean;
  hasDarkHeader: boolean;
  isSearchScreen?: boolean;
}) => {
  const { baseViews } = useContext(BaseViewsContext);
  const {
    dataType,
    gridQueryOverride,
    gridTotalsQueryOverride,
    gridCountQueryOverride,
    baseViewOverride,
    sortOverride,
    gridId,
  } = useContext(GridContext);

  const baseView = baseViewOverride ? baseViewOverride : baseViews[dataType];

  if (baseView === undefined) {
    return <NoDataToDisplay size={'normal'} />;
  }

  return (
    <GridContainer
      key={gridId}
      baseView={baseView}
      bottomBar={bottomBar}
      gridQueryOverride={gridQueryOverride}
      gridCountQueryOverride={gridCountQueryOverride}
      gridTotalsQueryOverride={gridTotalsQueryOverride}
      isBoard={isBoard}
      sort={sortOverride}
      onRowClicked={onRowClicked}
      getContextMenuItems={getContextMenuItems}
      hideTotalsRow={hideTotalsRow}
      disableAutoLayout={disableAutoLayout}
      layoutOnModelUpdated={layoutOnModelUpdated}
      layoutOnFirstRender={layoutOnFirstRender}
      layoutOnColumnChange={layoutOnColumnChange}
      autoSizeFirstColumnOnly={autoSizeFirstColumnOnly}
      autoSizeSecondColumnOnly={autoSizeSecondColumnOnly}
      hasBorders={hasBorders}
      hasDarkHeader={hasDarkHeader}
      isSearchScreen={isSearchScreen}
    />
  );
};

const useShouldUseNewDataSource = (
  gridQueryOverride?: string,
  currentGroup?: string,
) => {
  const getShouldUseNewDataSource = useCallback(() => {
    const isFetchRecords = !gridQueryOverride;
    return isFetchRecords && !currentGroup;
  }, [currentGroup, gridQueryOverride]);
  const [shouldUseNewDataSource, setShouldUseNewDataSource] = useState<boolean>(
    getShouldUseNewDataSource(),
  );
  useEffect(() => {
    setShouldUseNewDataSource(getShouldUseNewDataSource());
  }, [getShouldUseNewDataSource]);

  return shouldUseNewDataSource;
};

export const useGridDataSource = ({
  gridQueryOverride,
  gridCountQueryOverride,
  baseView,
  sort,
}: {
  gridQueryOverride?: string;
  gridCountQueryOverride?: string;
  baseView: FleetOps.BaseView;
  sort?: SimpleGridSort[];
}) => {
  const { chartDefinition } = useContext(CardContext);
  const { dataType } = useContext(GridContext);
  const { defaultDatasetFiltersLookup } = useContext(
    DefaultDatasetFiltersContext,
  );
  const { quickFilters, isDefaultFiltersDisabled } =
    useContext(FilterPlatesContext);
  const { client } = useContext(GqlClientContext);
  const getSearchFields = useCallback(() => {
    if (!chartDefinition) {
      return undefined;
    }

    const newFields = Object.keys(baseView.fields);
    if (DRIVER_BONUS_DATA_SETS.includes(baseView.type)) {
      // We don't know what the full set of fields we require
      // to driver the StatusFlagCall is as fields will be defined
      // in the response expectations field.
      // For these grids, just get all fields.
      return undefined;
    }

    return newFields;
  }, [baseView.fields, baseView.type, chartDefinition]);

  const [includeFields, setIncludeFields] = useState<string[] | undefined>(() =>
    getSearchFields(),
  );

  useEffect(() => {
    setIncludeFields(getSearchFields());
  }, [getSearchFields]);

  const shouldUseNewDataSource = useShouldUseNewDataSource(gridQueryOverride);
  const baseFilterInput = useFilterInput();
  const dateScope = useDateScope({});
  const filterInput = useMemo((): FilterInput[] => {
    const result: FilterInput[] = [baseFilterInput, ...quickFilters];
    if (!isDefaultFiltersDisabled) {
      const f = defaultDatasetFiltersLookup[baseView.type];
      if (f) {
        result.push(f.filterInput);
      }
    }

    return result;
  }, [
    baseView.type,
    defaultDatasetFiltersLookup,
    baseFilterInput,
    isDefaultFiltersDisabled,
    quickFilters,
  ]);

  const { dataSource: paginationDataSource } = useDatasource(
    includeFields,
    dataType,
    filterInput,
  );
  const [dataSource, setDataSource] = useState<
    IServerSideDatasource | DataSource
  >();

  const getDataSource = useCallback(() => {
    if (shouldUseNewDataSource) {
      return paginationDataSource;
    } else {
      return ServerSideDataSource({
        client,
        filterInput,
        dateScope,
        baseView,
        gridQueryOverride,
        gridCountQueryOverride,
        sort,
      });
    }
  }, [
    baseView,
    client,
    dateScope,
    filterInput,
    gridCountQueryOverride,
    gridQueryOverride,
    paginationDataSource,
    shouldUseNewDataSource,
    sort,
  ]);

  useEffect(() => {
    setDataSource(getDataSource());
  }, [getDataSource]);

  return dataSource;
};

const GridContainer = ({
  bottomBar,
  baseView,
  gridQueryOverride,
  gridCountQueryOverride,
  gridTotalsQueryOverride,
  isBoard,
  sort,
  onRowClicked,
  getContextMenuItems,
  hideTotalsRow,
  disableAutoLayout,
  layoutOnColumnChange,
  layoutOnFirstRender,
  layoutOnModelUpdated,
  autoSizeFirstColumnOnly,
  autoSizeSecondColumnOnly,
  hasBorders,
  hasDarkHeader,
  isSearchScreen,
}: {
  bottomBar?: JSX.Element | JSX.Element[];
  baseView: FleetOps.BaseView;
  gridQueryOverride?: string;
  gridCountQueryOverride?: string;
  gridTotalsQueryOverride?: string;
  isBoard?: boolean;
  sort?: SimpleGridSort[];
  onRowClicked?: (event: RowClickedEvent) => void;
  getContextMenuItems?: (params: GetContextMenuItemsParams) => {
    name: string;
    action: () => void;
  }[];
  hideTotalsRow?: boolean;
  disableAutoLayout?: boolean;
  layoutOnModelUpdated?: boolean;
  layoutOnFirstRender?: boolean;
  layoutOnColumnChange?: boolean;
  autoSizeFirstColumnOnly?: boolean;
  autoSizeSecondColumnOnly?: boolean;
  hasBorders: boolean;
  hasDarkHeader: boolean;
  isSearchScreen?: boolean;
}) => {
  // Context
  const { rowHeight } = useContext(RowHeightContext);
  const { quickFilters } = useContext(FilterPlatesContext);
  const { isCustomerLaneSlideOpen } = useContext(CustomerLaneSlideOutContext);
  const { client } = useContext(GqlClientContext);
  const { isSlideOutOpen: isNewCommitmentSlideOpen } = useContext(
    CommitmentQueryContext,
  );
  const { gridId, dataType, setGridApi, setColumnApi, gridApi } =
    useContext(GridContext);

  // State
  const [totalsRow, setTotalsRow] = useState<any>();
  const [selectedOrderNumber, setSelectedOrderNumber] = useState<
    string | undefined
  >();
  const [gridOptions, setGridOptions] = useState<GridOptions>();

  // Hooks
  const columnDefs = useColumnDefs(
    baseView,
    undefined,
    sort,
    disableAutoLayout || autoSizeFirstColumnOnly || autoSizeSecondColumnOnly,
  );
  const filterInput = useFilterInput();
  const dateScope = useDateScope({});
  const {
    isOpen: isDetailsSlideOpen,
    open: openDetailsSlide,
    close: closeDetailsSlide,
  } = usePopup();

  // DATA SOURCE
  const dataSource = useGridDataSource({
    baseView,
    gridQueryOverride,
    gridCountQueryOverride,
    sort,
  });

  useEffect(() => {
    if (!dataSource) {
      return;
    }

    const newGridOptions = buildGridOptions({
      columnDefs,
      rowModelType: 'serverSide',
      suppressColumnVirtualisation: true,
      rowHeight,
    });

    setGridOptions({
      ...newGridOptions,
      popupParent: document.querySelector('body'),
      getContextMenuItems,
      serverSideDatasource: dataSource,
    });
  }, [columnDefs, dataSource, getContextMenuItems, rowHeight]);

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api);
      setColumnApi(event.columnApi);
    },
    [setColumnApi, setGridApi],
  );

  useEffect(() => {
    if (gridApi && dataSource) {
      gridApi.setGridOption('serverSideDatasource', dataSource);
    }
  }, [dataSource, gridApi]);

  useNetworkingEffect(() => {
    if (hideTotalsRow) {
      return;
    }
    const fields = getBaseViewFields(baseView)
      .filter((c) => c.aggFunc)
      .map((c) => ({
        field: c.field,
        aggFunc: c.aggFunc,
      })) as GridAggregateField[];
    let isActive = true;

    getGridTotalsRow({
      query: gridTotalsQueryOverride ? gridTotalsQueryOverride : TOTALS_QUERY,
      dateScope,
      dataType,
      filters: [filterInput, ...quickFilters],
      fields,
      client,
    }).then((totals: any) => {
      if (!isActive) {
        return;
      }
      const row = totals[0];
      if (!row) {
        setTotalsRow(undefined);
        return;
      }

      const fields = Object.keys(row) as string[];
      fields.forEach((f) => {
        const parts = f.split('.');
        if (parts.length === 2) {
          if (!row[parts[0]]) {
            row[parts[0]] = {};
          }
          row[parts[0]][parts[1]] = row[f];
        }
      });

      setTotalsRow([row]);
    });
    return () => {
      isActive = false;
    };
  }, [
    baseView,
    client,
    dataType,
    dateScope,
    filterInput,
    gridTotalsQueryOverride,
    hideTotalsRow,
    quickFilters,
  ]);

  if (!gridOptions) {
    return null;
  }

  return (
    <div style={{ height: '100%' }}>
      <DetailsSlideOutContext.Provider
        value={{
          selectedOrderNumber,
          setSelectedOrderNumber,
          isDetailsSlideOpen,
          openDetailsSlide,
          closeDetailsSlide,
        }}
      >
        <GridDataSourceContext.Provider value={{ dataSource }}>
          <Grid
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            totalsRow={totalsRow}
            gridId={gridId}
            bottomBar={bottomBar}
            isDetailsSlideOpen={isDetailsSlideOpen}
            isCustomerLaneSlideOpen={isCustomerLaneSlideOpen}
            isNewCommitmentSlideOpen={isNewCommitmentSlideOpen}
            isBoard={isBoard}
            onRowClicked={onRowClicked}
            disableAutoLayout={disableAutoLayout}
            layoutOnColumnChange={layoutOnColumnChange}
            layoutOnFirstRender={layoutOnFirstRender}
            layoutOnModelUpdated={layoutOnModelUpdated}
            autoSizeFirstColumnOnly={autoSizeFirstColumnOnly}
            autoSizeSecondColumnOnly={autoSizeSecondColumnOnly}
            hasBorders={hasBorders}
            hasDarkHeader={hasDarkHeader}
            isSearchScreen={isSearchScreen}
          />
        </GridDataSourceContext.Provider>
      </DetailsSlideOutContext.Provider>
    </div>
  );
};

export default GridContainerGate;
