import { useContext } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import fiToDrillDowns from 'screens/DataManager/Metrics/MetricPopup/MetricForm/NormalMetricForm/fiToDrillDowns';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';
import DateInputContext from 'contexts/DateInputContext';
import usePopupCallback from 'hooks/usePopupCallback';
import isV5ChartDef from 'types/visTypeCheckers/isV5ChartDef';
import isSingleMetricDateMatrix from 'types/visTypeCheckers/isSingleMetricDateMatrix';
import isRankingMatrix from 'types/visTypeCheckers/isRankingMatrix';
import { getEndOf } from 'removePartialIntervals';
import buildDrillDown from 'buildDrillDown';
import { toFilterPlate } from 'migrateScope';

import toAutoInterval, { toInterval } from '../../toAutoInterval';
import isPaceMatrix from '../../../../types/visTypeCheckers/isPaceMatrix';
import filterTypeCheckers from '../../../FilterPlateForm/filterTypeCheckers';
import useToFixedFilters from 'hooks/useToFixedFilters';

// I'm making this change under time pressure.
// I suspect there is a better way to go about this.
const isFilterEqual = (a: FixedFilter, b: FixedFilter) => {
  if (a.field !== b.field) {
    return false;
  }

  if (
    filterTypeCheckers.isTextFilter(a) &&
    filterTypeCheckers.isTextFilter(b)
  ) {
    return _.isEqual(a.keywordValues, b.keywordValues);
  }

  if (
    filterTypeCheckers.isBooleanFilter(a) &&
    filterTypeCheckers.isBooleanFilter(b)
  ) {
    return a.booleanValue === b.booleanValue;
  }

  if (
    filterTypeCheckers.isNumberFilter(a) &&
    filterTypeCheckers.isNumberFilter(b)
  ) {
    return _.isEqual(a.rangeValue, b.rangeValue);
  }

  if (
    filterTypeCheckers.isWildcardFilter(a) &&
    filterTypeCheckers.isWildcardFilter(b)
  ) {
    return _.isEqual(a.wildcardFilter, b.wildcardFilter);
  }

  if ('exists' in a && 'exists' in b) {
    return a.exists === b.exists;
  }

  return false;
};

const usePopup = ({
  dashboardGadget,
  filterInput,
  rawDate,
  term,
  variableFilters,
  dateField,
  dateScope,
  autoIntervalResponse,
}: {
  dashboardGadget?: DashboardGadget;
  filterInput?: FilterInput;
  rawDate?: string;
  term: string;
  variableFilters: VariableDrillDownType[];
  dateField?: string;
  dateScope: DateRangeInput;
  autoIntervalResponse?: string;
}) => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const { dateRange, relativeDateRange, advancedRelativeDateRange } =
    useContext(DateInputContext);
  const toDrillDowns = useToFixedFilters();
  const isHistogram =
    rawDate &&
    dashboardGadget &&
    isV5ChartDef(dashboardGadget.chartDef) &&
    dashboardGadget.chartDef &&
    dashboardGadget.chartDef.trendByCalendarInterval &&
    dashboardGadget.chartDef.gadgetType !== 'matrix';

  const drillDownDateScope = (() => {
    if (
      isHistogram &&
      dashboardGadget &&
      isV5ChartDef(dashboardGadget.chartDef) &&
      dashboardGadget.chartDef &&
      dashboardGadget.chartDef.trendByCalendarInterval &&
      rawDate
    ) {
      const interval = dashboardGadget.chartDef
        .trendByCalendarInterval as FleetOps.Interval;
      if (interval === 'auto') {
        return undefined;
      }
      const date = DateTime.fromISO(rawDate);
      return {
        startDate: rawDate,
        endDate:
          interval === 'day'
            ? rawDate
            : DateTime.fromMillis(getEndOf(date.toMillis(), interval))
                .minus({ day: 1 })
                .toISODate(),
      } as DateRangeInput;
    } else {
      return dateScope;
    }
  })();

  const drillDownFromInteraction = (() => {
    if (
      term &&
      dashboardGadget &&
      isV5ChartDef(dashboardGadget.chartDef) &&
      dashboardGadget.chartDef.dimensionA &&
      dashboardGadget.chartDef.dimensionA.fieldType &&
      (dashboardGadget.chartDef.dimensionA.fieldType === 'text' ||
        dashboardGadget.chartDef.dimensionA.fieldType === 'date')
    ) {
      const drillField = (() => {
        if (dashboardGadget.chartDef.dimensionA) {
          if (dashboardGadget.chartDef.dimensionA.field === 'date') {
            if (
              dashboardGadget.chartDef.trendByCalendarInterval === 'auto' &&
              autoIntervalResponse
            ) {
              return toInterval(autoIntervalResponse) || 'day';
            }
            return dashboardGadget.chartDef.trendByCalendarInterval || 'day';
          }
          return dashboardGadget.chartDef.dimensionA.field;
        }
        return '';
      })();

      if (rawDate && dashboardGadget.chartDef.dimensionA.fieldType === 'date') {
        return buildDrillDown(
          drillField,
          dashboardGadget.chartDef.dimensionA.fieldType === 'date'
            ? 'dateText'
            : dashboardGadget.chartDef.dimensionA.fieldType,
          rawDate,
          dashboardGadget.chartDef,
          dateScope,
          autoIntervalResponse
            ? toAutoInterval(autoIntervalResponse)
            : undefined,
        );
      } else {
        return buildDrillDown(
          drillField,
          dashboardGadget.chartDef.dimensionA.fieldType,
          term,
          dashboardGadget.chartDef,
          dateScope,
        );
      }
    }

    if (
      term &&
      dashboardGadget &&
      (isSingleMetricDateMatrix(dashboardGadget.chartDef) ||
        isRankingMatrix(dashboardGadget.chartDef) ||
        isPaceMatrix(dashboardGadget.chartDef))
    ) {
      return buildDrillDown(
        dashboardGadget.chartDef.groupByField,
        'text',
        term,
        dashboardGadget.chartDef,
        dateScope,
      );
    }
  })();

  const unsavedFilter = (() => {
    if (dashboardGadget) {
      if (filterInput) {
        const baseDrillDowns = fiToDrillDowns(filterInput, datasets);
        if (drillDownFromInteraction) {
          baseDrillDowns.push(drillDownFromInteraction);
        }
        // remove drillDowns derived from scope:
        const finalDrillDowns = baseDrillDowns.filter((dd) => {
          const matchingScope = dashboardGadget.scope.find((sd) => {
            const netFilters = toDrillDowns({
              plates: [sd],
              variableDrillDowns: variableFilters,
            });
            if (netFilters.length > 0) {
              const netFilter = netFilters[0];
              return isFilterEqual(netFilter, dd);
            }
            return false;
          });

          return !matchingScope;
        });

        return {
          comparison: dashboardGadget.comparison,
          drillDowns: finalDrillDowns.map(toFilterPlate),
          dateField,
          dateScope: drillDownDateScope,
          dateRange: isHistogram ? undefined : dateRange,
          relativeDateRange: isHistogram ? undefined : relativeDateRange,
          advancedRelativeDateRange: isHistogram
            ? undefined
            : advancedRelativeDateRange,
          scope: toDrillDowns({
            plates: dashboardGadget.scope,
            variableDrillDowns: variableFilters,
          }).map(toFilterPlate),
        } as UnSavedFilter;
      } else {
        const baseDrillDowns = [...dashboardGadget.drillDowns];
        if (drillDownFromInteraction) {
          baseDrillDowns.push(toFilterPlate(drillDownFromInteraction));
        }

        // remove drillDowns derived from scope:
        const finalDrillDowns = baseDrillDowns.filter((dd) => {
          const matchingScope = dashboardGadget.scope.find((sd) => {
            const netSd = (() => {
              const netFilters = toDrillDowns({
                plates: [sd],
                variableDrillDowns: variableFilters,
              });
              if (netFilters.length > 0) {
                const netFilter = netFilters[0];
                return netFilter;
              }
              return undefined;
            })();

            const netDd = (() => {
              const netFilters = toDrillDowns({
                plates: [dd],
                variableDrillDowns: variableFilters,
              });
              if (netFilters.length > 0) {
                const netFilter = netFilters[0];
                return netFilter;
              }
              return undefined;
            })();

            return !!netDd && !!netSd && isFilterEqual(netDd, netSd);
          });

          return !matchingScope;
        });

        return {
          comparison: dashboardGadget.comparison,
          drillDowns: toDrillDowns({
            plates: finalDrillDowns,
            variableDrillDowns: variableFilters,
          }).map(toFilterPlate),
          dateField,
          dateScope: drillDownDateScope,
          dateRange: isHistogram ? undefined : dateRange,
          relativeDateRange: isHistogram ? undefined : relativeDateRange,
          advancedRelativeDateRange: isHistogram
            ? undefined
            : advancedRelativeDateRange,
          scope: toDrillDowns({
            plates: dashboardGadget.scope,
            variableDrillDowns: variableFilters,
          }).map(toFilterPlate),
        } as UnSavedFilter;
      }
    } else {
      return undefined;
    }
  })();

  const gadgetWithDateField = (() => {
    if (dashboardGadget && dateField) {
      return {
        ...dashboardGadget,
        dateField,
      };
    }
    return dashboardGadget;
  })();

  const callback = usePopupCallback(
    gadgetWithDateField,
    unsavedFilter,
    undefined,
    undefined,
    undefined,
    undefined,
    term,
    drillDownFromInteraction,
  );
  return callback;
};

export default usePopup;
