import React, { useCallback } from 'react';
import ColumnSizeSettingsStyles from './styles';
import SpecificFieldSettings from './SpecificFieldSettings';

const FitGridWidth = ({
  columnSizeStrategy,
  setColumnSizeStrategy,
}: {
  columnSizeStrategy: PerformanceBoardTypes.ColumnSizeStrategies.FitGridWidth;
  setColumnSizeStrategy: React.Dispatch<
    React.SetStateAction<
      PerformanceBoardTypes.ColumnSizeStrategies.Strategy | undefined
    >
  >;
}) => {
  const isMinWidthEnabled = columnSizeStrategy.defaultMinWidth !== undefined;
  const isMaxWidthEnabled = columnSizeStrategy.defaultMaxWidth !== undefined;

  const onMinWidthToggled = useCallback(() => {
    setColumnSizeStrategy({
      ...columnSizeStrategy,
      defaultMinWidth: isMinWidthEnabled ? undefined : 80,
    });
  }, [columnSizeStrategy, isMinWidthEnabled, setColumnSizeStrategy]);
  const onMaxWidthToggled = useCallback(() => {
    setColumnSizeStrategy({
      ...columnSizeStrategy,
      defaultMaxWidth: isMaxWidthEnabled ? undefined : 80,
    });
  }, [columnSizeStrategy, isMaxWidthEnabled, setColumnSizeStrategy]);

  const onMinWidthChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setColumnSizeStrategy({
        ...columnSizeStrategy,
        defaultMinWidth: Number.parseInt(event.target.value),
      });
    },
    [columnSizeStrategy, setColumnSizeStrategy],
  );

  const onMaxWidthChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setColumnSizeStrategy({
        ...columnSizeStrategy,
        defaultMaxWidth: Number.parseInt(event.target.value),
      });
    },
    [columnSizeStrategy, setColumnSizeStrategy],
  );

  return (
    <>
      <ColumnSizeSettingsStyles.Section>
        <ColumnSizeSettingsStyles.OptionalNumberInput
          label={'Default Min Width'}
          isEnabled={isMinWidthEnabled}
          onToggled={onMinWidthToggled}
          value={columnSizeStrategy.defaultMinWidth}
          onChange={onMinWidthChanged}
          testIdPrefix={'default-min'}
        />
      </ColumnSizeSettingsStyles.Section>
      <ColumnSizeSettingsStyles.Section>
        <ColumnSizeSettingsStyles.OptionalNumberInput
          label={'Default Max Width'}
          isEnabled={isMaxWidthEnabled}
          onToggled={onMaxWidthToggled}
          value={columnSizeStrategy.defaultMaxWidth}
          onChange={onMaxWidthChanged}
          testIdPrefix={'default-max'}
        />
      </ColumnSizeSettingsStyles.Section>
      <ColumnSizeSettingsStyles.Section>
        <SpecificFieldSettings
          columnSizeStrategy={columnSizeStrategy}
          setColumnSizeStrategy={setColumnSizeStrategy}
        />
      </ColumnSizeSettingsStyles.Section>
    </>
  );
};

export default FitGridWidth;
