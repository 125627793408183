import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import ChartSvg from '../../icons/Chart.svg';
import MetricsSvg from '../../icons/Metrics.svg';
import SimpleGridSvg from '../../icons/SimpleGrid.svg';
import PropertiesSvg from '../../icons/Properties.svg';
import CommentsSvg from '../../icons/Comments.svg';

import PerformanceBoardSlideOutContext from 'components/PerformanceBoards/contexts/PerformanceBoardSlideOutContext';
import useCreateCommentsSection from 'components/PerformanceBoards/hooks/useCreateCommentsSection';
import AnalyticsContext from 'contexts/AnalyticsContext';
import WidgetItem from './WidgetItem';

const Wrapper = styled.div`
  padding: 16px 54px;

  display: flex;
  flex-direction: column;
`;

const EmptySlideout = () => {
  const { setNavState, performanceFields } = useContext(
    PerformanceBoardSlideOutContext,
  );
  const { trackEvent } = useContext(AnalyticsContext);

  const createCommentsSection = useCreateCommentsSection();

  const openWidget = useCallback(
    (type: PerformanceBoardTypes.SlideOut.SectionType) => {
      if (type === 'Comments') {
        createCommentsSection();
        trackEvent('Board - Slide out - Comments Widget Added');
      } else {
        setNavState({
          slideoutMode: 'Creating',
          creatingSectionType: type,
        });

        trackEvent('Board - Slide out - Widget type selected', {
          widgetType: type,
        });
      }
    },
    [createCommentsSection, setNavState, trackEvent],
  );

  return (
    <Wrapper>
      {performanceFields.length !== 0 && (
        <>
          <WidgetItem
            iconSvg={ChartSvg}
            title="Performance Graph"
            description="Graph the difference above or below target, Accompanied by a grid"
            onClick={() => openWidget('Performance Graph')}
          />
          <WidgetItem
            iconSvg={ChartSvg}
            title="Target Progress"
            description="Display a list of targets"
            onClick={() => openWidget('Target Progress')}
          />
        </>
      )}
      <WidgetItem
        iconSvg={MetricsSvg}
        title="Metric Widget"
        description="Display a list of KPIs"
        onClick={() => openWidget('Metric Widget')}
      />
      <WidgetItem
        iconSvg={SimpleGridSvg}
        title="Simple Grid"
        description="Display raw data for current interval"
        onClick={() => openWidget('Simple Grid')}
      />
      <WidgetItem
        iconSvg={PropertiesSvg}
        title="Properties"
        description="Display latest information for the entity such as Hire date for a driver"
        onClick={() => openWidget('Properties')}
      />
      <WidgetItem
        iconSvg={CommentsSvg}
        title="Comments"
        description="Allow users to leave comments"
        onClick={() => openWidget('Comments')}
      />
      {performanceFields.length === 0 && (
        <>
          <WidgetItem
            isDisabled
            iconSvg={ChartSvg}
            title="Performance Graph"
            description="Your Dataset has no performance fields"
            onClick={() => {}}
          />
          <WidgetItem
            isDisabled
            iconSvg={ChartSvg}
            title="Target Progress"
            description="Your Dataset has no performance fields"
            onClick={() => {}}
          />
        </>
      )}
    </Wrapper>
  );
};

export default EmptySlideout;
