import { useCallback, useContext, useState } from 'react';
import STORE from 'store';

import AccountPickerContext from 'contexts/AccountPickerContext';
import BoardContext from 'contexts/BoardContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import PerformanceBoardSettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import isPerformanceBoard from 'isPerformanceBoard';
import getTimeStamp from 'getTimeStamp';

const useUpdateFilters = () => {
  const { board } = useContext(BoardContext);
  const { setNavState } = useContext(PerformanceBoardSettingsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const currentUser = useContext(CurrentUserContext);
  const [quickFilterOrder, setQuickFilterOrder] = useState<string[]>(
    () => board.quickFilterOrder,
  );

  const updateOrder = useCallback(
    ({ newOrder }: { newOrder: string[] }) => {
      setQuickFilterOrder(newOrder);
      const newBoard = {
        ...board,
        quickFilterOrder: newOrder,
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
      };

      if (isPerformanceBoard(newBoard)) {
        STORE.contentDistributions
          .getPerformanceBoardsRef({
            accountId: selectedAccountId,
          })
          .doc(newBoard.id)
          .set(newBoard);
      }
    },
    [board, currentUser.id, selectedAccountId],
  );

  const updateFilters = useCallback(
    ({
      mode,
      filter,
      newOrder,
    }: {
      mode: 'set' | 'delete' | 'reorder';
      filter?: LegacySavedFilter;
      newOrder?: string[];
    }) => {
      if (mode === 'set' && filter) {
        STORE.filterDefinitions
          .getLegacySavedFiltersRef({
            accountId: selectedAccountId,
          })
          .doc(filter.id)
          .set(filter)
          .then(() => {
            setNavState({ mode: 'Quick Filters' });
          });
      } else if (mode === 'delete' && filter) {
        STORE.filterDefinitions
          .getLegacySavedFiltersRef({
            accountId: selectedAccountId,
          })
          .doc(filter.id)
          .delete()
          .then(() => {
            const orderWithoutDeleted = quickFilterOrder.filter(
              (fid) => !fid.includes(filter.id),
            );
            updateOrder({ newOrder: orderWithoutDeleted });
          });
      } else if (mode === 'reorder' && newOrder) {
        updateOrder({ newOrder });
      }
    },
    [quickFilterOrder, selectedAccountId, setNavState, updateOrder],
  );

  return { updateFilters, quickFilterOrder };
};

export default useUpdateFilters;
