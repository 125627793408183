import React, { useContext } from 'react';
import useFullDataInterval from 'components/PerformanceBoards/hooks/useFullDataInterval';
import usePerformanceSlideOutFilterInput from 'components/PerformanceBoards/hooks/usePerformanceSlideOutFilterInput';
import GridSlideoutPreview from './GridSlideout';
import PerformanceBoardSlideOutContext from 'components/PerformanceBoards/contexts/PerformanceBoardSlideOutContext';

const GridContainer = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.GridSection;
}) => {
  const filterInput = usePerformanceSlideOutFilterInput();
  const { dateScope } = useFullDataInterval({
    mode: section.dateRange.mode,
    n: section.dateRange.n,
    isToDate: section.dateRange.isToDate,
  });

  const { navState } = useContext(PerformanceBoardSlideOutContext);

  if (!filterInput || !dateScope) {
    return null;
  }

  if (navState.slideoutMode === 'Creating' || navState.editingSection) {
    return (
      <div
        style={{
          marginBottom: '24px',
          padding: '16px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
        }}
      >
        <GridSlideoutPreview
          filters={filterInput}
          dateScope={dateScope}
          dataType={section.dataset}
          fields={section.fields}
          noMargin
        />
      </div>
    );
  }

  return (
    <GridSlideoutPreview
      filters={filterInput}
      dateScope={dateScope}
      dataType={section.dataset}
      fields={section.fields}
    />
  );
};

export default GridContainer;
