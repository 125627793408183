import React, { useContext } from 'react';
import MetricListColumn from '../MetrlicListColumn';
import Delta from '../../Gadget/Delta';
import getComparison from '../../V5Gadget/Matrix/getComparison';
import Tooltip from '../../Tooltip';
import DeltaTooltip from '../../Gadget/DeltaTooltip';
import useValueFormatters from '../../../hooks/useValueFormatters';
import {
  COL_BORDER_COLOR,
  EVEN_ROW_HOVER,
  ODD_ROW_COLOR,
  ODD_ROW_HOVER,
} from '../../Grid/StyleOverrides';

import styled from 'styled-components';
import { RowHeightContext } from '../../../contextProviders/SplashScreenProviders/MiscProviders/RowHeightProvider';
import Loading from '../../Loading/Loading';

const MetricRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: ${ODD_ROW_COLOR};
  &:hover {
    background-color: ${ODD_ROW_HOVER};
  }

  &:nth-child(2n) {
    background-color: #ffffff;
    &:hover {
      background-color: ${EVEN_ROW_HOVER};
    }
  }

  border-bottom: 1px solid ${COL_BORDER_COLOR};
`;

const Change = ({
  metric,
  current,
  currentFilterInput,
  currentDateRange,
  previous,
  previousFilterInput,
  previousDateRange,
  formatting,
}: {
  metric: Metrics.Metric;
  current?: number | null;
  currentFilterInput?: FilterInput;
  currentDateRange?: DateRangeInput;
  previous?: number | null;
  previousFilterInput?: FilterInput;
  previousDateRange?: DateRangeInput;
  formatting: MetricFormatting;
}) => {
  if (
    current === undefined ||
    previous === undefined ||
    current === null ||
    previous === null ||
    previous === 0 ||
    !previousFilterInput ||
    !currentFilterInput
  ) {
    return (
      <div>
        <span>-</span>
      </div>
    );
  }

  const { delta, isGood } = getComparison({
    value: current,
    previous,
    formatting,
    type: 'delta',
  });

  if (!currentDateRange || !previousDateRange) {
    return (
      <Delta metricId={metric.id} isGood={isGood} delta={delta} isGrid isRaw />
    );
  }

  return (
    <div>
      <Tooltip
        content={
          <DeltaTooltip
            metric={metric}
            current={current}
            previous={previous}
            currentDateRange={currentDateRange}
            previousDateRange={previousDateRange}
          />
        }
        isAltTooltip
      >
        <Delta
          metricId={metric.id}
          isGood={isGood}
          delta={delta}
          isGrid
          isRaw
        />
      </Tooltip>
    </div>
  );
};

const MetricItem = ({
  name,
  onNameClicked,
  metric,
  current,
  currentFilterInput,
  previous,
  previousFilterInput,
  currentDateRange,
  previousDateRange,
  isLoading,
}: {
  name: string;
  onNameClicked?: () => void;
  metric?: Metrics.Metric;
  current?: number | null;
  currentFilterInput?: FilterInput;
  previous?: number | null;
  previousFilterInput?: FilterInput;
  currentDateRange?: DateRangeInput;
  previousDateRange?: DateRangeInput;
  isLoading: boolean;
}) => {
  const { formatMetric } = useValueFormatters();
  const { rowHeight } = useContext(RowHeightContext);

  return (
    <MetricRow>
      <MetricListColumn
        flex={2}
        onClick={onNameClicked}
        isClickable={!!onNameClicked}
        rowHeight={rowHeight}
      >
        <span style={{ color: onNameClicked ? '#0041ea' : undefined }}>
          {name}
        </span>
      </MetricListColumn>

      <MetricListColumn flex={1} rowHeight={rowHeight}>
        {metric && !isLoading ? (
          <span>{formatMetric({ value: current, metricId: metric.id })}</span>
        ) : (
          <Loading isTiny />
        )}
      </MetricListColumn>
      <MetricListColumn flex={1} rowHeight={rowHeight}>
        {metric && !isLoading ? (
          <Change
            metric={metric}
            current={current}
            currentFilterInput={currentFilterInput}
            previous={previous}
            previousFilterInput={previousFilterInput}
            formatting={metric.formatting}
            currentDateRange={currentDateRange}
            previousDateRange={previousDateRange}
          />
        ) : (
          <Loading isTiny />
        )}
      </MetricListColumn>
    </MetricRow>
  );
};

export default MetricItem;
