import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ContactUsForm from './ContactUsForm';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';
import {
  submitContactUsForm,
  wakeContactUsCloudFunction,
} from '../../api/submitContactUsForm';
import ToastContext from '../../contexts/ToastContext';
import VersionContext from '../../contexts/VersionContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import AccountContext from '../../contexts/AccountContext';
import USER_ROLES from '../../roles';
import captureException from '../../services/captureException';
import BreadcrumbsContext from '../../contexts/BreadcrumbsContext';
import UnsavedChangesProvider, {
  UnsavedChangesContext,
} from '../../contextProviders/UnsavedChangesProvider';

const ContactUsFormContainer = ({ close }: { close: () => void }) => {
  const { apiGateway } = useContext(CloudFunctionClientContext);
  const { showToast } = useContext(ToastContext);
  const currentUser = useContext(CurrentUserContext);
  const { trackEvent, recordInHotjar } = useContext(AnalyticsContext);
  const { breadcrumbs } = useContext(BreadcrumbsContext);

  const { buildNumber } = useContext(VersionContext);
  const { id, email, displayName } = useContext(CurrentUserContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const { carrierName, id: accountId } = useContext(AccountContext);
  const { onCloseAttempt, setHasUnsavedChanges } = useContext(
    UnsavedChangesContext,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [attachments, setAttachments] = useState<File[]>([]);

  // ping the cloud function, so it is hot when the form is submitted.
  useEffect(() => {
    wakeContactUsCloudFunction({ api: apiGateway }).then();
  }, [apiGateway]);

  const collectMetadata = useCallback(() => {
    let role = '';
    if (selectedAccount.roles.includes(USER_ROLES.ADMIN)) {
      role = 'ADMIN';
    } else if (selectedAccount.roles.includes(USER_ROLES.VIEWER)) {
      role = 'VIEWER';
    } else if (selectedAccount.roles.includes(USER_ROLES.EDITOR)) {
      role = 'EDITOR';
    }
    let metadata = '<h2>Ticket Metadata from app</h2><br/>';
    metadata += `<ul>`;
    metadata += `<li><b>User: </b> ${displayName} | ${role} | ${email} | ${id}</li>`;
    metadata += `<li><b>Carrier: </b> ${carrierName} | ${accountId})</li>`;
    metadata += `<li><b>URL : </b> <a href="${window.location}">${window.location}</a></li>`;
    metadata += `<li><b>User Agent: </b> ${window.navigator.userAgent}</li>`;
    metadata += `<li><b>Build Number</b> ${buildNumber}</li>`;
    metadata += `<li><b>Screen: </b> ${window.screen.availHeight} X ${window.screen.availWidth}</li>`;
    metadata += `<li><b>Browser: </b> ${window.screen.height} X ${window.screen.width}</li>`;
    metadata += `</ul>`;
    metadata += '<h2>Breadcrumbs (most recent first)</h2><br/>';
    metadata += '<ol>';
    for (let crumb of breadcrumbs) {
      const idText = crumb.id ? `ID: ${crumb.id || '-'} | ` : '';
      metadata += `<li><b>${crumb.name}</b> ${idText}| <a href="${crumb.url}">${crumb.url}</a></li>`;
    }
    metadata += '</ol>';
    return metadata;
  }, [
    buildNumber,
    displayName,
    email,
    id,
    carrierName,
    accountId,
    selectedAccount,
    breadcrumbs,
  ]);

  const onSubmit = useCallback(() => {
    if (currentUser === undefined) {
      alert('Input is not valid');
      return;
    }
    setIsLoading(true);
    const metadata = collectMetadata();
    submitContactUsForm({
      api: apiGateway,
      type,
      subject,
      message,
      attachments,
      metadata,
    }).then((success) => {
      trackEvent('Help - Contact Form Submitted');
      recordInHotjar('submitted_support_ticket', {
        type,
        subject,
        message,
      });
      setIsLoading(false);
      if (success) {
        close();
        showToast('Message Sent');
      } else {
        const e = new Error();
        e.name = 'ContactUsForm: Failed to submit';
        captureException(e);
        alert('Something went wrong! Please email us at support@fleetops.com');
      }
    });
  }, [
    currentUser,
    setIsLoading,
    apiGateway,
    type,
    subject,
    message,
    attachments,
    collectMetadata,
    trackEvent,
    recordInHotjar,
    showToast,
    close,
  ]);

  const onSubjectChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSubject(event.target.value);
    },
    [setSubject],
  );

  const onMessageChanged = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(event.target.value);
    },
    [setMessage],
  );

  useEffect(() => {
    setHasUnsavedChanges(
      !!message || !!subject || !!type || attachments.length > 0,
    );
  }, [attachments.length, message, setHasUnsavedChanges, subject, type]);

  return (
    <ContactUsForm
      onSubmit={onSubmit}
      close={onCloseAttempt}
      isLoading={isLoading}
      subject={subject}
      onSubjectChanged={onSubjectChanged}
      type={type}
      setType={setType}
      message={message}
      onMessageChanged={onMessageChanged}
      attachments={attachments}
      setAttachments={setAttachments}
    />
  );
};

const Gate = ({ close }: { close: () => void }) => {
  return (
    <UnsavedChangesProvider close={close}>
      <ContactUsFormContainer close={close} />
    </UnsavedChangesProvider>
  );
};

export default Gate;
