import React, { useCallback, useState } from 'react';
import FilterPlateFormContext from 'contexts/FilterPlateFormContext';

import useOnRemoved from './hooks/useOnRemoved';
import useOnDrillDownConfirmed from './hooks/useOnDrillDownConfirmed';
import useOnVariableFilterAdded from './hooks/useOnVariableFilterAdded';
import FilterPlateForm from './FilterPlateForm';
import useOnSavedFilterAdded from './hooks/useOnSavedFilterAdded';

const FilterPlateFormContainer = ({
  close,
  drillDown,
  isScope,
  onUpdated,
  onManualFilterChanged,
  onFilterAdded,
  isDateScopeDisabled,
}: {
  close: () => void;
  drillDown?: FixedFilter;
  isScope: boolean;
  onUpdated?: (newDrillDown: FixedFilter) => void;
  onManualFilterChanged?: () => void;
  onFilterAdded?: () => void;
  isDateScopeDisabled?: boolean;
}) => {
  const [isEditing] = useState<boolean>(!!drillDown);
  const [field, setField] = useState<string | undefined>(
    drillDown ? drillDown.field : undefined,
  );
  const [fieldType, setFieldType] = useState<FleetOps.FieldType | undefined>(
    drillDown ? drillDown.fieldType : undefined,
  );
  const [dataset, setDataset] = useState(
    drillDown ? drillDown.dataset : undefined,
  );

  const stage =
    isEditing || (field && fieldType) ? 'valueSelection' : 'fieldSelection';
  const onDrillDownConfirmed = useOnDrillDownConfirmed({
    close,
    drillDown,
    isScope,
    onUpdated,
    onManualFilterChanged,
    onFilterAdded,
    isEditing,
    stage,
  });
  const onVariableFilterAdded = useOnVariableFilterAdded({
    close,
    drillDown,
    isScope,
  });
  const onSavedFilterAdded = useOnSavedFilterAdded({
    close,
    drillDown,
    isScope,
  });
  const onRemoved = useOnRemoved({
    isScope,
    drillDown,
  });
  const reset = useCallback(() => {
    setField(undefined);
    setFieldType(undefined);
  }, []);

  const onFieldSelected = useCallback(
    (field: FleetOps.Field | DateBucketField) => {
      setField(field.field);
      setFieldType(field.type);

      if (field.dataType) {
        setDataset(field.dataType);
      }
    },
    [],
  );

  return (
    <FilterPlateFormContext.Provider
      value={{
        isEditing,
        stage,
        field,
        fieldType,
        dataset,
        drillDown,
        onFieldSelected,
        onDrillDownConfirmed,
        reset,
        onRemoved,
        onVariableFilterAdded,
        onSavedFilterAdded: onSavedFilterAdded,
        isVariableFilterValuePicker: !!onUpdated,
        isScope,
        isDateScopeDisabled,
      }}
    >
      <FilterPlateForm stage={stage} fieldType={fieldType} />
    </FilterPlateFormContext.Provider>
  );
};

export default FilterPlateFormContainer;
