const toRangeInput = (field: string, range: FilterRangeType): RangeInput => {
  const { operator, valueA, valueB } = range;
  switch (operator) {
    case 'is between':
      return {
        field,
        gte: valueA,
        lt: valueB,
      };
    case 'equals':
      return {
        field,
        gte: valueA,
        lte: valueA,
      };
    case 'is less than':
      return {
        field,
        lt: valueA,
      };
    case 'is less than or equal to':
      return {
        field,
        lte: valueA,
      };
    case 'is more than':
      return {
        field,
        gt: valueA,
      };
    case 'is more than or equal to':
      return {
        field,
        gte: valueA,
      };
    default:
      throw new Error('Could not transform to RangeInput');
  }
};

export const toRangeDrillDown = (range: RangeInput): FilterRangeType => {
  const { gte, lt, lte, gt } = range;

  const operator = ((): FilterRangeOperator => {
    if (
      (gte === lt && lt !== undefined) ||
      (gte === lte && lte !== undefined)
    ) {
      return 'equals';
    }

    if (gte && !lt) {
      return 'is more than or equal to';
    }
    if (lte) {
      return 'is less than or equal to';
    }
    if (lt && !gte) {
      return 'is less than';
    }
    if (gt) {
      return 'is more than';
    }

    return 'is between';
  })();

  const valueA = ((): number => {
    switch (operator) {
      case 'equals':
        return gte as number;
      case 'is between':
        return gte as number;
      case 'is less than or equal to':
        return lte as number;
      case 'is less than':
        return lt as number;
      case 'is more than or equal to':
        return gte as number;
      case 'is more than':
        return gt as number;
      default:
        throw new Error('unknown valueA');
    }
  })();
  const valueB = ((): number | undefined => {
    switch (operator) {
      case 'equals':
      case 'is less than or equal to':
      case 'is less than':
      case 'is more than or equal to':
      case 'is more than':
        return undefined;
      case 'is between':
        return lt as number;
      default:
        throw new Error('unknown valueA');
    }
  })();

  return {
    operator,
    valueA,
    valueB,
  };
};

export default toRangeInput;
