import React from 'react';

export type FilterPlateFormStage = 'fieldSelection' | 'valueSelection';

interface FilterPlateFormContextType {
  isEditing: boolean;
  stage: FilterPlateFormStage;
  field?: string;
  dataset?: string;
  fieldType?: FleetOps.FieldType;
  drillDown?: FixedFilter;
  onFieldSelected: (field: FleetOps.Field | DateBucketField) => void;
  onDrillDownConfirmed: (newDrillDown: FixedFilter) => void;
  reset: () => void;
  onRemoved: () => void;
  onVariableFilterAdded: (vf: VariableDrillDownType) => void;
  onSavedFilterAdded: (df: SavedFilter) => void;
  isVariableFilterValuePicker: boolean;
  isScope: boolean;
  isDateScopeDisabled?: boolean;
}

const FilterPlateFormContext = React.createContext<FilterPlateFormContextType>(
  {} as FilterPlateFormContextType,
);

export default FilterPlateFormContext;
