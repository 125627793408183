import { useCallback, useContext, useEffect, useState } from 'react';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const usePortalsWithAccess = ({
  type,
  typeId,
}: {
  type: ExecutivePortalPublishableContentType;
  typeId: string;
}) => {
  const { publishedLookup } = useContext(PortalsContext);

  const getPortalsWithAccess = useCallback(() => {
    if (type === 'report') {
      return publishedLookup.reports[typeId] || [];
    } else if (type === 'dashboard') {
      return publishedLookup.dashboards[typeId] || [];
    } else if (type === 'scorecard') {
      return publishedLookup.scorecards[typeId] || [];
    } else {
      return [];
    }
  }, [
    publishedLookup.dashboards,
    publishedLookup.reports,
    publishedLookup.scorecards,
    type,
    typeId,
  ]);

  const [portalsWithAccess, setPortalsWithAccess] = useState<
    (ExecutivePortal | EngagementPortal)[]
  >(() => getPortalsWithAccess());

  useEffect(() => {
    setPortalsWithAccess(getPortalsWithAccess());
  }, [getPortalsWithAccess]);

  return portalsWithAccess;
};

export default usePortalsWithAccess;
