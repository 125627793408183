import { useContext } from 'react';
import FilterPlatesContext from '../../../contexts/FilterPlatesContext';

const useSetDrillDowns = ({ isScope }: { isScope: boolean }) => {
  const { setDrillDowns: setRunTimeDs, setScope: setSavedDs } =
    useContext(FilterPlatesContext);
  const setDrillDowns = isScope ? setSavedDs : setRunTimeDs;

  return setDrillDowns;
};

export default useSetDrillDowns;
