import React, { useContext, useEffect, useState } from 'react';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import useDashboardGadgetDataTypes from '../../hooks/useDashboardGadgetDataTypes';
import Tooltip from '../Tooltip';
import FreshnessTooltipCTA from './FreshnessTooltipCTA';
import FreshnessContent from './FreshnessTooltipContent';
import ScorecardContext from '../../contexts/ScorecardContext';
import useScorecardDatasets from '../../screens/ScorecardShow/useScorecardDatasets';
import { DatasetFreshnessContext } from '../../contextProviders/SplashScreenProviders/DatasetProviders/DatasetFreshnessProvider';
import useGetDatasetLabel from '../Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';

export const DashboardGadgetFreshnessTooltip = () => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const datasets = useDashboardGadgetDataTypes(dashboardGadget);

  return (
    <Tooltip content={<FreshnessContent datasets={datasets} />}>
      <FreshnessTooltipCTA />
    </Tooltip>
  );
};

export const ScorecardFreshnessTooltip = () => {
  const { scorecard } = useContext(ScorecardContext);
  const datasets = useScorecardDatasets(scorecard);

  return (
    <Tooltip content={<FreshnessContent datasets={datasets} />}>
      <FreshnessTooltipCTA />
    </Tooltip>
  );
};

export const ReportFreshnessTooltipSection = ({
  dataset,
  Body,
}: {
  dataset: string;
  Body: ({ children }: { children: string }) => JSX.Element;
}) => {
  const { getLastSyncedAt } = useContext(DatasetFreshnessContext);
  const getDatasetLabel = useGetDatasetLabel();

  return (
    <Body>{`${getDatasetLabel(dataset)} (${getLastSyncedAt(dataset)})`}</Body>
  );
};

export const BoardFreshnessTooltip = ({
  board,
}: {
  board: PerformanceBoardTypes.Board | GeneralBoard;
}) => {
  const [datasets, setDatasets] = useState<string[]>([board.dataType]);
  useEffect(() => {
    setDatasets([board.dataType]);
  }, [board.dataType]);

  return (
    <Tooltip content={<FreshnessContent datasets={datasets} />}>
      <FreshnessTooltipCTA />
    </Tooltip>
  );
};
