import { useCallback, useContext, useState } from 'react';
import UsersContext from '../../../contexts/UsersContext';
import userTypeCheckers from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import getUserAccountAccess from '../../../api/getUserAccountAccess';
import usePermissionsApi from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/RolesProvider/usePermissionsApi';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import FeatureGatesContext from '../../../contexts/FeatureGatesContext';

interface UserRow {
  email: string;
  displayName: string;
  role: string;
  ui?: string;
}

const useIsUiColumnRequired = () => {
  const { isPortalsEnabledAtAccountLevel } = useContext(PortalsContext);
  const { isPortalsUIPerUserToggleEnabled } = useContext(FeatureGatesContext);

  if (isPortalsEnabledAtAccountLevel) {
    return undefined;
  }
  if (!isPortalsUIPerUserToggleEnabled) {
    return undefined;
  }

  return true;
};

const useGetUserRow = () => {
  const isUiColumnRequired = useIsUiColumnRequired();
  const api = usePermissionsApi();

  const getRoles = useCallback(
    async (
      user: UserManagement.SignedUpUser | UserManagement.PendingUser,
    ): Promise<string | undefined> => {
      const accessProfileResponse = await getUserAccountAccess(user.id, api);
      if (accessProfileResponse.ok === false) {
        return undefined;
      }
      if (accessProfileResponse.data === undefined) {
        return undefined;
      }
      if (!accessProfileResponse.data.userProfile) {
        return undefined;
      }
      if (!accessProfileResponse.data.userProfile.roles) {
        return undefined;
      }

      const roles = accessProfileResponse.data.userProfile.roles;
      if (roles.some((r) => r === 'fleetops.roles.admin')) {
        return 'Admin';
      }
      if (roles.some((r) => r === 'fleetops.roles.editor')) {
        return 'Executive';
      }
      if (roles.some((r) => r === 'fleetops.roles.viewer')) {
        return 'Basic User';
      }
      return undefined;
    },
    [api],
  );

  const getUserRow = useCallback(
    async (
      user: UserManagement.SignedUpUser | UserManagement.PendingUser,
    ): Promise<UserRow> => {
      const role = await getRoles(user);
      const ui = (() => {
        if (!isUiColumnRequired) {
          return undefined;
        }

        return user.ui;
      })();

      return {
        email: user.email,
        displayName: user.displayName,
        role: role ? role : '',
        ui,
      };
    },
    [getRoles, isUiColumnRequired],
  );

  return getUserRow;
};

const useExportUsers = () => {
  const { allUsers } = useContext(UsersContext);
  const getUserRow = useGetUserRow();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isUiColumnRequired = useIsUiColumnRequired();

  const buildCsv = useCallback(async () => {
    const headers = `email,display name,role,${isUiColumnRequired ? 'ui,' : ''}\r\n`;
    const exportingUsers = allUsers
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .filter((u) => !u.isDeleted);
    const userRows = await Promise.all(exportingUsers.map(getUserRow));

    const content = userRows
      .map((u) => {
        if (isUiColumnRequired) {
          return `${u.email},${u.displayName},${u.role},${u.ui},`;
        }
        return `${u.email},${u.displayName},${u.role},`;
      })
      .join('\r\n');
    return `${headers}${content}`;
  }, [allUsers, getUserRow, isUiColumnRequired]);

  const onExportClicked = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const csv = await buildCsv();
    // Create a blob
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'fleetops-users.csv');
    pom.click();
    setIsLoading(false);
  }, [buildCsv, isLoading]);

  return { onExportClicked, isLoading };
};

export default useExportUsers;
