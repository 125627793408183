import isCustomRangeInput from '../../isCustomRangeInput';
import { toRangeDrillDown } from '../../utils/buildFilterInput/toRangeInput';

const termToRangeDrill = (
  term: string,
  chartDef: V5ChartDefinition,
): FilterRangeType => {
  if (chartDef.dimensionA && chartDef.dimensionA.rangeInput) {
    if (isCustomRangeInput(chartDef.dimensionA.rangeInput)) {
      const customInputItem = chartDef.dimensionA.rangeInput.items.find(
        (i) => i.label === term,
      );
      if (!customInputItem) {
        throw new Error(`Could not find customInputItem for ${term}`);
      }

      const rangeInput = {
        field: chartDef.dimensionA.field,
        gte: customInputItem.from,
        lt: customInputItem.to,
      };
      return toRangeDrillDown(rangeInput);
    } else {
      const gteRe = /^\d+/;
      const ltRe = /\d+$/;
      const gteExec = gteRe.exec(term);
      const ltExec = ltRe.exec(term);
      if (gteExec && !!gteExec[0] && ltExec && !!ltExec[0]) {
        return toRangeDrillDown({
          field: chartDef.dimensionA.field,
          gte: Number(gteExec[0]),
          lt: Number(ltExec[0]),
        });
      }
    }
  }
  throw new Error('Not a range');
};

export default termToRangeDrill;
