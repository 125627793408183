import React, { useContext } from 'react';
import userTypeCheckers from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import UsersContext from 'contexts/UsersContext';
import isDefined from 'isDefined';
import Row from '../Common/Row';
import UserIcon from 'components/Common/UserIcon';

const UserBadgesRow = ({ userIds }: { userIds: string[] }) => {
  const { allUsers } = useContext(UsersContext);

  const users = userIds
    .map((uid) => allUsers.find((u) => u.id === uid))
    .filter(isDefined)
    .filter(userTypeCheckers.isNotWallboardUser);

  return (
    <Row centerAlign>
      {users.slice(0, 3).map((u) => (
        <UserIcon {...u} key={u.id} noMargin />
      ))}
      {users.length > 3 && (
        <UserIcon
          {...users[3]}
          key={users[3].id}
          noMargin
          isEllipsis
          displayName={`+ ${users.length - 3}`}
          tooltip={users
            .slice(3)
            .map((u) => u.displayName)
            .join(', ')}
        />
      )}
    </Row>
  );
};

export default UserBadgesRow;
