import React, { useCallback, useContext, useEffect, useState } from 'react';
import BooleanPicker from './BooleanPicker';
import FilterPlateFormContext from '../../../contexts/FilterPlateFormContext';
import filterTypeCheckers from '../filterTypeCheckers';
import getIdentifier from '../../../getIdentifier';

const useEditingBooleanFilter = () => {
  const { drillDown } = useContext(FilterPlateFormContext);

  if (!drillDown) {
    return undefined;
  }
  if (filterTypeCheckers.isBooleanFilter(drillDown)) {
    return drillDown;
  }

  return undefined;
};

const BooleanPickerContainer = () => {
  const { field, onDrillDownConfirmed, isEditing, dataset } = useContext(
    FilterPlateFormContext,
  );
  const editingBooleanFilter = useEditingBooleanFilter();
  const [booleanValue, setBooleanValue] = useState<
    'true' | 'false' | 'has no value' | undefined
  >(() => {
    if (editingBooleanFilter) {
      if (editingBooleanFilter.booleanValue === true) {
        return 'true';
      }
      if (editingBooleanFilter.booleanValue === false) {
        return 'false';
      }
      if (editingBooleanFilter.exists === false) {
        return 'has no value';
      }
    }

    return undefined;
  });
  const [options, setOptions] = useState<RadioOption[]>([]);
  useEffect(() => {
    setOptions([
      {
        key: 'true',
        isSelected: 'true' === booleanValue,
        onSelected: () => {
          setBooleanValue('true');
        },
        label: 'True',
      },
      {
        key: 'false',
        isSelected: 'false' === booleanValue,
        onSelected: () => {
          setBooleanValue('false');
        },
        label: 'False',
      },
      {
        key: 'has no value',
        isSelected: 'has no value' === booleanValue,
        onSelected: () => {
          setBooleanValue('has no value');
        },
        label: 'Has no value',
      },
    ]);
  }, [booleanValue, setBooleanValue]);

  const onConfirm = useCallback(() => {
    if (!field) {
      return;
    }
    const newDrillDown: BooleanFilter = (() => {
      const base = {
        field,
        fieldType: 'boolean' as 'boolean',
        dataset,
        mode: 'editing' as 'editing',
        booleanValue:
          booleanValue === 'true'
            ? true
            : booleanValue === 'false'
              ? false
              : undefined,
        exists: booleanValue === 'has no value' ? false : undefined,
      };
      if (editingBooleanFilter) {
        return {
          ...editingBooleanFilter,
          ...base,
        };
      }

      return {
        id: getIdentifier(undefined, true),
        ...base,
      };
    })();
    onDrillDownConfirmed(newDrillDown);
  }, [
    booleanValue,
    dataset,
    editingBooleanFilter,
    field,
    onDrillDownConfirmed,
  ]);

  return (
    <BooleanPicker
      isValid={booleanValue !== undefined}
      options={options}
      onConfirm={onConfirm}
      isEditing={isEditing}
    />
  );
};

export default BooleanPickerContainer;
