import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

const runDateHistogramAggregationQuery = ({
  query,
  client,
}: {
  query: any;
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  return client.query(query);
};

export default runDateHistogramAggregationQuery;
