import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import RangePicker from './RangePicker';
import FilterPlateFormContext from '../../../contexts/FilterPlateFormContext';
import filterTypeCheckers, { NUMBER_FIELD_TYPES } from '../filterTypeCheckers';
import getIdentifier from '../../../getIdentifier';

const OPTION_TYPES = [
  'equals',
  'is less than',
  'is less than or equal to',
  'is more than',
  'is more than or equal to',
  'is between',
  'has some value',
  'has no value',
] as FilterRangeOperator[];

const useEditingNumberFilter = () => {
  const { drillDown } = useContext(FilterPlateFormContext);

  if (!drillDown) {
    return undefined;
  }
  if (filterTypeCheckers.isNumberFilter(drillDown)) {
    return drillDown;
  }

  return undefined;
};

const RangePickerContainer = () => {
  const { field, dataset, onDrillDownConfirmed, isEditing, fieldType } =
    useContext(FilterPlateFormContext);
  const editingDrillDown = useEditingNumberFilter();
  const [exists, setExists] = useState<boolean | undefined>(
    editingDrillDown ? editingDrillDown.exists : undefined,
  );
  const [rangeValue, setRangeValue] = useState<FilterRangeType | undefined>(
    editingDrillDown ? editingDrillDown.rangeValue : undefined,
  );
  const [operator, setOperator] = useState<FilterRangeOperator>(
    (() => {
      if (exists === undefined) {
        return rangeValue ? rangeValue.operator : 'equals';
      } else if (exists) {
        return 'has some value';
      } else {
        return 'has no value';
      }
    })(),
  );
  const [valueA, setValueA] = useState<string>(
    rangeValue && rangeValue.valueA ? rangeValue.valueA.toString(10) : '0',
  );
  const [valueB, setValueB] = useState<string>(
    rangeValue && rangeValue.valueB ? rangeValue.valueB.toString(10) : '0',
  );
  const buildOperatorOptions = useCallback(() => {
    return OPTION_TYPES.map((t) => ({
      label: t,
      value: t,
      onSelected: () => {
        setOperator(t);
        if (t !== 'is between') {
          setValueB('0');
        }

        if (t === 'has some value' || t === 'has no value') {
          setRangeValue(undefined);
          setExists(t === 'has some value');
        } else {
          setExists(undefined);
        }
      },
      isSelected: operator === t,
    }));
  }, [operator, setExists, setRangeValue]);
  const [operatorOptions, setOperatorOptions] = useState<DropdownOption[]>(() =>
    buildOperatorOptions(),
  );
  useEffect(() => {
    setOperatorOptions(buildOperatorOptions());
  }, [buildOperatorOptions]);
  const [isValueBRequired, setIsValueBeRequired] = useState(
    operator === 'is between',
  );
  useEffect(() => {
    setIsValueBeRequired(operator === 'is between');
  }, [operator]);

  const onValueAChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValueA(event.target.value);
  };

  const onValueBChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValueB(event.target.value);
  };

  useEffect(() => {
    if (operator === 'has some value' || operator === 'has no value') {
      return;
    }
    setRangeValue({
      operator,
      valueA: Number.parseFloat(valueA),
      valueB: Number.parseFloat(valueB),
    });
  }, [operator, valueA, valueB, setRangeValue]);

  const onConfirm = useCallback(() => {
    if (!field || !fieldType || !NUMBER_FIELD_TYPES.includes(fieldType)) {
      return;
    }
    const newDrillDown: NumberFilter = (() => {
      const base = {
        field,
        fieldType: fieldType as 'int' | 'long' | 'float',
        dataset,
        rangeValue,
        mode: 'editing' as 'editing',
        exists,
      };
      if (editingDrillDown) {
        return {
          ...editingDrillDown,
          ...base,
        };
      }

      return {
        id: getIdentifier(undefined, true),
        ...base,
      };
    })();
    onDrillDownConfirmed(newDrillDown);
  }, [
    dataset,
    editingDrillDown,
    exists,
    field,
    fieldType,
    onDrillDownConfirmed,
    rangeValue,
  ]);

  const isExistsFilter =
    operator === 'has some value' || operator === 'has no value';

  return (
    <RangePicker
      field={field}
      operator={operator}
      operatorOptions={operatorOptions}
      valueA={valueA}
      onValueAChange={onValueAChange}
      valueB={valueB}
      onValueBChange={onValueBChange}
      isValueBRequired={isValueBRequired}
      isBAndADisabled={isExistsFilter}
      onConfirm={onConfirm}
      isEditing={isEditing}
      isValid={rangeValue !== undefined || isExistsFilter}
    />
  );
};

export default RangePickerContainer;
