import React from 'react';
import LastViewedAtProvider from './LastViewedAtProvider';
import NavSidebarProvider from './NavSideBarProvider';
import FavouritesProvider from './FavouritesProvider';
import DashboardMetricUsageLookupProvider from './DashboardMetricUsageLookupProvider';
import MetricUsageLookupProvider from './MetricUsageLookupProvider';
import SavedFiltersUsageLookupProvider from './SavedFiltersUsageLookupProvider';
import SharedProvider from './SharedProvider';
import AvailableWorkSpacesProvider from './AvailableWorkSpacesProvider';

const ContentDependantProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <LastViewedAtProvider>
    <NavSidebarProvider>
      <FavouritesProvider>
        <DashboardMetricUsageLookupProvider>
          <MetricUsageLookupProvider>
            <SavedFiltersUsageLookupProvider>
              <SharedProvider>
                <AvailableWorkSpacesProvider>
                  {children}
                </AvailableWorkSpacesProvider>
              </SharedProvider>
            </SavedFiltersUsageLookupProvider>
          </MetricUsageLookupProvider>
        </DashboardMetricUsageLookupProvider>
      </FavouritesProvider>
    </NavSidebarProvider>
  </LastViewedAtProvider>
);

export default ContentDependantProviders;
