import React, { ChangeEvent, useContext } from 'react';
import { Link } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../../../../../components/Inputs';
import toSentenceCase from '../../../../../../services/toSentenceCase';
import TriggerPipelineButton from '../../../TriggerPipelineButton';
import SPECIFIC_SET_ROUTES from '../../../../SpecificSet/routes';
import CreatingBadge from '../../../Badges/CreatingBadge';
import ErrorBadge from '../../../Badges/ErrorBadge';
import ItemRow from '../ItemRow';
import { PortalsContext } from '../../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const DatasetItem = ({
  status,
  currentName,
  baseView,
  lastUpdatedAt,
  onIsHiddenChanged,
  onDisplayNameChanged,
  onDatasetLinkClicked,
}: {
  status?: FleetOps.PerformanceDatasetStatus;
  currentName: string;
  baseView: FleetOps.BaseView;
  lastUpdatedAt?: string;
  onIsHiddenChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onDisplayNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onDatasetLinkClicked: () => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <ItemRow
      datasetCol={
        <Link
          to={SPECIFIC_SET_ROUTES.buildBase(baseView.type, selectedPortal)}
          onClick={onDatasetLinkClicked}
        >
          <Typography.Body
            type="Link"
            data-testid={`show-base-view-${baseView.type}`}
          >
            {toSentenceCase(baseView.type)}
          </Typography.Body>
          {status && status === 'creating' && <CreatingBadge />}
          {status && status === 'error' && <ErrorBadge />}
        </Link>
      }
      nameCol={
        <Inputs.TextInput onChange={onDisplayNameChanged} value={currentName} />
      }
      descriptionCol={
        <Typography.Body type="Body 12">{baseView.description}</Typography.Body>
      }
      hideCol={
        <Inputs.TextInput
          data-testid={`${baseView.type}-isHidden`}
          style={{ width: 13 }}
          type="checkbox"
          value={''}
          checked={baseView.isHidden || false}
          defaultChecked={baseView.isHidden}
          onChange={onIsHiddenChanged}
        />
      }
      lastUpdatedCol={
        <Typography.Body type="Body 12">{lastUpdatedAt}</Typography.Body>
      }
      triggerCol={<TriggerPipelineButton dataType={baseView.type} />}
    />
  );
};

export default DatasetItem;
