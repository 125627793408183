import React, { useContext, useEffect, useState } from 'react';
import LegacySavedFiltersContext from '../../../contexts/LegacySavedFiltersContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import migrateScope, { migrateDrillDowns } from '../../../migrateScope';
import store from '../../../store';

const useLegacySavedFilters = () => {
  const [savedFilters, setSavedFilters] = useState<LegacySavedFilter[]>([]);
  const [savedFiltersLookup, setSavedFiltersLookup] = useState<{
    [id: string]: LegacySavedFilter | undefined;
  }>({});
  const [isLoading, setLoading] = useState(true);
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    setLoading(true);
    const listener = store
      .getLegacySavedFiltersRef({ accountId: selectedAccountId })
      .onSnapshot((snapshot) => {
        const data: LegacySavedFilter[] = [];
        snapshot.docs.forEach((d) =>
          // @ts-ignore
          data.push({ id: d.id, ...d.data() } as LegacySavedFilter),
        );
        const newSavedFilters = data
          .filter((sf) => !sf.isHidden)
          .map((sf) => ({
            ...sf,
            drillDowns: migrateDrillDowns(sf.drillDowns),
            scope: migrateScope(sf.scope),
          }));
        setSavedFilters(newSavedFilters);
        const newSavedFiltersLookup = {} as {
          [id: string]: LegacySavedFilter | undefined;
        };
        newSavedFilters.forEach((sf) => {
          newSavedFiltersLookup[sf.id] = sf;
        });
        setSavedFiltersLookup(newSavedFiltersLookup);

        setLoading(false);
      });
    return () => {
      listener();
    };
  }, [accountRef, selectedAccountId]);

  return {
    savedFilters,
    savedFiltersLookup,
    isLoading,
  };
};

const LegacySavedFiltersProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isLoading, savedFilters, savedFiltersLookup } =
    useLegacySavedFilters();

  return (
    <LegacySavedFiltersContext.Provider
      value={{ savedFilters, savedFiltersLookup, isLoading }}
    >
      {children}
    </LegacySavedFiltersContext.Provider>
  );
};

export default LegacySavedFiltersProvider;
