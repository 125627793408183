import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import MetricInfoPopup from './MetricInfoPopup';
import Loading from '../../Loading';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import getMetricInfoLabel from './getMetricInfoLabel';

const useIsSidebarVisible = (metrics: MetricsInfoButton.MetricInfo[]) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(
    () => metrics.length > 1,
  );
  useEffect(() => {
    setIsSidebarVisible(metrics.length > 1);
  }, [metrics.length]);

  return isSidebarVisible;
};

const useSelectedMetric = (metrics: MetricsInfoButton.MetricInfo[]) => {
  const [selectedMetricInfo, setSelectedMetricInfo] = useState<
    MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo | undefined
  >();
  useEffect(() => {
    if (metrics.length === 0) {
      setSelectedMetricInfo(undefined);
      return;
    }

    setSelectedMetricInfo(metrics[0]);
  }, [metrics]);

  const onMetricInfoSelected = (
    metric: MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo,
  ) => {
    setSelectedMetricInfo(metric);
  };

  return { selectedMetricInfo, onMetricInfoSelected };
};

const useFilteredMetrics = (metrics: MetricsInfoButton.MetricInfo[]) => {
  const [filteredMetricInfos, setFilteredMetricInfos] = useState<
    (MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo)[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );
  useEffect(() => {
    const newMetrics =
      searchText === ''
        ? metrics
        : metrics.filter((m) =>
            getMetricInfoLabel(m)
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          );

    setFilteredMetricInfos(newMetrics);
  }, [metrics, searchText]);

  return {
    filteredMetricInfos,
    searchText,
    onSearchTextChanged,
  };
};

const MetricInfoPopupContainer = ({
  metricsInfo,
  close,
}: {
  metricsInfo: MetricsInfoButton.MetricInfo[];
  close: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const isSidebarVisible = useIsSidebarVisible(metricsInfo);
  const { selectedMetricInfo, onMetricInfoSelected } =
    useSelectedMetric(metricsInfo);
  const { filteredMetricInfos, searchText, onSearchTextChanged } =
    useFilteredMetrics(metricsInfo);

  useEffect(() => {
    trackEvent('Metric definition - Opened');
  }, [trackEvent]);

  if (!selectedMetricInfo) {
    return <Loading />;
  }

  return (
    <MetricInfoPopup
      isSidebarVisible={isSidebarVisible}
      metricInfos={filteredMetricInfos}
      selectedMetricInfo={selectedMetricInfo}
      onMetricInfoSelected={onMetricInfoSelected}
      onSearchTextChanged={onSearchTextChanged}
      searchText={searchText}
      close={close}
    />
  );
};

export default MetricInfoPopupContainer;
