import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

const runAggregateCompoundMetricQuery = ({
  query,
  client,
}: {
  query: any;
  client: ApolloClient<NormalizedCacheObject>;
  chartDefId?: string; // For mocking purposes
  groupBy?: V5GroupBy; // For mocking purposes
  trendByCalendarInterval?: FleetOps.Interval; // // For mocking purposes
}) => {
  return client.query(query);
};

export default runAggregateCompoundMetricQuery;
