import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const createCostModel = async ({
  createArgs,
  client,
}: {
  createArgs: Costs.CreateCostModelInput;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<string> => {
  const mutation = {
    mutation: gql`
      mutation createCostModel(
        $modelType: CostModelType!
        $groupFieldName: String
        $groups: [GroupDefinitionInput!]
        $interval: Interval!
        $dateField: String!
        $startDate: Date!
        $startOfWeek: StartOfWeek
        $categories: [CostCategoryInput!]!
        $activityCostCalculationMode: ActivityCostCalculationMode
        $marginExcludeFSC: Boolean
        $marginExcludePassThru: Boolean
      ) {
        createCostModel(
          modelType: $modelType
          groupFieldName: $groupFieldName
          groups: $groups
          interval: $interval
          dateField: $dateField
          startDate: $startDate
          startOfWeek: $startOfWeek
          categories: $categories
          activityCostCalculationMode: $activityCostCalculationMode
          marginExcludeFSC: $marginExcludeFSC
          marginExcludePassThru: $marginExcludePassThru
        )
      }
    `,
    variables: {
      ...createArgs,
    },
  };

  const response = await client.mutate(mutation);
  return response.data.createCostModel;
};

export default createCostModel;
