import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';

export const ACTIVE_QUICK_FILTER_ID = 'ACTIVE_QUICK_FILTER';
export const CLOSED_QUICK_FILTER_ID = 'CLOSED_QUICK_FILTER';

const useQuickFilters = (source: string) => {
  const [quickFilters, setQuickFilters] = useState<LegacySavedFilter[]>([]);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = STORE.filterDefinitions
      .getLegacySavedFiltersRef({
        accountId: selectedAccountId,
      })
      .where('source', '==', source)
      .onSnapshot((s) => {
        const data: LegacySavedFilter[] = [];
        s.docs.forEach((d) => data.push(d.data()));
        setQuickFilters([...data]);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId, source]);

  return quickFilters;
};

export default useQuickFilters;
