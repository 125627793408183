import { useCallback, useContext, useEffect, useState } from 'react';
import visTypeCheckers from '../../../types/visTypeCheckers';
import captureException from '../../../services/captureException';
import LegacySavedFiltersContext from '../../../contexts/LegacySavedFiltersContext';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import buildFilterInput from '../../../utils/buildFilterInput';
import useToDrillDown from '../../../hooks/useToFixedFilters';
import isDefined from '../../../isDefined';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import {
  isKpiRow,
  isManualKpiInfo,
  isManualKpiRow,
  isNormalMetricInfo,
} from 'hooks/kpiTypeCheckers';
import FilterPlatesContext from '../../../contexts/FilterPlatesContext';

const useMetricsInfoFromVis = (
  vis?: ExtendedVisualisationDefinition | Metrics.Metric,
) => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const { savedFiltersLookup } = useContext(LegacySavedFiltersContext);
  const { isDefaultFiltersDisabled } = useContext(FilterPlatesContext);
  const toDrillDown = useToDrillDown();
  const getMetricsBase = useCallback((): (
    | MetricsInfoButton.NormalMetricInfo
    | MetricsInfoButton.ManualKpiInfo
  )[] => {
    if (vis === undefined) {
      return [];
    } else if (metricTypeCheckers.isMetric(vis)) {
      const metric = metricOptionsLookup[vis.id];
      if (!metric) {
        return [];
      }
      return [
        {
          type: 'NormalMetricInfo' as 'NormalMetricInfo',
          key: vis.id,
          metric,
          metricAlias: vis.name,
          isDefaultFiltersDisabled: !!isDefaultFiltersDisabled,
        },
      ];
    } else if (visTypeCheckers.isGeneralGoal(vis)) {
      const metric = metricOptionsLookup[vis.metricId];
      if (!metric) {
        return [];
      }
      return [
        {
          type: 'NormalMetricInfo' as 'NormalMetricInfo',
          key: `${vis.metricId}-${vis.id}`,
          metric,
          metricAlias: vis.title,
          additionalFilters: buildFilterInput({
            scopes: [],
            drillDowns: toDrillDown({ plates: vis.drillDowns }),
          }),
          isDefaultFiltersDisabled: !!vis.isDefaultFiltersDisabled,
        },
        ...vis.kpis
          .map((k) => {
            const metric = metricOptionsLookup[k.metricId];
            if (!metric) {
              return undefined;
            }
            return {
              type: 'NormalMetricInfo' as 'NormalMetricInfo',
              key: `${k.metricId}-${k.id}`,
              metric,
              metricAlias: k.name,
              additionalFilters: buildFilterInput({
                scopes: [],
                drillDowns: toDrillDown({ plates: vis.drillDowns }),
              }),
              isDefaultFiltersDisabled: !!k.isDefaultFiltersDisabled,
            };
          })
          .filter(isDefined),
      ];
    } else if (visTypeCheckers.isGauge(vis)) {
      const metric = metricOptionsLookup[vis.metricId];
      if (!metric) {
        return [];
      }
      return [
        {
          type: 'NormalMetricInfo' as 'NormalMetricInfo',
          key: `${vis.metricId}-${vis.id}`,
          metric,
          metricAlias: vis.metricDisplayName,
          isDefaultFiltersDisabled: !!isDefaultFiltersDisabled,
        },
      ];
    } else if (visTypeCheckers.isRankingMatrix(vis)) {
      return vis.metrics
        .map((m) => {
          const metric = metricOptionsLookup[m.metricId];
          if (!metric) {
            return undefined;
          }

          return {
            type: 'NormalMetricInfo' as 'NormalMetricInfo',
            key: `${m.metricId}-${vis.id}`,
            metric,
            metricAlias: m.displayName,
            isDefaultFiltersDisabled: !!isDefaultFiltersDisabled,
          };
        })
        .filter(isDefined);
    } else if (visTypeCheckers.isPaceMatrix(vis)) {
      const metric = metricOptionsLookup[vis.metricId];
      if (!metric) {
        return [];
      }
      return [
        {
          type: 'NormalMetricInfo' as 'NormalMetricInfo',
          key: `${vis.metricId}-${vis.id}`,
          metric,
          metricAlias: vis.metricDisplayName,
          isDefaultFiltersDisabled: !!isDefaultFiltersDisabled,
        },
      ];
    } else if (visTypeCheckers.isSingleMetricDateMatrix(vis)) {
      const metric = metricOptionsLookup[vis.metricId];
      if (!metric) {
        return [];
      }

      return [
        {
          type: 'NormalMetricInfo' as 'NormalMetricInfo',
          key: `${vis.metricId}-${vis.id}`,
          metric,
          metricAlias: vis.metricDisplayName,
          isDefaultFiltersDisabled: !!isDefaultFiltersDisabled,
        },
      ];
    } else if (visTypeCheckers.isRemindersGadget(vis)) {
      return [];
    } else if (visTypeCheckers.isMetricList(vis)) {
      return vis.listOrder
        .map((listItemId) => {
          return vis.list.find((li) => li.id === listItemId);
        })
        .filter(isDefined)
        .map((li) => {
          const metric = metricOptionsLookup[li.metricId];
          if (!metric) {
            return undefined;
          }
          const sf = li.savedFilterId
            ? savedFiltersLookup[li.savedFilterId]
            : undefined;
          const additionalFilters = sf
            ? buildFilterInput({
                scopes: toDrillDown({ plates: sf.scope }),
                drillDowns: toDrillDown({ plates: sf.drillDowns }),
              })
            : undefined;

          return {
            type: 'NormalMetricInfo' as 'NormalMetricInfo',
            key: `${li.metricId}-${li.id}`,
            metric,
            metricAlias: li.name,
            additionalFilters,
            isDefaultFiltersDisabled: !!li.isDefaultFiltersDisabled,
          };
        })
        .filter(isDefined);
    } else if (visTypeCheckers.isScorecard(vis)) {
      return vis.kpis
        .map((k) => {
          if (isKpiRow(k)) {
            const metric = metricOptionsLookup[k.metricId];
            if (!metric) {
              return undefined;
            }
            return {
              type: 'NormalMetricInfo' as 'NormalMetricInfo',
              key: `${k.metricId}-${k.id}`,
              metric,
              metricAlias: k.displayName,
              additionalFilters: buildFilterInput({
                drillDowns: toDrillDown({ plates: k.drillDowns }),
                scopes: toDrillDown({ plates: k.scopeDrillDowns }),
              }),
              isDefaultFiltersDisabled: !!k.isDefaultFiltersDisabled,
            };
          } else if (isManualKpiRow(k)) {
            return {
              type: 'ManualKpiInfo' as 'ManualKpiInfo',
              key: `${k.id}-${k.id}`,
              label: k.label,
              isDefaultFiltersDisabled: false,
            };
          } else {
            return undefined;
          }
        })
        .filter(isDefined);
    } else if (visTypeCheckers.isV5ChartDef(vis)) {
      return vis.series
        .map((s) => {
          const metric = metricOptionsLookup[s.metricId];
          if (!metric) {
            return undefined;
          }
          return {
            type: 'NormalMetricInfo' as 'NormalMetricInfo',
            key: `${s.metricId}-${s.id}`,
            metric,
            metricAlias: s.displayName,
            isDefaultFiltersDisabled: !!isDefaultFiltersDisabled,
          };
        })
        .filter(isDefined);
    } else {
      const e = new Error();
      e.name = 'Unknown vis type found';
      try {
        e.message = vis;
      } catch (_ex) {
        // do nothing
      }
      captureException(e);
      return [];
    }
  }, [
    isDefaultFiltersDisabled,
    metricOptionsLookup,
    savedFiltersLookup,
    toDrillDown,
    vis,
  ]);

  const getMetrics = useCallback((): (
    | MetricsInfoButton.NormalMetricInfo
    | MetricsInfoButton.ManualKpiInfo
  )[] => {
    const base = getMetricsBase();
    return base
      .map((mi) => {
        if (isNormalMetricInfo(mi)) {
          return {
            ...mi,
            metricAlias: mi.metricAlias === '' ? undefined : mi.metricAlias,
          };
        } else {
          return mi;
        }
      })
      .filter((m) => isManualKpiInfo(m) || m.metric !== undefined) as (
      | MetricsInfoButton.NormalMetricInfo
      | MetricsInfoButton.ManualKpiInfo
    )[];
  }, [getMetricsBase]);

  const [metrics, setMetrics] = useState<
    (MetricsInfoButton.NormalMetricInfo | MetricsInfoButton.ManualKpiInfo)[]
  >(() => getMetrics());
  useEffect(() => {
    setMetrics(getMetrics());
  }, [getMetrics, vis]);

  return metrics;
};

export default useMetricsInfoFromVis;
