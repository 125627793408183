import getIdentifier from '../../getIdentifier';

const buildCardLayout = (
  x: number,
  y: number,
  i?: string,
  w?: number,
  h?: number,
) => ({
  i: i ? i : getIdentifier(undefined, true),
  x,
  y,
  w: w ? w : 4,
  h: h ? h : 7,
  minW: 2,
  minH: 2,
  maxW: 12,
  maxH: 24,
  static: false,
});

export default buildCardLayout;
