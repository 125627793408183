import { useContext, useEffect, useState } from 'react';
import STORE from '../../../../store';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import isDefined from '../../../../isDefined';

const useUsers = () => {
  const { isWallboardUser, id } = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [users, setUsers] = useState<
    (
      | UserManagement.SignedUpUser
      | UserManagement.PendingUser
      | UserManagement.WallboardUser
    )[]
  >([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);

  useEffect(() => {
    if (isWallboardUser) {
      STORE.users
        .getUsersRef({ accountId: selectedAccountId })
        .doc(id)
        .get()
        .then((snap) => {
          const u = snap.data();
          setUsers([u].filter(isDefined));
          setIsLoadingUsers(false);
        });
      return;
    }

    let isActive = true;
    setUsers([]);
    setIsLoadingUsers(true);
    STORE.users
      .getUsersRef({ accountId: selectedAccountId })
      .onSnapshot((usersSnapshot) => {
        if (!isActive) {
          return;
        }

        const newUsers = usersSnapshot.docs.map((d) => d.data());
        setUsers(newUsers);
        setIsLoadingUsers(false);
      });

    return () => {
      isActive = false;
    };
  }, [id, isWallboardUser, selectedAccountId]);

  return {
    users,
    isLoadingUsers,
  };
};

export default useUsers;
