import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import TopNavigator from './TopNavigator';
import { CLOSED_NAV_WIDTH, OPENED_NAV_WIDTH } from '../../../navigation/styles';
import styled from 'styled-components';
import NavSideBarContext from '../../../contexts/NavSideBarContext';
import DataManagerSpecificSetProvider from '../../../contextProviders/DataManagerProviders/DataManagerSpecificSetProvider';
import PERMISSIONS from '../../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';

const OPEN_NAV_OFFSET = OPENED_NAV_WIDTH;
const CLOSED_NAV_OFFSET = CLOSED_NAV_WIDTH;

const Wrapper = styled.div<{ isMainNavOpen: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  width: calc(
    100vw -
      ${(props) =>
        props.isMainNavOpen ? OPEN_NAV_OFFSET : CLOSED_NAV_OFFSET}px
  );

  background-color: white;
`;

const SpecificSet = () => {
  const { isOpen: isMainNavOpen } = useContext(NavSideBarContext);
  return (
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.DATA_MANAGEMENT.DATA_MANAGER_ACCESS}
    >
      <div id="data-manager-start" data-testid="data-manager-start">
        <DataManagerSpecificSetProvider>
          <Wrapper isMainNavOpen={isMainNavOpen}>
            <TopNavigator />
            <Outlet />
          </Wrapper>
        </DataManagerSpecificSetProvider>
      </div>
    </PermissionGates.Has>
  );
};

export default SpecificSet;
