import React, { useCallback } from 'react';
import Typography from 'kingpin/atoms/Typography';
import DetailsCard from 'screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/DetailsCard';
import { Item } from './SingleItemPicker';
import Tooltip from './Tooltip';

const SavedFilterPickerItem = ({
  filter,
  onOptionSelected,
}: {
  filter: SavedFilter;
  onOptionSelected: (filter: SavedFilter) => void;
}) => {
  const handleOptionSelected = useCallback(() => {
    onOptionSelected(filter);
  }, [filter, onOptionSelected]);

  return (
    <Tooltip
      isAltTooltip
      position="top"
      content={<DetailsCard filter={filter} />}
    >
      <Item
        data-testid={`select-dataset-filter-${filter.name}`}
        key={filter.id}
        onClick={handleOptionSelected}
      >
        <Typography.Body type="Body 12" isEllipsis>
          {filter.name}
        </Typography.Body>
      </Item>
    </Tooltip>
  );
};

export default SavedFilterPickerItem;
