import React, { useContext, useEffect, useState } from 'react';
import DashboardMetricUsageLookupContext from '../../../contexts/DashboardMetricUsageLookupContext';
import DashboardsContext from '../../../contexts/DashboardsContext';
import DashboardGadgetsContext from '../../../contexts/DashboardGadgetsContext';
import ScorecardsContext from '../../../contexts/ScorecardsContext';
import MetricListsContext from '../../../contexts/MetricListsContext';
import cardTypeCheckers from '../../../types/cardTypeCheckers';
import useGetMetricIdsFromVis from 'screens/DataManager/DatasetFilters/SavedFilters/FilterSlideOut/UsageTab/hooks/useGetMetricIdsFromVis';

const addUsage = ({
  currentLookup,
  metricId,
  usedIn,
  dashboard,
  gadgetType,
  gadgetName,
  gadgetId,
}: {
  currentLookup: FleetOps.DashboardMetricUsageLookup;
  metricId: string;
  usedIn: DashboardGadget | MetricListGadgetType | Scorecards.Scorecard;
  dashboard: DashboardType;
  gadgetId: string;
  gadgetName: string;
  gadgetType: 'scorecard' | 'chartDef' | 'metricList';
}) => {
  const usageList = currentLookup[metricId] || [];
  usageList.push({
    dashboard,
    usedIn,
    gadgetId,
    gadgetName,
    gadgetType,
  });

  currentLookup[metricId] = usageList;
};

const DashboardMetricUsageLookupProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { allDashboards } = useContext(DashboardsContext);
  const { dashboardGadgetsLookup } = useContext(DashboardGadgetsContext);
  const { scorecardsLookup } = useContext(ScorecardsContext);
  const { metricListsLookup } = useContext(MetricListsContext);
  const [lookup, setLookup] = useState<FleetOps.DashboardMetricUsageLookup>({});
  const getMetricIds = useGetMetricIdsFromVis();

  useEffect(() => {
    const newLookup = {} as FleetOps.DashboardMetricUsageLookup;
    allDashboards.forEach((d) => {
      d.canvas.cards.forEach((card) => {
        if (cardTypeCheckers.isDashboardGadget(card)) {
          const gadget = dashboardGadgetsLookup[card.content.dashboardGadgetId];
          if (!gadget) {
            return;
          }

          const usedMetrics = getMetricIds(gadget.chartDef);
          usedMetrics.forEach((metric) => {
            addUsage({
              currentLookup: newLookup,
              dashboard: d,
              metricId: metric,
              usedIn: gadget,
              gadgetId: gadget.id,
              gadgetName: gadget.chartDef.name,
              gadgetType: 'chartDef',
            });
          });
        } else if (cardTypeCheckers.isMetricList(card)) {
          const metricList = metricListsLookup[card.content.metricListId];
          if (!metricList) {
            return;
          }

          const usedMetrics = getMetricIds(metricList);
          usedMetrics.forEach((metric) => {
            addUsage({
              currentLookup: newLookup,
              dashboard: d,
              metricId: metric,
              usedIn: metricList,
              gadgetId: metricList.id,
              gadgetName: metricList.name,
              gadgetType: 'metricList',
            });
          });
        } else if (cardTypeCheckers.isScorecard(card)) {
          const scorecard = scorecardsLookup[card.content.scorecardId];
          if (!scorecard) {
            return;
          }
          const usedMetrics = getMetricIds(scorecard);
          usedMetrics.forEach((metric) => {
            addUsage({
              currentLookup: newLookup,
              dashboard: d,
              metricId: metric,
              usedIn: scorecard,
              gadgetId: scorecard.id,
              gadgetName: scorecard.title,
              gadgetType: 'scorecard',
            });
          });
        }
      });
    });

    setLookup(newLookup);
  }, [
    allDashboards,
    dashboardGadgetsLookup,
    getMetricIds,
    metricListsLookup,
    scorecardsLookup,
  ]);

  return (
    <DashboardMetricUsageLookupContext.Provider
      value={{ dashboardMetricUsageLookup: lookup }}
    >
      {children}
    </DashboardMetricUsageLookupContext.Provider>
  );
};

export default DashboardMetricUsageLookupProvider;
