import { useContext, useEffect, useState } from 'react';
import RolesContext from '../../../../contexts/RolesContext';
import PERMISSIONS from '../../../../permissionDefinitions';

const ADMIN_PORTAL: AdminPortal = {
  id: 'admin-portal',
  name: 'Admin Portal',
  color: 'grey',
  type: 'Admin Portal',
  updatedBy: '',
  updatedOn: '',
  createdOn: '',
  createdBy: '',
  urlSlug: 'admin',
};

const useAdminPortal = () => {
  const { currentPermissions } = useContext(RolesContext);
  const [adminPortal, setAdminPortal] = useState<AdminPortal | undefined>(
    undefined,
  );

  useEffect(() => {
    if (
      currentPermissions.includes(PERMISSIONS.CONTENT_ACCESS.VIEW_ADMIN_PORTAL)
    ) {
      setAdminPortal(ADMIN_PORTAL);
    } else {
      setAdminPortal(undefined);
    }
  }, [currentPermissions]);

  return adminPortal;
};

export default useAdminPortal;
