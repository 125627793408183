import { useCallback, useContext, useEffect, useState } from 'react';

import GqlClientContext from 'contexts/GqlClientContext';
import PeriodsContext from 'contexts/PeriodsContext';
import useLockedDebouncedEffect from 'hooks/useLockedDebouncedEffect';

import PerformanceBoardSlideOutContext from '../../../contexts/PerformanceBoardSlideOutContext';
import usePerformanceSlideOutFilterInput from '../../../hooks/usePerformanceSlideOutFilterInput';
import getGridData from '../api/getGridData';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

interface GetDocumentParams {
  dataType: string;
  filters: FilterInput[];
  dateScope: DateRangeInput;
  sortBy: SortField[];
  client: ApolloClient<NormalizedCacheObject>;
}

interface GetDocumentResponse {
  count: number;
  searchAfter: string;
  documents: ElasticDocument[];
}

const useSelectedDoc = () => {
  const { client } = useContext(GqlClientContext);
  const { performanceDataset } = useContext(PerformanceBoardSlideOutContext);
  const filterInput = usePerformanceSlideOutFilterInput();
  const { selectedPeriod } = useContext(PeriodsContext);
  const [selectedDoc, setSelectedDoc] = useState<ElasticDocument | undefined>();

  const [dateScope, setDateScope] = useState<DateRangeInput | undefined>(
    selectedPeriod
      ? {
          startDate: selectedPeriod.startDate,
          endDate: selectedPeriod.endDate,
        }
      : undefined,
  );

  useEffect(() => {
    setDateScope(
      selectedPeriod
        ? {
            startDate: selectedPeriod.startDate,
            endDate: selectedPeriod.endDate,
          }
        : undefined,
    );
  }, [selectedPeriod]);

  const getArgs = useCallback((): GetDocumentParams | undefined => {
    if (!dateScope) {
      return undefined;
    }
    if (!filterInput) {
      return undefined;
    }

    return {
      dataType: performanceDataset,
      client,
      dateScope,
      filters: [filterInput],
      sortBy: [],
    };
  }, [client, dateScope, filterInput, performanceDataset]);

  const [args, setArgs] = useState<GetDocumentParams | undefined>(() =>
    getArgs(),
  );
  useEffect(() => {
    setArgs(getArgs());
  }, [getArgs]);

  const responseHandler = useCallback((response: GetDocumentResponse) => {
    if (response.documents.length === 0) {
      setSelectedDoc(undefined);
      return;
    }

    if (response.documents.length > 1) {
      setSelectedDoc(undefined);
      return;
    }

    setSelectedDoc(response.documents[0]);
  }, []);

  const callback = useCallback(
    (params: GetDocumentParams): Promise<GetDocumentResponse> => {
      setSelectedDoc(undefined);
      return getGridData(params);
    },
    [],
  );

  useLockedDebouncedEffect({
    args,
    callback,
    responseHandler,
  });

  return {
    isLoading: selectedDoc === undefined,
    selectedDoc,
  };
};

export default useSelectedDoc;
