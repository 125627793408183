import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../navigation/appRoutes';
import InlineDialog from '../../components/InlineDialog';
import usePopup from '../../hooks/usePopup';
import NavItem from './NavItem';
import { ContextMenuDiv, Option } from '../atoms/ContextMenu';
import ContextMenuContext from 'kingpin/atoms/ContextMenu/ContextMenuContext';

const ProfileButton = () => {
  const { isOpen, open, close } = usePopup();
  const navigate = useNavigate();
  const onMyProfileClicked = useCallback(() => {
    navigate(appRoutes.loggedIn.myAccount);
    close();
  }, [close, navigate]);
  const onSignOutClicked = useCallback(() => {
    navigate(appRoutes.loggedIn.signOut);
    close();
  }, [close, navigate]);

  // Sorry about the random 36, under time pressure
  return (
    <div style={{ height: 36, marginTop: '2px' }}>
      <ContextMenuContext.Provider
        value={{
          closeMenu: close,
          hideMenu: close,
        }}
      >
        <InlineDialog
          content={
            <ContextMenuDiv>
              <Option label={'My Profile'} onClick={onMyProfileClicked} />
              <Option label={'Sign Out'} onClick={onSignOutClicked} />
            </ContextMenuDiv>
          }
          isOpen={isOpen}
          onClose={close}
        >
          <NavItem
            to={'#'}
            isActiveOverride={false}
            icon={'account-circle'}
            iconColor={'#666666'}
            onClick={open}
          />
        </InlineDialog>
      </ContextMenuContext.Provider>
    </div>
  );
};

export default ProfileButton;
