import Flash from '../../../Flash';
import React, { useEffect, useState } from 'react';
import getTimeStamp from '../../../../getTimeStamp';
import useUser from '../../../../hooks/useUser';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import getUserDisplayName from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/getUserDisplayName';

const useLastUpdateNotification = (metrics: Metrics.Metric[]) => {
  const [isFlashVisible, setIsFlashVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [lastUpdatedMetric, setLastUpdatedMetric] = useState<
    Metrics.NormalMetric | Metrics.CompoundMetric | undefined
  >();
  const [lastUpdateAt, setLastUpdateAt] = useState<string>(() =>
    getTimeStamp(),
  );
  const updater = useUser(
    lastUpdatedMetric ? lastUpdatedMetric.updatedBy : undefined,
  );

  useEffect(() => {
    const updatedMetric = metrics.find((m) => {
      if (metricTypeCheckers.isSpecialMetric(m)) {
        return false;
      }

      return m.updatedOn > lastUpdateAt;
    });
    if (!updatedMetric) {
      return;
    }

    if (metricTypeCheckers.isSpecialMetric(updatedMetric)) {
      setLastUpdatedMetric(undefined);
      setLastUpdateAt(getTimeStamp());
    } else {
      setLastUpdatedMetric(updatedMetric);
      setLastUpdateAt(getTimeStamp());
    }
  }, [metrics, lastUpdateAt]);

  useEffect(() => {
    if (!lastUpdatedMetric || !updater.user || updater.isLoading) {
      return;
    }

    setIsFlashVisible(true);
    setMessage(
      `${lastUpdatedMetric.name} was edited by ${getUserDisplayName(
        updater.user,
      )}`,
    );
  }, [lastUpdatedMetric, updater.isLoading, updater.user]);

  useEffect(() => {
    if (isFlashVisible) {
      return;
    }

    setMessage('');
  }, [isFlashVisible]);

  return { isFlashVisible, setIsFlashVisible, message };
};

const MetricUpdateBanner = ({ metrics }: { metrics: Metrics.Metric[] }) => {
  const { isFlashVisible, setIsFlashVisible, message } =
    useLastUpdateNotification(metrics);

  return (
    <div style={{ position: 'absolute', width: '100%', left: 0 }}>
      <Flash
        isFlashVisible={isFlashVisible}
        setIsFlashVisible={setIsFlashVisible}
        type="success"
        message={message}
      />
    </div>
  );
};

export default MetricUpdateBanner;
