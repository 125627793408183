import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  AgGridEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community';

import useMetricMatrix from '../../../../hooks/useMetricMatrix';
import Grid from '../../../Grid/Grid';

import buildGridOptions from '../../../Grid/buildGridOptions';
import useChartDrillDowns from '../../../../hooks/useChartDrillDowns';
import GridRowCountContext from 'contexts/GridRowCountContext';
import useRowHighlighter from 'hooks/useRowHighlighter';
import isHistrogramicMatrix from '../isHistogramicMatrix';
import HideUnlessMouseOver from '../../../HideUnlessMouseOver';
import useWindowSize from '../../../../hooks/useWindowSize';
import MetricMatrixContext from '../../../../contexts/MetricMatrixContext';
import useIsCompatibleComparison from '../../../../hooks/useIsCompatableComparison';
import IncompatibleComparison from '../../../IncompatibleComparison';
import { RowHeightContext } from '../../../../contextProviders/SplashScreenProviders/MiscProviders/RowHeightProvider';
const aguid = require('aguid');

const MatrixContentContainer = ({
  chartDefinition,
  gridApi,
  setGridApi,
}: {
  chartDefinition: V5ChartDefinition;
  gridApi?: GridApi;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
}) => {
  const { rowHeight } = useContext(RowHeightContext);
  const isCompatibleComparison = useIsCompatibleComparison(chartDefinition);
  const [gridId] = useState<string>(() => aguid());
  const [def, setDef] = useState<V5ChartDefinition>(chartDefinition);
  const [gridOptions, setGridOptions] = useState<GridOptions>();
  const [currentSortBy, setCurrentSortBy] = useState<
    V5ChartDefinitionSeries | undefined
  >();
  useEffect(() => {
    setCurrentSortBy(def.series.find((s) => s.sortByMetricId));
  }, [def.series]);

  const { isLoading, isInitialLoading, rowsToDisplay, columnDefs, totals } =
    useMetricMatrix({
      chartDef: def,
      currentSortBy: currentSortBy ? currentSortBy.metricId : undefined,
    });

  useEffect(() => {
    if (gridApi) {
      if (isLoading) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [gridApi, isLoading]);

  const isTwoDimensional =
    !!chartDefinition.dimensionA && !!chartDefinition.dimensionB;

  const drillField = (() => {
    if (chartDefinition.dimensionA) {
      if (chartDefinition.dimensionA.field === 'date') {
        return chartDefinition.trendByCalendarInterval || 'day';
      }
      return chartDefinition.dimensionA.field;
    }
    return '';
  })();

  const { onDrillDown } = useChartDrillDowns(
    drillField,
    undefined,
    'matrix',
    chartDefinition,
  );

  const { isMobile } = useWindowSize();

  useRowHighlighter({
    gridApi,
    drillField,
    visId: gridId,
  });

  useEffect(() => {
    if (gridApi) {
      gridApi.refreshCells();
    }
  }, [drillField, onDrillDown, gridApi]);

  useEffect(() => {
    setDef(chartDefinition);
  }, [chartDefinition, setDef]);

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('rowData', rowsToDisplay);
    }
  }, [gridApi, rowsToDisplay]);

  useEffect(() => {
    setGridOptions({
      ...buildGridOptions({
        columnDefs,
        rowModelType: 'clientSide',
        rowHeight,
      }),
      context: {
        onDrillDown,
      },
      rowSelection: 'multiple',
      suppressCellFocus: true,
      suppressRowClickSelection: true,
    });
  }, [columnDefs, onDrillDown, rowHeight]);

  const noDataToDisplay = rowsToDisplay.length === 0;

  const onSortBy = useCallback(
    (sortCol: string, sortOrder: SortBy) => {
      if (isTwoDimensional) {
        return;
      }
      const isGroupingSort = sortCol === drillField;
      if (isGroupingSort) {
        setDef({
          ...def,
          series: def.series.map((s) => ({ ...s, sortByMetricId: false })),
          groupBySortBy: sortOrder,
        });
      }

      const metricSeries = def.series.find((s) => s.metricId === sortCol);
      if (!metricSeries) {
        return;
      }

      if (currentSortBy) {
        setDef({
          ...def,
          groupBySortBy: sortOrder,
          series: def.series.map((s) => {
            if (s.metricId === metricSeries.metricId) {
              return {
                ...s,
                sortByMetricId: true,
              };
            }
            if (s.metricId === currentSortBy.metricId) {
              return {
                ...s,
                sortByMetricId: false,
              };
            }
            return s;
          }),
        });
      } else {
        setDef({
          ...def,
          groupBySortBy: sortOrder,
          series: def.series.map((s) => {
            if (s.id === metricSeries.id) {
              return {
                ...s,
                sortByMetricId: true,
              };
            }
            return s;
          }),
        });
      }
    },
    [currentSortBy, def, drillField, isTwoDimensional],
  );

  const onSortByRemoved = useCallback(() => {
    setDef({
      ...def,
      series: def.series.map((s) => ({ ...s, sortByMetricId: false })),
      groupBySortBy: undefined,
    });
  }, [def]);

  const getDefaultSortModel = useCallback(() => {
    const sortedSeries = def.series.find((s) => !!s.sortByComparison);
    const serverSortSeries = def.series.some((s) => s.sortByMetricId);

    if (!sortedSeries || !sortedSeries.sortByComparison || serverSortSeries) {
      return undefined;
    }
    const isPercentDelta = sortedSeries.sortByComparison.includes('percent');
    const colId = `${sortedSeries.metricId}-${
      isPercentDelta ? 'percentDelta' : 'delta'
    }`;
    const sort = sortedSeries.sortByComparison.includes('asc')
      ? ('asc' as 'asc')
      : ('desc' as 'desc');
    return [
      {
        colId,
        sort,
      },
    ];
  }, [def.series]);

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api);
      const defaultSortModel = getDefaultSortModel();

      if (defaultSortModel) {
        event.api.applyColumnState({
          state: defaultSortModel,
        });
      }

      event.api.addEventListener('sortChanged', (event: AgGridEvent) => {
        const sortedCol = event.columnApi
          .getColumnState()
          .find((c) => !!c.sort);
        if (
          sortedCol &&
          (sortedCol.sort === 'asc' || sortedCol.sort === 'desc')
        ) {
          onSortBy(sortedCol.colId, sortedCol.sort);
        } else {
          onSortByRemoved();
        }
      });
    },
    [getDefaultSortModel, onSortBy, onSortByRemoved, setGridApi],
  );

  const isHistrogramic = isHistrogramicMatrix(chartDefinition);

  if (!gridOptions) {
    return null;
  }

  if (!isCompatibleComparison) {
    return <IncompatibleComparison />;
  }

  return (
    <MetricMatrixContext.Provider
      value={{
        onDrillDown,
      }}
    >
      <GridRowCountContext.Provider value={{ rowCount: rowsToDisplay.length }}>
        <HideUnlessMouseOver>
          <Grid
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            totalsRow={totals}
            gridId={gridId}
            noDataToDisplay={noDataToDisplay}
            isLoading={isInitialLoading}
            autoSizeFirstColumnOnly={isHistrogramic || isMobile ? false : true}
            layoutOnFirstRender={true}
            layoutOnColumnChange={true}
            layoutOnModelUpdated={true}
            hasBorders={false}
            hasDarkHeader={false}
          />
        </HideUnlessMouseOver>
      </GridRowCountContext.Provider>
    </MetricMatrixContext.Provider>
  );
};

export default MatrixContentContainer;
