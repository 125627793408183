import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import AccountPickerContext from '../../contexts/AccountPickerContext';
import STORE from '../../store';
import FeatureGatesContext from '../../contexts/FeatureGatesContext';

const addRogueVariableFiltersToTemplates = (
  baseDashboards: DashboardType[],
): DashboardType[] => {
  const newDashboards = baseDashboards.map((d1) => {
    if (!d1.isTemplate) {
      return d1;
    }

    const instances = baseDashboards.filter(
      (d2) => d2.templateId && d2.templateId === d1.id,
    );
    const allVariableDrills = instances.reduce((a, b) => {
      return [...a, ...b.variableDrillDowns];
    }, d1.variableDrillDowns);
    return {
      ...d1,
      variableDrillDowns: _.uniqBy(allVariableDrills, 'id'),
    };
  });

  return newDashboards;
};

const useDashboards = () => {
  const [dashboards, setDashboards] = useState<DashboardType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { isTemplatesEnabled } = useContext(FeatureGatesContext);

  useEffect(() => {
    const listener = STORE.getDashboardsRef({
      accountId: selectedAccountId,
    }).onSnapshot((snapshot) => {
      const data: DashboardType[] = [];
      snapshot.docs.forEach((d) => data.push(d.data()));
      const baseDashboards = data
        .filter((d) => d.isHidden !== true)
        .filter((d) => {
          if (d.isTemplate || d.templateId) {
            return isTemplatesEnabled;
          }
          return true;
        });
      setDashboards(addRogueVariableFiltersToTemplates(baseDashboards));
      setIsLoading(false);
    });
    return () => {
      listener();
    };
  }, [isTemplatesEnabled, selectedAccountId]);

  const addOrUpdateDashboard = useCallback((newDashboard: DashboardType) => {
    setDashboards((ds) => {
      if (ds.some((d) => d.id === newDashboard.id)) {
        return ds.map((d) => {
          if (d.id === newDashboard.id) {
            return newDashboard;
          } else {
            return d;
          }
        });
      } else {
        return [...ds, newDashboard];
      }
    });
  }, []);

  return {
    dashboards,
    addOrUpdateDashboard,
    isLoading,
  };
};

export default useDashboards;
