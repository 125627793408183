import React from 'react';
import Alert from '../../../kingpin/Alert';

const DefaultFiltersDisabledAlert = ({
  metricInfo,
}: {
  metricInfo: MetricsInfoButton.NormalMetricInfo;
}) => {
  if (!metricInfo.isDefaultFiltersDisabled) {
    return null;
  }

  return (
    <div style={{ marginTop: 8 }}>
      <Alert type="Info" title={'Not filtered by dataset level filters'} />
    </div>
  );
};

export default DefaultFiltersDisabledAlert;
