import React from 'react';
import PermissionGates from '../PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';

import ContextMenu, { Option, Break, Info } from 'kingpin/atoms/ContextMenu';

const ReportMenu = ({
  onStartEditing,
  openWidgetGallery,
  onOpenSettings,
  onSaveAs,
  onDelete,
  isValidationReport,
  onValidationReportRemoved,
  report,
}: {
  onStartEditing: () => void;
  openWidgetGallery: () => void;
  onOpenSettings: () => void;
  onSaveAs: () => void;
  onDelete: () => void;
  isValidationReport: boolean;
  onValidationReportRemoved: () => void;
  report?: ReportType;
}) => (
  <ContextMenu testId={'report-menu'}>
    <>
      <PermissionGates.Has
        requiredPermission={
          PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC
        }
      >
        <Option label={'Settings'} onClick={onOpenSettings} />
      </PermissionGates.Has>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.REPORT_MANAGEMENT.EDIT_LAYOUT}
      >
        <Option label={'Edit'} onClick={onStartEditing} />
      </PermissionGates.Has>
      <PermissionGates.Has
        requiredPermission={
          PERMISSIONS.REPORT_MANAGEMENT.ADD_REMOVE_VISUALISATIONS
        }
      >
        <Option label={'Add widget'} onClick={openWidgetGallery} />
      </PermissionGates.Has>
      {isValidationReport && (
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.DATA_MANAGEMENT.ADD_VALIDATION_REPORT}
        >
          <Option
            label={'Remove validation report'}
            onClick={onValidationReportRemoved}
          />
        </PermissionGates.Has>
      )}
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.REPORT_MANAGEMENT.COPY}
      >
        <Option label={'Save as'} onClick={onSaveAs} />
      </PermissionGates.Has>
      {!!report && <Info {...report} />}
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.REPORT_MANAGEMENT.DELETE}
      >
        <Break />
        <Option label={'Delete'} onClick={onDelete} isDanger />
      </PermissionGates.Has>
    </>
  </ContextMenu>
);

export default ReportMenu;
