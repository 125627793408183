import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import userTypeCheckers from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';

const converter = {
  toFirestore(
    pendingUser:
      | UserManagement.SignedUpUser
      | UserManagement.PendingUser
      | UserManagement.WallboardUser,
  ): firebase.firestore.DocumentData {
    return pendingUser;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ):
    | UserManagement.SignedUpUser
    | UserManagement.PendingUser
    | UserManagement.WallboardUser {
    const data = {
      ...snapshot.data(options),
    };

    const user = data as
      | UserManagement.SignedUpUser
      | UserManagement.PendingUser
      | UserManagement.WallboardUser;

    if (userTypeCheckers.isWallboardUser(user)) {
      return user;
    }

    if (!user.displayName) {
      user.displayName = '';
    }

    if (!user.ui) {
      user.ui = 'legacy';
    }

    return user;
  },
};

const getUsersRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.USERS_COLLECTION)
    .withConverter(converter);
};

export default getUsersRef;
