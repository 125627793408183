import { useCallback, useContext } from 'react';
import _ from 'lodash';

import isGeneralBoard from '../../../../isBoardWithSlideOut';
import isCustomerLaneBoard from '../../../../isCustomerLaneBoard';
import isDriverPerformanceBoard from '../../../../isDriverPerformanceBoard';
import captureException from '../../../../services/captureException';
import BoardsContext from '../../../../contexts/BoardsContext';
import isPerformanceBoard from '../../../../isPerformanceBoard';
import sectionTypeCheckers from 'components/PerformanceBoards/PerformanceBoardSlideOut/sectionTypeCheckers';

const useGetBoardUsage = () => {
  const { boards } = useContext(BoardsContext);

  const getBoardUsage = useCallback(
    (
      metric: Metrics.NormalMetric | Metrics.CompoundMetric,
    ): (Board | PerformanceBoardTypes.Board)[] => {
      const usage = [] as (Board | PerformanceBoardTypes.Board)[];
      boards.forEach((b) => {
        if (isPerformanceBoard(b)) {
          const isUsed = b.slideout.some(
            (section) =>
              sectionTypeCheckers.isMetricSection(section) &&
              section.metricIds.includes(metric.id),
          );
          if (isUsed) {
            usage.push(b);
          }
        } else if (
          isGeneralBoard(b) ||
          isCustomerLaneBoard(b) ||
          isDriverPerformanceBoard(b)
        ) {
          if (b.metricIds && b.metricIds.some((mid) => mid === metric.id)) {
            usage.push(b);
          }
        } else {
          const error = new Error();
          error.name = `Unknown board type`;
          captureException(error);
        }
      });
      return _.uniqBy(usage, 'id');
    },
    [boards],
  );

  return getBoardUsage;
};

export default useGetBoardUsage;
