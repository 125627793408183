import { useCallback, useContext } from 'react';
import aguid from 'aguid';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import useSetDrillDowns from './useSetDrillDowns';
import useIsReportOrDashboardCard from './useIsReportOrDashboardCard';

const useOnVariableFilterAdded = ({
  close,
  drillDown,
  isScope,
}: {
  close: () => void;
  drillDown?: FixedFilter;
  isScope: boolean;
}) => {
  const { isDashboardGadget, isReport } = useIsReportOrDashboardCard();
  const setDrillDowns = useSetDrillDowns({ isScope });
  const { trackEvent } = useContext(AnalyticsContext);

  const onVariableFilterAdded = useCallback(
    (vf: VariableDrillDownType) => {
      if (isReport) {
        if (isScope) {
          trackEvent('Report - Edit - Scope Changed (Variable Filter)');
        } else {
          trackEvent('Report - Filter Changed (Variable Filter)');
        }
      }
      if (isDashboardGadget) {
        trackEvent('Dashboard Card - Edit - Filter Changed (Variable Filter)');
      }

      const filterPlate = {
        id: drillDown ? drillDown.id : aguid(),
        type: 'Variable',
        variableId: vf.id,
      } as FilterPlateType;
      if (drillDown === undefined) {
        setDrillDowns((currentDrillDowns) => [
          ...currentDrillDowns,
          filterPlate,
        ]);
      } else {
        setDrillDowns((currentDrillDowns) =>
          currentDrillDowns.map((d) => {
            if (d.id === filterPlate.id) {
              return filterPlate;
            } else {
              return d;
            }
          }),
        );
      }

      close();
    },
    [
      close,
      drillDown,
      isDashboardGadget,
      isReport,
      isScope,
      setDrillDowns,
      trackEvent,
    ],
  );

  return onVariableFilterAdded;
};

export default useOnVariableFilterAdded;
