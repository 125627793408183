import { useCallback, useContext } from 'react';
import metricTypeCheckers from '../types/metricTypeCheckers';
import isDefined from '../isDefined';
import MetricOptionsContext from '../contexts/MetricOptionsContext';

const useGetMetricDataTypes = () => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);

  const getMetricDataTypes = useCallback(
    (metricId: string): string[] => {
      if (!metricId) {
        return [];
      }

      const metric = metricOptionsLookup[metricId];
      if (!metric) {
        return [];
      }

      if (metricTypeCheckers.isCompoundMetric(metric)) {
        return metric.metricIds
          .map((id) => metricOptionsLookup[id])
          .filter(isDefined)
          .filter(metricTypeCheckers.isNotCompoundMetric)
          .map((m) => m.dataType);
      }
      return [metric.dataType];
    },
    [metricOptionsLookup],
  );

  return getMetricDataTypes;
};

export default useGetMetricDataTypes;
