import React from 'react';
import WallboardsContext from 'contexts/WallboardsContext';
import useWallBoards from '../../../hooks/useWallBoards';

const WallboardsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { wallBoards, isLoading } = useWallBoards();

  return (
    <WallboardsContext.Provider
      value={{
        wallBoards,
        isLoading,
      }}
    >
      {children}
    </WallboardsContext.Provider>
  );
};

export default WallboardsProvider;
