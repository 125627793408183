import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = (input: Queries.AggregateMetricsGroupByInput) => {
  return {
    query: gql`
      query aggregateMetricsGroupBy(
        $metrics: [MetricInput]!
        $expressions: [Expression2!]
        $dateScope: [DateRangeInput!]!
        $filters: [FilterInput]!
        $ranking: [RankInput!]
        $groupBy: GroupBy2!
      ) {
        aggregateMetricsGroupBy(
          metrics: $metrics
          expressions: $expressions
          dateScope: $dateScope
          filters: $filters
          ranking: $ranking
          groupBy: $groupBy
        ) {
          terms
          totals
        }
      }
    `,
    variables: {
      metrics: input.metrics,
      expressions: input.expressions,
      dateScope: input.dateScope,
      filters: input.filters,
      ranking: input.ranking,
      groupBy: input.groupBy,
    },
  };
};

const aggregateMetricsGroupByInput = async ({
  client,
  input,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  input: Queries.AggregateMetricsGroupByInput;
}): Promise<Queries.AggregateMetricsGroupByResponse> => {
  const query = buildQuery(input);
  const response = await client.query(query);
  return response.data.aggregateMetricsGroupBy;
};

export default aggregateMetricsGroupByInput;
