import React from 'react';
import MetricsContext from 'contexts/MetricsContext';
import useMetrics from '../../../hooks/useMetrics';

const MetricsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { metrics, isLoadingMetrics } = useMetrics();

  return (
    <MetricsContext.Provider
      value={{
        allMetrics: metrics,
        isLoading: isLoadingMetrics,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};
export default MetricsProvider;
