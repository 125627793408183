import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = () => ({
  query: gql`
    query GetDatasetInfo {
      getDatasetInfo {
        name
        latestRecordTimeStamp
      }
    }
  `,
});

const getDatasetInfo = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<{ name: string; latestRecordTimeStamp: string }[]> => {
  const query = buildQuery();
  const response = await client.query(query);
  return response.data.getDatasetInfo;
};

export default getDatasetInfo;
