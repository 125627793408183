const isVariable = (filter: FilterPlateType): filter is VariableFilterPlate =>
  filter.type === 'Variable';

const isFixed = (filter: FilterPlateType): filter is FixedFilterPlate =>
  filter.type === 'Fixed';

const isSavedFilter = (filter: FilterPlateType): filter is SavedFilterPlate =>
  filter.type === 'Dataset';

const isNotSavedFilter = (
  filter: FilterPlateType,
): filter is VariableFilterPlate | FixedFilterPlate => !isSavedFilter(filter);

const isNotVariable = (
  filter: FilterPlateType,
): filter is SavedFilterPlate | FixedFilterPlate => !isVariable(filter);

const filterPlateTypeCheckers = {
  isVariable,
  isFixed,
  isSavedFilter,
  isNotSavedFilter,
  isNotVariable,
};

export default filterPlateTypeCheckers;
