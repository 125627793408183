import React from 'react';
import Typography from 'kingpin/atoms/Typography';

import styled from 'styled-components';
import VariableButton from './VariableButton';
import ShowOnMouseOverUnlessTour from '../../ShowOnMouseOverUnlessTour';
import Colors2 from '../../../theme/Colors2';
import { FILTER_PLATE_HEIGHT } from './Interactive';

const PlateStyle = styled.div`
  height: ${FILTER_PLATE_HEIGHT}px;
  padding: 0px 16px;

  cursor: not-allowed;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: #f6f6f6;
  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);

  display: flex;
  flex-direction: row;
  align-items: center;

  height: 32px;
  max-width: 250px;

  .showOnMouseOver {
    width: 0px;
    visibility: hidden;
  }

  .visible {
    width: 78px;
    margin-left: 8px;
  }

  &:hover {
    .showOnMouseOver {
      width: unset;
      visibility: visible;
    }
  }
`;

const Plate = ({
  description,
  onSetToFixed,
  onSetToVariable,
  isVariable,
  isTemplate,
}: {
  description: string;
  onSetToFixed: () => void;
  onSetToVariable: () => void;
  isVariable: boolean;
  isTemplate: boolean;
}) => (
  <PlateStyle>
    <Typography.Body type={'Label'} color={Colors2.Grey['5']} isEllipsis>
      {description}
    </Typography.Body>
    {isTemplate && (
      <React.Fragment>
        {isVariable && (
          <div className="visible">
            <VariableButton isVariable={isVariable} onClick={onSetToFixed}>
              <Typography.Body type={'Body 12'}>Is Variable</Typography.Body>
            </VariableButton>
          </div>
        )}
        {!isVariable && (
          <ShowOnMouseOverUnlessTour>
            <VariableButton isVariable={isVariable} onClick={onSetToVariable}>
              <Typography.Body type={'Body 12'}>Is Variable</Typography.Body>
            </VariableButton>
          </ShowOnMouseOverUnlessTour>
        )}
      </React.Fragment>
    )}
  </PlateStyle>
);

export default Plate;
