import React, { useEffect, useState } from 'react';
import UsersContext from '../../../../contexts/UsersContext';
import useUsers from './useUsers';
import useStaffUsers from './useStaffUsers';

const UsersProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [allUsers, setAllUsers] = useState<UserManagement.User[]>([]);
  const { isLoadingUsers, users } = useUsers();
  const { isLoadingStaffUsers, staffUsers } = useStaffUsers();

  useEffect(() => {
    setAllUsers([...staffUsers, ...users]);
  }, [staffUsers, users]);

  return (
    <UsersContext.Provider
      value={{
        allUsers,
        isLoading: isLoadingUsers || isLoadingStaffUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;
