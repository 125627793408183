import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import Colors from 'theme/colors';
import useQueryParams from 'hooks/useQueryParams';
import CloudFunctionClientContext from 'contexts/CloudFunctionClientContext';
import captureException from 'services/captureException';
import { buildSsoConfigurationsSettings } from 'navigation/appRoutes';
import postPermissionGranted from './api/postPermissionGranted';
import { SSO_SETUP_ERROR_MSG_KEY } from '../../../Login/ErrorBanner';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import { PortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const ScreenContent = styled.div`
  display: flex;
  flex: 1;
  padding: 32px;
  background-color: ${Colors.BLUE_GREY};
  justify-content: center;
  align-items: center;
`;

const useResponseQueryParams = () => {
  const query = useQueryParams();
  const getParam = useCallback(
    (name: string) => {
      if (query.has(name)) {
        return query.get(name) as string;
      }
      return undefined;
    },
    [query],
  );

  const getQueryParams = useCallback(() => {
    return {
      tenantId: getParam('tenant'),
      adminConsent: getParam('admin_consent'),
      error: getParam('error'),
    };
  }, [getParam]);

  const [queryParams, setQueryParams] = useState<{
    tenantId?: string;
    adminConsent?: string;
    error?: string;
  }>(() => getQueryParams());

  useEffect(() => {
    setQueryParams(getQueryParams());
  }, [getQueryParams]);

  return queryParams;
};

// Handle microsoft consent redirection
const ConsentRedirect = () => {
  const { usEastApi: api } = useContext(CloudFunctionClientContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { selectedPortal } = useContext(PortalsContext);
  const { tenantId, adminConsent, error } = useResponseQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (error === 'access_denied') {
      window.localStorage.setItem(SSO_SETUP_ERROR_MSG_KEY, 'Access Denied');
      navigate(buildSsoConfigurationsSettings(selectedPortal));
      return;
    } else if (error !== undefined) {
      const e = new Error();
      e.name = `Unexpected sso error: ${error}`;
      captureException(e);
      window.alert('Something went wrong. Please contact support.');
      return;
    }

    if (adminConsent && tenantId) {
      postPermissionGranted(api, tenantId).then((response) => {
        if (response.ok) {
          trackEvent('SSO Configurations - Microsoft - Permission Granted');
          navigate(buildSsoConfigurationsSettings(selectedPortal));
        } else {
          const e = new Error();
          e.name = 'Failed to configure microsoft sso';
          captureException(e);
          alert('Something went wrong. Please contact support@fleetops.com');
        }
      });
    }
  }, [
    adminConsent,
    api,
    error,
    navigate,
    selectedPortal,
    tenantId,
    trackEvent,
  ]);

  return (
    <ScreenContent>
      <div style={{ padding: 32 }}>
        <Typography.Header type="H5">
          We are setting up your microsoft integration. You will be redirected
          shorty...
        </Typography.Header>
      </div>
    </ScreenContent>
  );
};

export default ConsentRedirect;
