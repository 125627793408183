import React, { useContext } from 'react';
import { DatasetFreshnessContext } from '../../contextProviders/SplashScreenProviders/DatasetProviders/DatasetFreshnessProvider';
import useGetDatasetLabel from '../Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import Typography from '../../kingpin/atoms/Typography';

const FreshnessContent = ({ datasets }: { datasets: string[] }) => {
  const { getLastSyncedAt } = useContext(DatasetFreshnessContext);
  const getDatasetLabel = useGetDatasetLabel();

  return (
    <div>
      <div>
        <Typography.Body type={'Body Bold'} color="white">
          Last Dataset Sync:
        </Typography.Body>
      </div>
      {datasets.map((dataset) => (
        <div key={dataset}>
          <Typography.Body type={'Body 13'} color="white">
            {`- ${getDatasetLabel(dataset)} (${getLastSyncedAt(dataset)})`}
          </Typography.Body>
        </div>
      ))}
    </div>
  );
};

export default FreshnessContent;
