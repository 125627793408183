import React, { useCallback, useContext, useEffect, useState } from 'react';

import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../theme/Colors2';
import { Link, useLocation } from 'react-router-dom';
import AnalyticsContext from '../contexts/AnalyticsContext';
import Badge from './Badge';
import { TOP_BAR_HEIGHT } from '../constants';
import ReportsContext from '../contexts/ReportsContext';
import ReportInfoIcon from './ReportInfoIcon';

const Wrapper = styled.div<{ isSelected?: boolean }>`
  height: ${TOP_BAR_HEIGHT}px;
  padding: 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  border-bottom: ${(props) =>
    props.isSelected
      ? `1px solid ${Colors2.Secondary.info}`
      : '1px solid transparent'};

  &:hover {
    opacity: 0.7;
  }
`;

export const NabTabLinkMargin = 20;

const ReportInfo = ({ reportId }: { reportId: string }) => {
  const [report, setReport] = useState<ReportType>();
  const { allReports } = useContext(ReportsContext);

  useEffect(() => {
    setReport(allReports.find((r) => r.id === reportId));
  }, [allReports, reportId]);

  if (!report) {
    return null;
  }

  return <ReportInfoIcon report={report} isSmall />;
};

const TabExtras = ({
  tabInfo,
}: {
  tabInfo: {
    type: string;
    typeId: string;
  };
}) => {
  if (tabInfo.type === 'report') {
    return <ReportInfo reportId={tabInfo.typeId} />;
  }
  return null;
};

export const NavTabLink = ({
  title,
  to,
  onClickEvent,
  id,
  isBeta,
  tabInfo,
}: {
  title: string;
  to: string;
  onClickEvent?: Analytics.EventType;
  id?: string;
  isBeta?: boolean;
  tabInfo?: {
    type: string;
    typeId: string;
  };
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { pathname } = useLocation();
  const getIsSelected = useCallback(() => {
    return pathname === to;
  }, [pathname, to]);

  const [isSelected, setIsSelected] = useState<boolean>(getIsSelected());

  const onClick = useCallback(() => {
    if (!onClickEvent) {
      return;
    }

    trackEvent(onClickEvent);
  }, [onClickEvent, trackEvent]);

  useEffect(() => {
    setIsSelected(getIsSelected());
  }, [getIsSelected]);

  return (
    <Link to={to} onClick={onClick} id={id}>
      <Wrapper isSelected={pathname === to}>
        <Typography.Body
          type="Button Text"
          color={isSelected ? Colors2.Secondary.info : Colors2.Grey['3']}
          isEllipsis
        >
          {title}
        </Typography.Body>
        {tabInfo && (
          <div style={{ marginLeft: 8 }}>
            <TabExtras tabInfo={tabInfo} />
          </div>
        )}
        {isBeta && (
          <div style={{ marginLeft: 8 }}>
            <Badge text="Beta" badgeType="Info" />
          </div>
        )}
      </Wrapper>
    </Link>
  );
};

export const NavTabButton = ({
  title,
  onClick,
  isSelected,
  testId,
}: {
  title: string;
  onClick: () => void;
  isSelected: boolean;
  testId?: string;
}) => {
  return (
    <Wrapper isSelected={isSelected} onClick={onClick} data-testid={testId}>
      <Typography.Body
        type="Button Text"
        isEllipsis
        color={isSelected ? Colors2.Secondary.info : Colors2.Grey['3']}
      >
        {title}
      </Typography.Body>
    </Wrapper>
  );
};
