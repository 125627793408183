import { useMemo } from 'react';
import _ from 'lodash';
import DefaultFiltersToggle from '../DefaultFiltersToggle';
import visTypeCheckers from '../../types/visTypeCheckers';
import useGetMetricDataTypes from '../../hooks/useGetMetricDataTypes';
import captureException from '../../services/captureException';

const DashboardGadgetDefaultFiltersToggle = ({
  chartDef,
}: {
  chartDef?: VisualisationDefinition;
}) => {
  const getMetricDataTypes = useGetMetricDataTypes();
  const relevantDataTypes = useMemo(() => {
    if (!chartDef) {
      return [];
    }

    if (visTypeCheckers.isPaceMatrix(chartDef)) {
      return getMetricDataTypes(chartDef.metricId);
    } else if (visTypeCheckers.isGauge(chartDef)) {
      return getMetricDataTypes(chartDef.metricId);
    } else if (visTypeCheckers.isRankingMatrix(chartDef)) {
      return _.uniq(
        chartDef.metrics
          .map((m) => m.metricId)
          .map(getMetricDataTypes)
          .reduce((a, b) => {
            return [...a, ...b];
          }, []),
      );
    } else if (visTypeCheckers.isSingleMetricDateMatrix(chartDef)) {
      return getMetricDataTypes(chartDef.metricId);
    } else if (visTypeCheckers.isV5ChartDef(chartDef)) {
      return _.uniq(
        chartDef.series
          .map((s) => s.metricId)
          .map(getMetricDataTypes)
          .reduce((a, b) => [...a, ...b], []),
      );
    } else {
      const e = new Error();
      e.name = 'DashboardGadgetDefaultFiltersToggle: Unexpect vis type found';
      captureException(e);
      return [];
    }
  }, [chartDef, getMetricDataTypes]);

  return <DefaultFiltersToggle relevantDataTypes={relevantDataTypes} />;
};

export default DashboardGadgetDefaultFiltersToggle;
