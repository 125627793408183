import React from 'react';

interface DatasetFiltersUsageLookupContextType {
  savedFiltersLookup: FleetOps.SavedFiltersUsageLookup;
}
const SavedFiltersUsageLookupContext =
  React.createContext<DatasetFiltersUsageLookupContextType>({
    savedFiltersLookup: {},
  });

export default SavedFiltersUsageLookupContext;
