import React, { useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useScorecardUsageReport from './useScorecardUsageReport';
import deleteScorecard from '../../contextProviders/SplashScreenProviders/ContentProviders/ScorecardsProvider/deleteScorecard';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import ConfirmationModal from '../ConfirmationModal';
import Typography from 'kingpin/atoms/Typography';
import appRoutes, { buildDashboardShow } from '../../navigation/appRoutes';
import WorkSpaceContext from 'contexts/WorkSpaceContext';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const ConfirmationBody = ({
  usage,
  isUsed,
}: {
  usage: Scorecards.UsageReport;
  isUsed: boolean;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  if (!isUsed) {
    return (
      <div style={{ marginBottom: 32 }}>
        <Typography.Body type="Body 12">
          Are you sure you want to make this change?
        </Typography.Body>
      </div>
    );
  } else {
    return (
      <div style={{ marginBottom: 32 }}>
        <Typography.Body type="Body 12">
          This KPI List appears in the following dashboards:
        </Typography.Body>
        {usage.dashboards.map((d) => (
          <div key={d.id}>
            <Link
              to={buildDashboardShow({ id: d.id, portal: selectedPortal })}
              target="_blank"
            >
              <Typography.Body type="Link">{`- ${d.name}`}</Typography.Body>
            </Link>
          </div>
        ))}
        <Typography.Body type="Body 12">
          You must remove it from these dashboards before deleting this KPI
          List.
        </Typography.Body>
      </div>
    );
  }
};

const ScorecardDeletionConfirmation = ({
  scorecard,
  isOpen,
  close,
}: {
  scorecard: Scorecards.Scorecard;
  isOpen: boolean;
  close: () => void;
}) => {
  const navigate = useNavigate();
  const { trackEvent } = useContext(AnalyticsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { onTabDeleted } = useContext(WorkSpaceContext);
  const { usage, isUsed } = useScorecardUsageReport(scorecard);

  const onDeleteConfirmed = useCallback(() => {
    if (isUsed) {
      return;
    }
    trackEvent('KPI List - Deleted', {
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
    deleteScorecard(scorecard, selectedAccountId).then(() => {
      if (onTabDeleted !== undefined) {
        onTabDeleted();
      } else {
        navigate(appRoutes.loggedIn.kpiLists);
      }
      close();
    });
  }, [
    isUsed,
    trackEvent,
    scorecard,
    selectedAccountId,
    onTabDeleted,
    close,
    navigate,
  ]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      close={close}
      title={'Delete KPI List?'}
      confirmText={'Delete'}
      onConfirmed={onDeleteConfirmed}
      isConfirmDisabled={isUsed}
      bodyComponent={<ConfirmationBody usage={usage} isUsed={isUsed} />}
    />
  );
};

export default ScorecardDeletionConfirmation;
