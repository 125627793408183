import { useContext, useEffect, useState } from 'react';
import formatFloat from '../../api/getChartSeries/formatFloat';
import getColor from '../../components/Goals/GoalListItem/GeneralGoalListItem/getColor';
import getRemainingIntervals from '../../utils/goalsHelpers/getRemainingIntervals';
import getEndMoment from '../../utils/goalsHelpers/getEndMoment';
import buildGoalFilterInput from '../../utils/goalsHelpers/buildGoalFilterInput';
import removeTodayIfItIsZero from '../../removeTodayIfItIsZero';
import removeFutureValues from '../../removeFutureValues';
import AccountContext from '../../contexts/AccountContext';
import useMetric from '../useMetric';
import MetricOptionsContext from '../../contexts/MetricOptionsContext';
import moment from 'moment';
import GeneralGoalContext from '../../contexts/GeneralGoalContext';
import useValueFormatters from '../useValueFormatters';
import useToFixedFilters from 'hooks/useToFixedFilters';

const buildAreaSeries = (
  data: Goals.TrendResult[],
  goal: GeneralGoal,
  metricOptions: Metrics.Metric[],
  metric: Metrics.Metric,
  formatting: MetricFormatting,
  isDemoAccount: boolean,
  demoAccountNow?: string,
): HistogramSeries[] => {
  const remainingIntervals = getRemainingIntervals(
    goal,
    !!isDemoAccount,
    demoAccountNow,
  );

  const tail = [];

  const endMoment = getEndMoment(goal);
  for (let i = 0; i < remainingIntervals; i++) {
    const date = endMoment.clone().subtract(i, 'days').format('YYYY-MM-DD');
    tail.push([new Date(date).getTime(), 0]);
  }

  if (data.length === 0) {
    return [];
  }
  const x = data
    .filter((d) => d.value !== undefined && d.value !== null)
    .map((d) => {
      return [
        new Date(d.date).getTime(),
        formatFloat(d.value, formatting.precision),
      ];
    })
    .concat(tail.reverse()) as [number, number][];

  return [
    {
      name: metric.name,
      data: removeTodayIfItIsZero(removeFutureValues(x)),
      marker: {
        enabled: false,
        symbol: 'circle',
      },
      metricId: metric.id,
    },
  ];
};

const useGoalAreaChart = (goal: GeneralGoal) => {
  const { primaryKpiData, dateBuckets, target, currentValue } =
    useContext(GeneralGoalContext);
  const { isDemoAccount, demoAccountNow } = useContext(AccountContext);
  const { formatMetric } = useValueFormatters();
  const today = moment(
    isDemoAccount && demoAccountNow ? demoAccountNow : undefined,
  )
    .utc()
    .format('YYYY-MM-DD');
  const [isLoadingSeries, setIsLoadingSeries] = useState<boolean>(true);
  const [isLoadingCurrentValue, setIsLoadingCurrentValue] =
    useState<boolean>(true);
  const [series, setSeries] = useState<any[]>();

  const toDrillDowns = useToFixedFilters();
  const { dateScope } = buildGoalFilterInput(goal, toDrillDowns);
  const { metricOptions } = useContext(MetricOptionsContext);
  const metric = useMetric(goal.metricId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatting = metric
    ? metric.formatting
    : {
        prefix: undefined,
        postfix: undefined,
        positiveDeltaIsGood: undefined,
        precision: undefined,
      };

  useEffect(() => {
    if (!primaryKpiData || !primaryKpiData.trend || !metric) {
      return;
    }

    const s = buildAreaSeries(
      primaryKpiData.trend,
      goal,
      metricOptions,
      metric,
      formatting,
      !!isDemoAccount,
      demoAccountNow,
    );

    const zones = [
      {
        value: target,
        color: getColor(false, goal.aboveTargetIsGood),
      },
      {
        color: getColor(true, goal.aboveTargetIsGood),
      },
    ];
    const sWithZone = {
      ...s[0],
      zones,
      threshold: target,
    };
    setSeries([sWithZone]);
    setIsLoadingSeries(false);
    setIsLoadingCurrentValue(false);
  }, [
    primaryKpiData,
    demoAccountNow,
    formatting,
    goal,
    isDemoAccount,
    metric,
    metricOptions,
    target,
  ]);

  const isAboveTarget =
    currentValue !== undefined ? currentValue > target : false;

  const performanceText = (() => {
    const distanceFromTarget =
      currentValue !== undefined ? target - currentValue : 0;

    return formatMetric({
      value: Math.abs(distanceFromTarget),
      metricId: goal.metricId,
    });
  })();

  const numIntervals = dateBuckets.length;
  const passedIntervals =
    primaryKpiData && primaryKpiData.trend
      ? primaryKpiData.trend.filter((t) => t.date <= today).length
      : 0;
  const remainingIntervals = `${numIntervals - passedIntervals} ${
    goal.cadence
  } remaining`;

  return {
    series,
    isLoading: isLoadingSeries || isLoadingCurrentValue,
    currentValue: formatMetric({
      value: currentValue,
      metricId: goal.metricId,
    }),
    performanceText,
    aboveTargetIsGood: goal.aboveTargetIsGood,
    isAboveTarget,
    remainingIntervals,
    dateRange: dateScope,
  };
};

export default useGoalAreaChart;
