import { useCallback, useContext } from 'react';
import SavedFiltersContext from 'screens/DataManager/DatasetFilters/SavedFilters/context/SavedFiltersContext';
import mergeFilterInputs from 'mergeFilterInputs';
import metricTypeCheckers from 'types/metricTypeCheckers';
import isDefined from 'isDefined';
import FilterPlatesContext from '../contexts/FilterPlatesContext';
import { DefaultDatasetFiltersContext } from '../contextProviders/SplashScreenProviders/DatasetProviders/DefaultDatasetFiltersProvider';

const useToMetricInput = () => {
  const { savedFilterIdLookup } = useContext(SavedFiltersContext);
  const { isDefaultFiltersDisabled } = useContext(FilterPlatesContext);
  const { defaultDatasetFiltersLookup } = useContext(
    DefaultDatasetFiltersContext,
  );

  /**
   * Converts the client's understanding of Metric into a graphQL input,
   * MetricInput
   *
   * Also, this is responsible for injecting in
   * {@link SavedFilter} and {@link DefaultDatasetFilter}
   *
   * @param metric
   * The metric we want to convert to a GQL input
   *
   * @param isDefaultFiltersDisabledOverride
   * Some visualisations require the default filter configuration in a way
   * which our FilterPlatesContext cannot provide for.
   *
   * Examples:
   * Scorecard
   * Goal
   */
  const toMetricInput = useCallback(
    (
      metric: Metrics.NormalMetric | MetricInput,
      isDefaultFiltersDisabledOverride?: boolean,
    ): MetricInput => {
      const baseMetricInput: MetricInput = {
        id: metric.id,
        field: metric.field,
        filters: metric.filters,
        aggFunc: metric.aggFunc,
        dataType: metric.dataType,
      };

      const shouldUseDefaultFilters = (() => {
        if (isDefaultFiltersDisabledOverride === undefined) {
          return !isDefaultFiltersDisabled;
        }

        return !isDefaultFiltersDisabledOverride;
      })();

      if (shouldUseDefaultFilters) {
        const defaultFilter = defaultDatasetFiltersLookup[metric.dataType];
        if (defaultFilter) {
          baseMetricInput.filters = mergeFilterInputs(
            baseMetricInput.filters,
            defaultFilter.filterInput,
          );
        }
      }

      if (
        !metricTypeCheckers.isNormalMetric(metric) ||
        !metric.datasetFilterIds
      ) {
        return baseMetricInput;
      }

      const usedDatasetFilters: FilterInput[] = metric.datasetFilterIds
        .map((dsFilterId) => savedFilterIdLookup[dsFilterId])
        .filter(isDefined)
        .map((dsFilter) => dsFilter.filterInput);

      const datasetFilterInput: FilterInput = usedDatasetFilters.reduce(
        (a, b) => {
          return mergeFilterInputs(a, b);
        },
        {},
      );

      const newFilterInput: FilterInput = mergeFilterInputs(
        baseMetricInput.filters,
        datasetFilterInput,
      );

      return {
        ...baseMetricInput,
        filters: newFilterInput,
      };
    },
    [
      savedFilterIdLookup,
      defaultDatasetFiltersLookup,
      isDefaultFiltersDisabled,
    ],
  );

  return toMetricInput;
};

export default useToMetricInput;
