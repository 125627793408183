import React, { useContext, useEffect, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import DashboardContextMenu from './DashboardContextMenu';
import DashboardContextMenuContext from '../../contexts/DashboardContextMenuContext';
import MenuPosition from './MenuPositioner';
import GridProvider from '../../contextProviders/GridProvider';
import PopupGrid from '../PopupGrid';
import buildFilterInput from '../../utils/buildFilterInput';
import mergeFilterInputs from '../../mergeFilterInputs';
import useDateScope from '../../hooks/useDateScope';
import MetricOptionsContext from '../../contexts/MetricOptionsContext';
import isDefined from '../../isDefined';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import filterTypeCheckers from '../FilterPlateForm/filterTypeCheckers';

const useMetrics = (metricIds: string[]) => {
  const { metricOptions, normalMetrics } = useContext(MetricOptionsContext);
  const [metrics, setMetrics] = useState<
    (Metrics.NormalMetric | Metrics.SpecialMetric)[]
  >([]);

  useEffect(() => {
    const foundMetrics = metricIds
      .map((mid) => metricOptions.find((mo) => mo.id === mid))
      .filter(isDefined);

    const newMetrics = [] as (Metrics.NormalMetric | Metrics.SpecialMetric)[];
    const basicMetrics = foundMetrics.filter(metricTypeCheckers.isNormalMetric);
    newMetrics.push(...basicMetrics);
    const specialMetrics = foundMetrics.filter(
      metricTypeCheckers.isSpecialMetric,
    );
    newMetrics.push(...specialMetrics);
    const compoundMetrics = foundMetrics.filter(
      metricTypeCheckers.isCompoundMetric,
    );
    compoundMetrics.forEach((cm) => {
      const metricsInCompound = normalMetrics.filter((m) =>
        cm.metricIds.includes(m.id),
      );

      newMetrics.push(...metricsInCompound);
    });

    setMetrics(newMetrics);
  }, [metricIds, metricOptions, normalMetrics]);

  return { metrics };
};

const applyDrillTerm = (
  metricFilters: FilterInput,
  drillDown: FixedFilter,
): FilterInput => {
  const drillDownFilter = buildFilterInput({
    scopes: [],
    drillDowns: [drillDown],
  });
  const hasConflict = (metricFilters.keywords || []).some(
    (k) => k.field === drillDown.field,
  );
  if (hasConflict) {
    const withoutConflict = {
      ...metricFilters,
      keywords: (metricFilters.keywords || []).filter(
        (k) => k.field !== drillDown.field,
      ),
    };
    return mergeFilterInputs(withoutConflict, drillDownFilter);
  } else {
    return mergeFilterInputs(metricFilters, drillDownFilter);
  }
};

const buildDateScopeOverride = (
  drillDown: FixedFilter,
  dateField?: string,
): DateRangeInput | undefined => {
  if (
    !filterTypeCheckers.isDateFilter(drillDown) ||
    !drillDown.dateRangeValues
  ) {
    return undefined;
  }

  return {
    ...drillDown.dateRangeValues[0].value,
    dateField: dateField ? dateField : 'date',
  };
};

const toLabel = (drillDown: FixedFilter): string => {
  if (filterTypeCheckers.isTextFilter(drillDown)) {
    if (drillDown.keywordValues[0].length === 0) {
      return '';
    }

    return drillDown.keywordValues[0];
  }

  if (filterTypeCheckers.isDateFilter(drillDown)) {
    if (!drillDown.dateRangeValues || drillDown.dateRangeValues.length === 0) {
      return '';
    }

    return drillDown.dateRangeValues[0].label;
  }

  if (filterTypeCheckers.isNumberFilter(drillDown)) {
    if (!drillDown.rangeValue) {
      return '';
    }

    if (drillDown.rangeValue.valueB) {
      return `${drillDown.rangeValue.operator} ${drillDown.rangeValue.valueA} and ${drillDown.rangeValue.valueB}`;
    }
    return `${drillDown.rangeValue.operator} ${drillDown.rangeValue.valueA}`;
  }

  if (filterTypeCheckers.isBooleanFilter(drillDown)) {
    return `${drillDown.field} is ${drillDown.booleanValue}`;
  }

  return '';
};

const DashboardContextMenuContainer = ({
  metricIds,
  drillDown,
}: {
  metricIds: string[];
  drillDown: FixedFilter;
}) => {
  const { dateField } = useDateScope({});
  const { selectedMetric, setSelectedMetric, closeMenu } = useContext(
    DashboardContextMenuContext,
  );

  const { metrics } = useMetrics(metricIds);

  return (
    <React.Fragment>
      {!selectedMetric && (
        <MenuPosition closeMenu={closeMenu}>
          <DashboardContextMenu
            metrics={metrics}
            label={toLabel(drillDown)}
            setSelectedMetric={setSelectedMetric}
          />
        </MenuPosition>
      )}
      <ModalTransition>
        {selectedMetric && (
          <Modal
            width={'80vw'}
            height={'90vh'}
            onClose={closeMenu}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <GridProvider
              dataType={selectedMetric.dataType}
              metricFiltering={applyDrillTerm(
                metricTypeCheckers.isSpecialMetric(selectedMetric)
                  ? window.emptyObject
                  : selectedMetric.filters,
                drillDown,
              )}
              dateScopeOverride={buildDateScopeOverride(drillDown, dateField)}
            >
              <PopupGrid close={closeMenu} />
            </GridProvider>
          </Modal>
        )}
      </ModalTransition>
    </React.Fragment>
  );
};

const Gate = () => {
  const { isOpen, drillDown, metricIds } = useContext(
    DashboardContextMenuContext,
  );

  if (isOpen && drillDown && metricIds) {
    return (
      <DashboardContextMenuContainer
        metricIds={metricIds}
        drillDown={drillDown}
      />
    );
  }
  return null;
};

export default Gate;
