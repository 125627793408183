import { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

import AccountPickerContext from '../contexts/AccountPickerContext';
import useUser from './useUser';
import userTypeCheckers from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';

const useAvatarUrl = (userId: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);
  const { user, isLoading: isLoadingUser } = useUser(userId);
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    if (!user) {
      setIsLoading(false);
      return;
    }

    if (!userTypeCheckers.isSignedUpUser(user)) {
      setIsLoading(false);
      setAvatarUrl(undefined);
      return;
    }

    if (!user.hasAvatar) {
      setIsLoading(false);
      setAvatarUrl(undefined);
      return;
    }
    setIsLoading(true);
    const accountId = accountRef.id;
    const path = `accounts/${accountId}/uploads/users/${userId}/avatar`;
    firebase
      .storage()
      .ref()
      .child(path)
      .getDownloadURL()
      .then((url) => {
        setAvatarUrl(url);
        setIsLoading(false);
      })
      .catch(({ code }) => {
        if (code === 'storage/object-not-found') {
          setAvatarUrl(undefined);
          setIsLoading(false);
        }
      });
  }, [accountRef, userId, isLoadingUser, user]);

  return { isLoading, avatarUrl };
};

export default useAvatarUrl;
