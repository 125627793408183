const isTextFilter = (filter: FixedFilter): filter is TextFilter => {
  return filter.fieldType === 'text' && !('wildcardFilter' in filter);
};

const isDateFilter = (filter: FixedFilter): filter is DateFilter => {
  return filter.fieldType === 'date' || filter.fieldType === 'dateText';
};

export const NUMBER_FIELD_TYPES = ['int', 'long', 'float'];

const isNumberFilter = (filter: FixedFilter): filter is NumberFilter => {
  return NUMBER_FIELD_TYPES.includes(filter.fieldType);
};

const isBooleanFilter = (filter: FixedFilter): filter is BooleanFilter => {
  return filter.fieldType === 'boolean';
};

const isWildcardFilter = (filter: FixedFilter): filter is WildcardFilter => {
  return filter.fieldType === 'text' && 'wildcardFilter' in filter;
};

const filterTypeCheckers = {
  isTextFilter,
  isDateFilter,
  isNumberFilter,
  isBooleanFilter,
  isWildcardFilter,
};

export default filterTypeCheckers;
