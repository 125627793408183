import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community';
import aguid from 'aguid';

import useIsCompatibleComparison from '../../../hooks/useIsCompatableComparison';
import useRankingMatrixData from './useRankingMatrixData';
import useColDefs from './useColDefs';
import useChartDrillDowns from '../../../hooks/useChartDrillDowns';
import buildGridOptions from '../../Grid/buildGridOptions';
import IncompatibleComparison from '../../IncompatibleComparison';
import MetricMatrixContext from '../../../contexts/MetricMatrixContext';
import Grid from '../../Grid/Grid';
import Loading from '../../Loading';
import useRowHighlighter from '../../../hooks/useRowHighlighter';
import { RowHeightContext } from '../../../contextProviders/SplashScreenProviders/MiscProviders/RowHeightProvider';

const useGridOptions = ({
  colDefs,
  terms,
  onDrillDown,
}: {
  colDefs: ColDef[];
  terms: any[] | undefined;
  onDrillDown: (term: string, ctrlKey?: boolean) => void;
}) => {
  const { rowHeight } = useContext(RowHeightContext);
  const getGridOptions = useCallback((): GridOptions | undefined => {
    if (!terms) {
      return undefined;
    }

    return {
      ...buildGridOptions({
        columnDefs: colDefs,
        rowModelType: 'clientSide' as 'clientSide',
        rowHeight,
      }),
      rowData: terms,
      context: {
        onDrillDown,
      },
      rowSelection: 'multiple' as 'multiple',
      suppressCellFocus: true,
    };
  }, [colDefs, onDrillDown, rowHeight, terms]);
  const [gridOptions, setGridOptions] = useState<GridOptions | undefined>(() =>
    getGridOptions(),
  );
  useEffect(() => {
    setGridOptions(getGridOptions());
  }, [getGridOptions]);

  return gridOptions;
};

const RankingMatrixContentContainer = ({
  gadget,
  gridApi,
  setGridApi,
}: {
  gadget: VisualisationDefinitions.RankingMatrix;
  gridApi?: GridApi;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
}) => {
  // Context
  const isCompatibleComparison = useIsCompatibleComparison(gadget);

  // State
  const [visId] = useState<string>(() => aguid());

  // Hooks
  const { isLoading, terms, totals } = useRankingMatrixData(gadget);
  const { colDefs } = useColDefs(gadget);
  const { onDrillDown } = useChartDrillDowns(
    gadget.groupByField,
    undefined,
    'matrix',
  );
  const gridOptions = useGridOptions({ colDefs, onDrillDown, terms });
  useRowHighlighter({
    gridApi,
    drillField: gadget.groupByField,
    visId,
  });

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('columnDefs', colDefs);
    }
  }, [colDefs, gridApi]);

  useEffect(() => {
    if (gridApi) {
      if (isLoading) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [gridApi, isLoading]);

  if (!isCompatibleComparison) {
    return <IncompatibleComparison />;
  }

  if (isLoading || !gridOptions) {
    return <Loading />;
  }

  return (
    <MetricMatrixContext.Provider
      value={{
        onDrillDown,
      }}
    >
      <Grid
        columnDefs={colDefs}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        totalsRow={totals ? totals : undefined}
        gridId={visId}
        layoutOnFirstRender={true}
        layoutOnColumnChange={true}
        layoutOnModelUpdated={true}
        hasBorders={false}
        hasDarkHeader={false}
      />
    </MetricMatrixContext.Provider>
  );
};

export default RankingMatrixContentContainer;
