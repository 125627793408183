import React, { useCallback, useMemo } from 'react';
import useSavedFilters from '../../../screens/DataManager/DatasetFilters/SavedFilters/hooks/useSavedFilters';
import SavedFiltersContext, {
  SavedFilterLookup,
  SavedFilterIdLookup,
} from '../../../screens/DataManager/DatasetFilters/SavedFilters/context/SavedFiltersContext';
import Loading from 'components/Loading';
import isDefined from 'isDefined';

export const savedFilterToPlate = (
  dsFilter: SavedFilter,
): SavedFilterPlate => ({
  id: dsFilter.id,
  type: 'Dataset',
  datasetFilterId: dsFilter.id,
});

const useSavedFiltersLookup = (
  savedFilters: SavedFilter[],
): SavedFilterLookup => {
  const lookup = useMemo(() => {
    const newLookup: SavedFilterLookup = {};
    savedFilters.forEach((savedFilter) => {
      const currentLookupForDataset = newLookup[savedFilter.dataset];
      if (currentLookupForDataset === undefined) {
        newLookup[savedFilter.dataset] = [savedFilter];
      } else {
        newLookup[savedFilter.dataset] = [
          ...currentLookupForDataset,
          savedFilter,
        ];
      }
    });

    return newLookup;
  }, [savedFilters]);

  return lookup;
};

const useSavedFilterIdLookup = (
  savedFilters: SavedFilter[],
): SavedFilterIdLookup => {
  const lookup = useMemo(() => {
    const newLookup: SavedFilterIdLookup = {};
    savedFilters.forEach((dsFilter) => {
      newLookup[dsFilter.id] = dsFilter;
    });

    return newLookup;
  }, [savedFilters]);

  return lookup;
};

const DatasetFiltersProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { savedFilters, isLoading } = useSavedFilters();
  const savedFilterLookup = useSavedFiltersLookup(savedFilters);
  const savedFilterIdLookup = useSavedFilterIdLookup(savedFilters);

  const getDataTypeSavedFilters = useCallback(
    (dataTypes: string[]): SavedFilter[] => {
      return dataTypes
        .map((dt) => savedFilterLookup[dt])
        .filter(isDefined)
        .reduce((a, b) => [...a, ...b], []);
    },
    [savedFilterLookup],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <SavedFiltersContext.Provider
      value={{
        savedFilters,
        getDataTypeSavedFilters,
        savedFilterLookup,
        savedFilterIdLookup,
      }}
    >
      {children}
    </SavedFiltersContext.Provider>
  );
};

export default DatasetFiltersProvider;
