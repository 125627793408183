import { useCallback, useContext, useEffect, useState } from 'react';
import LocalTimelineContext from '../../../../../../contexts/Timeline/LocalTimelineContext';
import useFilterDescription from '../../../../../../hooks/useFilterDescription';
import isNormalMetric from '../../../../../../types/metricTypeCheckers/isNormalMetric';
import metricTypeCheckers from '../../../../../../types/metricTypeCheckers';
import isCompoundMetric from '../../../../../../types/metricTypeCheckers/isCompoundMetric';
import useGetDatasetLabel from '../../../../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import SavedFiltersContext from 'screens/DataManager/DatasetFilters/SavedFilters/context/SavedFiltersContext';
import useUpdateMetricTimeline from './useUpdateMetricTimeline';
import useUpdateSavedFilterMetricTimeline from './useUpdateSavedFilterMetricTimeline';

const useMetricsTimelineEventCreator = ({
  metricDraft,
  selectedMetric,
}: {
  metricDraft?: Metrics.NormalMetric | Metrics.CompoundMetric;
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const getDatasetLabel = useGetDatasetLabel();
  const { addEvent } = useContext(LocalTimelineContext);
  const { savedFilters } = useContext(SavedFiltersContext);

  const getSavedFilters = useCallback(
    (metric: Metrics.Metric | undefined) => {
      if (
        !metricTypeCheckers.isNormalMetric(metric) ||
        !metric.datasetFilterIds ||
        metric.datasetFilterIds.length === 0
      ) {
        return undefined;
      }

      return savedFilters.filter((df) =>
        metric.datasetFilterIds?.includes(df.id),
      );
    },
    [savedFilters],
  );

  const oldFilterDescription = useFilterDescription({
    filterInput: isNormalMetric(selectedMetric)
      ? selectedMetric.filters
      : undefined,
    savedFilters: getSavedFilters(selectedMetric),
  });
  const newFilterDescription = useFilterDescription({
    filterInput: isNormalMetric(metricDraft) ? metricDraft.filters : undefined,
    savedFilters: getSavedFilters(metricDraft),
  });
  const [isFilterDifferent, setIsFilterDifferent] = useState<boolean>(
    oldFilterDescription !== newFilterDescription,
  );
  useEffect(() => {
    setIsFilterDifferent(oldFilterDescription !== newFilterDescription);
  }, [newFilterDescription, oldFilterDescription]);

  const updateMetricTimeline = useUpdateMetricTimeline();
  const updateSavedFilterMetricTimeline = useUpdateSavedFilterMetricTimeline();

  // When using "save as" we need to inject the new document id
  const getDestinationOverride = useCallback(
    (metricId: string): Timeline.EventSource => {
      if (!metricDraft) {
        const e = new Error();
        e.name = 'No draft found';
        throw e;
      }

      return {
        type: metricTypeCheckers.isNormalMetric(metricDraft)
          ? 'Metric'
          : 'CompoundMetric',
        id: metricId,
      };
    },
    [metricDraft],
  );

  const createdMetric = useCallback(
    async (metricId: string) => {
      if (!addEvent || !metricDraft) {
        return;
      }

      await addEvent({
        actionText: 'Created metric',
        contextText: `"${metricDraft.name}"`,
        destinationOverride: getDestinationOverride(metricId),
      });
    },
    [addEvent, getDestinationOverride, metricDraft],
  );

  const addedMetricToCoreList = useCallback(
    async (metricId: string) => {
      if (!addEvent || !metricDraft) {
        return;
      }

      await addEvent({
        actionText: 'Added metric to core list',
        contextText: `"${metricDraft.name}"`,
        destinationOverride: getDestinationOverride(metricId),
      });
    },
    [addEvent, getDestinationOverride, metricDraft],
  );

  const removedMetricFromCoreList = useCallback(async () => {
    if (!addEvent || !metricDraft) {
      return;
    }

    await addEvent({
      actionText: 'Removed metric from core list',
      contextText: `"${metricDraft.name}"`,
    });
  }, [addEvent, metricDraft]);

  const savedChanges = useCallback(async () => {
    if (!addEvent || !metricDraft) {
      return;
    }

    await updateMetricTimeline({
      metricDraft,
      oldFilterDescription,
      newFilterDescription,
    });

    await updateSavedFilterMetricTimeline({ selectedMetric, metricDraft });
  }, [
    addEvent,
    metricDraft,
    newFilterDescription,
    oldFilterDescription,
    selectedMetric,
    updateSavedFilterMetricTimeline,
    updateMetricTimeline,
  ]);

  const archivedMetric = useCallback(async () => {
    if (!addEvent || !metricDraft) {
      return;
    }

    await addEvent({
      actionText: 'Archived metric',
      contextText: `"${metricDraft.name}"`,
    });
  }, [addEvent, metricDraft]);

  const unArchivedMetric = useCallback(async () => {
    if (!addEvent || !metricDraft) {
      return;
    }

    await addEvent({
      actionText: 'Unarchived metric',
      contextText: `"${metricDraft.name}"`,
    });
  }, [addEvent, metricDraft]);

  const aggFuncChanged = useCallback(async () => {
    if (!addEvent || !metricDraft || !selectedMetric) {
      return;
    }
    if (isCompoundMetric(metricDraft) || isCompoundMetric(selectedMetric)) {
      return;
    }

    await addEvent({
      actionText: 'Saved changes',
      contextText: `on "${metricDraft.name}"`,
      contentText: `aggregation changed from ${selectedMetric.aggFunc} to ${metricDraft.aggFunc}`,
    });
  }, [addEvent, metricDraft, selectedMetric]);

  const fieldChanged = useCallback(async () => {
    if (!addEvent || !metricDraft || !selectedMetric) {
      return;
    }
    if (isCompoundMetric(metricDraft) || isCompoundMetric(selectedMetric)) {
      return;
    }

    await addEvent({
      actionText: 'Saved changes',
      contextText: `on "${metricDraft.name}"`,
      contentText: `field changed from ${selectedMetric.field} to ${metricDraft.field}`,
    });
  }, [addEvent, metricDraft, selectedMetric]);

  const datasetChanged = useCallback(async () => {
    if (!addEvent || !metricDraft || !selectedMetric) {
      return;
    }
    if (isCompoundMetric(metricDraft) || isCompoundMetric(selectedMetric)) {
      return;
    }

    await addEvent({
      actionText: 'Saved changes',
      contextText: `on "${metricDraft.name}"`,
      contentText: `dataset changed from ${getDatasetLabel(
        selectedMetric.dataType,
      )} to ${getDatasetLabel(metricDraft.dataType)}`,
    });
  }, [addEvent, getDatasetLabel, metricDraft, selectedMetric]);

  const createTimelineEvents = useCallback(
    async (
      newStatus: Metrics.MetricStatus,
      isSaveAs: boolean,
      newMetricId: string,
    ) => {
      const isUpdate = selectedMetric !== undefined && !isSaveAs;
      if (isUpdate) {
        if (!selectedMetric || !metricDraft) {
          return;
        }

        if (selectedMetric.status !== 'core' && newStatus === 'core') {
          await addedMetricToCoreList(newMetricId);
        }
        if (selectedMetric.status === 'core' && newStatus !== 'core') {
          await removedMetricFromCoreList();
        }
        if (newStatus === 'archived' && selectedMetric.status !== 'archived') {
          await archivedMetric();
        }
        if (selectedMetric.status === 'archived' && newStatus !== 'archived') {
          await unArchivedMetric();
        }
        if (isFilterDifferent) {
          await savedChanges();
        }
        if (
          isNormalMetric(selectedMetric) &&
          isNormalMetric(metricDraft) &&
          selectedMetric.dataType !== metricDraft.dataType
        ) {
          await datasetChanged();
        }
        if (
          isNormalMetric(selectedMetric) &&
          isNormalMetric(metricDraft) &&
          selectedMetric.field !== metricDraft.field
        ) {
          await fieldChanged();
        }
        if (
          isNormalMetric(selectedMetric) &&
          isNormalMetric(metricDraft) &&
          selectedMetric.aggFunc !== metricDraft.aggFunc
        ) {
          await aggFuncChanged();
        }
      } else {
        if (!metricDraft) {
          return;
        }

        await createdMetric(newMetricId);
        if (newStatus === 'core') {
          await addedMetricToCoreList(newMetricId);
        }
      }
    },
    [
      addedMetricToCoreList,
      aggFuncChanged,
      archivedMetric,
      createdMetric,
      datasetChanged,
      fieldChanged,
      isFilterDifferent,
      metricDraft,
      removedMetricFromCoreList,
      savedChanges,
      selectedMetric,
      unArchivedMetric,
    ],
  );

  return createTimelineEvents;
};

export default useMetricsTimelineEventCreator;
