import { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';

import MetricOptionsContext from '../../contexts/MetricOptionsContext';
import buildFilterInput from '../../utils/buildFilterInput';
import isDefined from '../../isDefined';
import AccountContext from '../../contexts/AccountContext';
import GqlClientContext from '../../contexts/GqlClientContext';
import useNetworkingEffect from '../../hooks/useNetworkingEffect';
import calculateGeneralGoal from './calculateGeneralGoal';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import useToMetricInput from 'hooks/useToMetricInput';
import useToFixedFilters from 'hooks/useToFixedFilters';

export const useGetMetric = () => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);

  const getMetric = useCallback(
    (metricId: string): Metrics.Metric | undefined => {
      return metricOptionsLookup[metricId];
    },
    [metricOptionsLookup],
  );

  return getMetric;
};

export const useBuildMetricInput = () => {
  const getMetric = useGetMetric();
  const toMetricInput = useToMetricInput();

  const buildMetricInput = useCallback(
    (
      metricId: string,
      isDefaultFiltersDisabled: boolean,
    ): MetricInput | undefined => {
      const metric = getMetric(metricId);
      if (!!metric && metricTypeCheckers.isNormalMetric(metric)) {
        return toMetricInput(metric, isDefaultFiltersDisabled);
      }
      return undefined;
    },
    [getMetric, toMetricInput],
  );

  return buildMetricInput;
};

export const useBuildCompoundMetricInput = () => {
  const getMetric = useGetMetric();
  const toMetricInput = useToMetricInput();
  const { normalMetrics } = useContext(MetricOptionsContext);

  const buildCompoundMetricInput = useCallback(
    (
      metricId: string,
      isDefaultFiltersDisabled: boolean,
    ): Goals.CompoundMetricInput | undefined => {
      const metric = getMetric(metricId);

      if (metric && metricTypeCheckers.isCompoundMetric(metric)) {
        const subMetrics = metric.metricIds
          .map((mid) => normalMetrics.find((m) => m.id === mid))
          .filter(isDefined)
          .map((m) => toMetricInput(m, isDefaultFiltersDisabled));
        return {
          id: metric.id,
          expression: metric.expression,
          metrics: subMetrics,
        };
      }
      return undefined;
    },
    [getMetric, normalMetrics, toMetricInput],
  );

  return buildCompoundMetricInput;
};

export const usePlatesToFilterInput = () => {
  const toDrillDowns = useToFixedFilters();

  const platesToFilterInput = useCallback(
    (plates: FilterPlateType[]) => {
      const drillDowns = toDrillDowns({
        plates,
        variableDrillDowns: [],
      });

      return buildFilterInput({ drillDowns, scopes: [] });
    },
    [toDrillDowns],
  );

  return platesToFilterInput;
};

const useBuildPrimaryKpiInput = (goal: GeneralGoal) => {
  const { metricOptions } = useContext(MetricOptionsContext);
  const buildMetricInput = useBuildMetricInput();
  const buildCompoundMetricInput = useBuildCompoundMetricInput();
  const platesToFilterInput = usePlatesToFilterInput();

  const buildPrimaryKpi = useCallback((): {
    primaryKpiInput: KpiInput;
    primaryKpiCumulatedInput: KpiInput | undefined;
    isPrimaryCumulative: boolean;
  } => {
    const primaryMetric = metricOptions.find((m) => m.id === goal.metricId);
    if (!primaryMetric) {
      const error = new Error();
      error.name = `Primary KPI not found on goal: ${goal.id}`;
      throw error;
    }

    const m = buildMetricInput(goal.metricId, !!goal.isDefaultFiltersDisabled);
    const cm = buildCompoundMetricInput(
      goal.metricId,
      !!goal.isDefaultFiltersDisabled,
    );

    const shouldCumulate = !!m && m.aggFunc === 'sum';
    const cumulativeM =
      !!m && shouldCumulate
        ? {
            ...m,
            id: `${m.id}-cumulated`,
            aggFunc: 'cumulative_sum' as 'cumulative_sum',
          }
        : undefined;
    const filters = platesToFilterInput(goal.drillDowns);
    const dateField = goal.dateField ? goal.dateField : 'date';

    const primaryKpiCumulatedInput = cumulativeM
      ? {
          metric: cumulativeM,
          compoundMetric: undefined,
          filters,
          dateField,
        }
      : undefined;

    const metric = m ? { ...m, id: m.id } : undefined;
    const compoundMetric = cm ? { ...cm, id: cm.id } : undefined;
    const primaryKpiInput = {
      metric,
      compoundMetric,
      filters,
      dateField,
    };

    return {
      primaryKpiInput,
      primaryKpiCumulatedInput,
      isPrimaryCumulative: shouldCumulate,
    };
  }, [
    buildCompoundMetricInput,
    buildMetricInput,
    goal.dateField,
    goal.drillDowns,
    goal.id,
    goal.isDefaultFiltersDisabled,
    goal.metricId,
    metricOptions,
    platesToFilterInput,
  ]);

  return buildPrimaryKpi;
};

const useBuildKpiInputs = (goal: GeneralGoal) => {
  const { metricOptions } = useContext(MetricOptionsContext);
  const buildMetricInput = useBuildMetricInput();
  const buildCompoundMetricInput = useBuildCompoundMetricInput();
  const platesToFilterInput = usePlatesToFilterInput();

  const buildKpiInputs = useCallback((): KpiInput[] => {
    const kpis = [...goal.kpis];
    return kpis
      .map((kpi) => {
        const kpiMetric = metricOptions.find((m) => m.id === kpi.metricId);
        if (!kpiMetric) {
          return undefined;
        }
        const m = buildMetricInput(
          kpi.metricId,
          !!kpi.isDefaultFiltersDisabled,
        );
        const cm = buildCompoundMetricInput(
          kpi.metricId,
          !!kpi.isDefaultFiltersDisabled,
        );
        const filters = platesToFilterInput(kpi.drillDowns);

        return {
          metric: m ? { ...m, id: kpi.id } : undefined,
          compoundMetric: cm ? { ...cm, id: kpi.id } : undefined,
          filters,
          dateField: kpi.dateField || 'date',
        };
      })
      .filter(isDefined)
      .filter((kpi) => kpi.metric || kpi.compoundMetric);
  }, [
    buildCompoundMetricInput,
    buildMetricInput,
    goal.kpis,
    metricOptions,
    platesToFilterInput,
  ]);

  return buildKpiInputs;
};

export const useGeneralGoalData = (goal: GeneralGoal) => {
  const { isDemoAccount, demoAccountNow } = useContext(AccountContext);
  const { client } = useContext(GqlClientContext);

  const [primaryKpiData, setPrimaryKpiData] = useState<
    Goals.MetricResult | undefined
  >();
  const [primaryKpiDataCumulated, setPrimaryKpiDataCumulated] = useState<
    Goals.MetricResult | undefined
  >();
  const [secondaryKpisData, setSecondaryKpisData] = useState<
    Goals.MetricResult[] | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [goalInput, setGoalInput] = useState<Scorecards.ScorecardInput>();
  const [isPrimaryCumulative, setIsPrimaryCumulative] =
    useState<boolean>(false);
  const buildKpiInputs = useBuildKpiInputs(goal);
  const buildPrimaryKpiInput = useBuildPrimaryKpiInput(goal);

  const getEndDate = useCallback(() => {
    const yesterday = moment.utc().subtract(1, 'day').format('YYYY-MM-DD');
    if (isDemoAccount) {
      const demoAccountYesterday = moment(demoAccountNow)
        .utc()
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      return demoAccountYesterday;
    } else if (yesterday > goal.fixedEndDate) {
      return goal.fixedEndDate;
    } else {
      return yesterday;
    }
  }, [demoAccountNow, goal.fixedEndDate, isDemoAccount]);

  const getCadence = useCallback((): 'week' | 'month' => {
    if (goal.cadence !== 'week' && goal.cadence !== 'month') {
      const e = new Error();
      e.name = `Unexpected goal cadence: ${goal.cadence}`;
      throw e;
    } else {
      return goal.cadence;
    }
  }, [goal.cadence]);

  const buildGoalInput = useCallback(():
    | {
        goalInput: Scorecards.ScorecardInput;
        isPrimaryCumulative: boolean;
      }
    | undefined => {
    const secondaryKpiInputs = buildKpiInputs();
    const { primaryKpiInput, isPrimaryCumulative, primaryKpiCumulatedInput } =
      buildPrimaryKpiInput();
    const cadence = getCadence();
    const kpiInputs = [primaryKpiInput, ...secondaryKpiInputs];

    if (primaryKpiCumulatedInput) {
      kpiInputs.push(primaryKpiCumulatedInput);
    }

    if (!kpiInputs || kpiInputs.length === 0) {
      return;
    }

    const dateScope = {
      startDate: goal.fixedStartDate,
      endDate: getEndDate(),
    };

    const goalInput = {
      metrics: kpiInputs,
      dateScope,
      interval: cadence,
    };
    return {
      goalInput,
      isPrimaryCumulative,
    };
  }, [
    buildKpiInputs,
    buildPrimaryKpiInput,
    getCadence,
    goal.fixedStartDate,
    getEndDate,
  ]);

  useEffect(() => {
    const goalInputResult = buildGoalInput();
    if (goalInputResult === undefined) {
      setGoalInput(undefined);
      setIsPrimaryCumulative(false);
      return;
    }

    const { goalInput: newGoalInput, isPrimaryCumulative } = goalInputResult;
    setGoalInput(newGoalInput);
    setIsPrimaryCumulative(isPrimaryCumulative);
  }, [buildGoalInput, goal]);

  useNetworkingEffect(() => {
    if (!goalInput) {
      return;
    }

    calculateGeneralGoal({ goalInput, client }).then((d) => {
      const primaryCumulated = d.find(
        (x) => x.id === `${goal.metricId}-cumulated`,
      );

      setPrimaryKpiDataCumulated(primaryCumulated);
      setPrimaryKpiData(d.find((x) => x.id === goal.metricId));
      setSecondaryKpisData(
        d.filter((x) => x.id !== goal.metricId).filter(isDefined),
      );
      setIsLoading(false);
    });
  }, [goalInput, isDemoAccount, client, demoAccountNow, goal.metricId]);

  return {
    primaryKpiData,
    primaryKpiDataCumulated,
    secondaryKpisData,
    isLoading,
    goalInput,
    isPrimaryCumulative,
  };
};
