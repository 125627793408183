import React, { useContext } from 'react';
import FilterPlates from './FilterPlates';
import FilterPlatesContext from '../../contexts/FilterPlatesContext';

const FilterPlatesContainer = ({
  isScope,
  isReport,
  isDashboardCard,
  onManualFilterChanged,
  onFilterAdded,
  onFilterRemoved,
  testIdPrefix,
  label,
}: {
  isScope?: boolean;
  isReport?: boolean;
  isDashboardCard?: boolean;
  onManualFilterChanged?: () => void;
  onFilterAdded?: () => void;
  onFilterRemoved?: () => void;
  testIdPrefix?: string;
  label?: string;
}) => {
  const { drillDowns, scope } = useContext(FilterPlatesContext);

  return (
    <FilterPlates
      drillDowns={drillDowns}
      scope={scope}
      isScope={!!isScope}
      isReport={isReport}
      isDashboardCard={isDashboardCard}
      onManualFilterChanged={onManualFilterChanged}
      onFilterAdded={onFilterAdded}
      onFilterRemoved={onFilterRemoved}
      testIdPrefix={testIdPrefix}
      label={label}
    />
  );
};

export default FilterPlatesContainer;
