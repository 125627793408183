import React, { useContext, useMemo } from 'react';
import SavedFilterPickerItem from 'components/SavedFilterPickerItem';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';
import FilterPlatesContext from '../../../contexts/FilterPlatesContext';

const SavedFilterList = ({
  savedDatasetFilters,
  onOptionSelected,
}: {
  savedDatasetFilters: SavedFilter[];
  onOptionSelected: (
    field:
      | FleetOps.Field
      | DateBucketField
      | VariableDrillDownType
      | SavedFilter,
  ) => void;
}) => {
  const { drillDowns } = useContext(FilterPlatesContext);
  const unusedFilterPlates = useMemo(
    () =>
      savedDatasetFilters.filter((f) => !drillDowns.some((d) => d.id === f.id)),
    [drillDowns, savedDatasetFilters],
  );

  const isShowingDatasetFilterList =
    savedDatasetFilters && unusedFilterPlates.length !== 0;

  if (!isShowingDatasetFilterList) {
    return null;
  }

  return (
    <>
      <div
        style={{
          borderBottom: `1px solid ${Colors2.Border}`,
          marginBottom: '8px',
        }}
      ></div>
      <div style={{ marginLeft: '4px' }}>
        <Typography.Header type="H6" color={Colors2.Grey['5']}>
          Saved Filters
        </Typography.Header>
      </div>

      {unusedFilterPlates.map((filter) => (
        <SavedFilterPickerItem
          key={filter.id}
          filter={filter}
          onOptionSelected={onOptionSelected}
        />
      ))}
      <div
        style={{
          borderBottom: `1px solid ${Colors2.Border}`,
          marginBottom: '16px',
          marginTop: '8px',
        }}
      ></div>
      <div style={{ marginLeft: '4px' }}>
        <Typography.Header type="H6" color={Colors2.Grey['5']}>
          Fields
        </Typography.Header>
      </div>
    </>
  );
};

export default SavedFilterList;
