import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';

import Dataset from './Dataset';
import DataDictionaryProvider from '../../../../contextProviders/DataManagerProviders/DataDictionaryProvider';
import DataDictionaryContext from '../../../../contexts/DataDictionaryContext';
import Typography from 'kingpin/atoms/Typography';
import BaseViewsContext from '../../../../contexts/BaseViewsContext';
import isDefined from '../../../../isDefined';
import PerformanceDatasetTopActions from './PerformanceDatasetTopActions';

const getOrderedFields = (
  baseView: FleetOps.BaseView,
  order: string[],
  searchText: string,
) => {
  const allFields = baseView
    ? (Object.values(baseView.fields) as FleetOps.BaseViewField[])
    : [];
  const filteredFields = allFields.filter(
    (v) =>
      v.field.toLowerCase().includes(searchText.toLowerCase()) ||
      (v.nameAlias &&
        v.nameAlias.toLowerCase().includes(searchText.toLowerCase())),
  );

  return _.uniq(order)
    .map((field) => filteredFields.find((f) => f.field === field))
    .filter(isDefined);
};

const useFieldQuery = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.has('field')) {
    return urlParams.get('field') as string;
  }
  return undefined;
};

const DatasetShowContainer = () => {
  const queryField = useFieldQuery();
  const [searchText, setSearchText] = useState<string>('');
  const [order, setOrder] = useState<string[]>([]);
  const [filteredFields, setFilteredFields] = useState<
    FleetOps.BaseViewField[]
  >([]);
  const { selectedBaseView, selectedField, setField } = useContext(
    DataDictionaryContext,
  );
  const { updateBaseView } = useContext(BaseViewsContext);

  const onSearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  useEffect(() => {
    setField(queryField);
  }, [queryField, setField]);

  useEffect(() => {
    setOrder(selectedBaseView ? _.uniq(selectedBaseView.fieldOrder) : []);
  }, [selectedBaseView]);

  const onOrderChanged = useCallback(
    (newOrderFields: FleetOps.BaseViewField[]) => {
      if (!selectedBaseView) {
        return;
      }
      const newOrder = newOrderFields.map((f) => f.field);
      const newBaseView = {
        ...selectedBaseView,
        fieldOrder: newOrder,
      };
      setOrder(_.uniq(newOrder));
      setFilteredFields(getOrderedFields(newBaseView, newOrder, searchText));
      updateBaseView(newBaseView);
    },
    [searchText, selectedBaseView, updateBaseView],
  );

  useEffect(() => {
    if (!selectedBaseView) {
      setFilteredFields([]);
      return;
    }

    setFilteredFields(getOrderedFields(selectedBaseView, order, searchText));
  }, [order, searchText, selectedBaseView]);

  if (!selectedBaseView) {
    return (
      <Typography.Body type="Body 12">Please select a Dataset</Typography.Body>
    );
  }

  return (
    <Dataset
      onSearchTextChanged={onSearchTextChanged}
      searchText={searchText}
      baseViewFields={filteredFields}
      baseView={selectedBaseView}
      onOrderChanged={onOrderChanged}
      canDrag={searchText === '' && !selectedField}
    />
  );
};

const Gate = () => {
  let { dataset } = useParams<{ dataset: string }>();
  return (
    <DataDictionaryProvider dataset={dataset}>
      <DatasetShowContainer />
      <PerformanceDatasetTopActions dataset={dataset} />
    </DataDictionaryProvider>
  );
};

export default Gate;
