import termToRangeDrill from './termToRangeDrill';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import aguid from 'aguid';
import * as _ from 'lodash';
import { toFilterPlate } from '../../migrateScope';

const buildOnRangeDrillDown = ({
  chartDef,
  drillDowns,
  field,
  setDrillDowns,
  isLastDrillForField,
  maybeOpenPopupReport,
  track,
}: {
  chartDef?: V5ChartDefinition;
  drillDowns: FixedFilter[];
  field: string;
  setDrillDowns: (drills: FilterPlateType[]) => void;
  isLastDrillForField: boolean;
  maybeOpenPopupReport?: (drillDown?: FixedFilter) => void;
  track: () => void;
}) => {
  return (term: string) => {
    track();
    const rangeValue = termToRangeDrill(term, chartDef as V5ChartDefinition);
    const fieldType = (() => {
      if (isV5ChartDef(chartDef)) {
        if (chartDef.dimensionA) {
          return chartDef.dimensionA.fieldType;
        }
      }

      return 'float';
    })();
    const newDrill: NumberFilter = {
      id: aguid(),
      field,
      fieldType,
      mode: 'editing',
      rangeValue,
    };

    if (maybeOpenPopupReport) {
      maybeOpenPopupReport(newDrill);
      return;
    }

    const currentDrill = drillDowns[drillDowns.length - 1];
    const isDrillRemoved =
      currentDrill &&
      'rangeValue' in currentDrill &&
      _.isEqual(newDrill.rangeValue, currentDrill.rangeValue);
    const withoutLast = drillDowns.filter(
      (d) => d.id !== currentDrill.id,
    ) as FixedFilter[];
    if (isDrillRemoved) {
      setDrillDowns([...withoutLast].map(toFilterPlate));
    } else if (isLastDrillForField && currentDrill) {
      setDrillDowns([...withoutLast, newDrill].map(toFilterPlate));
    } else {
      setDrillDowns([...drillDowns, newDrill].map(toFilterPlate));
    }
  };
};

export default buildOnRangeDrillDown;
