import MetricOptionsContext from 'contexts/MetricOptionsContext';
import { useCallback, useContext } from 'react';
import metricTypeCheckers from 'types/metricTypeCheckers';

const useGetIsUsedInMetricId = () => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);

  const isFilterIdInMetric = useCallback(
    ({
      metric,
      filterId,
    }: {
      metric: Metrics.NormalMetric | Metrics.SingleUseMetric;
      filterId: string;
    }) => {
      return !!metric.datasetFilterIds?.find((dfId) => dfId === filterId);
    },
    [],
  );

  const isUsedInCompoundMetric = useCallback(
    (metric: Metrics.CompoundMetric, filter: SavedFilter) => {
      return !!metric.metricIds.find((metricId) => {
        const metric = metricOptionsLookup[metricId];
        if (metricTypeCheckers.isNormalMetric(metric)) {
          return isFilterIdInMetric({ metric, filterId: filter.id });
        } else {
          return false;
        }
      });
    },
    [isFilterIdInMetric, metricOptionsLookup],
  );

  const getIsUsedInMetricId = useCallback(
    ({ filter, metricId }: { filter: SavedFilter; metricId: string }) => {
      const metric = metricOptionsLookup[metricId];
      if (metricTypeCheckers.isNormalMetric(metric)) {
        return isFilterIdInMetric({ metric, filterId: filter.id });
      } else if (metricTypeCheckers.isCompoundMetric(metric)) {
        return isUsedInCompoundMetric(metric, filter);
      } else {
        return false;
      }
    },
    [isFilterIdInMetric, isUsedInCompoundMetric, metricOptionsLookup],
  );
  return getIsUsedInMetricId;
};

export default useGetIsUsedInMetricId;
