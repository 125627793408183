const aguid = require('aguid');

const isOldScope = (s: KeywordFilter | FixedFilter): s is KeywordFilter => {
  // @ts-ignore
  return s.fieldType === undefined;
};

const toDrillDown = (k: KeywordFilter): FixedFilter => ({
  id: aguid(),
  mode: 'editing',
  field: k.field,
  fieldType: 'text',
  keywordValues: k.values,
  isExclude: k.exclude,
});

export const isFilterPlate = (
  s: KeywordFilter | FixedFilter | FilterPlateType,
): s is FilterPlateType => {
  // @ts-ignore
  return s.type !== undefined;
};

export const toFilterPlate = (r: FixedFilter): FilterPlateType => {
  return {
    id: r.id,
    type: 'Fixed',
    fixedValue: r,
    appliedByInteractionWithChartDefId: r.appliedByInteractionWithChartDefId,
  };
};

const migrateScope = (
  scope: (KeywordFilter | FixedFilter | FilterPlateType)[],
): FilterPlateType[] => {
  return scope.map((s) => {
    if (isFilterPlate(s)) {
      return s;
    }

    if (isOldScope(s)) {
      return toFilterPlate(toDrillDown(s));
    }

    return toFilterPlate(s);
  });
};

export const migrateDrillDowns = (
  drillDowns: (KeywordFilter | FixedFilter | FilterPlateType)[],
): FilterPlateType[] => {
  return drillDowns.map((s) => {
    if (isFilterPlate(s)) {
      return s;
    }

    if (isOldScope(s)) {
      return toFilterPlate(toDrillDown(s));
    }

    return toFilterPlate(s);
  });
};

export default migrateScope;
