import React from 'react';
import BoardSettingsHeader from '../BoardSettingsHeader/BoardSettingsHeader';
import Row from '../../../../Common/Row';
import Button from '../../../../../kingpin/atoms/Button';
import SizeStrategyDropdown from './SizeStrategyDropdown';

const SizeSettingsLayout = ({
  children,
  datasetLabel,
  hasUnsavedChanges,
  onSave,
  columnSizeStrategy,
  setColumnSizeStrategy,
}: {
  children: JSX.Element | JSX.Element[] | null;
  datasetLabel: string;
  hasUnsavedChanges: boolean;
  onSave: () => void;
  columnSizeStrategy:
    | PerformanceBoardTypes.ColumnSizeStrategies.Strategy
    | undefined;
  setColumnSizeStrategy: React.Dispatch<
    React.SetStateAction<
      PerformanceBoardTypes.ColumnSizeStrategies.Strategy | undefined
    >
  >;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div>
        <BoardSettingsHeader
          title="Column Size Settings"
          description={`Dataset: ${datasetLabel}`}
        />
        <SizeStrategyDropdown
          columnSizeStrategy={columnSizeStrategy}
          setColumnSizeStrategy={setColumnSizeStrategy}
        />
        {children}
      </div>
      <Row
        style={{ padding: '8px 0', height: '46px', justifyContent: 'flex-end' }}
      >
        <Button
          type="Primary"
          size="Small"
          isDisabled={!hasUnsavedChanges}
          onClick={onSave}
          label="Save"
        />
      </Row>
    </div>
  );
};

export default SizeSettingsLayout;
