import { useCallback, useContext, useEffect, useState } from 'react';
import useMetric from 'hooks/useMetric';
import CompoundMetricsContext from 'contexts/CompoundMetricsContext';
import metricTypeCheckers from 'types/metricTypeCheckers';
import { IPaceCalculateForExpressionArgs } from '../types';
import useBaseArgs from './useBaseArgs';
import useToMetricInput from 'hooks/useToMetricInput';

const useCompoundMetricArgs = ({
  paceMatrix,
}: {
  paceMatrix: VisualisationDefinitions.PaceMatrix;
}): IPaceCalculateForExpressionArgs | undefined => {
  const metric = useMetric(paceMatrix.metricId);
  const baseArgs = useBaseArgs({
    paceMatrix,
  });
  const toMetricInput = useToMetricInput();
  const { getCompoundMetricWithMetricDefs } = useContext(
    CompoundMetricsContext,
  );

  const getArgs = useCallback(():
    | IPaceCalculateForExpressionArgs
    | undefined => {
    if (!metric || !baseArgs || !metricTypeCheckers.isCompoundMetric(metric)) {
      return;
    }
    const withMetricDefs = getCompoundMetricWithMetricDefs(metric.id);
    if (!withMetricDefs) {
      return;
    }

    return {
      ...baseArgs,
      expression: {
        id: withMetricDefs.id,
        expression: withMetricDefs.expression,
      },
      metrics: withMetricDefs.metrics.map((m) => toMetricInput(m)),
    };
  }, [baseArgs, getCompoundMetricWithMetricDefs, metric, toMetricInput]);
  const [args, setArgs] = useState<IPaceCalculateForExpressionArgs | undefined>(
    () => getArgs(),
  );
  useEffect(() => {
    setArgs(getArgs());
  }, [getArgs]);

  return args;
};

export default useCompoundMetricArgs;
