import moment from 'moment';
import isDateOptionEqual from '../../components/FilterPlateForm/isDateOptionEqual';
import aguid from 'aguid';
import { toFilterPlate } from '../../migrateScope';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import filterTypeCheckers from '../../components/FilterPlateForm/filterTypeCheckers';
import formatDateLabel from '../../components/V5Gadget/formatDateLabel';

const dateFields = ['hour', 'day', 'week', 'month', 'quarter', 'year'];

const ISO_FORMAT = 'YYYY-MM-DD';
const ISO_HOURLY_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const buildDateInputFromTerm = (
  chartDef: VisualisationDefinition | undefined,
  term: string,
  field: string,
  dateScope: DateRangeInput,
  autoInterval?: AutoInterval,
) => {
  if (!chartDef || term === 'Total') {
    return;
  }

  const interval =
    isV5ChartDef(chartDef) && chartDef.trendByCalendarInterval
      ? chartDef.trendByCalendarInterval
      : undefined;

  if (interval === undefined || field === 'dayOfWeek') {
    return undefined;
  }

  let isToDate = false;

  const floorEndDateToDateScope = (endDate: string) => {
    if (!dateScope.endDate) {
      return endDate;
    }

    if (endDate > dateScope.endDate) {
      isToDate = true;
      return dateScope.endDate;
    }

    return endDate;
  };

  const dateInput = (() => {
    if (interval === 'auto') {
      if (!autoInterval) {
        return undefined;
      }

      return {
        startDate: moment.utc(term).format(ISO_FORMAT),
        endDate: floorEndDateToDateScope(
          moment
            .utc(term)
            .add(autoInterval.numIntervals, autoInterval.interval)
            .subtract(1, 'd')
            .format(ISO_FORMAT),
        ),
      } as DateRangeInput;
    } else {
      return {
        startDate: moment
          .utc(term)
          .format(interval === 'hour' ? ISO_HOURLY_FORMAT : ISO_FORMAT),
        endDate: floorEndDateToDateScope(
          moment
            .utc(term)
            .add(1, interval)
            .subtract(1, interval === 'hour' ? 's' : 'd')
            .format(interval === 'hour' ? ISO_HOURLY_FORMAT : ISO_FORMAT),
        ),
      } as DateRangeInput;
    }
  })();

  if (!dateInput || !dateInput.startDate) {
    return undefined;
  }

  return {
    label: `${formatDateLabel(dateInput.startDate, interval, true)}${isToDate ? ' (To Date)' : ''}`,
    value: dateInput,
  } as DateOption;
};

const buildOnDateDrillDown = ({
  chartDef,
  drillDowns,
  field,
  setDrillDowns,
  isLastDrillForField,
  autoInterval,
  maybeOpenPopupReport,
  dateScope,
  track,
}: {
  chartDef?: V5ChartDefinition;
  drillDowns: FixedFilter[];
  field: string;
  setDrillDowns: (drills: FilterPlateType[]) => void;
  isLastDrillForField: boolean;
  autoInterval?: AutoInterval;
  maybeOpenPopupReport?: (drillDown?: FixedFilter) => void;
  dateScope: DateRangeInput;
  track: () => void;
}) => {
  return (term: string, ctrlKey?: boolean) => {
    track();
    const dateOption = buildDateInputFromTerm(
      chartDef,
      term,
      field,
      dateScope,
      autoInterval,
    );
    if (!dateOption) {
      return;
    }

    const newDrillDown = (() => {
      if (dateFields.includes(field)) {
        const dateDrillDown: DateFilter = {
          id: aguid(),
          field: field,
          fieldType: 'date',
          mode: 'editing',
          dateRangeValues: [dateOption],
          appliedByInteractionWithChartDefId: chartDef
            ? chartDef.id
            : undefined,
        };
        return dateDrillDown;
      } else if (field === 'auto' && autoInterval) {
        const dateDrillDown: DateFilter = {
          id: aguid(),
          field: autoInterval.interval,
          fieldType: 'date',
          mode: 'editing',
          dateRangeValues: [dateOption],
          appliedByInteractionWithChartDefId: chartDef
            ? chartDef.id
            : undefined,
        };
        return dateDrillDown;
      } else if (field === 'date') {
        const dateDrillDown: DateFilter = {
          id: aguid(),
          field: 'date',
          fieldType: 'date',
          mode: 'plate',
          dateRangeValues: [dateOption],
          appliedByInteractionWithChartDefId: chartDef
            ? chartDef.id
            : undefined,
        };
        return dateDrillDown;
      }
    })();

    if (maybeOpenPopupReport) {
      maybeOpenPopupReport(newDrillDown);
      return;
    }

    if (isLastDrillForField) {
      const currentDrill = drillDowns[drillDowns.length - 1];
      const currentDateRangeValues = (() => {
        if (!filterTypeCheckers.isDateFilter(currentDrill)) {
          return [];
        }
        return currentDrill.dateRangeValues || [];
      })();

      const updatedDrill = (() => {
        const isDeselecting = currentDateRangeValues
          .map((d) => d.label)
          .includes(term);

        if (isDeselecting) {
          return {
            ...currentDrill,
            dateRangeValues: currentDateRangeValues.filter(
              (t) => !isDateOptionEqual(t, dateOption),
            ),
          };
        } else {
          return {
            ...currentDrill,
            dateRangeValues: ctrlKey
              ? [...currentDateRangeValues, dateOption]
              : [dateOption],
          };
        }
      })();

      const isDrillRemoved =
        updatedDrill.dateRangeValues &&
        updatedDrill.dateRangeValues.length === 0;
      if (isDrillRemoved) {
        setDrillDowns(
          drillDowns.filter((d) => d.id !== currentDrill.id).map(toFilterPlate),
        );
      } else {
        setDrillDowns(
          drillDowns
            .map((d) => {
              if (d.id === currentDrill.id) {
                return updatedDrill;
              }
              return d;
            })
            .map(toFilterPlate),
        );
      }
    } else if (newDrillDown) {
      setDrillDowns([...drillDowns, newDrillDown].map(toFilterPlate));
    }
  };
};

export default buildOnDateDrillDown;
