import React, { useContext } from 'react';
import ValueSelectionTop from './ValueSelectionTop';
import FilterPlateFormContext from '../../../contexts/FilterPlateFormContext';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import useContextualDataTypes from '../hooks/useContextualDataTypes';

const ValueSelectionTopContainer = () => {
  const { field, reset, isVariableFilterValuePicker } = useContext(
    FilterPlateFormContext,
  );
  const dataTypes = useContextualDataTypes();
  const { baseViews } = useContext(BaseViewsContext);

  const baseView = (() => {
    if (dataTypes.length === 1) {
      return baseViews[dataTypes[0]];
    }
  })();
  const baseFieldView = baseView && field ? baseView.fields[field] : undefined;
  const fieldName =
    baseFieldView && baseFieldView.nameAlias ? baseFieldView.nameAlias : field;
  const onBackPressed = isVariableFilterValuePicker
    ? undefined
    : () => {
        reset();
      };

  return (
    <ValueSelectionTop fieldName={fieldName} onBackPressed={onBackPressed} />
  );
};

export default ValueSelectionTopContainer;
