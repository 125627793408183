import { useCallback, useContext } from 'react';
import _ from 'lodash';

import sectionTypeCheckers from 'components/PerformanceBoards/PerformanceBoardSlideOut/sectionTypeCheckers';
import BoardsContext from 'contexts/BoardsContext';
import isPerformanceBoard from 'isPerformanceBoard';
import useGetIsUsedInMetricId from './useGetIsUsedInMetricId';
import isGeneralBoard from 'isBoardWithSlideOut';
import isCustomerLaneBoard from 'isCustomerLaneBoard';
import isDriverPerformanceBoard from 'isDriverPerformanceBoard';
import captureException from 'services/captureException';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';

const useGetBoardUsage = () => {
  const { boards } = useContext(BoardsContext);
  const getIsUsedInMetricId = useGetIsUsedInMetricId();

  const getBoardUsage = useCallback(
    (filter: SavedFilter): (Board | PerformanceBoardTypes.Board)[] => {
      const usage: (Board | PerformanceBoardTypes.Board)[] = [];
      boards.forEach((b) => {
        if (isPerformanceBoard(b)) {
          const isUsedInMetric = b.slideout.some(
            (section) =>
              sectionTypeCheckers.isMetricSection(section) &&
              section.metricIds.some((metricId) =>
                getIsUsedInMetricId({ filter, metricId }),
              ),
          );
          const isUsedInPlate = b.plates.find(
            (plate) =>
              filterPlateTypeCheckers.isSavedFilter(plate) &&
              plate.datasetFilterId === filter.id,
          );
          if (isUsedInMetric || isUsedInPlate) {
            usage.push(b);
          }
        } else if (
          isGeneralBoard(b) ||
          isCustomerLaneBoard(b) ||
          isDriverPerformanceBoard(b)
        ) {
          if (
            b.metricIds &&
            b.metricIds.some((metricId) =>
              getIsUsedInMetricId({ filter, metricId }),
            )
          ) {
            usage.push(b);
          }
        } else {
          const error = new Error();
          error.name = `Unknown board type`;
          captureException(error);
        }
      });
      return _.uniqBy(usage, 'id');
    },
    [boards, getIsUsedInMetricId],
  );

  return getBoardUsage;
};

export default useGetBoardUsage;
