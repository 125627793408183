import firebase from 'firebase/compat/app';
import withoutNulls from './search/withoutNulls';
import { REPORTS_COLLECTION } from '../constants';

const updateReport = async (
  id: string,
  newReport: ReportType,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef
    .collection(REPORTS_COLLECTION)
    .doc(id)
    .set(withoutNulls(newReport));
};

export default updateReport;
