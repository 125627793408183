import { useCallback, useContext, useEffect, useState } from 'react';
import {
  GridApi,
  IServerSideGetRowsParams,
  SortModelItem,
} from 'ag-grid-community';
import _ from 'lodash';

import GridContext from '../../contexts/GridContext';
import captureException from '../../services/captureException';
import search from './search';
import BoardSlideOutContext from '../../contexts/BoardSlideOutContext';
import GqlClientContext from '../../contexts/GqlClientContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import useDateScope from '../useDateScope';
import getIdentifier from '../../getIdentifier';
import {
  CONTRACT_PROGRESS_CELL_FIELD,
  PROGRESS_CELL_TYPE,
} from '../../components/Grid/gridConstants';

const aguid = require('aguid');

const toSortField = (
  sortModelItems: SortModelItem[],
  gridApi: GridApi,
): SortField[] => {
  if (sortModelItems.length === 0) {
    return [];
  }

  const sortColDef = gridApi.getColumnDef(sortModelItems[0].colId);

  const isSortingByProgress =
    !!sortColDef &&
    sortColDef.cellRendererParams &&
    sortColDef.cellRendererParams.cell &&
    sortColDef.cellRendererParams.cell.type === PROGRESS_CELL_TYPE;
  if (
    sortModelItems.length > 0 &&
    sortModelItems[0].colId === CONTRACT_PROGRESS_CELL_FIELD
  ) {
    return [
      {
        field: 'contractCommitment',
        sort: sortModelItems[0].sort as SortBy,
      },
      {
        field: 'commitment',
        sort: sortModelItems[0].sort as SortBy,
      },
    ];
  } else if (isSortingByProgress && sortColDef) {
    try {
      const cell = sortColDef.cellRendererParams.cell;
      const { totalField, targetField } = cell;
      return [
        {
          field: targetField,
          sort: sortModelItems[0].sort as SortBy,
        },
        {
          field: totalField,
          sort: sortModelItems[0].sort as SortBy,
        },
      ];
    } catch (ex) {
      captureException(ex);
      return [];
    }
  }

  return sortModelItems.map(
    (s) =>
      ({
        field: s.colId,
        sort: s.sort,
      }) as SortField,
  );
};

const useDatasource = (
  includeFields: string[] | undefined,
  dataType: string,
  filterInput: FilterInput[],
) => {
  const { client } = useContext(GqlClientContext);
  const { restartGrid } = useContext(GridContext);
  const dateScope = useDateScope({});
  const driverBonusSlideOutContext = useContext(BoardSlideOutContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [gridSessionId] = useState<string>(aguid());

  const trackInitialPage = useCallback(() => {
    trackEvent('Grid - Initial Page loaded', { dataType, gridSessionId });
  }, [dataType, gridSessionId, trackEvent]);
  const trackAdditionalPage = useCallback(() => {
    trackEvent('Grid - Additional Page loaded', { dataType, gridSessionId });
  }, [dataType, gridSessionId, trackEvent]);
  const trackFailedToLoadAdditionalPage = useCallback(() => {
    trackEvent('Grid - Failed to load additional page', {
      dataType,
      gridSessionId,
    });
  }, [dataType, gridSessionId, trackEvent]);

  const createDataSource = useCallback(() => {
    return {
      documents: [],
      hasMoreRows() {
        if (!this.searchResult) {
          return false;
        }
        return this.documents.length < this.searchResult.count;
      },
      lastRequestId: undefined,
      getRows(params: IServerSideGetRowsParams) {
        const { success, fail } = params;
        const requestId = getIdentifier();
        this.lastRequestId = requestId;

        if (
          this.searchResult &&
          _.isEqual(this.searchResult.sorting, params.request.sortModel)
        ) {
          // Get next page (using searchAfter)
          search({
            dataType,
            sortBy: toSortField(params.request.sortModel, params.api),
            filters: filterInput,
            dateScope,
            client,
            searchAfter: this.searchResult.searchAfter,
            includeFields,
          })
            .then((result) => {
              if (requestId !== this.lastRequestId) {
                return;
              }
              if (this.searchResult) {
                this.searchResult = {
                  ...this.searchResult,
                  searchAfter: result.searchAfter,
                };
              }

              this.documents = [...this.documents, ...result.documents];
              trackAdditionalPage();
              success({
                rowData: result.documents,
                rowCount: this.hasMoreRows()
                  ? this.documents.length + 1
                  : this.documents.length,
              });
            })
            .catch((ex) => {
              captureException(ex);
              fail();
              trackFailedToLoadAdditionalPage();
              restartGrid();
            });
        } else {
          // Clear searchResult
          this.searchResult = undefined;

          // Get first page
          search({
            dataType,
            sortBy: toSortField(params.request.sortModel, params.api),
            filters: filterInput,
            client,
            dateScope,
            includeFields,
          })
            .then((result) => {
              if (requestId !== this.lastRequestId) {
                return;
              }
              trackInitialPage();
              this.documents = result.documents;
              this.searchResult = {
                count: result.count,
                searchAfter: result.searchAfter,
                sorting: params.request.sortModel,
              };
              success({
                rowData: result.documents,
                rowCount: this.hasMoreRows()
                  ? this.documents.length + 1
                  : this.documents.length,
              });
            })
            .catch((ex) => {
              captureException(ex);
              fail();
            });
        }
      },
    } as DataSource;
  }, [
    client,
    dataType,
    dateScope,
    filterInput,
    includeFields,
    restartGrid,
    trackAdditionalPage,
    trackFailedToLoadAdditionalPage,
    trackInitialPage,
  ]);

  const [dataSource, setDataSource] = useState<DataSource | undefined>();

  useEffect(() => {
    setDataSource(createDataSource());
  }, [createDataSource]);

  useEffect(() => {
    if (
      driverBonusSlideOutContext &&
      driverBonusSlideOutContext.gridRequiresRefresh
    ) {
      setDataSource(createDataSource());
      driverBonusSlideOutContext.setGridRequiresRefresh(false);
    }
  }, [driverBonusSlideOutContext, createDataSource]);

  return {
    dataSource,
  };
};

export default useDatasource;
