import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import MetricItemEditForm from './MetricItemEditForm';
import useLegacySavedFilter from '../../../../hooks/useLegacySavedFilter';
import Loading from '../../../Loading';
import useMetric from '../../../../hooks/useMetric';
import DashboardContext from '../../../../contexts/DashboardContext';
import useTemplateInstances from '../../../../hooks/dashboards/useTemplateInstances';
import MetricOptionsContext from '../../../../contexts/MetricOptionsContext';
import isDefined from '../../../../isDefined';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import FilterPlatesProvider from '../../../../contextProviders/FilterPlatesProvider';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import STORE from '../../../../store';

const MetricItemEditFormContainer = ({
  metricItem,
  setMetricList,
  savedFilter,
  metric,
  metricId,
  setMetricId,
  close,
}: {
  metricItem: MetricListItemType;
  setMetricList: React.Dispatch<React.SetStateAction<MetricListItemType[]>>;
  savedFilter: LegacySavedFilter;
  metric: Metrics.Metric;
  metricId: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  close: () => void;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);

  const lastMetricIdRef = useRef<string>();
  const [name, setName] = useState<string>(metricItem.name);
  const [drillDowns, setDrillDowns] = useState<FilterPlateType[]>(
    savedFilter.drillDowns,
  );
  const [scope, setScope] = useState<FilterPlateType[]>(savedFilter.scope);
  const [reportDrillDownId, setReportDrillDownId] = useState<
    string | undefined
  >(metricItem ? metricItem.reportDrillDownId : undefined);
  const [boardDrillDownId, setBoardDrillDownId] = useState<string | undefined>(
    metricItem ? metricItem.boardDrillDownId : undefined,
  );
  const [isEntityFilterEnabled, setIsEntityFilterEnabled] = useState<boolean>(
    () => {
      if (metricItem.isEntityFilterEnabled === undefined) {
        return false;
      }
      return metricItem.isEntityFilterEnabled;
    },
  );

  const { metricOptions } = useContext(MetricOptionsContext);
  const { dashboard } = useContext(DashboardContext);
  const templateInstances = useTemplateInstances(dashboard);

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (lastMetricIdRef.current) {
      if (!metricId) {
        return;
      }
      const metric = metricOptions.find((m) => m.id === metricId);
      if (!metric) {
        return;
      }

      setName(metric.name);
    } else {
      lastMetricIdRef.current = metricId;
    }
  }, [metricId, metricOptions]);

  const dataSources = (() => {
    const sources = [] as string[];
    if (metricTypeCheckers.isCompoundMetric(metric)) {
      const baseMetrics = metric.metricIds
        .map((mId) => {
          return metricOptions.find((m) => m.id === mId);
        })
        .filter(metricTypeCheckers.isNormalMetric)
        .filter(isDefined);

      baseMetrics.forEach((m) => {
        sources.push(m.dataType);
      });
    } else {
      sources.push(metric.dataType);
    }

    return sources;
  })();

  const onSave = async () => {
    if (templateInstances.length > 0) {
      const confirmed = window.confirm(
        `${templateInstances.length} dashboard${
          templateInstances.length !== 1 ? 's' : ''
        } will be affected. Are you sure you want to make this change?`,
      );
      if (!confirmed) {
        return;
      }
    }
    const newFilter: LegacySavedFilter = {
      ...savedFilter,
      drillDowns,
      scope,
    };
    await STORE.filterDefinitions
      .getLegacySavedFiltersRef({ accountId: selectedAccountId })
      .doc(newFilter.id)
      .set(newFilter);

    const newItem: MetricListItemType = {
      ...metricItem,
      name,
      metricId,
      reportDrillDownId,
      boardDrillDownId,
      isEntityFilterEnabled,
    };
    setMetricList((list) =>
      list.map((i) => {
        if (i.id === newItem.id) {
          return newItem;
        } else {
          return i;
        }
      }),
    );
    close();
  };

  const dataSource = dataSources.length === 0 ? '' : dataSources[0];

  return (
    <FilterPlatesProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      scope={scope}
      setScope={setScope}
      dataType={dataSource}
    >
      <MetricItemEditForm
        name={name}
        onNameChanged={onNameChanged}
        metricId={metricId}
        setMetricId={setMetricId}
        onSave={onSave}
        close={close}
        reportDrillDownId={reportDrillDownId}
        setReportDrillDownId={setReportDrillDownId}
        boardDrillDownId={boardDrillDownId}
        setBoardDrillDownId={setBoardDrillDownId}
        metric={metric}
        isEntityFilterEnabled={isEntityFilterEnabled}
        setIsEntityFilterEnabled={setIsEntityFilterEnabled}
      />
    </FilterPlatesProvider>
  );
};

const Gate = ({
  metricItem,
  setMetricList,
  close,
}: {
  metricItem: MetricListItemType;
  setMetricList: React.Dispatch<React.SetStateAction<MetricListItemType[]>>;
  close: () => void;
}) => {
  const [metricId, setMetricId] = useState<string | undefined>(
    metricItem.metricId,
  );
  const { savedFilter } = useLegacySavedFilter(metricItem.savedFilterId);
  const metric = useMetric(metricId);

  if (savedFilter && metric && metricId) {
    return (
      <MetricItemEditFormContainer
        metricItem={metricItem}
        setMetricList={setMetricList}
        savedFilter={savedFilter}
        metric={metric}
        metricId={metricId}
        setMetricId={setMetricId}
        close={close}
      />
    );
  } else {
    return <Loading />;
  }
};

export default Gate;
