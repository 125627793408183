import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { Link } from 'react-router-dom';
import { getMetricLink } from '../../../AllSets/routes';
import React, { useContext } from 'react';
import Card from '../../../../../components/Common/Card';
import { PortalsContext } from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const CompoundMetricsUsage = ({
  compoundMetrics,
  usedId,
}: {
  compoundMetrics: Metrics.CompoundMetric[];
  usedId: string;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <>
      {compoundMetrics.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: '8px' }}>
            <Typography.Header type="H5">
              {`Compound Metrics (${compoundMetrics.length})`}
            </Typography.Header>
          </div>

          <Card>
            {compoundMetrics.map((cm, index) => (
              <ListItemWrapper
                key={`${cm.id}-${usedId}`}
                isLast={compoundMetrics.length - 1 === index}
              >
                <Column
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Link
                    to={getMetricLink({
                      metric: cm,
                      portal: selectedPortal,
                    })}
                    target="_blank"
                    style={{ display: 'flex' }}
                  >
                    <Typography.Body type="Body 12" color="#0041ea">
                      {cm.name}
                    </Typography.Body>
                  </Link>
                </Column>
              </ListItemWrapper>
            ))}
          </Card>
        </div>
      )}
    </>
  );
};

export default CompoundMetricsUsage;
