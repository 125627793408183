import useDashboardGadgets from '../../../hooks/dashboards/useDashboardGadgets';
import React from 'react';
import DashboardGadgetsContext from '../../../contexts/DashboardGadgetsContext';

const DashboardGadgetsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { dashboardGadgets, isLoading, dashboardGadgetsLookup } =
    useDashboardGadgets();

  return (
    <DashboardGadgetsContext.Provider
      value={{
        allGadgets: dashboardGadgets,
        isLoading,
        dashboardGadgetsLookup,
      }}
    >
      {children}
    </DashboardGadgetsContext.Provider>
  );
};

export default DashboardGadgetsProvider;
