import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import Button from '../../../kingpin/atoms/Button';
import usePopup from '../../../hooks/usePopup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';
import Typography from '../../../kingpin/atoms/Typography';
import Alert from '../../../kingpin/Alert';
import Dropdown from '../../../components/Inputs/Dropdown';
import Row from '../../../components/Common/Row';
import Tooltip from '../../../components/Tooltip';
import STORE from '../../../store';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import useDangerConfirmation from '../../../components/ConfirmationModals/hooks/useDangerConfirmation';
import UsersContext from '../../../contexts/UsersContext';
import userTypeCheckers from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';

const useAffectedUsers = () => {
  const { allUsers } = useContext(UsersContext);
  const [affectedUsers, setAffectedUsers] = useState<
    (UserManagement.PendingUser | UserManagement.SignedUpUser)[]
  >([]);

  useEffect(() => {
    setAffectedUsers(allUsers.filter(userTypeCheckers.isSignedUpOrPendingUser));
  }, [allUsers]);

  return {
    affectedUsers,
    affectedUsersCount: affectedUsers.length,
  };
};

const AccountLevelUiSettingsModal = ({ close }: { close: () => void }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { isPortalsEnabledAtAccountLevel } = useContext(PortalsContext);
  const [selectedVersion, setSelectedVersion] = useState<'old' | 'new'>(
    isPortalsEnabledAtAccountLevel ? 'new' : 'old',
  );
  const { affectedUsersCount } = useAffectedUsers();
  const { getConfirmationThat, DangerConfirmation } = useDangerConfirmation({
    title: 'Switch layout?',
    checkboxText: `I understand that this will change how ${affectedUsersCount} of my staff members experience FleetOps`,
  });

  const getOptions = useCallback((): DropdownOption[] => {
    return [
      {
        label: 'Use new layout',
        isSelected: selectedVersion === 'new',
        onSelected: () => {
          setSelectedVersion('new');
        },
      },
      {
        label: 'Use old layout',
        isSelected: selectedVersion === 'old',
        onSelected: () => {
          setSelectedVersion('old');
        },
      },
    ];
  }, [selectedVersion]);
  const [options, setOptions] = useState<DropdownOption[]>(() => getOptions());
  useEffect(() => {
    setOptions(getOptions());
  }, [getOptions]);

  const onSwitchClicked = useCallback(async () => {
    if (isLoading) {
      return;
    }
    const isConfirmed = await getConfirmationThat(
      'This will immediately affect all users in this account',
    );
    if (!isConfirmed) {
      return;
    }

    setIsLoading(true);
    await STORE.getAccountRef({ accountId: selectedAccountId }).update({
      isPortalsEnabled: selectedVersion === 'new',
    });
    setIsLoading(false);
    window.location.reload();
  }, [getConfirmationThat, isLoading, selectedAccountId, selectedVersion]);

  const isDisabled = isPortalsEnabledAtAccountLevel
    ? selectedVersion === 'new'
    : selectedVersion === 'old';

  return (
    <>
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <Form>
          <FormHeader title={'Change layout For All Users'} onClose={close} />
          <FormContent>
            <div>
              <Typography.Body type="Placeholder" color="#808080">
                Switch between the new or old layout for all users.
              </Typography.Body>
            </div>
            {selectedVersion === 'new' && !isPortalsEnabledAtAccountLevel && (
              <div style={{ marginBottom: 16 }}>
                <Alert
                  type={'Danger'}
                  body={`When the new layout has been set, per user settings are ignored and users will not be able to revert to the old UI`}
                />
              </div>
            )}
            <div style={{ marginBottom: 16 }}>
              <Dropdown options={options} />
            </div>
          </FormContent>
          <Row spaceBetween>
            <div />
            <Tooltip
              content={
                isDisabled
                  ? 'This version of the layout is already active'
                  : undefined
              }
            >
              <Button
                label="Switch Layout"
                size="Small"
                type={'Primary'}
                onClick={onSwitchClicked}
                isDisabled={
                  isPortalsEnabledAtAccountLevel
                    ? selectedVersion === 'new'
                    : selectedVersion === 'old'
                }
              />
            </Tooltip>
          </Row>
        </Form>
      </Modal>
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </>
  );
};

const AccountLevelUiSettings = () => {
  const { open, close, isOpen } = usePopup();

  return (
    <>
      <Button
        size={'Small'}
        type={'Secondary'}
        onClick={open}
        label={'Change layout For All Users'}
      />
      <ModalTransition>
        {isOpen && <AccountLevelUiSettingsModal close={close} />}
      </ModalTransition>
    </>
  );
};

export default AccountLevelUiSettings;
