import { useCallback, useState } from 'react';

const EXTRA_INFO_KEY = 'extra-account-picker-info';
const TRUE = 'true';
const FALSE = 'false';

const useHasExtraInfo = () => {
  const getHasExtraInfo = useCallback((): boolean => {
    const v = window.localStorage.getItem(EXTRA_INFO_KEY);
    if (!v) {
      return false;
    }

    return v === TRUE;
  }, []);
  const [hasExtraInfo, setHasExtraInfo] = useState<boolean>(() =>
    getHasExtraInfo(),
  );

  const onHasExtraInfoChanged = useCallback((newValue: boolean) => {
    window.localStorage.setItem(EXTRA_INFO_KEY, newValue ? TRUE : FALSE);
    setHasExtraInfo(newValue);
  }, []);

  return {
    hasExtraInfo,
    onHasExtraInfoChanged,
  };
};

export default useHasExtraInfo;
