import { useContext, useEffect, useState } from 'react';
import STORE from '../../../../../store';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';

const useSavedDatasetWizards = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [savedWizards, setSavedWizards] = useState<
    PerformanceConfiguration.WizardState[]
  >([]);
  const [isLoadingSavedWizards, setIsLoadingSavedWizards] =
    useState<boolean>(true);

  useEffect(() => {
    setIsLoadingSavedWizards(true);
    let isActive = true;
    STORE.savedProgresses
      .getPerformanceDatasetsRef({ accountId: selectedAccountId })
      .onSnapshot((snapshot) => {
        if (!isActive) {
          return;
        }
        const data: PerformanceConfiguration.WizardState[] = [];
        snapshot.docs.forEach((doc) => data.push(doc.data()));
        setSavedWizards(data.filter((d) => !d.isHidden));
        setIsLoadingSavedWizards(false);
      });

    return () => {
      isActive = false;
    };
  }, [selectedAccountId]);

  return { isLoadingSavedWizards, savedWizards };
};

export default useSavedDatasetWizards;
