import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import FilterPlateFormContext from '../../../contexts/FilterPlateFormContext';
import FilterPlatesContext from '../../../contexts/FilterPlatesContext';

const useContextualDataTypes = () => {
  const { dataset } = useContext(FilterPlateFormContext);
  const { dataTypes: contextDataTypes } = useContext(FilterPlatesContext);

  const getContextualDataTypes = useCallback((): string[] => {
    const currentContext = contextDataTypes || [];

    if (dataset) {
      return _.uniq([...currentContext, dataset]);
    }
    return currentContext;
  }, [contextDataTypes, dataset]);

  const [contextualDataTypes, setContextualDataTypes] = useState<string[]>(() =>
    getContextualDataTypes(),
  );

  useEffect(() => {
    setContextualDataTypes(getContextualDataTypes());
  }, [getContextualDataTypes]);

  return contextualDataTypes;
};

export default useContextualDataTypes;
