/**
 *
 * Navigation
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useContext } from 'react';

import Authenticated from './Authenticated';
import UnAuthenticated from './UnAuthenticated';
import AuthenticationContext from '../contexts/AuthenticationContext';
import SplashScreen from './SplashScreen';
import CloudFunctionClientProvider from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/CloudFunctionClientProvider';

const Navigation = () => {
  const { authenticated } = useContext(AuthenticationContext);

  if (!authenticated) {
    return (
      <CloudFunctionClientProvider>
        <UnAuthenticated />
      </CloudFunctionClientProvider>
    );
  }

  return (
    <SplashScreen>
      <Authenticated />
    </SplashScreen>
  );
};

export default Navigation;
