import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMetricsOnReport from '../../../../components/Report/useMetricsOnReportCanvas';
import ReportsContext from '../../../../contexts/ReportsContext';
import Report from '../../../../components/Report';
import Loading from '../../../../components/Loading';
import ValidationReportContext from '../../../../contexts/ValidationReportContext';

const useReport = () => {
  const { reportId } = useParams<{ reportId?: string }>();
  const { allReports } = useContext(ReportsContext);
  const [report, setReport] = useState<ReportType | undefined>();
  useEffect(() => {
    setReport(allReports.find((r) => r.id === reportId));
  }, [allReports, reportId]);

  return report;
};

const ValidationReportContainer = () => {
  const report = useReport();
  const { metrics, compoundMetrics } = useMetricsOnReport(report);

  if (!report) {
    return <Loading />;
  }

  return (
    <ValidationReportContext.Provider
      value={{
        metrics,
        compoundMetrics,
      }}
    >
      <Report report={report} key={report.id} isValidationReport />
    </ValidationReportContext.Provider>
  );
};

export default ValidationReportContainer;
