import React, { useCallback, useContext, useEffect, useState } from 'react';
import useFilterInput from '../../../hooks/useFilterInput';
import Card from '../../Common/Card';
import CardInner from './CardInner';
import Typography from 'kingpin/atoms/Typography';
import FilterPlates from './FilterPlates';
import CardContext from '../../../contexts/CardContext';
import visTypeCheckers from '../../../types/visTypeCheckers';
import mergeFilterInputs from '../../../mergeFilterInputs';

const useGroupingField = () => {
  const { chartDefinition } = useContext(CardContext);

  if (!chartDefinition) {
    return undefined;
  }

  if (visTypeCheckers.isV5ChartDef(chartDefinition)) {
    return chartDefinition.dimensionA
      ? chartDefinition.dimensionA.field
      : undefined;
  }

  if (visTypeCheckers.isSingleMetricDateMatrix(chartDefinition)) {
    return chartDefinition.groupByField;
  }

  if (visTypeCheckers.isRankingMatrix(chartDefinition)) {
    return chartDefinition.groupByField;
  }

  return undefined;
};

const useContextualFilters = (
  metricInfo: MetricsInfoButton.NormalMetricInfo,
) => {
  const groupingField = useGroupingField();
  const additionalCardFilters = useFilterInput(groupingField);

  const getAdditionalFilters = useCallback(() => {
    const additionalMetricFilters = metricInfo.additionalFilters;
    const newFilters: FilterInput[] = [];
    newFilters.push(additionalCardFilters);
    if (additionalMetricFilters) {
      newFilters.push(additionalMetricFilters);
    }

    return newFilters.reduce((a, b) => {
      return mergeFilterInputs(a, b);
    }, {});
  }, [additionalCardFilters, metricInfo.additionalFilters]);
  const [additionalFilters, setAdditionalFilters] =
    useState<FilterInput>(getAdditionalFilters);
  useEffect(() => {
    setAdditionalFilters(getAdditionalFilters());
  }, [getAdditionalFilters]);

  return additionalFilters;
};

const useAdditionalFilters = (
  metricInfo: MetricsInfoButton.NormalMetricInfo,
) => {
  const additionalFilters = useContextualFilters(metricInfo);
  const [numAdditionalFilters, setNumAdditionalFilters] = useState<number>(0);

  useEffect(() => {
    let newNum = 0;
    const {
      keywords,
      wildcardFilters,
      booleanFilters,
      ranges,
      mustExistFilters,
      mustNotExistFilters,
    } = additionalFilters;
    if (keywords) {
      newNum += keywords.length;
    }
    if (wildcardFilters) {
      newNum += wildcardFilters.length;
    }
    if (booleanFilters) {
      newNum += booleanFilters.length;
    }
    if (ranges) {
      newNum += ranges.length;
    }
    if (mustExistFilters) {
      newNum += mustExistFilters.length;
    }
    if (mustNotExistFilters) {
      newNum += mustNotExistFilters.length;
    }

    setNumAdditionalFilters(newNum);
  }, [additionalFilters]);

  return {
    additionalFilters,
    numAdditionalFilters,
  };
};

const AdditionalFilters = ({
  metricInfo,
}: {
  metricInfo: MetricsInfoButton.NormalMetricInfo;
}) => {
  const { additionalFilters, numAdditionalFilters } =
    useAdditionalFilters(metricInfo);

  if (numAdditionalFilters === 0) {
    return null;
  }

  return (
    <Card style={{ marginTop: 16 }}>
      <CardInner>
        <div style={{ marginBottom: 8 }}>
          <Typography.Body type="Label">
            The current view of this metric has the following additional filters
            applied:
          </Typography.Body>
        </div>
        <FilterPlates filters={additionalFilters} />
      </CardInner>
    </Card>
  );
};

export default AdditionalFilters;
