import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import MetricOptionsContext from '../../contexts/MetricOptionsContext';
import { isKpiRow } from '../../hooks/kpiTypeCheckers';
import isDefined from '../../isDefined';
import metricTypeCheckers from '../../types/metricTypeCheckers';

const useScorecardDatasets = (scorecard: Scorecards.Scorecard) => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const getDatasets = useCallback(() => {
    const metrics = scorecard.kpis
      .filter(isKpiRow)
      .map((kpi) => metricOptionsLookup[kpi.metricId])
      .filter(isDefined)
      .filter(metricTypeCheckers.isNotSpecialMetric)
      .reduce((normalMetrics: Metrics.NormalMetric[], currentMetric) => {
        if (metricTypeCheckers.isNormalMetric(currentMetric)) {
          return [...normalMetrics, currentMetric];
        }

        const subMetrics = currentMetric.metricIds
          .map((mid) => metricOptionsLookup[mid])
          .filter(isDefined)
          .filter(metricTypeCheckers.isNormalMetric);

        return [...normalMetrics, ...subMetrics];
      }, []);

    return _.uniq(metrics.map((m) => m.dataType));
  }, [metricOptionsLookup, scorecard.kpis]);
  const [datasets, setDatasets] = useState<string[]>(() => getDatasets());
  useEffect(() => {
    setDatasets(getDatasets());
  }, [getDatasets]);

  return datasets;
};

export default useScorecardDatasets;
