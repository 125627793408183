import { DateTime, DurationUnit } from 'luxon';

// Note that this is specific to luxon
export const getLuxonStartWeekday = (startOfWeek: WeekStartsOn): number => {
  switch (startOfWeek) {
    case 'MON':
      return 1;
    case 'TUE':
      return 2;
    case 'WED':
      return 3;
    case 'THUR':
      return 4;
    case 'FRI':
      return 5;
    case 'SAT':
      return 6;
    case 'SUN':
      return 7;
  }
};

const getStartOfWeek = (
  startOfWeek: WeekStartsOn,
  comparedTo?: string,
): DateTime => {
  const today = getBase({ unit: 'day', startOfWeek, comparedTo });
  const todayWeekday = today.weekday;
  const startWeekday = getLuxonStartWeekday(startOfWeek);
  const diff = Math.abs(startWeekday - todayWeekday);
  if (startWeekday > todayWeekday) {
    return today.minus({ day: 7 - diff });
  } else {
    return today.minus({ day: diff });
  }
};

export const getBase = ({
  unit,
  comparedTo,
  startOfWeek,
}: {
  unit: DurationUnit;
  comparedTo?: string;
  startOfWeek: WeekStartsOn;
}) => {
  if (unit === 'week') {
    return getStartOfWeek(startOfWeek, comparedTo);
  }

  if (comparedTo) {
    return DateTime.fromISO(comparedTo).startOf(unit);
  } else {
    return DateTime.local().startOf(unit);
  }
};

const relativeDateRangeToDateRange = ({
  relativeDateRange,
  startOfWeek,
  comparedTo,
  dateField,
}: {
  relativeDateRange: RelativeDateRange;
  startOfWeek: WeekStartsOn;
  comparedTo?: string;
  dateField?: string;
}): DateRangeInput => {
  const { type, interval, n, currentToDate } = relativeDateRange;
  if (type === 'last') {
    return {
      dateField,
      startDate: getBase({ unit: interval, startOfWeek, comparedTo })
        .minus({
          [interval]: n,
        })
        .toISODate(),
      endDate: currentToDate
        ? getBase({ unit: 'day', startOfWeek, comparedTo })
            .minus({ day: interval === 'day' ? 0 : 1 })
            .toISODate()
        : getBase({ unit: interval, startOfWeek, comparedTo })
            .minus({ day: 1 })
            .toISODate(),
    };
  } else if (type === 'current') {
    return {
      dateField,
      startDate: getBase({
        unit: interval,
        startOfWeek,
        comparedTo,
      }).toISODate(),
      endDate: currentToDate
        ? getBase({ unit: 'day', startOfWeek, comparedTo }).toISODate()
        : getBase({ unit: interval, startOfWeek, comparedTo })
            .plus({ [interval]: 1 })
            .minus({ day: 1 })
            .toISODate(),
    };
  } else if (type === 'next') {
    return {
      dateField,
      startDate: currentToDate
        ? getBase({ unit: interval, startOfWeek, comparedTo }).toISODate()
        : getBase({ unit: interval, startOfWeek, comparedTo })
            .plus({
              [interval]: 1,
            })
            .toISODate(),
      endDate: getBase({ unit: interval, startOfWeek, comparedTo })
        .plus({ [interval]: n ? n + 1 : 2 })
        .minus({ day: 1 })
        .toISODate(),
    };
  } else {
    const error = new Error();
    error.name = `Unknown relative date type: ${type}`;
    throw error;
  }
};

export default relativeDateRangeToDateRange;
