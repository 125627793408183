import React from 'react';
import DatasetFiltersProvider from './DatasetFiltersProvider';
import DatasetDefinitionsProvider from './DatasetDefinitionsProvider';
import BaseViewsProvider from './BaseViewsProvider';
import DatasetFreshnessProvider from './DatasetFreshnessProvider';
import DefaultDatasetFiltersProvider from './DefaultDatasetFiltersProvider';

const DatasetProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <DatasetFiltersProvider>
    <DatasetDefinitionsProvider>
      <DefaultDatasetFiltersProvider>
        <BaseViewsProvider>
          <DatasetFreshnessProvider>{children}</DatasetFreshnessProvider>
        </BaseViewsProvider>
      </DefaultDatasetFiltersProvider>
    </DatasetDefinitionsProvider>
  </DatasetFiltersProvider>
);

export default DatasetProviders;
