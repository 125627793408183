import React, {
  ChangeEvent,
  useContext,
  useState,
  useCallback,
  useEffect,
  memo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import FieldListItem from './FieldListItem';
import DataDictionaryContext from '../../../../../contexts/DataDictionaryContext';
import BaseViewsContext from '../../../../../contexts/BaseViewsContext';
import appRoutes from '../../../../../navigation/appRoutes';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';
import { PortalsContext } from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const useField = (fieldView: FleetOps.BaseViewField) => {
  const { selectedDataset } = useContext(DataDictionaryContext);

  const getField = useCallback(() => {
    if (selectedDataset) {
      return selectedDataset.fields.find((f) => f.field === fieldView.field);
    }
    return undefined;
  }, [fieldView.field, selectedDataset]);
  const [field, setField] = useState<FleetOps.Field | undefined>(getField);
  useEffect(() => {
    setField(getField());
  }, [getField]);

  return field;
};

const FieldListItemContainer = ({
  fieldView,
  baseView,
  canDrag,
}: {
  fieldView: FleetOps.BaseViewField;
  baseView: FleetOps.BaseView;
  canDrag: boolean;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const navigate = useNavigate();
  const { selectedField, selectedBaseView } = useContext(DataDictionaryContext);
  const field = useField(fieldView);
  const { updateBaseView } = useContext(BaseViewsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const isSelected = selectedField && selectedField.field === fieldView.field;
  const [currentName, setCurrentName] = useState<string | undefined>(
    fieldView.nameAlias,
  );

  const onDisplayNameChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newDisplayName = event.target.value;
      const newField = {
        ...fieldView,
        nameAlias: newDisplayName === '' ? undefined : newDisplayName,
      } as FleetOps.BaseViewField;
      const newFields = {
        ...baseView.fields,
        [fieldView.field]: newField,
      };
      const newBaseView = {
        ...baseView,
        fields: newFields,
      };
      setCurrentName(newDisplayName);
      updateBaseView(newBaseView);
      trackEvent('Datasets - Field - Display Name Changed', {
        datasetName: baseView.type,
        field: fieldView.field,
      });
    },
    [baseView, fieldView, trackEvent, updateBaseView],
  );

  const onShowInGridChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newIsVisible = event.target.checked;
      const newField = {
        ...fieldView,
        isVisible: newIsVisible,
      } as FleetOps.BaseViewField;
      const newFields = {
        ...baseView.fields,
        [fieldView.field]: newField,
      };
      const newBaseView = {
        ...baseView,
        fields: newFields,
      };
      updateBaseView(newBaseView);
    },
    [baseView, fieldView, updateBaseView],
  );

  const onDescriptionChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newDescription = event.target.value;
      const newField = {
        ...fieldView,
        description: newDescription,
      } as FleetOps.BaseViewField;
      const newFields = {
        ...baseView.fields,
        [fieldView.field]: newField,
      };
      const newBaseView = {
        ...baseView,
        fields: newFields,
      };
      updateBaseView(newBaseView);
      trackEvent('Datasets - Field - Description Changed', {
        datasetName: baseView.type,
        field: fieldView.field,
      });
    },
    [baseView, fieldView, trackEvent, updateBaseView],
  );

  const onSelected = useCallback(() => {
    if (selectedBaseView) {
      trackEvent('Datasets - Field - Opened', {
        datasetName: baseView.type,
        field: fieldView.field,
      });
      navigate(
        appRoutes.loggedIn.datasetDefinitions.buildShowField(
          selectedBaseView.type,
          fieldView.field,
          selectedPortal,
        ),
      );
    }
  }, [
    baseView.type,
    fieldView.field,
    navigate,
    selectedBaseView,
    selectedPortal,
    trackEvent,
  ]);

  if (!field) {
    return null;
  }

  return (
    <FieldListItem
      isSelected={!!isSelected}
      currentName={currentName}
      field={field}
      fieldView={fieldView}
      onDisplayNameChanged={onDisplayNameChanged}
      onShowInGridChanged={onShowInGridChanged}
      onDescriptionChanged={onDescriptionChanged}
      canDrag={canDrag}
      onSelected={onSelected}
    />
  );
};

const MemoizedFieldListItemContainer = memo(FieldListItemContainer);

export default MemoizedFieldListItemContainer;
