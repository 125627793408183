import { useCallback, useContext } from 'react';
import BoardsContext from 'contexts/BoardsContext';
import PopupContext from 'contexts/PopupContext';
import ReportsContext from 'contexts/ReportsContext';
import ScorecardContext from 'contexts/ScorecardContext';
import { isKpiRow } from 'hooks/kpiTypeCheckers';
import moment from 'moment/moment';

const useOpenKpiPopup = (
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow | undefined,
  period: Period | undefined,
) => {
  const { allReports } = useContext(ReportsContext);
  const { boards } = useContext(BoardsContext);
  const { openPopupReport, openPopupBoard } = useContext(PopupContext);
  const { setPoppedUpKpi, periods } = useContext(ScorecardContext);

  const openKpiPopup = useCallback(() => {
    if (!kpi || !period) {
      return;
    }

    if (kpi.reportDrillDownId || kpi.boardDrillDownId) {
      setPoppedUpKpi(kpi);
    }

    if (kpi.reportDrillDownId) {
      const report = allReports.find((r) => r.id === kpi.reportDrillDownId);
      if (report) {
        const endDate = (() => {
          const today = moment().startOf('day').format('YYYY-MM-DD');
          if (period.endDate > today) {
            return moment()
              .startOf('day')
              .subtract({ day: 1 })
              .format('YYYY-MM-DD');
          } else {
            return period.endDate;
          }
        })();

        const unSavedFilter: UnSavedFilter = {
          drillDowns: isKpiRow(kpi) ? kpi.drillDowns : window.emptyArray,
          dateScope: {},
          scope: isKpiRow(kpi) ? kpi.scopeDrillDowns : window.emptyArray,
          dateField: isKpiRow(kpi) ? kpi.queryDateField || 'date' : 'date',
          dateRange: {
            startDate: period.startDate,
            endDate,
          },
        };

        openPopupReport({
          drillDowns: [],
          origin: 'Scorecard',
          scorecardPeriodSettings: {
            availablePeriods: periods,
            selectedPeriod: period,
            kpi,
          },
          selectedReport: report,
          unSavedFilter,
        });
      }
    } else if (isKpiRow(kpi) && kpi.boardDrillDownId) {
      const board = boards.find((b) => b.id === kpi.boardDrillDownId);
      if (board) {
        const dateRange = {
          startDate: period.startDate,
          endDate: period.endDate,
        };
        openPopupBoard({
          popupFilters: kpi.drillDowns,
          scope: kpi.scopeDrillDowns,
          popupDateRange: dateRange,
          origin: 'Goal',
          scorecardPeriodSettings: {
            availablePeriods: periods,
            selectedPeriod: period,
            kpi,
          },
          selectedBoard: board,
        });
      }
    }
  }, [
    kpi,
    setPoppedUpKpi,
    allReports,
    period,
    openPopupReport,
    periods,
    boards,
    openPopupBoard,
  ]);

  return kpi && period ? openKpiPopup : undefined;
};

export default useOpenKpiPopup;
