import React, { useCallback, useContext, useState } from 'react';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import STORE from '../../../store';
import NotificationFactory from '../../../NotificationFactory';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import appRoutes, {
  buildDashboardShow,
  buildReportShow,
} from '../../../navigation/appRoutes';
import ShareModal from './ShareModal';
import useSharedWith from './useSharedWith';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const ShareModalContainer = ({
  type,
  typeId,
  contentName,
  close,
}: {
  type: 'report' | 'dashboard' | 'scorecard' | 'workSpace';
  typeId: string;
  contentName: string;
  close: () => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { displayName } = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { sharedWith, isLoadingSharedWith, refreshSharedWith } = useSharedWith({
    typeId,
    type,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<
    (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
  >([]);
  const [shareMessage, setShareMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | undefined>();

  const onShareMessageChanged = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setShareMessage(event.target.value);
    },
    [],
  );

  const showSuccessMessage = useCallback(() => {
    const message =
      selectedUsers.length === 1
        ? `Shared with ${selectedUsers[0].displayName}`
        : `Shared with ${selectedUsers.length} Portals`;
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(undefined);
    }, 10000);
  }, [selectedUsers]);

  const getContentLink = useCallback(() => {
    if (typeId === 'dashboard') {
      return buildDashboardShow({ id: typeId, portal: selectedPortal });
    } else if (typeId === 'report') {
      return buildReportShow({ id: typeId, portal: selectedPortal });
    }

    return appRoutes.home;
  }, [selectedPortal, typeId]);

  const onShareClicked = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const promises = selectedUsers.map(async (user) => {
      const sharedContentRef = STORE.users.getSharedContentRef({
        accountId: selectedAccountId,
        userId: user.id,
      });
      const currentSharedContentDoc = await sharedContentRef.get();
      const sharedContent: PersistedSharedContent =
        currentSharedContentDoc.data() || {
          workspaceIds: [],
          reportIds: [],
          dashboardIds: [],
          scorecardIds: [],
        };
      const newSharedContent = {
        ...sharedContent,
      };
      if (type === 'report') {
        newSharedContent.reportIds = [...newSharedContent.reportIds, typeId];
      }
      if (type === 'dashboard') {
        newSharedContent.dashboardIds = [
          ...newSharedContent.dashboardIds,
          typeId,
        ];
      }
      if (type === 'scorecard') {
        newSharedContent.scorecardIds = [
          ...newSharedContent.scorecardIds,
          typeId,
        ];
      }
      if (type === 'workSpace') {
        newSharedContent.workspaceIds = [
          ...newSharedContent.workspaceIds,
          typeId,
        ];
      }

      if (shareMessage !== '') {
        NotificationFactory.contentShared({
          sharedByDisplayName: displayName,
          shareMessage,
          contentName,
          link: getContentLink(),
        });
      }

      return sharedContentRef.set(newSharedContent);
    });

    await Promise.all(promises);
    showSuccessMessage();
    setSelectedUsers([]);
    setIsLoading(false);
    refreshSharedWith();
  }, [
    contentName,
    displayName,
    getContentLink,
    isLoading,
    refreshSharedWith,
    selectedAccountId,
    selectedUsers,
    shareMessage,
    showSuccessMessage,
    type,
    typeId,
  ]);

  return (
    <ShareModal
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
      sharedWith={sharedWith}
      isLoadingSharedWith={isLoadingSharedWith}
      shareMessage={shareMessage}
      onShareMessageChanged={onShareMessageChanged}
      contentName={contentName}
      type={type}
      typeId={typeId}
      isLoading={isLoading}
      successMessage={successMessage}
      onShareClicked={onShareClicked}
      close={close}
      refreshSharedWith={refreshSharedWith}
    />
  );
};

export default ShareModalContainer;
