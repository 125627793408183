import React, { useCallback, useContext, useEffect } from 'react';
import STORE from 'store';
import BoardContext from 'contexts/BoardContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import PerformanceBoardSettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import isPerformanceBoard from 'isPerformanceBoard';
import getTimeStamp from 'getTimeStamp';
import useUpdateFilters from '../hooks/useUpdateFilters';
import QuickFilters from './QuickFilters/QuickFilters';

const BoardFiltersSettings = () => {
  const { setHasUnsavedChanges } = useContext(PerformanceBoardSettingsContext);
  const { board, quickFilters } = useContext(BoardContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { setNavState } = useContext(PerformanceBoardSettingsContext);
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, [setHasUnsavedChanges]);

  const { updateFilters, quickFilterOrder } = useUpdateFilters();

  const orderedFilters = quickFilterOrder
    .map((filterId) => quickFilters.find((f) => f.id === filterId))
    .filter((f) => !!f) as LegacySavedFilter[];
  const filtersNotInOrder = quickFilters.filter(
    (f) => !quickFilterOrder.includes(f.id),
  );
  orderedFilters.push(...filtersNotInOrder);

  const deleteFilter = useCallback(
    (f: LegacySavedFilter) => {
      STORE.filterDefinitions
        .getLegacySavedFiltersRef({ accountId: selectedAccountId })
        .doc(f.id)
        .delete()
        .then(() => {
          const orderWithoutDeleted = quickFilterOrder.filter(
            (fid) => !fid.includes(f.id),
          );
          const newBoard = {
            ...board,
            quickFilterOrder: orderWithoutDeleted,
            updatedBy: currentUser.id,
            updatedOn: getTimeStamp(),
          };

          if (isPerformanceBoard(newBoard)) {
            STORE.contentDistributions
              .getPerformanceBoardsRef({
                accountId: selectedAccountId,
              })
              .doc(newBoard.id)
              .set(newBoard);
          }
        });
    },
    [selectedAccountId, board, currentUser.id, quickFilterOrder],
  );

  const editFilter = useCallback(
    (filter: LegacySavedFilter) => {
      setNavState({ mode: 'Quick Filters Config', editQuickFilter: filter });
    },
    [setNavState],
  );
  return (
    <QuickFilters
      editFilter={editFilter}
      deleteFilter={deleteFilter}
      orderedFilters={orderedFilters}
      updateFilters={updateFilters}
    />
  );
};

export default BoardFiltersSettings;
