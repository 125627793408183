import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Typography from 'kingpin/atoms/Typography';

import ToastContext from '../../../contexts/ToastContext';
import Colors2 from '../../../theme/Colors2';
import { Z_INDEX } from '../../../constants';

const ToastMessage = styled(motion.div)`
  position: absolute;
  width: fit-content;
  top: 0px;
  left: calc(50% - 100px);
  margin: auto;
  background-color: ${Colors2.Grey['2']};
  padding: 8px 32px;

  text-align: center;
  border-radius: 8px;

  box-shadow:
    0px 0px 0px 1px rgba(87, 89, 97, 0.1),
    1px 7px 20px -2px rgba(0, 0, 0, 0.4),
    0px -1px 3px -2px rgba(0, 0, 0, 0.25);

  z-index: ${Z_INDEX.TOAST};
`;

const DEFAULT_TOP = 50;

const variants = {
  opened: (custom: { top: number }) => ({ opacity: 1, top: custom.top }),
  closed: {
    top: 0,
    opacity: 0,
  },
};

const ToastProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [top, setTop] = useState<number>(DEFAULT_TOP);

  const hideToast = useCallback(() => {
    setIsVisible(false);
    setMessage('');
    setTop(DEFAULT_TOP);
  }, []);

  const showToast = useCallback(
    (newMessage: string, customTop?: number) => {
      setMessage(newMessage);
      if (customTop !== undefined) {
        setTop(customTop);
      }
      setIsVisible(true);

      setTimeout(() => {
        hideToast();
      }, 3000);
    },
    [hideToast],
  );

  return (
    <ToastContext.Provider
      value={{
        showToast,
      }}
    >
      {children}
      <ToastMessage
        variants={variants}
        animate={isVisible ? 'opened' : 'closed'}
        custom={{ top }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Header type="H4" color="white">
            {message}
          </Typography.Header>
        </div>
      </ToastMessage>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
