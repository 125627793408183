import React, { useState } from 'react';
import Typography from 'kingpin/atoms/Typography';
import PlateStyles from './PlateStyles';

const WildcardFilterPlate = ({ filter }: { filter: WildcardFilterInput }) => {
  const [middleText] = useState<string>(() => {
    if (filter.exclude) {
      return 'does not match';
    }

    return 'matches';
  });
  return (
    <div className="filter-plate">
      <PlateStyles.Plate>
        <PlateStyles.LeftPlate>
          <Typography.Body type="Body 12">{filter.field}</Typography.Body>
        </PlateStyles.LeftPlate>
        <PlateStyles.MiddlePlate>
          <Typography.Body type="Body 12">{middleText}</Typography.Body>
        </PlateStyles.MiddlePlate>
        <PlateStyles.RightPlate>
          <Typography.Body type="Body 12">{filter.pattern}</Typography.Body>
        </PlateStyles.RightPlate>
      </PlateStyles.Plate>
    </div>
  );
};

export default WildcardFilterPlate;
