import { useContext, useEffect, useState } from 'react';
import { FEATURE_GATES } from '../../../../components/FeatureGate';
import { ProductTierContext } from '../ProductTierProvider';

type TProductTierGates = {
  [key in FleetOps.TProductTier]: FleetOps.FeatureGate[];
};

const EXEC_GATES: FleetOps.FeatureGate[] = [];
const ENGAGE_GATES: FleetOps.FeatureGate[] = [
  FEATURE_GATES.PERFORMANCE,
  FEATURE_GATES.TEMPLATES,
  FEATURE_GATES.VIEWER_ROLE_ASSIGNMENT,
  FEATURE_GATES.MICROSOFT_SSO,
];
const ENTERPRISE_GATES: FleetOps.FeatureGate[] = [];

const ProductTierGates: TProductTierGates = {
  Exec: [...EXEC_GATES],
  Engage: [...EXEC_GATES, ...ENGAGE_GATES],
  Enterprise: [...EXEC_GATES, ...ENGAGE_GATES, ...ENTERPRISE_GATES],
};

const useProductTierOpenedGates = () => {
  const { productTier, isSSODisabled } = useContext(ProductTierContext);

  const [isPerformanceEnabled, setIsPerformanceEnabled] =
    useState<boolean>(false);
  const [isTemplatesEnabled, setIsTemplatesEnabled] = useState<boolean>(false);
  const [isViewerRoleAssignmentEnabled, setIsViewerRoleAssignmentEnabled] =
    useState<boolean>(false);
  const [isMicrosoftSsoEnabled, setIsMicrosoftSsoEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    const openedGates = ProductTierGates[productTier];
    setIsPerformanceEnabled(openedGates.includes(FEATURE_GATES.PERFORMANCE));
    setIsTemplatesEnabled(openedGates.includes(FEATURE_GATES.TEMPLATES));
    setIsViewerRoleAssignmentEnabled(
      openedGates.includes(FEATURE_GATES.VIEWER_ROLE_ASSIGNMENT),
    );
    setIsMicrosoftSsoEnabled(
      openedGates.includes(FEATURE_GATES.MICROSOFT_SSO) && !isSSODisabled,
    );
  }, [isSSODisabled, productTier]);

  return {
    isPerformanceEnabled,
    isTemplatesEnabled,
    isViewerRoleAssignmentEnabled,
    isMicrosoftSsoEnabled,
  };
};

export default useProductTierOpenedGates;
