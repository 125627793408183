import React, { useContext, useState } from 'react';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import DataDictionaryContext from '../../contexts/DataDictionaryContext';

const DataDictionaryProvider = ({
  dataset,
  children,
}: {
  dataset?: string;
  children: JSX.Element | JSX.Element[];
}) => {
  const [field, setField] = useState<string | undefined>();
  const { datasets } = useContext(DatasetDefinitionsContext);
  const { baseViews } = useContext(BaseViewsContext);

  const selectedDataset = dataset
    ? datasets.find((d) => d.type === dataset)
    : undefined;
  const selectedBaseView = dataset ? baseViews[dataset] : undefined;
  const selectedField =
    selectedDataset && field
      ? selectedDataset.fields.find((f) => f.field === field)
      : undefined;
  const selectedBaseFieldView =
    selectedBaseView && field ? selectedBaseView.fields[field] : undefined;

  return (
    <DataDictionaryContext.Provider
      value={{
        selectedBaseView,
        selectedDataset,
        selectedField,
        selectedBaseFieldView,
        setField,
      }}
    >
      {children}
    </DataDictionaryContext.Provider>
  );
};

export default DataDictionaryProvider;
