import React from 'react';

import GlobalTasksProvider from '../../TasksProvider/GlobalTasksProvider';
import GlobalTimelineProvider from '../../TimelineProvider/GlobalTimelineProvider';
import NotificationsProvider from './NotificationsProvider';
import ToastProvider from './ToastProvider';
import RowHeightProvider from './RowHeightProvider';

const MiscProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <NotificationsProvider>
    <GlobalTasksProvider>
      <GlobalTimelineProvider>
        <ToastProvider>
          <RowHeightProvider>{children}</RowHeightProvider>
        </ToastProvider>
      </GlobalTimelineProvider>
    </GlobalTasksProvider>
  </NotificationsProvider>
);

export default MiscProviders;
