import { ApisauceInstance } from 'apisauce';

export const submitContactUsForm = async ({
  api,
  type,
  subject,
  message,
  attachments,
  metadata,
}: {
  api: ApisauceInstance;
  type: string;
  subject: string;
  message: string;
  attachments: File[];
  metadata: string;
}): Promise<boolean> => {
  const form = new FormData();
  form.append('type', type);
  form.append('subject', subject);
  form.append('message', message);
  form.append('metadata', metadata);
  for (const attachment of attachments) {
    form.append(attachment.name, attachment);
  }

  const response = await api.post<boolean>('/v1/create_support_ticket', form);
  return !!(response.ok && response.data);
};

// wake up the cloud function, so it will respond faster once the form is filled out and submitted.
export const wakeContactUsCloudFunction = async ({
  api,
}: {
  api: ApisauceInstance;
}) => {
  const form = new FormData();
  form.append('ping', 'true');
  await api.post<void>('/v1/create_support_ticket', form);
};
