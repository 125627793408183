import { useCallback, useContext } from 'react';
import MetricOptionsContext from 'contexts/MetricOptionsContext';
import mergeFilterInputs from 'mergeFilterInputs';
import {
  usePlatesToFilterInput,
  useBuildCompoundMetricInput,
  useBuildMetricInput,
} from 'screens/GoalShow/useGoalData';
import isDefined from 'isDefined';

const useBuildKpiInputs = (scorecard: Scorecards.Scorecard) => {
  const { metricOptions } = useContext(MetricOptionsContext);
  const buildMetricInput = useBuildMetricInput();
  const buildCompoundMetricInput = useBuildCompoundMetricInput();
  const platesToFilterInput = usePlatesToFilterInput();

  const buildKpiInputs = useCallback((): KpiInput[] => {
    const kpis = [...scorecard.kpis] as Scorecards.ScorecardKpi[];
    return kpis
      .map((kpi: Scorecards.ScorecardKpi) => {
        const kpiMetric = metricOptions.find((m) => m.id === kpi.metricId);
        if (!kpiMetric) {
          return undefined;
        }
        const m = buildMetricInput(
          kpi.metricId,
          !!kpi.isDefaultFiltersDisabled,
        );
        const cm = buildCompoundMetricInput(
          kpi.metricId,
          !!kpi.isDefaultFiltersDisabled,
        );
        const filters = mergeFilterInputs(
          platesToFilterInput(kpi.drillDowns),
          platesToFilterInput(kpi.scopeDrillDowns),
        );
        return {
          metric: m ? { ...m, id: kpi.id } : undefined,
          compoundMetric: cm ? { ...cm, id: kpi.id } : undefined,
          filters,
          dateField: kpi.queryDateField || 'date',
        };
      })
      .filter(isDefined)
      .filter((kpi) => kpi.metric || kpi.compoundMetric);
  }, [
    buildCompoundMetricInput,
    buildMetricInput,
    metricOptions,
    platesToFilterInput,
    scorecard.kpis,
  ]);

  return buildKpiInputs;
};

export default useBuildKpiInputs;
