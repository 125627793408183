import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import LocalTimelineContext from '../../../../contexts/Timeline/LocalTimelineContext';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import getTimeStamp from '../../../../getTimeStamp';
import { getMetricLink } from '../../AllSets/routes';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../../../components/Inputs';
import Row from '../../../../components/Common/Row';
import LocalTimelineProvider from 'contextProviders/TimelineProvider/LocalTimelineProvider';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import createCompoundMetric from '../../../../api/compoundMetrics/createCompoundMetric';
import createMetric from '../../../../api/metrics/createMetric';
import { PortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const DuplicatePopup = ({
  duplicateId,
  metric,
  close,
  openMetricPopup,
}: {
  duplicateId: string;
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  close: () => void;
  openMetricPopup: (
    metric: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const currentUser = useContext(CurrentUserContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const navigate = useNavigate();
  const { dataset } = useParams<{ dataset?: string }>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [name, setName] = useState<string>(`${metric.name} (Copy)`);

  const onNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const onSave = useCallback(() => {
    setIsSaving(true);
    if (metricTypeCheckers.isCompoundMetric(metric)) {
      const newMetric = {
        ...metric,
        id: duplicateId,
        name,
        status: 'public' as 'public',
        createdBy: currentUser.id,
        createdOn: getTimeStamp(),
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
      };

      createCompoundMetric(newMetric, selectedAccountId).then(async () => {
        trackEvent('Metric Builder - Metric Saved As', {
          metricName: newMetric.name,
          metricId: newMetric.id,
        });
        if (addEvent) {
          await addEvent({
            actionText: 'Created metric',
            contextText: newMetric.name,
          });
        }
        setIsSaving(false);
        navigate(
          getMetricLink({
            metric: newMetric,
            dataset,
            portal: selectedPortal,
          }),
        );
        openMetricPopup(newMetric);
        close();
      });
    } else {
      const newMetric = {
        ...metric,
        id: duplicateId,
        name,
        status: 'public' as 'public',
        createdBy: currentUser.id,
        createdOn: getTimeStamp(),
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
      };

      createMetric(newMetric, selectedAccountId).then(async () => {
        trackEvent('Metric Builder - Metric Saved As', {
          metricName: newMetric.name,
          metricId: newMetric.id,
        });
        if (addEvent) {
          await addEvent({
            actionText: 'Created metric',
            contextText: newMetric.name,
          });
        }
        setIsSaving(false);
        navigate(
          getMetricLink({
            metric: newMetric,
            dataset,
            portal: selectedPortal,
          }),
        );
        openMetricPopup(newMetric);
        close();
      });
    }
  }, [
    metric,
    duplicateId,
    name,
    currentUser.id,
    selectedAccountId,
    trackEvent,
    addEvent,
    navigate,
    dataset,
    selectedPortal,
    openMetricPopup,
    close,
  ]);

  return (
    <Modal
      onClose={close}
      shouldScrollInViewport={false}
      autoFocus={false}
      width={800}
    >
      <div style={{ padding: 16 }}>
        <Typography.Header type="H4">{`Duplicate ${metric.name}`}</Typography.Header>
        <div style={{ marginBottom: 16 }}>
          <Typography.Body type="Label">Name</Typography.Body>
          <Inputs.TextInput
            value={name}
            onChange={onNameChanged}
            data-testid="dupe-name-input"
          />
        </div>
        <Row spaceBetween>
          <Button type="Ghost" size="Small" label="Cancel" onClick={close} />
          <Button
            type="Primary"
            size="Small"
            onClick={onSave}
            isDisabled={isSaving || name === ''}
            isLoading={isSaving}
            testId="create-duplicate"
            label="Create"
          />
        </Row>
      </div>
    </Modal>
  );
};

const Gate = ({
  duplicateId,
  metric,
  close,
  openMetricPopup,
}: {
  duplicateId: string;
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  close: () => void;
  openMetricPopup: (
    metric: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
}) => {
  const [timelineSources] = useState<Timeline.EventSource[]>([
    {
      type: metricTypeCheckers.isCompoundMetric(metric)
        ? 'CompoundMetric'
        : 'Metric',
      id: duplicateId,
    },
  ]);
  const [timelineDestination] = useState<Timeline.EventSource>({
    type: metricTypeCheckers.isCompoundMetric(metric)
      ? 'CompoundMetric'
      : 'Metric',
    id: duplicateId,
  });

  return (
    <LocalTimelineProvider
      sources={timelineSources}
      destination={timelineDestination}
    >
      <DuplicatePopup
        duplicateId={duplicateId}
        metric={metric}
        close={close}
        openMetricPopup={openMetricPopup}
      />
    </LocalTimelineProvider>
  );
};

export default Gate;
