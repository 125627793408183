// What portals does this user have access to this content through?
import { useCallback, useContext, useEffect, useState } from 'react';
import { ExecutivePortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/ExecutivePortalsProvider';
import { EngagementPortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/EngagementPortalsProvider';

const usePortalAccess = ({
  type,
  typeId,
  user,
}: {
  type: 'report' | 'dashboard' | 'scorecard' | 'workSpace';
  typeId: string;
  user: UserManagement.SignedUpUser | UserManagement.PendingUser;
}) => {
  const { allEngagementPortals } = useContext(EngagementPortalsContext);
  const { allExecutivePortals } = useContext(ExecutivePortalsContext);

  const getAccessPortals = useCallback(() => {
    const portalsWithThisContent = [
      ...allExecutivePortals,
      ...allEngagementPortals,
    ].filter((portal) => {
      // We don't care for workSpaces here
      if (type === 'dashboard') {
        return portal.dashboardIds.includes(typeId);
      } else if (type === 'report') {
        return portal.reportIds.includes(typeId);
      } else if (type === 'scorecard') {
        return portal.scorecardIds.includes(typeId);
      }
      return false;
    });

    return portalsWithThisContent.filter((portal) =>
      portal.managerIds.includes(user.id),
    );
  }, [allEngagementPortals, allExecutivePortals, type, typeId, user.id]);

  const [accessPortals, setAccessPortals] = useState<
    (ExecutivePortal | EngagementPortal)[]
  >(() => getAccessPortals());

  useEffect(() => {
    setAccessPortals(getAccessPortals());
  }, [getAccessPortals]);

  return accessPortals;
};

export default usePortalAccess;
