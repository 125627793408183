import ChartDefinitionsContext from 'contexts/ChartDefinitionsContext';
import { useCallback, useContext } from 'react';
import isFilterUsedInChart from '../isFilterUsedInChart';
import useGetIsUsedInMetricId from './useGetIsUsedInMetricId';

const useGetChartDefinitionUsage = () => {
  const { definitions } = useContext(ChartDefinitionsContext);
  const getIsUsedInMetricId = useGetIsUsedInMetricId();

  const getChartDefinitionUsage = useCallback(
    (filter: SavedFilter): VisualisationDefinition[] => {
      return definitions.filter((def) => {
        return isFilterUsedInChart(filter, def, getIsUsedInMetricId);
      });
    },
    [definitions, getIsUsedInMetricId],
  );

  return getChartDefinitionUsage;
};

export default useGetChartDefinitionUsage;
