import getSavedFiltersRef from './getSavedFiltersRef';
import getDefaultDatasetFiltersRef from './getDefaultDatasetFiltersRef';
import getLegacySavedFiltersRef from './getLegacySavedFiltersRef';

const filterDefinitions = {
  getSavedFiltersRef,
  getDefaultDatasetFiltersRef,
  getLegacySavedFiltersRef,
};

export default filterDefinitions;
