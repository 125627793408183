import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import RankingMatrixForm from './RankingMatrixForm';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import useRankingMatrixGroupByField from './useRankingMatrixGroupByField';
import saveRankingMatrix from './saveRankingMatrix';
import appRoutes, { buildGadgetShow } from '../../navigation/appRoutes';
import deleteRankingMatrix from './deleteRankingMatrix';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
const aguid = require('aguid');

const DEFAULT_SORT = {
  mode: 'alphabetical',
  order: 'asc',
} as VisualisationDefinitions.RankingMatrixSorting;

const RankingMatrixFormContainer = ({
  gadget,
  isGadgetBuilder,
  onChange,
}: {
  gadget?: VisualisationDefinitions.RankingMatrix;
  isGadgetBuilder?: boolean;
  onChange?: React.Dispatch<
    React.SetStateAction<undefined | VisualisationDefinition>
  >;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const navigate = useNavigate();

  // Base
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [id] = useState(gadget ? gadget.id : aguid());
  const [name, setName] = useState<string>(gadget ? gadget.name : '');
  const [description, setDescription] = useState<string>(
    gadget ? gadget.description : '',
  );

  // RankingMatrix specifics
  const [metrics, setMetrics] = useState<
    VisualisationDefinitions.RankingMatrixMetric[]
  >(gadget ? gadget.metrics : []);
  const { groupByField, groupingOptions, clearGrouping } =
    useRankingMatrixGroupByField(
      metrics,
      gadget ? gadget.groupByField : undefined,
    );
  const [sorting, setSorting] =
    useState<VisualisationDefinitions.RankingMatrixSorting>(
      gadget ? gadget.sorting : DEFAULT_SORT,
    );
  const [isValid, setIsValid] = useState<boolean>(false);
  const [draftGadget, setDraftGadget] = useState<
    VisualisationDefinitions.RankingMatrix | undefined
  >();

  useEffect(() => {
    if (metrics.length === 0) {
      setSorting(DEFAULT_SORT);
      clearGrouping();
    }
  }, [clearGrouping, metrics.length]);

  const updateMetric = useCallback(
    (newMetric: VisualisationDefinitions.RankingMatrixMetric) => {
      setMetrics((current) =>
        current.map((c) => {
          if (c.key === newMetric.key) {
            return newMetric;
          }
          return c;
        }),
      );
    },
    [],
  );

  const onMetricAdded = useCallback((selectedMetric: Metrics.Metric) => {
    const newMetric = {
      key: aguid(),
      metricId: selectedMetric.id,
      isRankingEnabled: false,
      isPercentageOfTotalEnabled: false,
    };

    setMetrics((current) => [...current, newMetric]);
  }, []);

  const removeMetric = useCallback(
    (removedMetric: VisualisationDefinitions.RankingMatrixMetric) => {
      setMetrics((current) =>
        current.filter((m) => m.metricId !== removedMetric.metricId),
      );
    },
    [],
  );

  useEffect(() => {
    if (name !== '' && metrics.length > 0 && !!groupByField) {
      setDraftGadget({
        type: 'RankingMatrix',
        id,
        name,
        description,
        metrics,
        sorting,
        groupByField,
      });
      setIsValid(true);
    } else {
      setDraftGadget(undefined);
      setIsValid(false);
    }
  }, [description, groupByField, id, metrics, name, sorting]);

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDescriptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    if (draftGadget && onChange) {
      onChange(draftGadget);
    }
  }, [draftGadget, onChange]);

  const onSave = useCallback(() => {
    if (!draftGadget) {
      return;
    }

    setIsLoading(true);
    saveRankingMatrix({
      accountId: selectedAccountId,
      rankingMatrix: draftGadget,
    }).then(() => {
      setIsLoading(false);
      navigate(buildGadgetShow(id, selectedPortal));
    });
  }, [selectedPortal, selectedAccountId, draftGadget, navigate, id]);

  const onSaveAs = useCallback(() => {
    const newId = aguid();
    if (!draftGadget) {
      return;
    }

    const newGadget = {
      ...draftGadget,
      id: newId,
    };

    setIsLoading(true);
    saveRankingMatrix({
      accountId: selectedAccountId,
      rankingMatrix: newGadget,
    }).then(() => {
      setIsLoading(false);
      navigate(buildGadgetShow(newId, selectedPortal));
    });
  }, [selectedPortal, draftGadget, navigate, selectedAccountId]);

  const onDelete = useCallback(() => {
    if (!draftGadget) {
      return;
    }

    setIsLoading(true);
    deleteRankingMatrix({
      accountId: selectedAccountId,
      rankingMatrix: draftGadget,
    }).then(() => {
      setIsLoading(false);
      navigate(appRoutes.loggedIn.gadgetBuilderNew);
      window.location.reload();
    });
  }, [draftGadget, navigate, selectedAccountId]);

  return (
    <RankingMatrixForm
      isGadgetBuilder={isGadgetBuilder}
      name={name}
      onNameChanged={onNameChanged}
      description={description}
      onDescriptionChanged={onDescriptionChanged}
      metrics={metrics}
      onMetricAdded={onMetricAdded}
      updateMetric={updateMetric}
      removeMetric={removeMetric}
      groupByField={groupByField}
      groupingOptions={groupingOptions}
      sorting={sorting}
      setSorting={setSorting}
      isValid={isValid}
      draftGadget={draftGadget}
      isLoading={isLoading}
      onSave={onSave}
      isEditing={!!gadget}
      onDelete={onDelete}
      onSaveAs={onSaveAs}
    />
  );
};

export default RankingMatrixFormContainer;
