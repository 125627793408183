import { useContext, useEffect, useState } from 'react';
import CurrentUserContext from '../contexts/CurrentUserContext';
import AccountPickerContext from '../contexts/AccountPickerContext';
import buildContentViewId from '../buildContentViewId';
import DashboardsContext from '../contexts/DashboardsContext';
import ReportsContext from '../contexts/ReportsContext';
import WallboardsContext from '../contexts/WallboardsContext';
import ScorecardsContext from '../contexts/ScorecardsContext';
import RolesContext from '../contexts/RolesContext';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import useHasAccess from './useHasAccess';
import useFavouritesOrder from './useFavouritesOrder';
import WorkSpacesContext from '../contexts/WorkSpacesContext';
import { PortalsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

export const sortFavourites = ({
  favourites,
  order,
}: {
  favourites: ContentView[];
  order: string[];
}): ContentView[] => {
  return favourites.sort((a, b) => {
    const aIndex = order.findIndex((id) => buildContentViewId(a) === id);
    const bIndex = order.findIndex((id) => buildContentViewId(b) === id);

    if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    } else if (aIndex < bIndex) {
      return -1;
    } else if (bIndex > aIndex) {
      return 1;
    } else {
      return 0;
    }
  });
};

const useFavourites = () => {
  const [favourites, setFavourites] = useState<ContentView[]>([]);
  const [rawFavourites, setRawFavourites] = useState<ContentView[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const currentUser = useContext(CurrentUserContext);
  const { availableReports } = useContext(ReportsContext);
  const { allDashboards } = useContext(DashboardsContext);
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const { accountRef } = useContext(AccountPickerContext);
  const { wallBoards } = useContext(WallboardsContext);
  const { scorecards } = useContext(ScorecardsContext);
  const { currentPermissions } = useContext(RolesContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const { isPortalsEnabled } = useContext(PortalsContext);
  const { favouritesOrder } = useFavouritesOrder();
  const hasAccess = useHasAccess();

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'test' ||
      !currentUser ||
      currentUser.isWallboardUser
    ) {
      return () => {};
    }

    const listener = accountRef
      .collection('users')
      .doc(impersonatorId || currentUser.id)
      .collection('favourites')
      .onSnapshot((snapshot) => {
        const data = [] as ContentView[];
        snapshot.docs.forEach((d) => {
          data.push({ ...d.data() } as ContentView);
        });
        setRawFavourites(data);
      });
    return () => {
      listener();
    };
  }, [accountRef, currentUser, impersonatorId]);

  useEffect(() => {
    const mappedFavorites = rawFavourites.map((r) => ({
      recent: r,
      resource: (() => {
        if (r.type === 'report') {
          return availableReports.find((report) => report.id === r.typeId);
        } else if (r.type === 'dashboard') {
          return allDashboards.find((d) => d.id === r.typeId);
        } else if (r.type === 'workspace' || r.type === 'targetsApp') {
          return allWorkSpaces.find((ws) => ws.id === r.typeId);
        } else if (r.type === 'wallboard') {
          return wallBoards.find((w) => w.id === r.typeId);
        } else if (r.type === 'scorecard') {
          return scorecards.find((s) => s.id === r.typeId);
        } else {
          return undefined;
        }
      })(),
    }));
    const visibleFavorites = mappedFavorites
      .filter((r) => {
        if (!r.resource) {
          return false;
        }

        if (isPortalsEnabled) {
          return true;
        }

        if (!r.resource.access) {
          return true;
        }

        if (r.recent.type === 'wallboard') {
          return true;
        }
        return hasAccess({
          resource: r.resource,
          access: r.resource.access,
          type: r.recent.type,
          typeId: r.recent.typeId,
        });
      })
      .map((r) => r.recent);
    setFavourites(
      sortFavourites({
        favourites: visibleFavorites,
        order: favouritesOrder,
      }),
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [
    currentPermissions,
    allDashboards,
    allWorkSpaces,
    availableReports,
    currentUser,
    favouritesOrder,
    rawFavourites,
    scorecards,
    wallBoards,
    hasAccess,
    isPortalsEnabled,
  ]);

  return {
    favourites,
    setFavourites,
    isLoading,
  };
};

export default useFavourites;
