import { useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../contexts/BaseViewsContext';
import isDefined from '../isDefined';
import _ from 'lodash';

export const getDrillDownFieldName = (
  drillDown: FixedFilter | undefined,
  baseViews: { [dataset: string]: FleetOps.BaseView | undefined },
): string => {
  const upCasedField = _.startCase(drillDown ? drillDown.field : '');

  const relevantViews = (() => {
    const baseViewsArr = Object.values(baseViews).filter(isDefined);
    if (!drillDown || !drillDown.dataset) {
      return baseViewsArr;
    }

    return baseViewsArr.filter(
      (baseView) => baseView.type === drillDown.dataset,
    );
  })();
  const relevantFields = relevantViews
    .map((v) => v.fields[drillDown ? drillDown.field : ''])
    .filter(isDefined)
    .filter((f) => f.nameAlias);
  if (relevantFields.length === 0) {
    return upCasedField;
  } else {
    const f = relevantFields[0];

    return f.nameAlias ? f.nameAlias : upCasedField;
  }
};

const useDrillDownFieldName = (drillDown?: FixedFilter): string => {
  const { baseViews } = useContext(BaseViewsContext);

  const [upCasedField] = useState(
    _.startCase(drillDown ? drillDown.field : ''),
  );
  const [name, setName] = useState<string>(upCasedField);

  useEffect(() => {
    setName(getDrillDownFieldName(drillDown, baseViews));
  }, [baseViews, drillDown, upCasedField]);

  return name;
};

export default useDrillDownFieldName;
