import React from 'react';

import Row from '../Common/Row';
import ShowOnMouseOverUnlessTour from 'components/ShowOnMouseOverUnlessTour';
import DateInput from '../DateInput';
import { DashboardGadgetFreshnessTooltip } from '../DatasetFreshness';

const DashboardGadgetBottomBar = ({
  onDatePickerOpen,
  onDatePickerClose,
  isBonusPeriodMode,
}: {
  isNarrow: boolean;
  onDatePickerOpen: () => void;
  onDatePickerClose: () => void;
  isForcedVisible: boolean;
  timeAgo?: string;
  isBonusPeriodMode?: boolean;
}) => (
  <div>
    <Row spaceBetween centerAlign>
      <div
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.stopPropagation();
        }}
      >
        <DateInput
          isDashboardCard
          isBonusPeriodMode={isBonusPeriodMode}
          onDatePickerOpen={onDatePickerOpen}
          onDatePickerClose={onDatePickerClose}
        />
      </div>
      <ShowOnMouseOverUnlessTour>
        <DashboardGadgetFreshnessTooltip />
      </ShowOnMouseOverUnlessTour>
    </Row>
  </div>
);

export default DashboardGadgetBottomBar;
