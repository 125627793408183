import React, { useContext, useEffect, useState } from 'react';
import Tooltip from '../Tooltip';
import Typography from 'kingpin/atoms/Typography';
import useDataTypesOnReportCanvas from '../Report/useDataTypesOnReportCanvas';
import { getDrillDownDescription } from '../FilterPlates/FilterPlate';
import { getDrillDownFieldName } from '../../hooks/useDrillDownFieldName';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import buildWeekStartsOnLabel from '../Report/buildWeekStartsOnLabel';
import AccountContext from '../../contexts/AccountContext';
import { InfoIconNoTooltip } from '../InfoIcon';
import filterPlateTypeCheckers from '../../types/filterPlateTypeCheckers';

import styled from 'styled-components';
import getIdentifier from '../../getIdentifier';
import { ReportFreshnessTooltipSection } from '../DatasetFreshness';

const Section = styled.div`
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const Heading = ({ children }: { children: string }) => {
  return (
    <div>
      <Typography.Body type="Body Bold" color={'white'}>
        {children}
      </Typography.Body>
    </div>
  );
};

const Body = ({ children }: { children: string }) => {
  return (
    <div>
      <Typography.Body type="Body 13" color={'white'}>
        {children}
      </Typography.Body>
    </div>
  );
};

const TooltipInner = ({
  usedDataTypes,
  scope,
  queryDate,
  reportingWeek,
}: {
  usedDataTypes: string[];
  scope: { description: string; key: string }[];
  queryDate: string;
  reportingWeek: string;
}) => (
  <div>
    <Section>
      <Heading>Datasets (Last Sync):</Heading>
      {usedDataTypes.map((dataset) => (
        <ReportFreshnessTooltipSection
          dataset={dataset}
          key={dataset}
          Body={Body}
        />
      ))}
    </Section>
    <Section>
      <Heading>Scope:</Heading>
      {scope.map((s) => (
        <Body key={s.key}>{s.description}</Body>
      ))}
    </Section>
    <Section>
      <Heading>Query date:</Heading>
      <Body>{queryDate}</Body>
    </Section>
    <Section>
      <Heading>Reporting Week:</Heading>
      <Body>{reportingWeek}</Body>
    </Section>
  </div>
);

const ReportInfoIcon = ({
  report,
  isSmall,
}: {
  report: ReportType;
  isSmall?: boolean;
}) => {
  const { baseViews } = useContext(BaseViewsContext);
  const { weekStartsOn } = useContext(AccountContext);
  const usedDataTypes = useDataTypesOnReportCanvas(report.canvas);

  const [scope, setScope] = useState<{ key: string; description: string }[]>(
    [],
  );
  const [queryDate] = useState<string>(report.dateField || 'date');
  const [reportingWeek] = useState<string>(
    buildWeekStartsOnLabel(report.weekStartsOnOverride || weekStartsOn),
  );

  useEffect(() => {
    setScope(
      report.scope
        .filter(filterPlateTypeCheckers.isFixed)
        .map((p) => p.fixedValue)
        .map((drillDown) =>
          getDrillDownDescription(
            drillDown,
            getDrillDownFieldName(drillDown, baseViews),
          ),
        )
        .map((description) => ({
          description,
          key: getIdentifier(),
        })),
    );
  }, [baseViews, report.scope]);

  return (
    <div style={{ marginLeft: -4 }}>
      <Tooltip
        content={
          <TooltipInner
            usedDataTypes={usedDataTypes}
            queryDate={queryDate}
            reportingWeek={reportingWeek}
            scope={scope}
          />
        }
        hideTooltipOnClick
      >
        <InfoIconNoTooltip testId={'report-info'} isSmall={isSmall} />
      </Tooltip>
    </div>
  );
};

export default ReportInfoIcon;
