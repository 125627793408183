import { useCallback, useContext } from 'react';
import portalTypeCheckers from '../types/portalTypeCheckers';
import STORE from '../store';
import { PortalsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import AccountPickerContext from '../contexts/AccountPickerContext';

const useAddNewReportToCurrentPortal = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { selectedPortal, locallyUpdateSelectedPortal } =
    useContext(PortalsContext);

  const addNewReportToCurrentPortal = useCallback(
    async (newReportId: string) => {
      if (!selectedPortal || portalTypeCheckers.isAdminPortal(selectedPortal)) {
        return;
      }

      const newPortal: EngagementPortal | ExecutivePortal = {
        ...selectedPortal,
        reportIds: [...selectedPortal.reportIds, newReportId],
      };

      locallyUpdateSelectedPortal(newPortal);

      if (portalTypeCheckers.isEngagementPortal(newPortal)) {
        return STORE.contentDistributions
          .getEngagementPortalsRef({ accountId: selectedAccountId })
          .doc(newPortal.id)
          .set(newPortal);
      } else {
        return STORE.contentDistributions
          .getExecutivePortalsRef({ accountId: selectedAccountId })
          .doc(newPortal.id)
          .set(newPortal);
      }
    },
    [selectedAccountId, selectedPortal, locallyUpdateSelectedPortal],
  );

  return addNewReportToCurrentPortal;
};

export default useAddNewReportToCurrentPortal;
