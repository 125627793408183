import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const DATASET_FILTER_DOC = 'defaultDatasetFilters';
export const CURRENT_DATASET_FILTER_VERSION = 'v1';

const defaultDatasetFilterConverter = {
  toFirestore(
    databaseFilter: DefaultDatasetFilter,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...databaseFilter });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<DefaultDatasetFilter>,
    options: firebase.firestore.SnapshotOptions,
  ): DefaultDatasetFilter {
    const data = snapshot.data(options);
    return data;
  },
};

/**
 * Path: accounts/:accountId/filterDefinitions/:defaultDatasetFilters/v1/:dataset
 */
const getDefaultDatasetFiltersRef = ({ accountId }: { accountId: string }) => {
  return firebase
    .firestore()
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.FILTER_DEFINITIONS_COLLECTION)
    .doc(DATASET_FILTER_DOC)
    .collection(CURRENT_DATASET_FILTER_VERSION)
    .withConverter(defaultDatasetFilterConverter);
};

export default getDefaultDatasetFiltersRef;
