import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'kingpin/atoms/Button';

import Row from '../../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import { getMetricLink } from '../../../../screens/DataManager/AllSets/routes';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import LinkSrc from './images/Link.svg';
import useCanEditMetric from '../useCanEditMetric';
import PopupStyles from './PopupStyles';
import { PortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const LinkIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 8px;
`;

const MetricName = ({ metric }: { metric: Metrics.Metric }) => {
  const { selectedPortal } = useContext(PortalsContext);
  const canEditMetric = useCanEditMetric(metric);
  if (
    canEditMetric &&
    (metricTypeCheckers.isNormalMetric(metric) ||
      metricTypeCheckers.isCompoundMetric(metric))
  ) {
    return (
      <Link
        to={getMetricLink({ metric, portal: selectedPortal })}
        target="_blank"
      >
        <Row centerAlign>
          <Typography.Body type="Body 12">{metric.name}</Typography.Body>
          <LinkIcon src={LinkSrc} />
        </Row>
      </Link>
    );
  }

  return <Typography.Body type="Body 12">{metric.name}</Typography.Body>;
};

const BottomBar = ({
  onMetricConfirmed,
  currentSelectedMetric,
  close,
}: {
  onMetricConfirmed: () => void;
  currentSelectedMetric?: Metrics.Metric;
  close: () => void;
}) => (
  <PopupStyles.PopupContent.BottomBarDiv centerAlign spaceBetween>
    <Button onClick={close} label="Cancel" type="Tertiary" size="Small" />
    {!!currentSelectedMetric &&
      !metricTypeCheckers.isSingleUseMetric(currentSelectedMetric) && (
        <Row centerAlign>
          <Row centerAlign style={{ marginRight: 16 }}>
            <div style={{ marginRight: 4, display: 'flex' }}>
              <Typography.Body type="Body 12">Selected Metric:</Typography.Body>
            </div>
            <MetricName metric={currentSelectedMetric} />
          </Row>
          <Button
            label="Confirm Selection"
            onClick={onMetricConfirmed}
            type="Primary"
            size="Small"
          />
        </Row>
      )}
  </PopupStyles.PopupContent.BottomBarDiv>
);

export default BottomBar;
