import React from 'react';
import Card from 'components/Common/Card';
import FilterPlates from 'components/MetricsInfoButton/MetricInfoPopup/FilterPlates';
import Typography from 'kingpin/atoms/Typography';

const DetailsCard = ({
  filter,
  hasPadding,
  hasBorder,
}: {
  filter: SavedFilter | DefaultDatasetFilter;
  hasPadding?: boolean;
  hasBorder?: boolean;
}) => {
  return (
    <Card
      hideBorders={!hasBorder}
      style={{ padding: hasPadding ? '24px' : '12px' }}
    >
      <div style={{ marginBottom: '8px' }}>
        <Typography.Header type="H5">Filter Details</Typography.Header>
      </div>

      <FilterPlates filters={filter.filterInput} />
    </Card>
  );
};

export default DetailsCard;
