import React, { useCallback, useContext } from 'react';
import AddFilterPlateButton from './AddFilterPlateButton';
import usePopup from '../../hooks/usePopup';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import ReportContext from '../../contexts/ReportContext';
import PopupContext from '../../contexts/PopupContext';

const AddFilterPlateButtonContainer = ({
  isScope,
  isReport,
  onManualFilterChanged,
  onOpened,
  onFilterAdded,
  testIdPrefix,
  label,
  isDisabled,
  isDateScopeDisabled,
}: {
  isScope: boolean;
  isReport?: boolean;
  onManualFilterChanged?: () => void;
  onOpened?: () => void;
  onFilterAdded?: () => void;
  testIdPrefix?: string;
  label?: string;
  isDisabled?: boolean;
  isDateScopeDisabled?: boolean;
}) => {
  const { isOpen, open: openPopup, close } = usePopup();
  const { trackEvent } = useContext(AnalyticsContext);
  const { isOpen: isPopupReportOpen } = useContext(PopupContext);
  const reportContext = useContext(ReportContext);

  const open = useCallback(() => {
    if (
      isReport &&
      reportContext &&
      reportContext.report &&
      !isPopupReportOpen
    ) {
      trackEvent('Report - Add Filter Clicked', {
        reportName: reportContext.report.name,
        reportId: reportContext.report.id,
      });
    }
    openPopup();
    if (onOpened) {
      onOpened();
    }
  }, [
    isReport,
    reportContext,
    isPopupReportOpen,
    openPopup,
    onOpened,
    trackEvent,
  ]);

  const isShowingLabel = true;

  return (
    <AddFilterPlateButton
      isScope={isScope}
      isOpen={isOpen}
      open={open}
      close={close}
      isShowingLabel={isShowingLabel}
      onManualFilterChanged={onManualFilterChanged}
      onFilterAdded={onFilterAdded}
      testIdPrefix={testIdPrefix}
      label={label}
      isDisabled={isDisabled}
      isDateScopeDisabled={isDateScopeDisabled}
    />
  );
};

export default AddFilterPlateButtonContainer;
