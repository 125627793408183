import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UsersSettingsContext from 'contexts/UsersSettingsContext';
import Loading from 'components/Loading';
import UsersContext from 'contexts/UsersContext';
import useConfiguration from 'screens/Settings/SsoConfigurations/Microsoft/hooks/useConfiguration';
import FlexCentered from 'components/Common/FlexCentered';

import userTypeCheckers from '../SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import useUsedSeats from './useUsedSeats';
import { ProductTierContext } from '../SplashScreenProviders/UserAndAccountProviders/ProductTierProvider';

const UsersSettingsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { userId } = useParams<{
    userId?: string;
  }>();
  const usedSeats = useUsedSeats();
  const { config, isLoading: isLoadingConfig } = useConfiguration();
  const { allUsers, isLoading } = useContext(UsersContext);
  const { seatLimitation, productTier } = useContext(ProductTierContext);
  const [users, setUsers] = useState<
    (UserManagement.PendingUser | UserManagement.SignedUpUser)[]
  >([]);
  const [selectedUser, setSelectedUser] = useState<
    UserManagement.PendingUser | UserManagement.SignedUpUser | undefined
  >(undefined);

  const getHasSeatsRemaining = useCallback(() => {
    if (productTier !== 'Exec') {
      return true;
    }

    if (seatLimitation === undefined || seatLimitation.isLimited === false) {
      return true;
    }

    return usedSeats < seatLimitation.allowedSeats;
  }, [productTier, seatLimitation, usedSeats]);
  const [hasSeatsRemaining, setHasSeatsRemaining] = useState<boolean>(
    getHasSeatsRemaining(),
  );
  useEffect(() => {
    setHasSeatsRemaining(getHasSeatsRemaining());
  }, [getHasSeatsRemaining]);

  useEffect(() => {
    const visibleUsers = allUsers
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .filter((u) => !u.isDeleted);

    const signedUpUsers = visibleUsers
      .filter(userTypeCheckers.isSignedUpUser)
      .sort((a, b) => {
        return a.displayName
          .toLowerCase()
          .trim()
          .localeCompare(b.displayName.toLowerCase().trim());
      });

    const pendingUsers = visibleUsers
      .filter(userTypeCheckers.isPendingUser)
      .sort((a, b) => {
        return (a.email || '')
          .toLowerCase()
          .trim()
          .localeCompare((b.email || '').toLowerCase().trim());
      });

    setUsers([...signedUpUsers, ...pendingUsers]);
  }, [allUsers]);

  useEffect(() => {
    const maybeUser = users.find((u) => u.id === userId);
    setSelectedUser(maybeUser);
  }, [users, userId]);

  if (isLoadingConfig) {
    return (
      <FlexCentered style={{ height: '100%', width: '100%' }}>
        <Loading />
      </FlexCentered>
    );
  }

  const maxSeats =
    seatLimitation === undefined
      ? undefined
      : seatLimitation.isLimited
        ? seatLimitation.allowedSeats
        : undefined;

  return (
    <UsersSettingsContext.Provider
      value={{
        selectedUser,
        users,
        isLoading,
        isSsoAccount: !!config && config.status === 'active',
        isLoadingIsSsoAccount: isLoadingConfig,
        isSeatsLimited: productTier === 'Exec' && seatLimitation.isLimited,
        maxSeats,
        usedSeats,
        hasSeatsRemaining,
      }}
    >
      {children}
    </UsersSettingsContext.Provider>
  );
};

export default UsersSettingsProvider;
