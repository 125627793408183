import { useCallback, useContext } from 'react';
import AnalyticsContext from 'contexts/AnalyticsContext';
import useSetDrillDowns from './useSetDrillDowns';
import useIsReportOrDashboardCard from './useIsReportOrDashboardCard';

const useOnRemoved = ({
  isScope,
  drillDown,
}: {
  isScope: boolean;
  drillDown?: FixedFilter;
}) => {
  const { isReport } = useIsReportOrDashboardCard();
  const { trackEvent } = useContext(AnalyticsContext);
  const setDrillDowns = useSetDrillDowns({
    isScope,
  });

  const onRemoved = useCallback(() => {
    if (!!drillDown) {
      if (isReport && isScope) {
        trackEvent('Report - Edit - Scope Changed');
      }
      setDrillDowns((currentDrillDowns) =>
        currentDrillDowns.filter((d) => d.id !== drillDown.id),
      );
    }
  }, [drillDown, isReport, isScope, setDrillDowns, trackEvent]);

  return onRemoved;
};

export default useOnRemoved;
