import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import STORE from '../../../store';

type DefaultDatasetFiltersLookup = {
  [dataset: string]: DefaultDatasetFilter | undefined;
};

export const DefaultDatasetFiltersContext = React.createContext<{
  defaultDatasetFilters: DefaultDatasetFilter[];
  defaultDatasetFiltersLookup: DefaultDatasetFiltersLookup;
}>({
  defaultDatasetFilters: [],
  defaultDatasetFiltersLookup: {},
});

const useDefaultDatasetFilters = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [defaultDatasetFilters, setDefaultDatasetFilters] = useState<
    DefaultDatasetFilter[]
  >([]);

  useEffect(() => {
    let isActive = true;
    const l = STORE.filterDefinitions
      .getDefaultDatasetFiltersRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        if (!isActive) {
          return;
        }
        const filters: DefaultDatasetFilter[] = [];
        snapshot.docs.forEach((d) => filters.push(d.data()));
        setDefaultDatasetFilters(filters);
      });

    return () => {
      isActive = false;
      l();
    };
  }, [selectedAccountId]);

  return defaultDatasetFilters;
};

const DefaultDatasetFiltersProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const defaultDatasetFilters = useDefaultDatasetFilters();
  const defaultDatasetFiltersLookup = useMemo(() => {
    const lookup: DefaultDatasetFiltersLookup = {};
    defaultDatasetFilters.forEach((filter) => {
      lookup[filter.dataset] = filter;
    });
    return lookup;
  }, [defaultDatasetFilters]);

  return (
    <DefaultDatasetFiltersContext.Provider
      value={{
        defaultDatasetFilters,
        defaultDatasetFiltersLookup,
      }}
    >
      {children}
    </DefaultDatasetFiltersContext.Provider>
  );
};

export default DefaultDatasetFiltersProvider;
