import React, { ChangeEvent, useContext, useState } from 'react';
import NewQuickFilterForm from './NewQuickFilterForm';
import aguid from 'aguid';

import FilterPlatesContext from '../../../../../contexts/FilterPlatesContext';
import BoardContext from '../../../../../contexts/BoardContext';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';
import FilterPlatesProvider from '../../../../../contextProviders/FilterPlatesProvider';
import STORE from '../../../../../store';

const NewQuickFilterFormContainer = ({ close }: { close: () => void }) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { dataTypes } = useContext(FilterPlatesContext);
  const { board } = useContext(BoardContext);
  const [name, setName] = useState<string>('');
  const [drillDowns, setDrillDowns] = useState<FilterPlateType[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const isValid = drillDowns.length !== 0;

  const onSave = () => {
    if (!isValid) {
      alert('Please input at least one filter');
    }

    const filter: LegacySavedFilter = {
      version: '2',
      id: aguid(),
      scope: [],
      drillDowns,
      source: `${board.dataType} - board: ${board.id}`,
      name,
      dateField: 'date',
    };

    setIsSaving(true);
    STORE.filterDefinitions
      .getLegacySavedFiltersRef({ accountId: selectedAccountId })
      .doc(filter.id)
      .set(filter)
      .then(() => {
        setIsSaving(false);
        close();
      });
  };

  return (
    <FilterPlatesProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      dataTypes={dataTypes}
    >
      <NewQuickFilterForm
        name={name}
        onNameChanged={onNameChanged}
        close={close}
        onSave={onSave}
        isSaving={isSaving}
        isValid={isValid}
      />
    </FilterPlatesProvider>
  );
};

export default NewQuickFilterFormContainer;
