import { useCallback, useContext } from 'react';
import useSetDrillDowns from './useSetDrillDowns';
import useIsReportOrDashboardCard from './useIsReportOrDashboardCard';
import AnalyticsContext from 'contexts/AnalyticsContext';

const useOnSavedFilterAdded = ({
  close,
  drillDown,
  isScope,
}: {
  close: () => void;
  drillDown?: FixedFilter;
  isScope: boolean;
}) => {
  const { isDashboardGadget, isReport } = useIsReportOrDashboardCard();
  const setDrillDowns = useSetDrillDowns({ isScope });
  const { trackEvent } = useContext(AnalyticsContext);

  const onSavedFilterAdded = useCallback(
    (df: SavedFilter) => {
      if (isReport) {
        trackEvent('Report - Filter Changed (Dataset Filter)');
      }
      if (isDashboardGadget) {
        trackEvent('Dashboard Card - Edit - Filter Changed (Dataset Filter)');
      }

      const filterPlate: SavedFilterPlate = {
        id: drillDown ? drillDown.id : df.id,
        type: 'Dataset',
        datasetFilterId: df.id,
      };
      if (!drillDown) {
        setDrillDowns((currentDrillDowns) => [
          ...currentDrillDowns,
          filterPlate,
        ]);
      } else {
        setDrillDowns((currentDrillDowns) =>
          currentDrillDowns.map((d) => {
            if (d.id === filterPlate.id) {
              return filterPlate;
            } else {
              return d;
            }
          }),
        );
      }

      close();
    },
    [close, drillDown, isDashboardGadget, isReport, setDrillDowns, trackEvent],
  );

  return onSavedFilterAdded;
};

export default useOnSavedFilterAdded;
