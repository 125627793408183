import React, { useCallback, useContext, useEffect, useState } from 'react';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from 'permissionDefinitions';
import visTypeCheckers from 'types/visTypeCheckers';
import { buildPaceSettings } from 'navigation/appRoutes';

import NavItem from './NavItem';
import ChartDefinitionsContext from '../../contexts/ChartDefinitionsContext';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const PaceSettingsNavItem = () => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <NavItem
      to={buildPaceSettings(selectedPortal)}
      icon={'navigation-goals'}
      label="Pace Settings"
    />
  );
};

const Gate = () => {
  const { definitions } = useContext(ChartDefinitionsContext);
  const getHasPace = useCallback(() => {
    return definitions.some(visTypeCheckers.isPaceMatrix);
  }, [definitions]);
  const [hasPace, setHasPace] = useState<boolean>(() => getHasPace());
  useEffect(() => {
    setHasPace(getHasPace());
  }, [getHasPace]);

  if (!hasPace) {
    return null;
  }

  return (
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.DATA_MANAGEMENT.GADGETS}
    >
      <PaceSettingsNavItem />
    </PermissionGates.Has>
  );
};

export default Gate;
