import { useCallback, useContext, useEffect, useState } from 'react';
import GqlClientContext from '../../../../contexts/GqlClientContext';
import getDatasetInfo from './getDatasetInfo';
import getIdentifier from '../../../../getIdentifier';
import useLockedDebouncedEffect from '../../../../hooks/useLockedDebouncedEffect';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

interface DatasetFreshnessInfoArgs {
  refreshKey: string;
  client: ApolloClient<NormalizedCacheObject>;
}

const useDatasetFreshnessInfo = () => {
  const { client } = useContext(GqlClientContext);
  const [refreshKey, setRefreshKey] = useState<string>(() => getIdentifier());
  const [data, setData] = useState<DatasetFreshnessInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [args, setArgs] = useState<DatasetFreshnessInfoArgs>({
    client,
    refreshKey,
  });

  useEffect(() => {
    setArgs({
      refreshKey,
      client,
    });
  }, [client, refreshKey]);

  const responseHandler = useCallback(
    (info: { name: string; latestRecordTimeStamp: string }[]) => {
      setData(
        info.map((d) => ({
          name: d.name,
          latestRecordTimeStampUTC: d.latestRecordTimeStamp,
        })),
      );
      setIsLoading(false);
    },
    [],
  );
  const callback = useCallback(({ client }: DatasetFreshnessInfoArgs) => {
    setIsLoading(true);
    return getDatasetInfo(client);
  }, []);

  useLockedDebouncedEffect({
    args,
    responseHandler,
    callback,
  });

  const refresh = useCallback(() => {
    setRefreshKey(getIdentifier(undefined, true));
  }, []);

  return { data, isLoading, refresh };
};

export default useDatasetFreshnessInfo;
