import { useContext, useEffect, useState } from 'react';
import SlideOutContext from '../contexts/PerformanceBoardSlideOutContext';
import AccountContext from '../../../contexts/AccountContext';

const usePerformanceFieldsOptions = ({
  initialSelectedField,
}: {
  initialSelectedField?: string;
}) => {
  const { isDemoAccount } = useContext(AccountContext);
  const { performanceFields } = useContext(SlideOutContext);
  const [fieldsOptions, setFieldsOptions] = useState<DropdownOption[]>([]);
  const [selectedField, setSelectedField] = useState<string | undefined>(
    initialSelectedField || undefined,
  );
  const [isPerformanceFieldRemoved, setIsPerformanceFieldRemoved] =
    useState<boolean>(false);

  useEffect(() => {
    // when field is no longer a performance field - setIsPerformanceFieldRemoved to true, but if we pick a selectedField - continue flow
    if (
      initialSelectedField &&
      !performanceFields.includes(initialSelectedField) &&
      !isDemoAccount
    ) {
      setIsPerformanceFieldRemoved(true);
      setSelectedField(undefined);
    } else {
      setIsPerformanceFieldRemoved(false);
    }

    const newOptions = performanceFields.map((field) => ({
      label: field,
      value: field,
      isSelected: selectedField === field,
      onSelected: () => {
        setSelectedField(field);
      },
    }));

    setFieldsOptions(newOptions);
  }, [initialSelectedField, isDemoAccount, performanceFields, selectedField]);

  return {
    fieldsOptions,
    selectedField,
    isPerformanceFieldRemoved,
  };
};

export default usePerformanceFieldsOptions;
