import React, { useContext } from 'react';
import { PortalsContext } from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import { CurrentUserContentSettingsContext } from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/CurrentUserContentSettingsProvider';

import LegacyMainNav from './LegacyMainNav';
import MainNav from './MainNav';
import EngagementModeNav from './EngagementModeNav';

const MainNavContainer = () => {
  const { contentSettings } = useContext(CurrentUserContentSettingsContext);

  const { isPortalsEnabled } = useContext(PortalsContext);
  if (!isPortalsEnabled) {
    return <LegacyMainNav />;
  }

  if (contentSettings.mode === 'engagement') {
    return <EngagementModeNav />;
  }

  return <MainNav />;
};

export default MainNavContainer;
