import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import PerformanceBoardSettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import useGetDatasetLabel from '../../../../Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';

import FitCellContents from './FitCellContents';
import SizeSettingsLayout from './SizeSettingsLayout';
import FitGridWidth from './FitGridWidth';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';
import STORE from '../../../../../store';
import withoutNulls from '../../../../../api/search/withoutNulls';
import getTimeStamp from '../../../../../getTimeStamp';

const BoardColumnSizeSettingsContainer = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { board, hasUnsavedChanges, setHasUnsavedChanges } = useContext(
    PerformanceBoardSettingsContext,
  );
  const [columnSizeStrategy, setColumnSizeStrategy] = useState<
    PerformanceBoardTypes.ColumnSizeStrategies.Strategy | undefined
  >(board.columnSizeStrategy);

  const getDatasetLabel = useGetDatasetLabel();

  useEffect(() => {
    setHasUnsavedChanges(
      !_.isEqual(columnSizeStrategy, board.columnSizeStrategy),
    );
  }, [board.columnSizeStrategy, columnSizeStrategy, setHasUnsavedChanges]);

  const onSave = useCallback(() => {
    STORE.contentDistributions
      .getPerformanceBoardsRef({
        accountId: selectedAccountId,
      })
      .doc(board.id)
      .set({
        ...board,
        columnSizeStrategy: columnSizeStrategy
          ? withoutNulls(columnSizeStrategy)
          : undefined,
        updatedOn: getTimeStamp(),
      });
  }, [board, columnSizeStrategy, selectedAccountId]);

  let content: JSX.Element | JSX.Element[] | null = null;
  if (columnSizeStrategy && columnSizeStrategy.type === 'fitCellContents') {
    content = (
      <FitCellContents
        columnSizeStrategy={columnSizeStrategy}
        setColumnSizeStrategy={setColumnSizeStrategy}
      />
    );
  } else if (columnSizeStrategy && columnSizeStrategy.type === 'fitGridWidth') {
    content = (
      <FitGridWidth
        columnSizeStrategy={columnSizeStrategy}
        setColumnSizeStrategy={setColumnSizeStrategy}
      />
    );
  }

  return (
    <SizeSettingsLayout
      onSave={onSave}
      hasUnsavedChanges={hasUnsavedChanges}
      datasetLabel={getDatasetLabel(board.dataType)}
      columnSizeStrategy={columnSizeStrategy}
      setColumnSizeStrategy={setColumnSizeStrategy}
      children={content}
    />
  );
};

export default BoardColumnSizeSettingsContainer;
